import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => {
  return {
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      // p: 4,
    },
    screen: {
      textAlign: "center",
      maxWidth: "420px",
      maxHeight: "600px",
      mx: "auto",
      my: "10%",
      // [theme.breakpoints.('xs')]: {
      //   height: '100%',
      //   width: "100%"
      // }
    }
  }
});