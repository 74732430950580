
// Otis Admin PRO React layouts
import Engager from "layouts/dashboards/analytics/engager";

// @mui icons
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PostAddIcon from '@mui/icons-material/PostAdd';
import InfoIcon from '@mui/icons-material/Info';
import HistoryIcon from '@mui/icons-material/History';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import GradingIcon from '@mui/icons-material/Grading';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined'


// Images
import EngagerProfile from "layouts/pages/EngagerDashboard/EngagerProfile";
import EngagementPosting from "layouts/pages/EngagerDashboard/EngagementPosting";
import CreateSubUser from "layouts/pages/EngagerDashboard/CreateSubUser";
import ProfileSearch from "layouts/pages/EngagerDashboard/ProfileSearch";
import EngagementList from "layouts/pages/EngagerDashboard/EngagementStage/EngagementList";
import Searchable from "layouts/pages/EngagerDashboard/EngagementStage/Searchable";
import ClosedEngagements from "layouts/pages/EngagerDashboard/ClosedEngagements";
import ManagePlans from "layouts/pages/AdminDashboard/MasterData/ManagePlans";

import ContractManagement from "layouts/pages/EngagerDashboard/ContractManagement";
import SubUserList from "layouts/pages/EngagerDashboard/SubUserList";
import EngagementHistory from "layouts/pages/EngagerDashboard/EngagementHistory";
import ComingSoon from "components/ComingSoon";
const routes = [
  {
    type: "route",
    name: "Dashboard",
    key: "dashboard",
    route: "/employer/dashboard",
    icon: <DashboardIcon />,
    component: process.env.REACT_APP_STAGE == "Prod" || process.env.REACT_APP_STAGE == "Dev" ? <Engager /> : <ComingSoon/>
    // component: <Engager />,
  },
  {
    type: "route",
    name: "Profile",
    key: "my-profile",
    route: "/employer/profile",
    icon: <AccountBoxOutlinedIcon />,
    component: <EngagerProfile />,
  },
  {
    type:"route",
    name:"Top-UP",
    key: "Top-Up",
    route:"/empolyer/plan-details",
    icon:<CurrencyRupeeOutlinedIcon/>,
    component:<ManagePlans/>
  },
  {
    type: "route",
    name: "Create Sub-Users",
    key: "create-sub-users",
    route: "/employer/create-sub-users",
    icon: <AddCircleOutlineIcon />,
    component: <CreateSubUser />,
  },
  {
    type: "route",
    name: "Sub-Users List",
    key: "sub-users-list",
    route: "/employer/sub-users-list",
    icon: <SupervisorAccountIcon />,
    component: <SubUserList />,
  },
  {
    type: "route",
    name: "Engagement Posting",
    key: "engagement-posting",
    route: "/employer/engagement-posting",
    icon: <PostAddIcon />,
    component: <EngagementPosting />,
  },
  // {
  //   type: "route",
  //   name: "Profile Search",
  //   key: "profile-search",
  //   route: "/employer/profile-search",
  //   component: <ProfileSearch />,
  // },

  {
    type: "collapse",
    name: "Engagement Details",
    key: "engagement-details",
    icon: <InfoIcon />,
    route: "/employer/engagement-details",
    collapse: [
      {
        name: "Current Engagements",
        key: "engagements",
        route: "/employer/engagement-details/engagements",
        component: <EngagementList />,
      },
      // {
      //   name: "Giglers Applied",
      //   key: "applied",
      //   route: "/employer/engagement-details/engagement/applied",
      //   component: <Applied />,
      // },
      {
        name: "Profile Search",
        key: "search",
        route: "/employer/engagement-details/engagement/search",
        component: <Searchable />,
        icon: <PersonSearchIcon />,

      },
      // {
      //   name: "Gigler Short-Listed",
      //   key: "short-listed",
      //   route: "/employer/engagement-details/engagement/short-listed",
      //   component: <ShortListed />,
      // },
      // {
      //   name: "Gigler Selected",
      //   key: "selected",
      //   route: "/employer/engagement-details/engagement/selected",
      //   component: <Selected />,
      // },
    ]
  },
  {
    type: "route",
    name: "Contract Management",
    key: "contract-management",
    route: "/employer/contract-management",
    icon: <ManageAccountsIcon />,
    component: <ContractManagement />,
  },

  {
    type: "route",
    name: "Engagement History",
    key: "engagement-history",
    route: "/employer/engagement-history",
    icon: <HistoryIcon />,
    component: <EngagementHistory />,
  },
  {
    type: "route",
    name: "Feedback",
    key: "closed-engagements",
    route: "/employer/closed-engagements",
    icon: <GradingIcon />,
    component: <ClosedEngagements />,
  },
  {
    type: "route",
    name: "Global Search",
    key: "global-search",
    route: "/employer/profile-search",
    icon: <PersonSearchIcon />,
    component: <ProfileSearch />,
  },
];

export default routes;
