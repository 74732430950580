import { Card, CardActions, CardContent, Divider, Modal } from '@mui/material'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useContext, useEffect, useState } from 'react'
import api from 'services/api'
import { UserContext } from "context/UserProvider";
import { useStyles } from './styles'
import MDBox from 'components/MDBox'
import { SnackbarContext } from 'context/SnackbarContext'
import EngagementDetails from 'components/EngagementDetails'

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const OpenEngagements = () => {

  const navigate = useNavigate();
  const classes = useStyles();
  const { userInfo } = useContext(UserContext);
  const { setSnack } = useContext(SnackbarContext);
  const [show, setShow] = useState(true)
  const handleBack = () => setShow(!show)
  const [engDetails, setEngDetails] = useState()
  const [engagements, setEngagements] = useState()

  const handleOpen = (row) => {
    console.log("eng details......", row)
    navigate(`/engagement/${row?.engagementId}`);
    // setShow(!show);
    // setEngDetails(row)
  }
  useEffect(() => {
    const id = userInfo?.id
    api.userInfo.getEngagements(id)
      .then((data) => {
        if (data?.data?.result?.data.length <= 0) {
          setEngagements("0")
        } else {
          console.log("open engagements", data)
          const list = data?.data?.result?.data[0]?.engagements
          if (list.length <= 0) {
            setEngagements("0");
          } else {
            setEngagements(list);
          }
        }
      })
  }, [])

  const handleApply = (row) => {
    const apply = {
      engagementId: row.engagementId,
      engagerId: row.engagerId,
      giglerId: userInfo.id,
      status: 'open',
      applied: true,
      shortListed: false,
      selected: false,
      createdBy: row.engagerName,
      updatedBy: row.engagerName,
      source: 'application',
      active: true
    }
    api.engagementProcess.create(apply)
      .then((data) => {
        handleClose()
        console.log("engagementProcess success", data)
        if (data) {
          setSnack({
            message: `Applied to engagement successfully with`,
            open: true,
            type: 'success',
          });
        }
        else {
          setSnack({
            message: `Error occurred while applying `,
            open: true,
            type: 'Error',
          });
        }
      })
  }
  const formatDate = (string) => {
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(string).toLocaleDateString([], options);
  }

  function sliceText(Title,maxLength){
    if(Title.length <= maxLength){
        return Title;
    }else{
      return Title.slice(0,maxLength-3) + '...';
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {show &&
        <MDBox>
          {engagements == "0" ?
            (
              <Card sx={{ minWidth: "600px", minHeight: "600px", px: "10px", py: "10px" }}>
                <MDTypography variant="h6"> No Engagements matching your profile </MDTypography>
              </Card>
            ) :
            (
              engagements?.map((row) => (
                // <Card sx={{ minWidth: 275, mt: "20px", px: "10px", py: "10px" }}>
                //   <CardContent>
                //     <MDTypography variant="h6" color="text.secondary" gutterBottom>
                //       {row?.engagementTitle}
                //     </MDTypography>
                //     <MDTypography variant="button" color="text.secondary">
                //       {row?.engagementDescription}
                //     </MDTypography>
                //     <MDTypography variant="body2">
                //       Domain : &nbsp;
                //       {row?.domain}
                //     </MDTypography>
                //   </CardContent>
                //   <CardActions>
                //     <MDButton size="small" color="dark" variant="outlined"
                //       onClick={() => handleOpen(row)}>View More</MDButton>
                //   </CardActions>
                // </Card>
                <Card sx={{ my: "10px" }}>
                  <MDBox px={2} py={3}>
                    <MDBox className={classes.box}>
                      {/* <MDTypography variant="subtitle1" color="info" fontWeight="medium" textTransform="capitalize" className={classes.engId}>
                        {row?.engagementId}
                      </MDTypography> */}
                      <Tooltip title={row.engagementTitle} placement='top' cursor='pointer'>
                      <MDTypography variant="subtitle1" fontWeight="medium" textTransform="capitalize" className={classes.engTitle} >
                        {/* {`-`}&nbsp;&nbsp;&nbsp; */}
                        {sliceText(row?.engagementTitle,10)} 
                      </MDTypography>
                      </Tooltip>
                    </MDBox>
                    <MDTypography variant="body2" textTransform="capitalize" className={classes.orgName}>
                      {row?.organizationName}
                    </MDTypography>
                    <MDBox className={classes.box}>
                      {/* <MDTypography variant="button" color="text" className={classes.icon}><AccountBalanceWalletIcon fontSize="string" /></MDTypography>
                      <MDTypography variant="button" textTransform="capitalize" className={classes.txt} >
                        {row?.indicativeBudget}(INR)
                      </MDTypography> */}
                      <MDTypography variant="button" color="text" className={classes.icon}><LocationOnIcon fontSize="string" /></MDTypography>
                      <MDTypography variant="button" textTransform="capitalize" className={classes.txt} >
                        {row?.location ? row?.location : '-'}
                      </MDTypography>
                    </MDBox>
                    <MDBox className={classes.box}>
                      <MDBox>
                        <MDTypography variant="button" color="text" className={classes.icon}>Posted :</MDTypography>
                        <MDTypography variant="button" color="dark" fontWeight="medium" className={classes.txt}>&nbsp;{formatDate(row?.createdAt)} </MDTypography>
                      </MDBox>
                      <Divider orientation="vertical" variant='string' color="dark" sx={{ color: "dark" }} />
                      <MDBox>
                        <MDTypography variant="button" color="text" className={classes.icon}>Billing :</MDTypography>
                        <MDTypography variant="button" color="dark" fontWeight="medium" className={classes.txt}>&nbsp;{row?.billing}
                        </MDTypography>
                      </MDBox>
                      <Divider orientation="vertical" variant='string' color="dark" sx={{ color: "dark" }} />
                      <MDBox>
                        <MDTypography variant="button" color="text" className={classes.icon}>No. of Positions :</MDTypography>
                        <MDTypography variant="button" color="dark" fontWeight="medium" className={classes.txt}>&nbsp;{row?.numberOfPositions}
                        </MDTypography>
                      </MDBox>
                      <MDBox>
                        <MDTypography variant="button" color="text" className={classes.icon}>Duration:</MDTypography>
                        <MDTypography variant="button" color="dark" fontWeight="medium" className={classes.txt}>&nbsp;{row?.engagementDuration}&nbsp;(Days)
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <CardActions>
                      <MDButton size="small" color="dark" variant="outlined"
                        onClick={() => handleOpen(row)}>View</MDButton>
                    </CardActions>
                  </MDBox>
                </Card>
              ))
            )
          }
        </MDBox>
      }
      {!show &&
        <MDBox>
          <EngagementDetails engDetails={engDetails} handleApply={handleApply} handleBack={handleBack} />
        </MDBox>
      }
    </DashboardLayout >
  )
}
export default OpenEngagements 