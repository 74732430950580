// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
// import StepLabel from "@mui/material/StepLabel"
import StepButton from '@mui/material/StepButton';
import { blueGrey } from '@mui/material/colors';

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "./components/Sidenav";
import Header from "./components/Header";
import BasicInfo from "./components/BasicInfo";
import Education from "./components/Education";
import Experience from "./components/Experience";
import Social from "./components/Social";
// import Skills from "./components/Skills";
import Consent from "./components/Consent";
import CurrentEngagement from "./components/CurrentEngagement";
import CareerStory from "./components/CareerStory";
import Profile from "./components/Profile";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Professional from "./components/Professional";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "context/UserProvider";
import api from "services/api";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import { Box } from "@mui/system";
import NotSoBasicInfo from "./components/NotSoBasicInfo";
import { useLocation, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function getSteps() {
  return ["BasicInfo", "Uploads","Gig Index","Edu/Exp","OtherInfo","ProRef & Consent"];
}

const GTProfile = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [userData, setUserData] = useState({});
  const [domainData, setDomainData] = useState([]);
  const { userInfo } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState({});
  const [uploadFlag, setUploadFlag] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  // const [edInputListLength , setEdInputListLength] = useState(1)
  // const [exInputListLength , setExInputListLength] = useState(1)
  const steps = getSteps();

  // const handleEdInputListChange = (newInputList) =>{
  //   setEdInputListLength(newInputList.length)
  // }

  // const handleExInputListChange = (newInputList)=>{
  //   setExInputListLength(newInputList.length)
  // }

  const handleFormSubmit = (values) =>{
    formik.handleSubmit(values)
  }

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()? // It's the last step, but not all steps have been completed,
          steps.findIndex((step, i) => !(i in completed))// find the first step that has been completed
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };
   const handleStepBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  useEffect(() => {
    if (userInfo.type == "gigler" && Object.keys(userInfo).length > 0) {
      const uid = userInfo?.id;
      api.userInfo.findOne(uid).then((data) => {
        if (data) {
          let temp = data?.data?.result?.data;
          setLoading(false);
          setUserData({ ...temp });
          setProfileData({
            userId:temp?.userId,
            resume: temp?.resume,
            video: temp?.video,
            resumeText: temp?.resumeText,
            avatar: temp?.avatar,
            mettlAssessmentLink: temp?.mettlAssessmentLink,            
          });
          if(temp?.phoneNumber){
            setUploadFlag(true);
          }
          console.log("temp",temp);
        }
      });
      api.domain
        .findAll()
        .then((result) => {
          let domains = result?.data?.result?.data;
          setDomainData(domains);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [userInfo]);

  useEffect(() => {
    if(userInfo.type == "admin"){
      console.log("gt values", state.values)
      setUserData(state.values)
      setProfileData({
        userId:state?.values.userId,
        resume: state?.values.resume,
        video: state?.values.video,
        resumeText: state?.values.resumeText,
        avatar: state?.values.avatar,
        mettlAssessmentLink: state?.values.mettlAssessmentLink,            
      });
      if(state?.values.phoneNumber){
        setUploadFlag(true);
      }
      api.domain
      .findAll()
      .then((result) => {
        let domains = result?.data?.result?.data;
        setDomainData(domains);
      })
      .catch((error) => {
        console.log("error", error);
      });
      setLoading(false)
    }
  },[])

  const updateUserData = (data) => {
    if(data?.phoneNumber){
      setUploadFlag(true);
    }
    setUserData(data);
  }

  const handleBack = ( ) => {
    navigate(-1);
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return domainData && domainData.length > 0?
        (<Grid container rowSpacing={2}>
          <Grid item xs={12} mx={2}>
        <Header userData={userData} updateUserData={updateUserData} />
        </Grid>
        <Grid item xs={12} mx={2}>
        <BasicInfo userData={userData} updateUserData={updateUserData} domainData={domainData} onSubmit={handleFormSubmit}/>
        </Grid>
        </Grid>) : null;
      case 1:
        return (
          <Grid item xs={12} mx={2}>
        <Profile userData={profileData} uploadFlag={uploadFlag} />
        </Grid>);
      case 2:
        return (
          <Grid item xs={12} mx={2}>
        <CurrentEngagement userData={userData} updateUserData={updateUserData} />
        </Grid>)
      case 3: 
      // const edListLength = edInputListLength;
      // const edMarginValue = edListLength == 1 ? "90px":'';
       // const exListLength = exInputListLength;
      // const exMarginValue = exListLength == 1? "90px":'';
        return (
          <Grid container rowSpacing={2}>
            <Grid item xs={12} mx={2}>
            <Education userData={userData} updateUserData={updateUserData} />
            </Grid>
            <Grid item xs={12} mx={2}>
            <Experience userData={userData} updateUserData={updateUserData}  />
            </Grid>
        </Grid>);
      case 4:
        return(
          <Grid container rowSpacing={2}> 
            <Grid item xs={12} mx={2}>
              <NotSoBasicInfo userData={userData} updateUserData={updateUserData} domainData={domainData} />
            </Grid>
            <Grid item xs={12} mx={2}>
            <Social userData={userData} updateUserData={updateUserData} />
            </Grid>
            <Grid item xs={12} mx={2}>
            <CareerStory userData={userData} updateUserData={updateUserData} />
            </Grid>
        </Grid>)
        case 5:
          return(
          <Grid container rowSpacing={2}>
            <Grid item xs={12} mx={2}>
              <Professional userData={userData} updateUserData={updateUserData} />
            </Grid>
            {userInfo.type == "gigler" && 
              <Grid item xs={12} mx={2}>
                <Consent userData={userData} updateUserData={updateUserData} />
              </Grid>}
          </Grid>)
      default: 
        return null;
    }
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {userInfo?.type=="admin"?(
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              {loading ? (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card id="basic-info" sx={{ overflow: "visible" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          py: 5,
                        }}
                      >
                        <CircularProgress color="primary" />
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                  {userInfo.type == "admin" && 
                    <MDButton variant="gradient" startIcon={<ArrowBackIcon/>} size="small" sx={{mb:"10px",  position: "absolute",top: "90px", right: "25px"}} onClick={() => handleBack()}>Back</MDButton>
                  }
                    <Header userData={userData} updateUserData={updateUserData} />
                  </Grid>
                  {/* {console.log("Domain",domainData)} */}
                  {domainData && domainData.length > 0 &&
                    <Grid item xs={12}>
                      <BasicInfo userData={userData} updateUserData={updateUserData} domainData={domainData} />
                    </Grid>
                  }
                  <Grid item xs={12}>
                    <Profile userData={profileData} uploadFlag={uploadFlag} />
                  </Grid>
                  {domainData && domainData.length > 0 &&
                    <Grid item xs={12}>
                      <NotSoBasicInfo userData={userData} updateUserData={updateUserData} domainData={domainData} />
                    </Grid>
                  }
                  {/* {domainData && domainData.length > 0 &&
                    <Grid item xs={12}>
                      <Skills userData={userData} domainData={domainData} updateUserData={updateUserData} />
                    </Grid>
                  } */}
                  <Grid item xs={12}>
                    <CurrentEngagement userData={userData} updateUserData={updateUserData} />
                  </Grid>
                  <Grid item xs={12}>
                    <Education userData={userData} updateUserData={updateUserData} />
                  </Grid>
                  <Grid item xs={12}>
                    <Experience userData={userData} updateUserData={updateUserData} />
                  </Grid>
                  <Grid item xs={12}>
                    <Social userData={userData} updateUserData={updateUserData} />
                  </Grid>
                  <Grid item xs={12}>
                    <CareerStory userData={userData} updateUserData={updateUserData} />
                  </Grid>
                  <Grid item xs={12}>
                    <Professional userData={userData} updateUserData={updateUserData} />
                  </Grid>
                  {userInfo.type == "gigler" && 
                  <Grid item xs={12}>
                    <Consent userData={userData} updateUserData={updateUserData} />
                  </Grid>
                  }
                </Grid>
              )}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>)
      :
      (
      <MDBox mt={4}>
        <Grid container justifyContent="center">
        <Grid item xs={12} lg={10} md={12} xl={10} sm={12}>
            <Card
            sx={{bgcolor:blueGrey[100]}}>
              <MDBox mt={-3} mx={2}>
                <Stepper nonLinear activeStep={activeStep} alternativeLabel>
                  {steps.map((label,index) => (
                    <Step key={label} completed={completed[index]}>
                      <StepButton color="inherit" onClick={handleStep(index)}>
                          {label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                <MDBox
                sx={{maxHeight:500,
                  minHeight:500,
                overflow:"auto",
                '&::-webkit-scrollbar': { width: 5, WebkitAppearance: 'none' },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: 8,
                  border: '1px',
                  backgroundColor: 'rgba(0 0 0 / 0.5)'}
                  }}>
                  {getStepContent(activeStep)}
                      {/* <MDBox mt={activeStep   === 1 ? 6 : 3}  mx={2} display="flex" justifyContent="space-between">
                    {activeStep === 0 ? (
                      <MDBox />
                    ) : (
                      <MDButton variant="gradient" color="dark" onClick={handleStepBack}>
                        back
                      </MDButton>
                    )}
                    <MDButton
                      variant="gradient"
                      color="dark"
                      onClick={handleNext}
                    >
                      Next
                    </MDButton>
                  </MDBox> */}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>)}
    </DashboardLayout>
  );
};

export default GTProfile;
