import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useContext } from "react";
import FormField from "layouts/pages/account/components/FormField";
import { useFormik } from "formik";
import MDButton from "components/MDButton";
import { SnackbarContext } from "context/SnackbarContext";
import api from "services/api";

const EditDomain = ({ ind, handleClose, mainIndustry, handleDomainList }) => {
  const { setSnack } = useContext(SnackbarContext);
  const initialValues = {
    Industry: mainIndustry.Industry || "",
    Domain: ind?.Domain || "",
    code: ind?.code || "",
    setIndustry: mainIndustry.Industry
  };
  const formik = useFormik({
    initialValues,
    // validationSchema,
    onSubmit: async (values) => {
      const id = ind.id;
      console.log(id);
      try {
        let setIndustry = values.Industry
        values.setIndustry
        const result = await api.domain.updateOne(id, values);
        console.log("edit result.......", result);
        if (result?.data?.message == "success") {
          setSnack({
            message: `Domain ${values.Domain} saved!`,
            open: true,
            type: "success",
          });
          handleDomainList();
          handleClose();
        } else if (result?.data?.message == "error") {
          setSnack({
            message: `Unable to edit ${values.Domain}`,
            open: true,
            type: "error"
          })
        } else {
          setSnack({
            message: `Some error occurred while editing domain`,
            open: true,
            type: "error"
          })
        }
      } catch (error) {
        setSnack({
          message: "Error occurred during Editing!",
          open: true,
          type: "error",
        });
        console.log("ERROR update: ", { ...error });
      }
    },
    // validate,
  });
  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z]+$/;
  return (
    <Card>
      <MDBox px={2} py={2}>
        <MDTypography variant="h6" color="dark">
          Edit Domain
        </MDTypography>
        <form onSubmit={formik.handleSubmit} method="post">
          <MDBox px={2} py={2}>
            <MDBox>
              <FormField
                disabled
                variant="outlined"
                label="Industry"
                name="Industry"
                {...formik.getFieldProps("Industry")}
                onKeyDown={(event) => {
                  if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </MDBox>
            <MDBox py={1}>
              <FormField
                variant="outlined"
                label="Domain"
                name="Domain"
                {...formik.getFieldProps("Domain")}
                onKeyDown={(event) => {
                  if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </MDBox>
            <MDBox py={1} display="flex" justifyContent="space-between">
              <FormField
                sx={{ width: "150px" }}
                variant="outlined"
                label="Series"
                name="code"
                {...formik.getFieldProps("code")}
                onKeyDown={(event) => {
                  if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />

              <MDBox py={1} display="flex" justifyContent="space-between">
                <MDButton variant="gradient" color="success" size="small" type="submit">
                  save
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </form>
      </MDBox>
    </Card>
  );
};

export default EditDomain;
