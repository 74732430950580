import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => {
  return {
    title: {
      paddingLeft: "20px",
      paddingTop: "5px"
    },
    engId: {
      paddingLeft: "20px",
      paddingTop: "5px"
    },
    orgName: {
      paddingLeft: "20px",
    },
    budget: {
      display: "flex"
    },
    icon: {
      float: "left",
      paddingLeft: "18px",
      paddingTop: "6px"
    },
    txt: {
      float: "left",
      paddingLeft: "5px",
      paddingTop: "6px"
    },
    box: {
      display: "flex",
      alignItem: "center",
    },
    desc: {
      paddingLeft: "18px",
      paddingTop: "6px"
    },
    role: {
      marginLeft: "20px",
      marginTop: "30px"
    },
    edu: {
      marginLeft: "20px"
    },
    backIcon: {
      position: "absolute",
      right: "10px",
      paddingTop: "10px",
      float: "right",
      paddingRight: "18px",
      cursor: "pointer"
    },
    apply: {
      position: "absolute",
      right: "10px",
      // paddingTop: "10px",
      paddingRight: "18px",
      cursor: "pointer"
    }
  }
});