import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
// import DataTable from 'examples/Tables/DataTable'
import React, { useEffect, useState } from 'react'
import dataTableData from './data/dataTableData'
import DataTable from './DataTable'
import api from "services/api";
import { useNavigate } from 'react-router-dom'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import { Card } from '@mui/material'


const ManageGigler = () => {

  const navigate = useNavigate();

  const [show, setShow] = useState(true)
  const [details, setDetails] = useState({
    columns: [
      { Header: "name", accessor: "firstName", width: "10%" },
      { Header: "Reg. Date", accessor: "createdAt", width: "10%" },
      { Header: "Gender", accessor: "gender", width: "10%" },
      { Header: "Industry", accessor: "industry", width: "10%" },
      { Header: "Domain", accessor: "domain", width: "10%" },
      { Header: "Email", accessor: "email", width: "10%" },
      { Header: "Phone Number", accessor: "phoneNumber", width: "10%" },
      { Header: "WhatsApp Number", accessor: "whatsappNumber", width: "10%" },
      { Header: "Primary skill", accessor: "primarySkills", width: "10%" },
      { Header: "Secondary skill", accessor: "secondarySkills", width: "10%" },
      { Header: "Current Location", accessor: "yourLocation", width: "10%" },
      { Header: "preferred Location", accessor: "preferredLocation", width: "10%" },
      { Header: "Passport", accessor: "passport", width: "10%" },
      { Header: "Compensation", accessor: "compensation", width: "10%" },
      { Header: "Compensation Amount", accessor: "compensationAmount", width: "10%" },
      { Header: "Professional Reference Name", accessor: "proRefName", width: "10%" },
      { Header: "Professional Reference Email", accessor: "proRefEmail", width: "10%" },
      { Header: "Professional Reference Number", accessor: "proRefNumber", width: "10%" },
      { Header: "Professional Reference Position", accessor: "proRefPosition", width: "10%" },
      { Header: "Professional Reference Check", accessor: "proRefCheck", width: "10%" },
      { Header: "Assigned", accessor: "assigned", width: "10%" },
      { Header: "Assessed", accessor: "assessed", width: "10%" },
      // { Header: "salary", accessor: "salary" },
    ],
    rows: []
  })
  useEffect(() => {
    api.userInfo.findAll().then(data => {
      console.log("list of giglers", data);
      let temp = data?.data?.result?.data;
      console.log(temp);
      setDetails({ ...details, rows: temp });
    }).catch(error => {
      console.log("error", error)
    })
  }, [])

  const handleClick = (Id) => {
    // console.log("eng id", engId)
    let id = Id
    api.userInfo.findOne(id)
      .then((data) => {
        console.log("result", data?.data?.result?.data)
        let row = data?.data?.result?.data
        navigate("/gigTalent-details", {
          state: {
            values: {
              user: row,
              show: true
            }
          }
        })
      })
  }

  const handleServiceGigler = () => {
    navigate("/admin/register-service-gigTalent")
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {show ? (
        <Card>
          <MDBox p={3} display="flex" justifyContent="space-between">
            <MDTypography variant="h5">GigTalent List</MDTypography>
            <MDButton variant="gradient" size="medium" color="success" onClick={() => navigate("/admin/register-service-gigTalent")}>
              Add Service GigTalent
            </MDButton>
          </MDBox>
          <DataTable table={details} canSearch handleClick={handleClick} />
        </Card>
      ) : (
        <MDBox>

        </MDBox>
      )}
    </DashboardLayout>
  )
}

export default ManageGigler