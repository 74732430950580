/**
=========================================================
* Otis Admin PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Timeline context
import { useTimeline } from "examples/Timeline/context";

// Custom styles for the TimelineItem
import timelineItem from "examples/Timeline/TimelineItem/styles";
import MDButton from "components/MDButton";

//icons
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { Domain } from "@mui/icons-material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Checkbox } from "@mui/material";
function TimelineItem({ color, icon, title, dateTime, description, lastItem, id, Industry, Domain, handleGiglerSearch }) {
  const isDark = useTimeline();

  return (
    <MDBox position="relative" mb={3} sx={(theme) => timelineItem(theme, { lastItem, isDark })}>
      {icon ?
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgColor={color}
          color="white"
          width="2rem"
          height="2rem"
          borderRadius="50%"
          position="absolute"
          top="8%"
          left="2px"
          zIndex={2}
          sx={{ fontSize: ({ typography: { size } }) => size.sm }}
        >
          <Icon fontSize="inherit">{icon}</Icon>
        </MDBox>
        : null}
      <MDBox ml={id ? 2.5 : 5.75} pt={description ? 0.7 : 0.5} lineHeight={0} maxWidth="30rem" >
        <MDBox sx={{ alignItems: 'center' }}>
          <MDTypography variant={id ? "h5" : "button"} fontWeight="medium" color={isDark ? "white" : "dark"}>
            {title}
          </MDTypography>
        </MDBox>
        <MDBox mt={0.5}>
          <MDTypography variant="caption" color={isDark ? "secondary" : "text"}>
            {dateTime}
          </MDTypography>
        </MDBox>
        {id ?
          <MDBox mt={0.5} sx={{ display: "flex", float: "right", mr: "30px" }}>
            <ChevronRightIcon fontSize="medium" color="info" onClick={() => handleGiglerSearch()} />
          </MDBox>
          : <MDBox mt={0.5} sx={{ display: "flex", float: "right", mr: "30px" }}>
            <Checkbox />
          </MDBox>}
        <MDBox mt={1} mb={1}>
          {Industry ? (
            <MDTypography variant="button" color={isDark ? "white" : "dark"}>
              Industry: {Industry}
            </MDTypography>
          ) : null}
        </MDBox>
        <MDBox mt={1} mb={1}>
          {Domain ? (
            <MDTypography variant="button" color={isDark ? "white" : "dark"}>
              Domain: {Domain}
            </MDTypography>
          ) : null}
        </MDBox>
        <MDBox mt={2} mb={1.5}>
          {description ? (
            <MDTypography variant="button" color={isDark ? "white" : "dark"}>
              {description}
            </MDTypography>
          ) : null}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of TimelineItem
TimelineItem.defaultProps = {
  color: "info",
  lastItem: false,
  description: "",
};

// Typechecking props for the TimelineItem
TimelineItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node,
  title: PropTypes.string,
  dateTime: PropTypes.string,
  description: PropTypes.string,
  lastItem: PropTypes.bool,
};

export default TimelineItem;
