import React, { useState,useEffect,useContext} from 'react'
import { useLocation } from 'react-router-dom';
import DefaultPricingCard from 'examples/Cards/PricingCards/DefaultPricingCard';
import api from 'services/api';
import { UserContext } from "context/UserProvider";
import { useMaterialUIController } from "context";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { Box,Grid } from '@mui/material';
 
export default function AdminManagePlans() {
 
    const {state} = useLocation();
    const { userInfo } = useContext(UserContext);
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const[plans,setPlans]=useState([]);
 
    useEffect(() => {
        api.plan.findAll()
          .then(data => {
            let temp = data?.data?.result?.data
            console.log("plans",temp, state)
            if(userInfo.type == "admin" && state){
              setPlans(temp.filter(x => x.id != state.values.planId ))
              // console.log("plans for admin edit", plans)
            }else{
              setPlans(temp)
            }
          })
      }, [])
  return (
    <>
     <DashboardLayout>
      <DashboardNavbar />
      <Box position="relative" zIndex={10} mt={8} px={{ xs: 1, sm: 0 }}>
        <Grid container spacing={3} justifyContent="center">
          {plans?.map((row) => (
            <Grid item xs={12} lg={4}>
              <DefaultPricingCard
                key={row?.id}
                color={darkMode ? "dark" : "white"}
                // badge={{ color: darkMode ? "warning" : "light", label: "Basic" }}
                badge={{ color: "info", label: (row?.title) }}
                price={{ currency: "Rs", value: (row?.price), type: "yr" }}
                specifications={[
                  { label: `${row?.numberOfUsers} - Users`, includes: true },
                  { label: `${row?.numberOfSubUsers} - Sub-user`, includes: true },
                  { label: `${row?.numberOfJobPostings} - Job-postings`, includes: true },
                  // { label: "Carry forward option", includes: (row?.carryForwardOption) },
                  { label: "Contact Details", includes: (row?.contactDetails) },
                  { label: "Paid Additional Job Posting Option", includes: true },
                  // { label: `${row?.numberOfProfileView} - Number of profile view`, includes: true },
                  { label: "Automatic profile matching", includes: (row?.automaticProfileMatchingAlert) },
                  // { label: `${row?.accessToProfileCategory} - Access to profile category `, includes: true },
                  // { label: "BGV of Candidates", includes: (row?.bgvOfTheCandidates) },
                  // { label: "Access to Assessor", includes: (row?.accessToAssessors) },
                  // { label: "Mentoring option", includes: (row?.mentoringOption) },
                ]}
                action={{
                  // type: "internal",
                  // route: "/",
                  color: darkMode ? "warning" : "dark",
                  label: `try ${row?.title}`,
                }}
                // onClick={() => {
                //   openDialog(row)
                //   setBlurredView(true);
                //   setButtonVisible(true);
                // }
                //   }
                // onClick={() => {
                //   navigate(`/employer/payment-page/${row.title}${row.id}`, {
                //       state: {
                //         ...row,
                //         Users: row.numberOfUsers,
                //         SubUsers: row.numberOfSubUsers,
                //       JobPostings: row.numberOfJobPostings,
                //       },
                //     });
                // }}
             
               />    
            </Grid>
          ))}
</Grid>
</Box>
</DashboardLayout>
    </>
  )
}