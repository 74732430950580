import { Avatar, Grid, CircularProgress, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import theme from 'assets/theme';
import { UserContext } from 'context/UserProvider';
import api from 'services/api';
// import { useTheme } from '@material-ui/core/styles';

const DomainList = ({ engagementList, handleGiglerList, handleClick, setData }) => {
  const { userInfo } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [domainList, setDomainlist] = useState([]);

  useEffect(() => {
    console.log("data",engagementList);
    setDomainlist(engagementList);
  },[engagementList])
  
  const handleSearch = (event) =>{
    console.log("event",event.target.value);
    if(event.target.value == ''){
      console.log("here", engagementList);      
      setDomainlist(engagementList);
      return;
    }
    if(event.target.value.length >= 0){
      setLoading(true)
      let list = engagementList.filter(x => x.Domain.toLowerCase().includes(event.target.value.toLowerCase()));
      if(list.length > 0){        
        setDomainlist(list);
        setLoading(false);
      }else {
        setLoading(false);
      }
      console.log("search",list);        
    }
  }

  const [selected, setSelected] = useState();
  return (

    <Grid container>
      <Grid item xs={12} md={12}>
        <MDBox display="flex" p={2} sx={{ alignItems: "center", justifyContent: "space-between" }}>
          <MDTypography variant="h6">Domain List</MDTypography>
          <MDInput type="search" label="search" onChange={(e) => handleSearch(e)}></MDInput>
        </MDBox>
        {engagementList && engagementList.length > 0 ? (
          <MDBox sx={{ maxHeight: 550, overflow: 'auto' }}>
            <List dense>
            {loading ? (
              <MDBox 
              sx={{display: "flex",
              flexDirection: "column",
              alignItems: "center",
              py: 5,
            }}>
                <CircularProgress color="error" />
              </MDBox>
            ):(
              domainList?.map((row, index) => (
                <ListItemButton
                  key={index}
                  id={index}
                  sx={{
                    backgroundColor: index == selected ? theme.palette.grey[300] : '#ffffff',
                    '&:hover': {
                      backgroundColor: index == selected ? theme.palette.grey[300] : '#ffffff',
                      "& $addIcon": {
                        color: "purple"
                      }
                    }
                  }}
                >
                  <ListItem
                    secondaryAction={
                      <MDButton variant="gradient" color="info" size="small"
                        onClick={() => {
                          setSelected(index);
                          handleGiglerList(row);
                        }}
                      >
                        Search
                      </MDButton>
                    }
                    divider={index == domainList.length - 1 ? false : true}
                    sx={{ p: "15px" }}
                  >
                    <ListItemText
                      primary={
                        <MDTypography variant="h6">
                          {row.Domain}
                        </MDTypography>}                     
                    />
                  </ListItem>
                </ListItemButton>
              )
              ))}  
            </List>
          </MDBox>
        ) : (
          <MDBox>
            <MDTypography px={2} py={2} color="dark" >No Domains</MDTypography>
          </MDBox>
        )}
      </Grid>
    </Grid >

  )
}

export default DomainList;