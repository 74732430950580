import React from 'react'
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, Checkbox, FormGroup, Icon, Stack } from "@mui/material";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Otis Admin PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/pages/profile/components/Header";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings";

// Data

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import selectData from "./data/selectData";
import EngagerList from "./EngagerList";
import MDButton from "components/MDButton";
import { useContext, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

//form
import { useFormik } from "formik";
import * as Yup from "yup";
import api from "services/api";
import { useNavigate } from "react-router-dom";
import { Field, Form, ErrorMessage } from "formik";
import MDSnackbar from "components/MDSnackbar";
import MDAlert from "components/MDAlert";
import FormField from "layouts/pages/account/components/FormField";
import { SnackbarContext } from "context/SnackbarContext";
import { UserContext } from 'context/UserProvider';


const EditEngagerProfile = ({ details, handleEdit, handleBack, load }) => {
  const {userInfo} = useContext(UserContext);
  let navigate = useNavigate();
  const { setSnack } = useContext(SnackbarContext);
  const [indData, setIndData] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const handleShow = () => setShowForm(true);
  const [initialIndustry, setInitialIndustry] = useState();
  const [loading, setLoading] = useState(load || true);
  const [ent, setEnt] = useState(["OPC", "LLP", "Public", "Private", "PSU", "Government", "NGO" , "Associates"])
  const initialValues = {
    engagerId: '',
    organizationName: details?.organizationName || '',
    industry: details?.industry || '',
    enterprise: details?.enterprise || '',
    location: details?.location || '',
    websiteAddress: details?.websiteAddress || '',
    gstIn: details?.gstIn || '',
    firstName: details?.firstName || '',
    lastName: details?.lastName || '',
    designation: details?.designation || '',
    department: details?.department || '',
    email: details?.email || '',
    phoneNumber: details?.phoneNumber || '',
    panNumber: details?.panNumber ||'',
    numberOfEmployees: details?.numberOfEmployees || ''
  }

  const validate = (values) => {
    let errors = {};

    if (!values.organizationName) {
      errors.organizationName = "Required";
    }
    if (!values.location) {
      errors.location = "Required";
    }
    if (!values.webAddress) {
      errors.webAddress = "Required";
    }
    if (!values.gstIn) {
      errors.gstIn = "Required";
    }
    if (!values.firstName) {
      errors.firstName = "Required";
    }
    if (!values.lastName) {
      errors.lastName = "Required";
    }
    if (!values.designation) {
      errors.designation = "Required";
    }
    if (!values.department) {
      errors.department = "Required";
    }
    if (!values.email) {
      errors.email = "Required";
    }
    if (!values.phoneNumber) {
      errors.mobileNumber = "Required";
    }
    if (!values.numberOfEmployees) {
      errors.numberOfEmployees = "Required";
    }
    return errors;
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object({
    organizationName: Yup.string().required("Organization Name is required"),
    location: Yup.string().required("Location is required"),
    // numberOfEmployees: Yup.number().required("Number of employees is required"),
    // websiteAddress: Yup.string().required("WebAddress is required"),
    // gstIn: Yup.string().required("Gst In is required"),
    designation: Yup.string().required("Designation is required"),
    department: Yup.string().required("Department is required"),
    panNumber:Yup.string(),
  });




  useEffect(() => {
    api.industry.findAll().then(data => {
      console.log("list of industries", data);
      let temp = data?.data?.result?.data;
      let tempInd = temp.find((x) => x.Industry == details?.industry)
      console.log("intial industry", tempInd.Industry)
      setInitialIndustry(tempInd.Industry);
      formik.setFieldValue("industry", tempInd.Industry, false);
      let enterprise = ["OPC", "LLP", "Public", "Private", "PSU", "Government", "NGO" , "Associates"]
      setEnt(enterprise)
      let tempEnterprise = enterprise.find((x) => x == details?.enterprise)
      formik.setFieldValue("enterprise", tempEnterprise, false)
      setLoading(false);
      console.log(temp);
      setIndData(temp);
    }).catch(error => {
      console.log("error", error)
    })
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        let id = details.engagerId;
        const registerEngager = await api.engager.update(id, values)
          .then((registerEngager) => {
            setSnack({
              message: `${values?.email} Engager edited Successfully`,
              open: true,
              type: 'success',
            });
            {userInfo.type == "admin" ? handleEdit() : handleBack()}
          })
          .catch((error) => {
            setSnack({
              message: 'Error occurred during Engager Edit  !',
              open: true,
              type: 'error',
            });
            console.log("Engager error", error)
          })
      } catch (error) {
        // console.log("ERROR LOGIN: ", { ...error });
      }
    },
  });

  return (
    <>
      {loading ? (
        <Card>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  py: 5,
                }}
              >
                <CircularProgress color="primary" />
              </Box>
            </Grid>
          </Grid>
        </Card>
      ) : (
        <Card>
          <MDBox>
            <MDBox p={3} display="flex" justifyContent="space-between">
              <MDTypography variant="h5">Engager Information</MDTypography>
              <MDButton size="small" color="info" onClick={() => {userInfo.type == "admin" ? handleEdit() : handleBack()}}>back</MDButton>
            </MDBox>
            <form onSubmit={formik.handleSubmit} onReset={() => handleReset()} method='post'>
              <MDBox pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <FormField label="Organization name" placeholder="Name of the organization" name='organizationName'
                      {...formik.getFieldProps("organizationName")}
                      inputProps={{ type: "text", readOnly: userInfo.type == "employer" ? true : false }}
                      error={formik.touched.organizationName && formik.errors.organizationName ? true : false}
                      helperText={
                        formik.touched.organizationName && formik.errors.organizationName
                          ? formik.errors.organizationName
                          : null
                      } />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {initialIndustry &&
                      <Autocomplete
                        id="select-industry"
                        name="industry"
                        options={indData}
                        autoHighlight
                        getOptionLabel={(option) => option.Industry || initialIndustry}
                        defaultValue={initialIndustry}
                        renderOption={(props, option) => (
                          <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.Industry}
                          </Box>
                        )}
                        renderInput={(params) => <FormField {...params} label="Select Industry"
                          {...formik.getFieldProps("industry")}
                          error={formik.touched.industry && formik.errors.industry ? true : false}
                          helperText={
                            formik.touched.industry && formik.errors.industry
                              ? formik.errors.industry
                              : null
                          }
                        />}
                        onChange={(event, newValue) => {
                          formik.setFieldValue("industry", newValue.Industry, false);
                        }}
                      />
                    }
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      options={ent}
                      defaultValue={details?.enterprise}
                      onChange={(event, option) => {
                        formik.setFieldValue("enterprise", option, false);
                      }}
                      name="enterprise"
                      disabled={userInfo.type == "employer" ? true : false}
                      renderInput={(params) => (
                        <FormField {...params} label="Enterprise" InputLabelProps={{ shrink: true }}
                          {...formik.getFieldProps("enterprise")}
                          error={formik.touched.enterprise && formik.errors.enterprise ? true : false}
                          helperText={
                            formik.touched.enterprise && formik.errors.enterprise
                              ? formik.errors.enterprise
                              : null
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField label="Location of HO" placeholder="Hyderabad" name="location" inputProps={{ type: "text" }}
                      {...formik.getFieldProps("location")}
                      error={formik.touched.location && formik.errors.location ? true : false}
                      helperText={
                        formik.touched.location && formik.errors.location
                          ? formik.errors.location
                          : null
                      } />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormField label="Number of employees" placeholder="20" name='numberOfEmployees' inputProps={{ type: "number" }}
                      {...formik.getFieldProps("numberOfEmployees")}
                      error={formik.touched.numberOfEmployees && formik.errors.numberOfEmployees ? true : false}
                      helperText={
                        formik.touched.numberOfEmployees && formik.errors.numberOfEmployees
                          ? formik.errors.numberOfEmployees
                          : null
                      } />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField label="Website address" placeholder="www.gigleJi.com" name="websiteAddress" inputProps={{ type: "text" }}
                      {...formik.getFieldProps("websiteAddress")}
                      error={formik.touched.websiteAddress && formik.errors.websiteAddress ? true : false}
                      helperText={
                        formik.touched.websiteAddress && formik.errors.websiteAddress
                          ? formik.errors.websiteAddress
                          : null
                      }></FormField>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <FormField label="GSTIN" placeholder="GSTIN97924678979465" name="gstIn" inputProps={{ type: "text" }}
                      {...formik.getFieldProps("gstIn")}
                      error={formik.touched.gstIn && formik.errors.gstIn ? true : false}
                      helperText={
                        formik.touched.gstIn && formik.errors.gstIn
                          ? formik.errors.gstIn
                          : null
                      }></FormField>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <FormField label="Pan Number" name="panNumber" inputProps={{ type: "text" }}
                      {...formik.getFieldProps("panNumber")}
                      error={formik.touched.panNumber && formik.errors.panNumber ? true : false}
                      helperText={
                        formik.touched.panNumber && formik.errors.panNumber
                          ? formik.errors.panNumber
                          : null
                      }></FormField>
                  </Grid>
                </Grid>
                  <MDBox p={3}>
                    <MDTypography variant="h5">SPOC Details</MDTypography>
                  </MDBox>
                <Grid container spacing={3} pl={2}>
                  <Grid item xs={12} sm={4}>
                    <FormField label="First Name" placeholder="Engager first name" name='firstName'
                      inputProps={{ type: "text", readOnly: true }}
                      {...formik.getFieldProps("firstName")}
                      error={formik.touched.firstName && formik.errors.firstName ? true : false}
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                          ? formik.errors.firstName
                          : null
                      } />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField label="Last Name" placeholder="Engager last Name" name='lastName'
                      inputProps={{ type: "text", readOnly: true }}
                      {...formik.getFieldProps("lastName")}
                      error={formik.touched.lastName && formik.errors.lastName ? true : false}
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                          ? formik.errors.lastName
                          : null
                      } />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField label="Designation" placeholder="Engager designation" name='designation' inputProps={{ type: "text", }}
                      {...formik.getFieldProps("designation")}
                      error={formik.touched.designation && formik.errors.designation ? true : false}
                      helperText={
                        formik.touched.designation && formik.errors.designation
                          ? formik.errors.designation
                          : null
                      } />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormField label="Department" placeholder="Engager department" name='department' inputProps={{ type: "text" }}
                      {...formik.getFieldProps("department")}
                      error={formik.touched.department && formik.errors.department ? true : false}
                      helperText={
                        formik.touched.department && formik.errors.department
                          ? formik.errors.department
                          : null
                      } />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormField label="Email" placeholder="Engager email" name='email'
                      inputProps={{ type: "text", readOnly: true }}
                      {...formik.getFieldProps("email")}
                      error={formik.touched.email && formik.errors.email ? true : false}
                      helperText={
                        formik.touched.email && formik.errors.email
                          ? formik.errors.email
                          : null
                      } />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormField label="Phone Number" placeholder="+9144552244" name='phoneNumber'
                      inputProps={{ type: "number",readOnly: true }}
                      {...formik.getFieldProps("phoneNumber")}
                      error={formik.touched.phoneNumber && formik.errors.phoneNumber ? true : false}
                      helperText={
                        formik.touched.phoneNumber && formik.errors.phoneNumber
                          ? formik.errors.phoneNumber
                          : null
                      } />
                  </Grid>
                  <Grid item xs={12} sm={12} display='flex' justifyContent='space-between'>
                    <MDButton variant="gradient" color="success" size="small" type="submit">
                      SUBMIT
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </form>
          </MDBox>
        </Card>
      )}
    </>
  )
}

export default EditEngagerProfile