import React, { useContext, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";
import { CircularProgress, Grid, Link, Modal } from "@mui/material";
import { useStyles } from "../../styles";
import { UserContext } from "context/UserProvider";
import api from "services/api";
import { Document, Page, pdfjs } from "react-pdf";
import FormField from "layouts/applications/wizard/components/FormField";
import { SnackbarContext } from "context/SnackbarContext";


pdfjs.GlobalWorkerOptions.workerSrc = `./pdf.worker.min.js`;

function Profile({ userData, uploadFlag }) {
  console.log("userdata", userData)
  const cv = {};
  let temp;
  const classes = useStyles();
  const { userInfo } = useContext(UserContext);
  const { setSnack } = useContext(SnackbarContext);
  const [resume, setResume] = useState();
  const [resumeType, setResumeType] = useState();
  const [url, setUrl] = useState();
  const [vurl, setVurl] = useState();
  const [resumeText, setResumeText] = useState();
  const [videoFile, setVideoFile] = useState();
  const [open, setOpen] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const handleOpenFile = () => setOpenFile(true);
  const handleCloseFile = () => setOpenFile(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedFile, setSelectedFile] = useState();
  const [resumeLoading, setresumeLoading] = useState(false);
  const [videoLoading, setvideoLoading] = useState(false);

  const handleClick = () => {
    document.getElementById("resumeFile").click();
  };
  const handleChange = (event) => {
    let temp = event.target.files[0];
    let type = event.target.files[0].type;
    console.log("resume file", temp);
    if(type == "application/pdf" || type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ){
    const formData = new FormData();
    formData.append("resume", temp);
    if(userInfo.type == "admin"){
      formData.append("id", userData?.userId);
    }else{
      formData.append("id", userInfo?.id);
    }
    // formData.append("id", {userInfo.type == "gigler" ? userInfo.id : userData?.userId});
    setresumeLoading(true);
    api.userUploads.resumeUpdate(formData).then((result) => {
      if (result.data?.message == "success") {
        setSnack({
          message: "Resume updated!",
          open: true,
          type: "success",
        });
        console.log("resume data", result);
        let temp2 = result?.data?.result?.data;
        setResume(temp2?.resume);
        setResumeText(temp2?.resumeText);
        setresumeLoading(false);
      } else {
        setSnack({
          message: "Error occurred while uploading resume!",
          open: true,
          type: "error",
        });
        setresumeLoading(false);
      }

      // setUrl(temp);
    });
  }
  else {
    setSnack({
      message: "Upload Only .pdf or .docx files",
      open: true,
      type: "error",
    });
  }
  };

  useEffect(() => {
    setResumeText(userData?.resumeText);
  }, [userData]);

  const handleVideoClick = () => {
    console.log("upload");
    document.getElementById("video").click();
  };

  // const formatFileSize = (bytes, decimalPoint) => {
  //   if (bytes == 0) return '0 Bytes';
  //   var k = 1000,
  //     dm = decimalPoint || 2,
  //     sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
  //     i = Math.floor(Math.log(bytes) / Math.log(k));
  //   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  // }
  const handleChangeVideo = (event) => {
    console.log("file changes");
    let tempVideo = event.target.files[0];
    let size = event.target.files[0].size;
    // console.log("video data", event.target.files[0]);
    let type = event.target.files[0].type;
    if(type == "video/mp4"){
      if (size >= 8000000) {
        setSnack({
          message: "Upload file size is larger than 8MB",
          open: true,
          type: "error",
        });
      } else if (size < 8000000) {
        setvideoLoading(true);
        setVurl(tempVideo);
        const videoFormData = new FormData();
        videoFormData.append("video", tempVideo);
        if(userInfo.type == "admin"){
          videoFormData.append("id", userData?.userId);
        }else{
          videoFormData.append("id", userInfo?.id);
        }
        api.userUploads.videoUpdate(videoFormData).then((data) => {
          if (data.data?.message == "success") {
            setSnack({
              message: "Video upload successful!",
              open: true,
              type: "success",
            });
            setVideoFile(data?.data?.result?.data?.video);
            setvideoLoading(false);

          } else if (data?.data?.message == "error") {
            setSnack({
              message: "Error occurred while uploading video!",
              open: true,
              type: "error",
            });
            setvideoLoading(false);
          }
          console.log("video data", data);
        });
      }
    }
    else {
      setSnack({
        message: "Upload Only Video files",
        open: true,
        type: "error",
      });
    }


  };

  useEffect(() => {
    if (userData) {
      console.log("resume", userData);
      console.log(
        "resume",
        userData?.resume ? userData?.resume.split(/[#?]/)[0].split(".").pop().trim() : ""
      );
      setResumeType(
        userData?.resume ? userData?.resume.split(/[#?]/)[0].split(".").pop().trim() : ""
      );
      const cv = userData?.resume;
      const vd = userData?.video;
      // console.log("video file ", vd)
      setResume(cv);
      setVideoFile(vd);
    }
  }, [userData]);

  return (
    <Grid container spacing={2}>
    <Grid item xs={12}>
      <Card id="Profile">
        <MDBox
          pr={3}
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <MDBox p={3} lineHeight={1}>
            <MDBox mb={1}>
              <MDTypography variant="h5">Profile </MDTypography>
            </MDBox>
            <MDTypography variant="button" color="text">
              Upload your resume here..
            </MDTypography>
          </MDBox>
          <form method="post">
            <MDBox display="flex">
            {resumeLoading ? (
            <MDBox 
            sx={{display: "flex",
            flexDirection: "column",
            alignItems: "center",
            py:5,
          }}>
                <CircularProgress color="error" />
             </MDBox>
            ) : (
              <>
              <Modal open={openFile} onClose={handleCloseFile}>
                <MDBox className={classes.resumeStyle}>
                  {resumeType == "pdf" ? (
                    <iframe
                      className={'pdf'}
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      src={`https://docs.google.com/gview?url=${resume}&embedded=true`}
                    ></iframe>
                  ) : (
                    <iframe
                      className={'docx'}
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      src={`https://docs.google.com/gview?url=${resume}&embedded=true`}
                    ></iframe>
                  )}
                </MDBox>
              </Modal>
              <MDBox sx={{ alignItems: "center" }}>
                {resume && (
                  <>
                    <MDTypography variant="button" color="text" sx={{ mr: "10px" }}>
                      {/* {resume.split("/").pop()} */}
                      {decodeURIComponent(resume.split('/').pop())}
                    </MDTypography>
                    <MDButton
                      color="info"
                      size="small"
                      variant="gradient"
                      // href={`https://docs.google.com/viewerng/viewer?url=${resume}`}
                      // target="_blank"
                      onClick={() => setOpenFile(true)}
                      sx={{ mr: "10px" }}
                    >
                      View
                    </MDButton>
                    <MDButton
                      color="info"
                      size="small"
                      variant="gradient"
                      href={resume}
                      sx={{ mr: "10px" }}
                    >
                      Download
                    </MDButton>
                  </>
                )}
                <input
                  type="file"
                  name="resumeFile"
                  id="resumeFile"
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  onChange={(e) => handleChange(e)}
                  className={classes.inputStyle}
                  sx={{ display: "none" }}
                />
                <MDButton
                  variant="gradient"
                  size="small"
                  color="success"
                  onClick={() => handleClick()}
                  disabled={!uploadFlag}
                >
                  Upload
                </MDButton>
              </MDBox>
              </>
              )}

            </MDBox>
          </form>
        </MDBox>
        {resumeText && (
          <MDBox p={3} lineHeight={1}>
            <form method="post">
              <MDBox>
                <MDTypography variant="h6">Resume Text</MDTypography>
                <FormField
                  placeholder="Resume text..."
                  name="resumetext"
                  multiline
                  value={resumeText}
                  defaultValue={resumeText}
                  rows={5}
                  inputProps={{ type: "text" }}
                />
              </MDBox>
            </form>
          </MDBox>
        )}
      </Card>
      </Grid>
      <Grid item xs={12}>
      <Card id="profile" sx={{ mt: "10px" }}>
        <MDBox
          pr={3}
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5">Upload your personalized video </MDTypography>
            <MDTypography variant="button" color="info" fontWeight="medium">Note: File size should be less than 8MB</MDTypography>
          </MDBox>
          <Modal open={open} onClose={handleClose}>
            <MDBox className={classes.videoStyle}>
              <video width="450" height="300" controls>
                <source src={videoFile} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </MDBox>
          </Modal>
          {videoLoading ? (
            <MDBox 
            sx={{display: "flex",
            flexDirection: "column",
            alignItems: "center",
            py:5,
          }}>
                <CircularProgress color="error" />
             </MDBox>
            ) : (
          <MDBox display="flex" flexDirection={{ xs: "column", sm: "row", alignItems: "center" }}>
            {videoFile && (
              <>
                <MDTypography color="text" variant="button" sx={{ mr: "10px" }}>
                  {/* {videoFile.split("/").pop()} */}
                  {decodeURIComponent(videoFile.split('/').pop())}
                </MDTypography>
                <MDButton
                  variant="gradient"
                  size="small"
                  onClick={() => setOpen(true)}
                  color="info"
                  sx={{ mr: "10px" }}
                >
                  View
                </MDButton>
              </>
            )}
            <input
              type="file"
              name="video"
              id="video"
              accept=".mp4"
              className={classes.inputStyle}
              onChange={(e) => handleChangeVideo(e)}
              sx={{ display: "none" }}
            />

            <MDButton
              variant="gradient"
              type="submit"
              size="small"
              onClick={() => handleVideoClick()}
              color="success"
            >
              Upload
            </MDButton>
          </MDBox>
            )}
        </MDBox>
      </Card>
      </Grid>
      {userInfo.type == "gigler" && userData?.mettlAssessmentLink &&
        <Card sx={{ mt: "10px" }}>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5">Mettl Assessment Link </MDTypography>
            {/* <MDTypography
            target="_blank"
            variant="button"
            color="info"
            fontWeight="medium"
            textGradient

          >
            https://trello.com/b/A9wFiEjh/gigleji
          </MDTypography> */}
            <MDTypography variant="button" color="info" fontWeight="medium">
              <Link href={userData?.mettlAssessmentLink || ""} target="_blank"  >
                Click here for Mettl Assessment
              </Link>
            </MDTypography>
          </MDBox>
        </Card>
      }
    </Grid>
  );
}

export default Profile;
