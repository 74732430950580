import { Card, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import React, { useContext, useEffect } from 'react'
import { useFormik } from "formik";
import api from 'services/api'
import { SnackbarContext } from "context/SnackbarContext";
import { UserContext } from 'context/UserProvider'
import moment from 'moment';
import MDBadge from 'components/MDBadge'

const EngagerFeedbackForm = ({ feedbackDetails, handleClose, engagementDesc, handleGiglerFeedback, callAgainHandleClick }) => {
  // console.log("feedback details", feedbackDetails)
  // console.log("engagment details", engagementDesc);
  const { userInfo } = useContext(UserContext)
  const { setSnack } = useContext(SnackbarContext);
  const initialValues = {
    engagementId: feedbackDetails.engagementId || "",
    engagerId: feedbackDetails.engagerId || "",
    giglerId: feedbackDetails.giglerId || "",
    giglerName: feedbackDetails?.users?.firstName + feedbackDetails?.users?.lastName || "",
    questions: "",
    q1answer: "",
    q2answer: "",
    q3answer: "",
    q4answer: "",
    q5answer: "",
    workAgainGigler: "",
  }
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      if(!values.workAgainGigler){
        setSnack({
          message: `Choose an option in want to work with GigTalent`,
          open: true,
          type: "success",
        });
      }else if(values.workAgainGigler){
      console.log("Feedback form values", values);
      try {
        let question = [];
        question.push({ q: 'Did the GigTalent have adequate technical expertise to execute the engagement?', q1a: values.q1answer })
        question.push({ q: 'Is the GigTalent a good communicator?', q2a: values.q2answer })
        question.push({ q: 'How was the quality of the GigTalents deliverables?', q3a: values.q3answer })
        question.push({ q: 'Was the GigTalent a team player and helping co-workers?', q4a: values.q4answer })
        question.push({ q: 'Did the GigTalent performed additional tasks outside of his role?', q5a: values.q5answer })
        values.questions = question;
        values.engagementTitle = engagementDesc.engagementTitle;
        values.engagementOwnerName = engagementDesc.engagerName;
        values.organizationName = engagementDesc.organizationName;
        const result = await api.engagerFeedback.create(values);
        console.log("feedback values.................", values)
        if (result) {
          setSnack({
            message: `Feedback for engagement ${values.engagementId} !`,
            open: true,
            type: "success",
          });
          handleClose();
          callAgainHandleClick();
        } else {
          throw new error("failed to update");
        }
      }
      catch (error) {
        setSnack({
          message: "Error occurred during feedback!",
          open: true,
          type: "error",
        });
        console.log("ERROR", { ...error });
      }
    }
  }
  });

  useEffect(() => {
    if (userInfo.type == "admin") {
      formik.setFieldValue("q1answer", feedbackDetails?.questions[0].q1a, false);
      formik.setFieldValue("q2answer", feedbackDetails?.questions[1].q2a, false);
      formik.setFieldValue("q3answer", feedbackDetails?.questions[2].q3a, false);
      formik.setFieldValue("q4answer", feedbackDetails?.questions[3].q4a, false);
      formik.setFieldValue("q5answer", feedbackDetails?.questions[4].q5a, false);
    }
  }, [])

  return (
    <Card sx={{ px: "40px", py: "20px" }}>
      <form onSubmit={formik.handleSubmit} method="post">
        <MDBox my={1} sx={{ textAlign: "center" }}>
          {userInfo.type == "admin" ? (
            <MDBox>
              <MDTypography variant="h4" color="dark" sx={{ display: "inline-block" }}>Feedback Sent</MDTypography>
            </MDBox>
          ) : (
            <MDTypography variant="h4" color="dark" sx={{ display: "inline-block" }}>Feedback Form</MDTypography>
          )}
        </MDBox>
        <MDBox display="flex" justifyContent="space-between">
          <MDBox >
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Engagement ID&nbsp;:
            </MDTypography>
            <MDTypography px={1} py={1} variant="button" fontWeight="bold" color="info" textTransform="capitalize">
              {feedbackDetails.engagementId}
            </MDTypography><br />
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              GigTalent name&nbsp;:
            </MDTypography>
            {userInfo.type == "admin" ? (
              <>
                <MDTypography px={1} py={1} variant="button" fontWeight="bold" color="info" textTransform="capitalize">
                  {feedbackDetails.giglerName}
                </MDTypography><br />
              </>
            ) : (
              <>
                <MDTypography px={1} py={1} variant="button" fontWeight="bold" color="info" textTransform="capitalize">
                  {feedbackDetails.users.firstName}{feedbackDetails.users.lastName}
                </MDTypography><br />
              </>
            )}
          </MDBox>
          <MDBox>
            <MDTypography variant="button" fontWeight="medium">
              Date&nbsp;:
            </MDTypography>
            <MDTypography variant="button" color="info" fontWeight="medium">
              &nbsp;{moment(feedbackDetails?.createdAt).format("DD-MM-YYYY")}
            </MDTypography>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox>
          {userInfo.type == "admin" ? (
            <>
              <MDTypography px={1} py={1} variant="button" >
                Q1: Did the GigTalent have adequate technical expertise to execute the engagement?
              </MDTypography>
              <MDTypography py={1} fontWeight="medium" variant="button"><br />
                Ans : {feedbackDetails?.questions[0].q1a}
              </MDTypography>
            </>
          ) : (
            <>
              <MDTypography px={1} py={1} variant="button" fontWeight="bold" textTransform="capitalize">
                Q1: Did the GigTalent have adequate technical expertise to execute the engagement?
              </MDTypography>
              <MDInput
                type="text"
                fullWidth
                name="q1answer"
                {...formik.getFieldProps("q1answer")}
              />
            </>
          )}
        </MDBox>
        <MDBox>
          {userInfo.type == "admin" ? (
            <>
              <MDTypography px={1} py={1} variant="button" >
                Q2: Is the GigTalent a good communicator?
              </MDTypography>
              <MDTypography px={1} py={1} fontWeight="medium" variant="button"><br />
                Ans : {feedbackDetails?.questions[1].q2a}
              </MDTypography>
            </>
          ) : (
            <>
              <MDTypography px={1} py={2} variant="button" fontWeight="bold" textTransform="capitalize">
                Q2: Is the GigTalent a good communicator?
              </MDTypography>
              <MDInput
                type="text"
                fullWidth
                name="q2answer"
                {...formik.getFieldProps("q2answer")}
              />
            </>
          )}
        </MDBox>
        <MDBox>
          {userInfo.type == "admin" ? (
            <>
              <MDTypography px={1} py={1} variant="button" >
                Q3: How was the quality of the GigTalents deliverables?
              </MDTypography>
              <MDTypography px={1} py={1} fontWeight="medium" variant="button"><br />
                Ans : {feedbackDetails?.questions[2].q3a}
              </MDTypography>
            </>
          ) : (
            <>
              <MDTypography px={1} py={2} variant="button" fontWeight="bold" textTransform="capitalize">
                Q3: How was the quality of the GigTalents deliverables?
              </MDTypography>
              <MDInput
                type="text"
                fullWidth
                name="q3answer"
                {...formik.getFieldProps("q3answer")}
              />
            </>
          )}
        </MDBox>
        <MDBox>
          {userInfo.type == "admin" ? (
            <>
              <MDTypography px={1} py={1} variant="button" >
                Q4:	Was the GigTalent a team player and helping co-workers?
              </MDTypography>
              <MDTypography px={1} py={1} fontWeight="medium" variant="button"><br />
                Ans : {feedbackDetails?.questions[3].q4a}
              </MDTypography>
            </>
          ) : (
            <>
              <MDTypography px={1} py={2} variant="button" fontWeight="bold" textTransform="capitalize">
                Q4:	Was the GigTalent a team player and helping co-workers?
              </MDTypography>
              <MDInput
                type="text"
                fullWidth
                name="q4answer"
                {...formik.getFieldProps("q4answer")}
              />
            </>
          )}
        </MDBox>
        <MDBox>
          {userInfo.type == "admin" ? (
            <>
              <MDTypography px={1} py={1} variant="button" >
                Q5: Did the GigTalent performed additional tasks outside of his role?
              </MDTypography>
              <MDTypography px={1} py={1} fontWeight="medium" variant="button"><br />
                Ans : {feedbackDetails?.questions[4].q5a}
              </MDTypography>
            </>
          ) : (
            <>
              <MDTypography px={1} py={2} variant="button" fontWeight="bold" textTransform="capitalize">
                Q5: Did the GigTalent performed additional tasks outside of his role?
              </MDTypography>
              <MDInput
                type="text"
                fullWidth
                name="q5answer"
                {...formik.getFieldProps("q5answer")}
              />
            </>
          )}

        </MDBox>
        {
          userInfo.type == "admin" ? (
            <>
              <MDTypography px={1} variant="button" fontWeight="bold">
                Want to work with this GigTalent in future&nbsp;: {feedbackDetails?.workAgainGigler ?
                  (<MDBadge badgeContent="Yes" size="sm" variant="gradient" color="success" container></MDBadge>) : (
                    <MDBadge badgeContent="No" size="sm" variant="gradient" color="error" container></MDBadge>
                  )}
              </MDTypography><br />
              <MDButton sx={{ mt: "10px" }} variant="outlined" color="dark" size="small" onClick={() => handleClose()}>close</MDButton>
            </>
          ) : (
            <>
              <MDBox mt={2}>
                <MDTypography px={1} py={2} variant="button" fontWeight="bold">
                  Want to work with this GigTalent in future&nbsp;:
                </MDTypography>
                <FormControl component="fieldset">
                  <RadioGroup
                    {...formik.getFieldProps("workAgainGigler")}
                  >
                    <Stack direction="row">
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label={
                          <MDTypography color="text" variant="p" fontWeight="regular">
                            Yes
                          </MDTypography>
                        }
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label={
                          <MDTypography color="text" variant="p" fontWeight="regular">
                            No
                          </MDTypography>
                        }
                      />
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </MDBox>
              <MDBox mt={1} >
                <MDButton variant="outlined" color="success" size="small" type="submit">submit</MDButton>
                <MDButton sx={{ ml: "10px" }} variant="outlined" color="dark" size="small" onClick={() => handleClose()}>cancel</MDButton>
              </MDBox>
            </>
          )
        }
      </form >
    </Card >
  )
}

export default EngagerFeedbackForm