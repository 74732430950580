// react-router-dom components
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";

// @mui material components
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

//icons
import LinkedIn from "assets/images/linkedin_icon.png";
import google from "assets/images/google_icon.png";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// import jwt_decode from "jwt-decode";
import { useLinkedIn } from 'react-linkedin-login-oauth2';

// other
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Images

// import bgImage from "assets/images/Gigleji-App-Landing-Page.-editjpg.jpg";
import bgImage from "assets/images/Gigleji-App-Landing-Page-Edit-301122.jpg";

import logo from "assets/images/gigleji_dark_new_logo.png"
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


//formik and form
import { useFormik } from "formik";
import * as Yup from "yup";
import api from "services/api";
import { Divider, FormControlLabel, FormLabel, Modal, Radio, RadioGroup } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "context/UserProvider";
import TermsAndConditions from "components/TermsAndConditions";
import PrivacyPolicy from "components/PrivacyPolicy";
import { useStyles } from './styles'
import axios from "axios";
import { SnackbarContext } from "context/SnackbarContext";
import EngagerRegistration from "./EngagerRegistration";


const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  password: "",
  type: "gigler",
};

const selectData = {
  type: ["Gigler", "Accessor", "Admin"],
};

const validate = (values) => {
  let errors = {};

  if (!values.firstName) {
    errors.firstName = "Required";
  }
  if (!values.lastName) {
    errors.lastName = "Required";
  }
  if (!values.email) {
    errors.email = "Required";
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = "Required";
  }
  if (!values.password) {
    errors.password = "Required";
  }
  return errors;
};

const phoneRegExp = /^[0-9]*$/;
  // /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object({
  firstName: Yup.string()
    .max(25, "Maximum 25 characters")
    .required("First Name is required"),
  lastName: Yup.string()
    .max(25, "Maximum 25 characters")
    .required("Last Name is required"),
  email: Yup.string().email().required("Email is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Too short")
    .max(10, "Too long"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Your password must be longer than 8 characters.")
    .max(25)
    .matches(/^(?=.{8,})/, "Must Contain 8 Characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])/,
      "Must Contain One Uppercase, One Lowercase"
    )
    .matches(
      /^(?=.*[!@#\$%\^&\*])/,
      "Must Contain One Special Case Character"
    )
    .matches(/^(?=.{6,20}$)\D*\d/, "Must Contain One Number"),
});

function Illustration() {
  let temp2;
  const css = useStyles();
  const { setSnack } = useContext(SnackbarContext);
  const [verify, setVerify] = useState(false);
  const [showAccept, setShowAccept] = useState(false);
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [googleData, setGoogleData] = useState();
  const [accept, setAccept] = useState(true);
  const [isLinkedIn, setIslinkedIn] = useState(false);
  const [isGoogle,setIsGoogle]=useState(false);
  const [register, setRegister] = useState(true);
  const handleClose = () => {
    setOpen(false);
    setChecked(true);
  }
  const { setIsAuthenticated, setUserInfo } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [linkType, setLinkType] = useState('terms');
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleBackdropClose = () => {
    setOpenBackdrop(false);
  };
  const handleBackdropOpen = () => {
    setOpenBackdrop(true);
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: '78v9aardd73e5v',
    scope: 'r_liteprofile,r_emailaddress',
    redirectUri: `${window.location.origin}/`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: (code) => {
      console.log(code);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    console.log('url', window.location.origin);
    let code = searchParams.get("code")
    console.log("code", code);
    if (code) {
      handleBackdropOpen();
      let reqData = {
        grant_type: 'authorization_code',
        code: code,
        // client_id: '78v9aardd73e5v',
        // client_secret: 'wgb0Z3ZmY1a3AH65',
        redirect_uri: `${window.location.origin}/`
      }
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
      api.users.registerLinkedIn(reqData).then(result => {
        console.log("register", result);

        if (result?.data?.result?.status === "old-user") {
          console.log("old user");
          let temp = result?.data?.result?.userData;
          setIsAuthenticated(true);
          setUserInfo(temp);
          console.log("user info", temp);
          localStorage.setItem("authToken", temp?.token);
          localStorage.setItem("user", JSON.stringify({ isAuthenticated: true, userInfo: temp }));
          handleBackdropClose();
          navigate(`/account/profile`);
        } else if (result?.data?.result?.status === "new-user") {
          const gigId = result?.data?.result?.data?.id;
          let userData = result?.data?.result?.data;
          console.log(gigId);
          const register = {
            userId: gigId,
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            avatar: userData.picture,
            type: "gigler"
          };
          api.userInfo.create(register).then((res) => {
            console.log(result?.data?.result?.data);
            let temp = result?.data?.result?.data;
            if (temp) {
              setIsAuthenticated(true);
              setUserInfo(temp);
              console.log("user info", temp);
              localStorage.setItem("authToken", temp?.token);
              localStorage.setItem("user", JSON.stringify({ isAuthenticated: true, userInfo: temp }));
              handleBackdropClose();
              navigate(`/account/profile`);
            }
          });
        }
      })
    }
  }, []);

  let navigate = useNavigate();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const userEmail = values.email
      api.users.findOneUser({ userEmail: userEmail })
        .then(data => {
          if (data?.data?.message == "success") {
            setSnack({
              message: `${values.email} Already exits`,
              open: true,
              type: "error",
            });
          } else {
            values.type = "gigler";
            values.email = (values.email).toLowerCase();
            temp2 = values;
            const temp3 = api.verifications.verify({ email: values.email, firstName: values.firstName });
            console.log(temp3);
            navigate("/register/verification", {
              state: {
                values: values,
              },
            });
          }
        }).catch(err => console.log("error", err))
    },
  });

  const handleAccept = () => {
    console.log(isLinkedIn,isGoogle);
    if (isLinkedIn && !isGoogle) {
      // setOpen(false);
      linkedInLogin();
    }
    else if(!isLinkedIn&&isGoogle){
      googleLogin();
    }
    else {
      console.log("accepted", googleData)
      api.users.registerGoogle(googleData).then((result) => {
        console.log("register", result?.data?.result?.status);
        handleClose();
        if (result?.data?.result?.status === "old-user") {
          console.log("userinfo.........", result?.data?.result?.data[0]);
          let temp = result?.data?.result?.data[0];
          setIsAuthenticated(true);
          setUserInfo(temp);
          localStorage.setItem('authToken', temp?.token);
          localStorage.setItem("user", JSON.stringify({ isAuthenticated: true, userInfo: temp }));
          navigate(`/account/profile`);
        } else if (result?.data?.result?.status === "new-user") {
          const gigId = result?.data?.result?.data?.id;
          console.log(gigId);
          const register = {
            userId: gigId,
            firstName: googleData.firstName,
            lastName: googleData.lastName,
            email: googleData.email,
            avatar: googleData.picture,
          };
          api.userInfo.create(register).then((res) => {
            console.log(result?.data?.result?.data);
            let temp = result?.data?.result?.data;
            if (temp) {
              setIsAuthenticated(true);
              setUserInfo(temp);
              localStorage.setItem('authToken', temp?.token);
              localStorage.setItem("user", JSON.stringify({ isAuthenticated: true, userInfo: temp }));
              navigate(`/account/profile`);
            }
          });
        }
      });
    }
  }
  // const responseGoogle = async (response) => {
  //   var decoded = jwt_decode(response?.credential);
  //   console.log(decoded);

  //   let userData = {
  //     firstName: decoded.given_name,
  //     lastName: decoded.family_name,
  //     email: decoded.email,
  //     password: "",
  //     type: "gigler",
  //   };
  //   console.log("signup details:::", userData);
  //   setGoogleData(userData);
  //   // setOpen(true);
  //   api.users.registerGoogle(userData).then((result) => {
  //     console.log("register", result?.data?.result?.status);

  //     if (result?.data?.result?.status === "old-user") {
  //       console.log("userinfo.........", result?.data?.result?.data[0]);
  //       let temp = result?.data?.result?.data[0];
  //       setIsAuthenticated(true);
  //       setUserInfo(temp);
  //       localStorage.setItem('authToken', temp?.token);
  //       localStorage.setItem("user", JSON.stringify({ isAuthenticated: true, userInfo: temp }));
  //       navigate(`/account/profile`);
  //     } else {
  //       const gigId = result?.data?.result?.data?.id;
  //       console.log(gigId);
  //       const register = {
  //         userId: gigId,
  //         firstName: userData.firstName,
  //         lastName: userData.lastName,
  //         email: userData.email,
  //         avatar: userData.picture,
  //       };
  //       api.userInfo.create(register).then((res) => {
  //         console.log(result?.data?.result?.data);
  //         let temp = result?.data?.result?.data;
  //         if (temp) {
  //           setIsAuthenticated(true);
  //           setUserInfo(temp);
  //           localStorage.setItem('authToken', temp?.token);
  //           localStorage.setItem("user", JSON.stringify({ isAuthenticated: true, userInfo: temp }));
  //           navigate(`/account/profile`);
  //         }
  //       });
  //     }
  //   });
  // };

  const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      // console.log(tokenResponse);
      // fetching userinfo can be done on the client or the server
      if (tokenResponse) {
        console.log("token", tokenResponse);
        const userInfo = await axios
          .get('https://www.googleapis.com/oauth2/v3/userinfo', {
            headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
          })
          .then(res => res.data);
        console.log("Google login@@@@@@@", userInfo);
        if (userInfo?.email && userInfo.given_name) {
          let userData = {
            firstName: userInfo.given_name,
            lastName: userInfo.family_name,
            email: userInfo.email,
            password: "",
            type: "gigler",
          };
          console.log("signup details:::", userData);
          setGoogleData(userData);
          setShowAccept(true);
          setOpen(true);
        } else {
          setSnack({
            message: `Error Login using Google please try again`,
            open: true,
            type: "error",
          });
        }
      } else {
        setSnack({
          message: `Error Login using Google please try again`,
          open: true,
          type: "error",
        });
      }
    },
  });

  return (
    <IllustrationLayout illustration={bgImage}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox
        // className={css.screen}
        sx={{ textAlign: "center", maxWidth: "420px",  mx: "auto", my: "10%", mb:"30px", display:'relative'  }}
      >
        <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <MDAvatar src={logo} alt="GIGLEJI" sx={{ width: "25%" }} variant="square" />
        </MDBox>
        <>
          <MDTypography variant="h5" color="text" mt={0} mb={0} sx={{ marginTop: "20px" }}>
            Register here
          </MDTypography>
          <FormControl>
            <RadioGroup
              row
              defaultValue="gigler"
              name="radio-buttons-group"
            >
              <FormControlLabel value="gigler" control={<Radio onChange={() => setRegister(true)} />} label={<MDTypography variant="button" color="info" fontWeight="medium">As GigTalent</MDTypography>} />
              <FormControlLabel value="engager" control={<Radio onChange={() => setRegister(false)} />} label={<MDTypography variant="button" color="info" fontWeight="medium">As Engager</MDTypography>} />
            </RadioGroup>
          </FormControl>
          {register &&
            <MDBox className="giglerSignup">
              <MDBox
                sx={{
                  display: "flex",
                  mt: "2px",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <MDBox>
                  <MDButton
                    // disabled
                    size="small"
                    color="text"
                    variant="text"
                    type="button"
                    sx={{ float: "left", p: "10px" }}
                    onClick={() => { setShowAccept(true); setIslinkedIn(true); setOpen(true); setIsGoogle(false)}}
                  >
                    <MDAvatar src={LinkedIn} sx={{ height: "30px", width: "30px" }} variant="square" />
                  </MDButton>
                </MDBox>
                <MDTypography variant="button" color="text" mx={2}>
                  Or
                </MDTypography>
                <MDButton
                  size="small"
                  color="text"
                  variant="text"
                  type="button"
                  sx={{ float: "left", border: "0px" }}
                  onClick={() => { setShowAccept(true); setIsGoogle(true); setOpen(true); setIslinkedIn(false) }}
                >
                  <MDAvatar
                    src={google}
                    sx={{ height: "30px", width: "30px" }}
                    variant="square"
                  />
                </MDButton>
              </MDBox>
              <Divider variant="middle"></Divider>
              <form onSubmit={formik.handleSubmit} method="post">
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  sx={{ alignItems: "center", justifyContent: "center" }}
                >
                  <Grid item xs={6} sm={6}>
                    <MDBox mb={0} >
                      <MDInput
                        type="text"
                        label="First Name"
                        variant="standard"
                        name="firstName"
                        value=""
                        {...formik.getFieldProps("firstName")}
                        error={formik.touched.firstName && formik.errors.firstName ? true : false}
                        helperText={
                          formik.touched.firstName && formik.errors.firstName
                            ? formik.errors.firstName
                            : null
                        }
                        fullWidth
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <MDBox mb={0}>
                      <MDInput
                        type="text"
                        label="Last Name"
                        name="lastName"
                        variant="standard"
                        value=""
                        {...formik.getFieldProps("lastName")}
                        error={formik.touched.lastName && formik.errors.lastName ? true : false}
                        helperText={
                          formik.touched.lastName && formik.errors.lastName
                            ? formik.errors.lastName
                            : null
                        }
                        fullWidth
                      />
                    </MDBox>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <MDBox mb={0}>
                      <MDInput
                        type="email"
                        label="Email"
                        name="email"
                        variant="standard"
                        value=""
                        {...formik.getFieldProps("email")}
                        inputProps={{ style: { textTransform: "lowercase" } }}
                        error={formik.touched.email && formik.errors.email ? true : false}
                        helperText={
                          formik.touched.email && formik.errors.email ? formik.errors.email : null
                        }
                        fullWidth
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <MDBox mb={0}>
                      <MDInput
                        type="tel"
                        label="Phone number"
                        name="phoneNumber"
                        variant="standard"
                        // placeholder="+91"
                        value=""
                        inputProps={{ type: "number" }}
                        onKeyPress = {(event) => {
                          if(!phoneRegExp.test(event.key))
                          event.preventDefault();
                        }}
                        {...formik.getFieldProps("phoneNumber")}
                        error={formik.touched.phoneNumber && formik.errors.phoneNumber ? true : false}
                        helperText={
                          formik.touched.phoneNumber && formik.errors.phoneNumber
                            ? formik.errors.phoneNumber
                            : null
                        }
                        fullWidth
                      />
                    </MDBox>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    {/* <MDBox mb={0.5}> */}
                      <FormControl sx={{ width: '100%' }} variant="standard">
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <Input
                          type={showPassword ? 'text' : 'password'}
                          label="Password"
                          variant="standard"
                          name="password"
                          value=""
                          {...formik.getFieldProps("password")}
                          endAdornment={
                            <InputAdornment position="end" >
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                sx={{marginRight:'10'}}
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                          error={formik.touched.password && formik.errors.password ? true : false}
                          success={formik.values.password > 0 && !formik.errors.password ? true : false}
                          helperText={
                            formik.touched.password && formik.errors.password
                              ? formik.errors.password
                              : null
                          }
                          fullWidth
                        />
                         {formik.touched.password && formik.errors.password && (
                         <div style={{fontSize:'12px', textAlign:'left'}} className="text-danger">{formik.errors.password}</div>
                           )}
                      </FormControl>
                    {/* </MDBox> */}
                  </Grid>
                </Grid>

                <MDBox display="flex" alignItems="center" ml={-1} mt={2}>
                  <Checkbox checked={checked} onChange={() => setChecked(!checked)} />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{  userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;I Agree&nbsp;
                  </MDTypography>
                  <MDTypography
                    // component="a"
                    // href={`https://docs.google.com/gview?url=http${pdf}&embedded=true`}
                    // target="blank"
                    variant="button"
                    fontWeight="bold"
                    color="info"
                    // textGradient
                    onClick={() => {setLinkType('terms'); setOpen(true)}}
                    sx={{ cursor: "pointer" }}
                  >
                    Terms and Conditions
                  </MDTypography>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{  userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;and&nbsp;&nbsp;
                  </MDTypography>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    color="info"
                    // textGradient
                    onClick={() => {setLinkType('privacy');setOpen(true);}}
                    sx={{ cursor: "pointer" }}
                  >
                    Privacy Policy
                  </MDTypography>
                  <Modal
                    open={open}
                    onClose={handleClose}
                  >
                    <MDBox className={css.modal}>
                      {linkType == 'terms' ?
                        (<TermsAndConditions handleClose={handleClose} showAccept={showAccept} handleAccept={handleAccept} />)
                        :(<PrivacyPolicy handleClose={handleClose} />)
                      }
                    </MDBox>
                  </Modal>

                </MDBox>
                <MDBox mt={1.5}>
                  <MDButton
                    //variant="gradient" 
                    color="info" type="submit" fullWidth
                    disabled={!checked}>
                    Login & sign Up
                  </MDButton>
                </MDBox>
                <MDBox mt={1.5} textAlign="center">
                  <MDTypography variant="button" color="text">
                    Already have an account?
                    <MDTypography
                      component={Link}
                      to="/login"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                    // textGradient
                    >
                      Sign In
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </form>
            </MDBox>
          }
          {!register &&
            <MDBox className="engagerSignup">
              <EngagerRegistration />
            </MDBox>
          }
        </>
      </MDBox >
    </IllustrationLayout >
  );
}

export default Illustration;
