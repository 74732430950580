import { Button, Card, Grid } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "services/api";
import { SnackbarContext } from "context/SnackbarContext";
import LoadingButton from "@mui/lab/LoadingButton";

const SignContractAdmin = () => {
  // const { userInfo } = useContext(UserContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { setSnack } = useContext(SnackbarContext);
  const [gigDetails, setGigDetails] = useState();
  const [engDetails, setEngDetails] = useState({});
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const [contractType, setContractType] = useState("");
  const [uploadFileName, setUploadFileName] = useState("");
  const [formData, setFormData] = useState({});
  const handleCloseFile = () => setOpenFile(false);
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState("");
  const [contractDetails, setContractDetails] = useState({});
  const [contractToGigler, setContractToGigler] = useState();

  useEffect(() => {
    console.log("state values", state);
    console.log("state status", state?.contractStatus);
    setGigDetails(state?.user);
    setContractDetails(state.contractDetails);
    setTemplate(state.contractDetails.documentSent);
    if (state.contractDetails.from == "engager") {
      let params = {
        engagementId: state.selectedEngagements?.engagementId,
        giglerId: state?.user?.userId,
        from: "gigleji",
      };
      api.contract.findOne(params).then((res) => {
        console.log("res", res?.data?.result);
        let temp = res?.data?.result;
        if (temp.data) {
          setContractToGigler(temp.data);
        }
      });
    }
    // setGigDetails(userInfo);
    setEngDetails(state.selectedEngagements);
  }, []);

  const getGiglerContract = () => {
    let params = {
      engagementId: state.selectedEngagements?.engagementId,
      giglerId: state?.user?.userId,
      from: "gigleji",
    };
    api.contract.findOne(params).then((res) => {
      console.log("res", res?.data?.result);
      let temp = res?.data?.result;
      if (temp.data) {
        setContractToGigler(temp.data);
      }
    });
  };

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  const handleClick = () => {
    document.getElementById("resumeFile").click();
  };

  const triggerhandleChangeAdmin = () => {
    document.getElementById("adminResumeFile").click();
  };

  const handleCancel = () => {};

  const handleChangeAdmin = (event) => {
    setLoading(true);
    console.log("clicked", event.target.files[0].name);
    let temp = event.target.files[0];
    setUploadFileName(event.target.files[0].name);
    // setResume(URL.createObjectURL(temp));
    console.log("resume link", URL.createObjectURL(temp));
    const formData = new FormData();
    formData.append("doc", temp);
    formData.append("engagementId", engDetails.engagementId);
    formData.append("engagerId", engDetails.engagerId);
    formData.append("giglerId", gigDetails.userId);
    formData.append("id", contractDetails._id);
    formData.append("receiveDate", new Date());
    formData.append("contractStatus", '1');

    console.log("admin submit", formData);
    api.contract.signContract(formData).then((data) => {
      console.log("resume data", data);
      setLoading(false);
      // navigate(-1);
    });
  };

  const handleChange = (event) => {
    setLoading(true);
    console.log("clicked", event.target.files[0].name);
    let temp = event.target.files[0];
    setUploadFileName(event.target.files[0].name);
    // setResume(URL.createObjectURL(temp));
    console.log("resume link", URL.createObjectURL(temp));
    const formData = new FormData();
    formData.append("doc", temp);

    formData.append("engagementId", engDetails.engagementId);
    formData.append("engagerId", engDetails.engagerId);
    formData.append("giglerId", gigDetails.userId);
    formData.append("id", contractDetails._id);
    formData.append("from", "gigleji");
    formData.append("to", "gigler");

    console.log("form data", formData);

    api.contract.contractUpdate(formData).then((data) => {
      console.log("resume data", data);
      setLoading(false);
      getGiglerContract();
      // navigate(-1);
      // navigate("/account/contracts");
    });
  };

  const formatDate = (string) => {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  };

  const endContract = () => {
    console.log("co", contractToGigler, state);
    let reqData = {
      id: contractToGigler.id,
      engagementId: state?.engagementId,
      giglerId: state?.giglerId,
      contractStatus: "6",
      endDate: new Date(),
    };
    console.log("req data", reqData);
    api.contract.endContract(reqData).then((data) => {
      console.log(data?.data?.message);
      if (data?.data?.message == "success") {
        setSnack({
          message: `Contract Closed For GigTalent`,
          open: true,
          type: "success",
        });
      }
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox position="relative" mb={5}>
        <Card
          sx={{
            position: "relative",
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={6}>
              <MDBox pt={2} px={2} pb={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Engagement Information
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Name&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{engDetails?.engagementId}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                {/* <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Email&nbsp;: &nbsp;
                </MDTypography> */}
                {/* <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{state?.engagementTitle}
                </MDTypography> */}
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <MDBox pt={2} px={2} pb={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  GigTalent Information
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Name&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{gigDetails?.firstName}&nbsp;{gigDetails?.lastName}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Email&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{gigDetails?.email}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
        <Card
          sx={{
            position: "relative",
            mt: 2,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} xl={12}>
              <MDBox pt={2} px={2} pb={2} sx={{ display: "flex", justifyContent: "space-between" }}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Download Contract
                </MDTypography>
                <MDBox>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Status:{" "}
                    {contractToGigler?.documentSent && !contractToGigler?.documentReceived
                      ? "Pending at GigTalent"
                      : state?.contractStatus == "1" ? "Contract Signed with GigTalent and Engager"
                      : state?.contractStatus == "0"
                      ? "Sent by Engager"
                      : state?.contractStatus == "4"
                      ? "Started"
                      : state?.contractStatus == "5"
                      ? "Contract Ended By Engager"
                      : state?.contractStatus == "6"
                      ? "Contract Ended with Engager And GigTalent"
                      : contractToGigler?.documentSent && contractToGigler?.documentReceived && state?.contractStatus !=1 ? "GigTalent Signed"
                      : "Pending"}
                  </MDTypography>
                </MDBox>
                {state?.contractStatus == "3" && (
                  <MDButton
                    color="warning"
                    size="small"
                    variant="outlined"
                    target="_blank"
                    // href={template}
                    onClick={endContract}
                    sx={{ ml: "10px" }}
                  >
                    End Contract For GigTalent
                  </MDButton>
                )}
              </MDBox>
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <MDBox px={2}>
                {contractDetails.from == "gigleji" ? (
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    color="text"
                    textTransform="capitalize"
                  >
                    Contract Sent by Admin to {contractDetails.to} on{" "}
                    {formatDate(contractDetails?.sentDate)}
                  </MDTypography>
                ) : (
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    color="text"
                    textTransform="capitalize"
                  >
                    Download Contract sent by {contractDetails.from} on{" "}
                    {formatDate(contractDetails?.sentDate)}
                  </MDTypography>
                )}
                <MDButton
                  color="info"
                  size="small"
                  variant="outlined"
                  target="_blank"
                  href={template}
                  sx={{ ml: "10px" }}
                >
                  Download
                </MDButton>
              </MDBox>
              <MDBox pt={2} px={2} sx={{ display: "flex", justifyContent: "space-between" }}>
                <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                  Send A Contract to GigTalent for this Engagement
                </MDTypography>
                <MDButton
                  color="info"
                  size="small"
                  variant="outlined"
                  target="_blank"
                  href={engDetails?.contractTemplate?.giglejiToGigler}
                  sx={{ ml: "10px" }}
                >
                  Download Template
                </MDButton>
              </MDBox>
              <MDBox pt={1} px={2} pb={2}>
                <input
                  type="file"
                  name="resumeFile"
                  id="resumeFile"
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  onChange={(e) => handleChange(e)}
                  style={{ display: "none" }}
                />
                <input
                  type="file"
                  name="adminResumeFile"
                  id="adminResumeFile"
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  onChange={(e) => handleChangeAdmin(e)}
                  style={{ display: "none" }}
                />
                {uploadFileName && (
                  <MDTypography variant="button" fontWeight="regular" color="text" pr={2}>
                    {uploadFileName}
                  </MDTypography>
                )}
                {contractToGigler && (
                  <MDBox>
                    <MDBox>
                      <MDTypography variant="button" fontWeight="regular" color="text" pr={2}>
                        Contract Sent to GigTalent on {formatDate(contractToGigler?.sentDate)}
                      </MDTypography>
                      <MDButton
                        color="info"
                        size="small"
                        variant="outlined"
                        target="_blank"
                        href={contractToGigler?.documentSent}
                        sx={{ ml: "10px" }}
                      >
                        Download
                      </MDButton>
                    </MDBox>
                    {contractToGigler?.documentReceived && !contractDetails?.documentReceived ? (
                      <MDBox>
                        <MDBox>
                          <MDTypography variant="button" fontWeight="regular" color="text" pr={2}>
                            Contract signed by GigTalent on{" "}
                            {formatDate(contractToGigler?.receiveDate)}
                          </MDTypography>
                          <MDButton
                            color="info"
                            size="small"
                            variant="outlined"
                            target="_blank"
                            href={contractToGigler?.documentReceived}
                            sx={{ ml: "10px" }}
                          >
                            Download
                          </MDButton>
                        </MDBox>
                        <MDTypography
                          variant="button"
                          fontWeight="medium"
                          textTransform="capitalize"
                          pr={2}
                        >
                          Complete the contract with engager
                        </MDTypography>
                        <LoadingButton
                          size="small"
                          onClick={() => triggerhandleChangeAdmin()}
                          loading={loading}
                          variant="outlined"
                          color="dark"
                          sx={{
                            color: "rgb(52, 71, 103)",
                            border: "1px solid rgb(52, 71, 103)",
                          }}
                        >
                          Upload & submit
                        </LoadingButton>
                      </MDBox>
                    ) : state?.contractStatus == "1" && contractToGigler?.documentReceived && contractDetails?.documentReceived ? (
                      <>
                       <MDBox>
                          <MDTypography variant="button" fontWeight="regular" color="text" pr={2}>
                            Contract signed by GigTalent on{" "}
                            {formatDate(contractToGigler?.receiveDate)}
                          </MDTypography>
                          <MDButton
                            color="info"
                            size="small"
                            variant="outlined"
                            target="_blank"
                            href={contractToGigler?.documentReceived}
                            sx={{ ml: "10px" }}
                          >
                            Download
                          </MDButton>
                        </MDBox>
                      <MDTypography variant="button" fontWeight="regular" color="text" pr={2}>
                        Contract Signed and sent to Engager
                      </MDTypography>
                      </>
                    ) : state?.contractStatus == "0" ? (
                      <MDTypography variant="button" fontWeight="regular" color="text" pr={2}>
                        Waiting for GigTalent to sign Contract
                      </MDTypography>
                    ) : (<></>)}
                  </MDBox>
                )}
                {state?.contractStatus == "0" && !contractToGigler ? (
                  <MDBox>
                    <MDTypography variant="button" fontWeight="regular" color="text" pr={2}>
                      Send Contract to GigTalent
                    </MDTypography>
                    <LoadingButton
                      size="small"
                      onClick={() => handleClick()}
                      loading={loading}
                      variant="outlined"
                      color="dark"
                      sx={{
                        color: "rgb(52, 71, 103)",
                        border: "1px solid rgb(52, 71, 103)",
                      }}
                    >
                      Upload & submit
                    </LoadingButton>
                  </MDBox>
                ) : state?.contractStatus == "4" ? (
                  <>
                       <MDBox>
                          <MDTypography variant="button" fontWeight="regular" color="text" pr={2}>
                            Contract signed by GigTalent on{" "}
                            {formatDate(contractToGigler?.receiveDate)}
                          </MDTypography>
                          <MDButton
                            color="info"
                            size="small"
                            variant="outlined"
                            target="_blank"
                            href={contractToGigler?.documentReceived}
                            sx={{ ml: "10px" }}
                          >
                            Download
                          </MDButton>
                        </MDBox>
                  <MDTypography variant="button" fontWeight="regular" color="text" pr={2}>
                    Contract Started on {formatDate(contractDetails?.startDate)} Tentative end date{" "}
                    {formatDate(contractDetails.tentativeEndDate)}
                  </MDTypography>
                  </>
                ) : state?.contractStatus == "1" && contractDetails.from == "gigleji" ? (
                  <MDTypography variant="button" fontWeight="regular" color="text" pr={2}>
                    Waiting for GigTalent to Sign Contract
                  </MDTypography>
                ) : state?.contractStatus == "5" || state?.contractStatus == "6" ? (
                  <>
                       <MDBox>
                          <MDTypography variant="button" fontWeight="regular" color="text" pr={2}>
                            Contract signed by GigTalent on{" "}
                            {formatDate(contractToGigler?.receiveDate)}
                          </MDTypography>
                          <MDButton
                            color="info"
                            size="small"
                            variant="outlined"
                            target="_blank"
                            href={contractToGigler?.documentReceived}
                            sx={{ ml: "10px" }}
                          >
                            Download
                          </MDButton>
                        </MDBox>
                  <MDBox>
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      color="text"
                      textTransform="capitalize"
                    >
                      Engager Started Contract on {formatDate(contractDetails?.startDate)} And Ended
                      on {formatDate(contractDetails?.endDate)}
                    </MDTypography>
                  </MDBox>
                  </>
                ) : (
                  <></>
                )}
                {/* <LoadingButton
                  size="small"
                  onClick={() => handleClick()}
                  loading={loading}
                  variant="outlined"
                  color="dark"
                  sx={{
                    color: "rgb(52, 71, 103)",
                    border: "1px solid rgb(52, 71, 103)",
                  }}
                >
                  Upload & submit
                </LoadingButton> */}
                {/* <MDButton
                  sx={{ ml: 2 }}
                  variant="outlined"
                  size="small"
                  color="warning"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </MDButton> */}
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default SignContractAdmin;
