// Otis Admin PRO React layouts
import Accessor from "layouts/dashboards/analytics/accessor";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import AllProjects from "layouts/pages/profile/all-projects";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Timeline from "layouts/pages/projects/timeline";
import PricingPage from "layouts/pages/pricing-page";
import Widgets from "layouts/pages/widgets";
import RTL from "layouts/pages/rtl";
import Charts from "layouts/pages/charts";
import Notifications from "layouts/pages/notifications";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ResetCover from "layouts/authentication/reset-password/cover";
// import EngagementPosting from "layouts/pages/EngagementPosting";

// Otis Admin PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';

// Images
import profilePicture from "assets/images/team-3.jpg";
import CurrentEngagement from "layouts/pages/account/settings/components/CurrentEngagement";
import AssignedGiglers from "layouts/pages/AssessorDashboard/AssignedGiglers";
import AssessedGiglers from "layouts/pages/AssessorDashboard/assessedGiglers";
import AssessorProfile from "layouts/pages/AssessorDashboard/assessorProfile";
import ComingSoon from "components/ComingSoon";

const routes = [
  {
    type: "route",
    name: "Dashboard",
    key: "dashboard",
    route: "/assessor/dashboard",
    icon: <DashboardIcon />,
    component: process.env.REACT_APP_STAGE == "Prod" || process.env.REACT_APP_STAGE == "Dev" ? <Accessor /> : <ComingSoon/>
    // component: <Accessor />,
  },
  {
    type: "route",
    name: "Profile",
    key: "my-profile",
    route: "/assessor/profile",
    icon: <AccountBoxOutlinedIcon />,
    component: <AssessorProfile />,
  },
  {
    type: "route",
    name: "Assigned GigTalent",
    key: "assigned-gigTalent",
    route: "/assessor/assigned-gigTalent",
    icon: <RecentActorsIcon />,
    component: <AssignedGiglers />,
  },
  {
    type: "route",
    name: "Assessed GigTalent",
    key: "assessed-gigTalent",
    route: "/assessor/assessed-gigTalent",
    component: <AssessedGiglers />,
    icon: <SwitchAccountIcon />,

  },
  // {
  //   type: "route",
  //   name: "Assessed Giglers",
  //   key: "engagement-posting",
  //   route: "/assessor/engagement-posting",
  //   component: <EngagementPosting />,
  // },
  // {
  //   type: "route",
  //   name: "Profile Search",
  //   key: "profile-search",
  //   route: "/assessor/profile-search",
  //   component: <Settings />,
  // },
  // {
  //   type: "route",
  //   name: "Contract Management",
  //   key: "contract-management",
  //   route: "/assessor/contract-management",
  //   component: <Timeline />,
  // },
  // {
  //   type: "route",
  //   name: "Engagement Details",
  //   key: "engagement-details",
  //   route: "/assessor/engagement-details",
  //   component: <Timeline />,
  // },
  // {
  //   type: "route",
  //   name: "Engagement History",
  //   key: "engagement-history",
  //   route: "/assessor/engagement-history",
  //   component: <Timeline />,
  // },
];

export default routes;
