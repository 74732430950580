import { Box, Card, Grid } from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import MDAvatar from './MDAvatar';
import MDBox from './MDBox';
import MDTypography from './MDTypography';
// import backgroundImage from "assets/images/bg-profile.jpeg";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import Autocomplete from "@mui/material/Autocomplete";

import { useFormik } from "formik";
import * as Yup from "yup";
import api from "services/api";
import MDButton from './MDButton';
import { SnackbarContext } from 'context/SnackbarContext';
import EditAssessorProfile from 'layouts/pages/AdminDashboard/ManageAssessors/EditAssessorProfile';
import backgroundImage from "assets/images/Gigleji-App-Profile-Background.jpg";
import AssignedGtList from 'layouts/pages/AdminDashboard/ManageAssessors/AssignedGtList';
import AssessedGtList from 'layouts/pages/AdminDashboard/ManageAssessors/AssessedGtList';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const AssessorProfile = () => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const [details, setDetails] = useState(state.value);
  const { setSnack } = useContext(SnackbarContext);
  const [show, setShow] = useState(true);

  const handleEdit = () => {
    setShow(!show);
  }

  const handleDelete = (value) => {
    console.log("assessor id", value.assessorId)
    let id = value.assessorId
    let data = {
      status: '0'
    }
    api.assessor.update(id, data)
      .then(res => {
        let uid = value.assessorId
        let data2 = {
          status: '0'
        }
        api.users.updateUser(uid, data2)
          .then((res) => {
            setSnack({
              message: `${value.email} Assessor is deleted`,
              open: true,
              type: "success",
            });
            navigate("/admin/manage-assessors");
          })
          .catch((err) => {
            setSnack({
              message: `Error occurred while deleting assessor`,
              open: true,
              type: "error",
            });
          })
      })
      .catch((err) => {
        setSnack({
          message: `Error occurred while deleting assessor`,
          open: true,
          type: "error",
        });
      })
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {show ? (
        <>
        <MDBox position="relative" mb={5}>
          <MDBox
            display="flex"
            alignItems="center"
            position="relative"
            minHeight="18.75rem"
            borderRadius="xl"
            sx={{
              backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                ` url(${backgroundImage})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
              overflow: "hidden",
            }}
          />
          <Card
            sx={{
              position: "relative",
              mt: -8,
              mx: 3,
              py: 2,
              px: 2,
            }}
          >
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={1}>
                <MDBox position="relative" height="max-content" mx="auto">
                  <MDAvatar
                    src={details?.avatar}
                    alt="profile picture"
                    size="xl"
                    variant="rounded"
                    shadow="sm"
                  />
                </MDBox>
              </Grid>
              <Grid item xs={7}>
                <MDBox height="100%" mt={0.5} lineHeight={1} dispaly="flex" justifyContent="space-between">
                  <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                    {details?.firstName}&nbsp;{details?.lastName}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={4}>
              <MDButton sx={{ mr: "5px" }} variant="gradient" color="warning" startIcon={<ArrowBackIcon/>} size="small" onClick={() => navigate(-1)}>Back</MDButton>
                <MDButton sx={{ mr: "5px" }} variant="gradient" color="error" size="small" startIcon={<DeleteIcon/>} onClick={() => handleDelete(state.value)}>delete</MDButton>
                <MDButton variant="gradient" color="info" size="small" startIcon={<EditIcon/>} onClick={() => handleEdit()}>edit</MDButton>
              </Grid>
            </Grid>
            <MDBox mt={1}>
              <Grid item xs={12} md={6} xl={4}>
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Name&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.firstName}&nbsp;{details?.lastName}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} px={2} alignItems="center">
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Industry&nbsp;: &nbsp;&nbsp;&nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.industry}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={2} alignItems="center">
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Domain&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.domain}
                  </MDTypography>
                </MDBox>
              </Grid>
            </MDBox>
            <MDBox mt={2} />
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} xl={4}>
                <MDBox alignItems="center" justifyContent="center" pt={2} px={2} pb={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Personal Information
                  </MDTypography>
                </MDBox>
                {/* <MDBox display="flex" py={1} pr={2} px={2}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                Name&nbsp;: &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp;{details?.firstName}&nbsp;{details?.lastName}
              </MDTypography>
            </MDBox> */}
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Email&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.email}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Mobile Number&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.phoneNumber}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Linkedin Profile&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.linkedinProfile}
                  </MDTypography>
                </MDBox>
                {/* <MDBox display="flex" py={1} pr={2} px={2}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                D.O.B&nbsp;: &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp;{details?.birthDate}
              </MDTypography>
            </MDBox> */}
                {/* <MDBox display="flex" py={1} pr={2} px={2}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                location&nbsp;: &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp;{details?.yourLocation}
              </MDTypography>
            </MDBox> */}
                {/* <MDBox display="flex" py={1} pr={2} px={2}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                Current Organization&nbsp;: &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp;{details?.currentOrganization}
              </MDTypography>
            </MDBox> */}
                {/* <MDBox display="flex" py={1} pr={2} px={2}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                Current Position&nbsp;: &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp;{details?.currentPosition}
              </MDTypography>
            </MDBox> */}
                {/* <MDBox display="flex" py={1} pr={2} px={2}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                Industry&nbsp;: &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp;{details?.industry}
              </MDTypography>
            </MDBox> */}
                {/* <MDBox display="flex" py={1} pr={2} px={2}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                Description&nbsp;: &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp;{details?.description}
              </MDTypography>
            </MDBox> */}
                {/* <MDBox display="flex" py={1} pr={2} px={2}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                Domain&nbsp;: &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp;{details?.domain}
              </MDTypography>
            </MDBox> */}
              </Grid>
              <Grid item xs={12} xl={4}>
                <MDBox alignItems="center" justifyContent="center" pt={2} px={2} pb={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Organizational Information
                  </MDTypography>
                </MDBox>
                {/* <MDBox display="flex" py={1} pr={2} px={2}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                Industry&nbsp;: &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp;{details?.industry}
              </MDTypography>
            </MDBox> */}
                {/* <MDBox display="flex" py={1} pr={2} px={2}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                Domain&nbsp;: &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp;{details?.domain}
              </MDTypography>
            </MDBox> */}

                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Current Organization&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.currentOrganization}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Current Position&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.currentPosition}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
            <MDBox sx={{ display: "flex", width: "750px" }}>
              <Grid item xs={12} xl={6}>
                <MDBox alignItems="center" justifyContent="center" pt={2} px={2} pb={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Career Story
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={2} sx={{ border: "2px", width: "650px" }}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Description&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.description}
                  </MDTypography>
                </MDBox>
              </Grid>
            </MDBox>
          </Card>
          <MDBox mx={2} my={2} px={2}>
            <AssignedGtList assrDetails={state.value}/>
          </MDBox>
          <MDBox mx={2} my={2} px={2}>
            <AssessedGtList assrDetails={state.value}/>
          </MDBox>
        </MDBox>
        
        </>

      ) : (
        <EditAssessorProfile details={details} handleEdit={handleEdit} />
      )}
    </DashboardLayout >
  )
}

export default AssessorProfile