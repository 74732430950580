import React, { useContext, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import FormField from "layouts/pages/account/components/FormField";
import MDButton from "components/MDButton";
import { SnackbarContext } from "context/SnackbarContext";

//form
import { useFormik } from "formik";
import * as Yup from "yup";
import api from "services/api";
import { useNavigate } from "react-router-dom";
import { Field, Form, ErrorMessage } from "formik";
import { UserContext } from "context/UserProvider";

function CareerStory({userData,updateUserData}) {
  const { userInfo } = useContext(UserContext);
  const { setSnack } = useContext(SnackbarContext);

  const initialValues = {
    description: "",
  };
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      console.log("description", values);
      try {
        let id = ""
        if(userInfo.type == "admin"){
          id = userData?.userId
        }else{
          id = userInfo?.id
        }
        updateUserData(values);
        const result = await api.userInfo.update(id, values);
        if (result) {
          // navigate(`/account/settings`);
          if (result?.data?.message == "success") {
            setSnack({
              message: "Career Story  details updated!",
              open: true,
              type: "success",
            });
          } else if(result?.data?.message == "error") {
            setSnack({
              message: "Error updating details!",
              open: true,
              type: "error",
            });
          }          
        } else {
          throw new error("failed to update");
        }
      } catch (error) {
        setSnack({
          message: "Error occurred during update!",
          open: true,
          type: "success",
        });
        console.log("ERROR LOGIN: ", { ...error });
      }
    },
  });
  useEffect(() =>{
    if(userData){
      formik.setValues(userData);
    }
  },[userData])
  return (
    <Card id="Career-Story">
      <form onSubmit={formik.handleSubmit} method="post">
        <MDBox p={2}>
          <MDTypography variant="h5" pb={2}>
            Career Story
          </MDTypography>
          <FormField
            label="Description"
            placeholder="Few words about your career..."
            name="description"
            {...formik.getFieldProps("description")}
            multiline
            rows={3}
            inputProps={{ type: "text", maxLength:250 }}
            {...formik.getFieldProps("description")}
            error={formik.touched.description && formik.errors.description ? true : false}
            helperText={
              formik.touched.description && formik.errors.description
                ? formik.errors.description
                : null
            }
          />
          <MDBox pt={1}>
            <MDButton variant="gradient" size="small" color="success" type="submit">
              SAVE
            </MDButton>
            {/* <MDButton variant="gradient" size="small" color="info" sx={{ ml: '8px' }}>EDIT</MDButton> */}
          </MDBox>
        </MDBox>
      </form>
    </Card>
  );
}

export default CareerStory;
