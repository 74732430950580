import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
 
function CircularProgressWithLabel(props) {
  return (
    <div style={{ backgroundColor: 'rgb(38, 36, 36)', display: 'flex', alignItems: 'center', borderRadius:'10px',justifyContent: 'center', height: '50px', width:'100%',position: 'relative',left:'35px'}}>
 
      <CircularProgress variant="determinate" value={props.value} sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }} />
   
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="white" >
          {`${props.minutes}:${props.seconds}`}
        </Typography>
      </Box>
    </div>
  );
}
 
CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
  seconds: PropTypes.number.isRequired,
};
 
export default CircularProgressWithLabel;