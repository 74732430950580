import { Modal } from "@mui/material";
import MDBox from "components/MDBox";
import { SnackbarContext } from "context/SnackbarContext";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "services/api";
import DataTable from "./DataTable";
import { useStyles } from "./styles";

const ContractSignedList = ({ giglerList, engDetails }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [docUrl, setDocUrl] = useState("");
  const [openView, setOpenView] = useState(false);
  const handleCloseFile = () => setOpenView(false);
  const { setSnack } = useContext(SnackbarContext);

  const [details, setDetails] = useState({
    columns: [
      { Header: "GigTalent", accessor: `user.firstName`, width: "20%" },
      { Header: "Status", accessor: `Status`, width: "25%" },
      { Header: "Sent To", accessor: `documentSent`, width: "25%" },
      // { Header: "Contract Receieved", accessor: `documentReceived`, width: "25%" },
      { Header: "Details", accessor: `user.actions` },
    ],
    rows: giglerList,
  });
  const handleClick = (row) => {
    console.log("row", row);
    navigate("/gigTalent-details", {
      state: {
        values: row,
      },
    });
  };

  const viewDoc = (url) => {
    console.log("doc", url);
    setDocUrl(url);
    setOpenView(true);
  };

  const editRow = (row) => {
    console.log("edit", row);
  };
  const cancelRow = (row) => {
    let id = row._id
    console.log("cancelRow", row, id);
    api.engagementProcess
      .updateOne(id, { contractStatus: "3" })
      .then((data) => {
        if(data){
          setSnack({
            message: `Contract Canceled`,
            open: true,
            type: "success",
          });
        }
      })
      .catch((err) => {
        setSnack({
          message: `error occured in Canceling`,
          open: true,
          type: "error",
        });
      });
  };
  const openDetails = (row) => {
    console.log('row',row);
    navigate("/engager/start-contract", {
      state: {
        values: row,
      },
    });    
  }
  return (
    <MDBox>
      <DataTable
        table={details}
        canSearch
        handleClick={handleClick}
        viewDoc={viewDoc}
        editRow={editRow}
        cancelRow={cancelRow}
        type={'engager'}
        openDetails={openDetails}
      />
      <Modal open={openView} onClose={handleCloseFile}>
        <MDBox className={classes.resumeStyle}>
          <iframe
            className={"docx"}
            width="100%"
            height="100%"
            frameBorder="0"
            src={`https://docs.google.com/gview?url=${docUrl}&embedded=true`}
          ></iframe>
        </MDBox>
      </Modal>
    </MDBox>
  );
};

export default ContractSignedList;
