import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// MUI  
import { Grid, Modal } from "@mui/material";
import Card from "@mui/material/Card";
// Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Layouts
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DataTable from "./DataTable";
import { useStyles } from "../../EngagerDashboard/ContractManagement/styles";
import { SnackbarContext } from "context/SnackbarContext";
import api from "services/api";
import { UserContext } from "context/UserProvider";

const ManageContractsAdmin = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { userInfo } = useContext(UserContext);
  const [docUrl, setDocUrl] = useState("");
  const [openView, setOpenView] = useState(false);
  const handleCloseFile = () => setOpenView(false);
  const { setSnack } = useContext(SnackbarContext);
  const [contractToGigler, setContractToGigler] = useState();

  const [details, setDetails] = useState({
    columns: [
      { Header: "Engagement", accessor: `engagementId`, width: "20%" },
      { Header: "Contract Sent By", accessor: `sentBy`, width: "25%" },
      { Header: "GigTalent", accessor: `signedTo`, width: "25%" },
      // { Header: "Document Receieved", accessor: `documentSent`, width: "25%" },
      { Header: "Status", accessor: `Status`, width: "25%" },
      // { Header: "Contract", accessor: `contract` },
      { Header: "Actions", accessor: `user.actions` },
    ],
    rows: [],
  });
  useEffect(() => {
    console.log("userid", userInfo);
    api.userInfo
      .adminEngagements({ contract: true, to: 'gigleji', from: 'gigleji' })
      .then((data) => {
        // console.log("list of assessors", data);
        let temp = data?.data?.result?.data;
        console.log("list of engagements", temp);
        setDetails({ ...details, rows: temp })
        // setData(temp);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);
  useEffect(()=>{
    api.contract.findAll({from:"gigleji"}).then((res) => {
      console.log("res", res?.data?.result);
      let temp = res?.data?.result;
      if (temp.data) {
        setContractToGigler(temp.data);
      }
    })
    .catch((error)=>{
      console.log("error",error)
    });
  }, [])

  const handleClick = (row) => {
    console.log("row", row);
    navigate("/gigTalent-details", {
      state: {
        values: row,
      },
    });
  };

  const viewDoc = (url) => {
    console.log("doc", url);
    setDocUrl(url);
    setOpenView(true);
  };

  const editRow = (row) => {
    console.log("edit", row);
  };
  const cancelRow = (row) => {
    let id = row._id;
    console.log("cancelRow", row, id);
    api.engagementProcess
      .updateOne(id, { contractStatus: "3" })
      .then((data) => {
        if (data) {
          setSnack({
            message: `Contract Canceled`,
            open: true,
            type: "success",
          });
        }
      })
      .catch((err) => {
        setSnack({
          message: `error occured in Canceling`,
          open: true,
          type: "error",
        });
      });
  };
  const openContract = (row) => {
    console.log("row", row);
    navigate("/admin/sign-contract", {
      state: row
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={12}>
            <Card>
              <MDBox>
                <MDTypography type="title" px={2} py={2}>
                  Contract Signed List
                </MDTypography>
              </MDBox>
              <MDBox>
                <DataTable
                  table={details}
                  canSearch
                  // handleClick={handleClick}
                  viewDoc={viewDoc}
                  editRow={editRow}
                  cancelRow={cancelRow}
                  type={'gigler'}
                  openContract={openContract}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Modal open={openView} onClose={handleCloseFile}>
        <MDBox className={classes.resumeStyle}>
          <iframe
            className={"docx"}
            width="100%"
            height="100%"
            frameBorder="0"
            src={`https://docs.google.com/gview?url=${docUrl}&embedded=true`}
          ></iframe>
        </MDBox>
      </Modal>
    </DashboardLayout>
  );
};

export default ManageContractsAdmin;