import { Card, Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react'
import api from 'services/api'
import { UserContext } from 'context/UserProvider'
import profile from "assets/images/profile.jpg";
import GiglerProfileForm from './GiglerProfileForm'
import { setOpenConfigurator } from 'context'
import AssessmentForm from './AssessmentForm'
import defaultProfile from "assets/images/defaultProfile.png"
  import moment from "moment";

const AssignedGiglers = () => {
  const { userInfo } = useContext(UserContext);
  const [open, setOpen] = useState("1");
  const [details, setDetails] = useState();
  const [assessment, setAssessment] = useState(false);
  const handleChange = () => {
    setAssessment(!assessment);
    setOpen("0");
  }

  const handleOpen = async (id) => {
    console.log("id", id)
    await api.userInfo.findOne(id)
      .then((data) => {
        const details = data?.data?.result?.data
        setDetails(details);
        console.log("details", details)
      })
    setOpen("2");
  }

  const [list, setList] = useState();
  const handleApi = () => {
    console.log("assessor id", userInfo)
    const id = userInfo?.id
    api.assigned.findAll(id, { assigned: true, assessed: false }).then(data => {
      console.log("list of gigTalent assigned", data);
      let temp = data?.data?.result?.data;
      setList(temp);
    }).catch(error => {
      console.log("error", error)
    })
  }
  useEffect(() => {
    handleApi()
  }, [])

  const formatDate = (string) => {
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(string).toLocaleDateString([], options);
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {open == "1" &&
        <Grid container spacing={2} direction="row">
          {list?.length <= 0 ?
            (<MDBox>
              <Card sx={{ height: 700, width: 1210, m: 2 }}>
                <MDTypography variant="h5" p={2}>
                  No gigTalent's assigned
                </MDTypography>
              </Card>
            </MDBox>
            ) : (
              list?.map((row) => (
                <Grid item xs={12} sm={2}>
                  <MDBox>
                    <Card sx={{ maxWidth: 250, maxHeight: 450 }} key={row.id}>
                      <CardMedia
                        component="img"
                        height="140"
                        image={row.userInfo[0]?.avatar || defaultProfile}
                        alt={defaultProfile}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div" textTransform="capitalize">
                          {row.userInfo[0]?.firstName}&nbsp;{row.userInfo[0]?.lastName}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {row.userInfo[0]?.industry}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {row.userInfo[0]?.domain}
                        </Typography>
                        <Typography variant="body2" color="info" fontWeight="medium">
                          {formatDate(row.userInfo[0]?.createdAt)}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        {/* <Button size="small">Share</Button> */}
                        <Button size="small" onClick={() => handleOpen(row.userInfo[0]?.userId)}>Details</Button>
                      </CardActions>
                    </Card>
                  </MDBox>
                </Grid>
              )
              ))}
        </Grid>
      }
     { console.log(details)}
      {open == "2" &&
        <GiglerProfileForm
          setOpen={setOpen}
          details={details}
          handleChange={handleChange} />
      }
      {
        open == "3" &&
        <AssessmentForm handleChange={handleChange} handleApi={handleApi} setOpen={setOpen} details={details} />
      }
    </DashboardLayout >
  )
}

export default AssignedGiglers