// @mui material components
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { SnackbarContext } from "context/SnackbarContext";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "services/api";

import DataTable from "./DataTable";
function SubAdminList() {
  const { setSnack } = useContext(SnackbarContext);
  const navigate = useNavigate()
  const [pagination, setPagination] = useState({});
  const [details, setDetails] = useState({
    columns: [
      { Header: "First name", accessor: "firstName", width: "20%" },
      { Header: "Last name", accessor: "lastName", width: "25%" },
      { Header: "Email", accessor: "email" },
      { Header: "Phone number", accessor: "phoneNumber" },
      { Header: "Type", accessor: "type" }
    ],
    rows: []
  })
  useEffect(() => {
    api.users.getSubAdmin().then(data => {
      console.log("list of sub admins", data);
      let temp = data?.data?.result?.data;
      console.log(temp);
      setDetails({ ...details, rows: temp });
    }).catch(error => {
      console.log("error", error)
    })
  }, [])

  return (
    <DataTable table={details} canSearch />
  );
}

export default SubAdminList;
