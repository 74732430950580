import Analytics from "layouts/dashboards/analytics";

// @mui icons
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import GradingIcon from '@mui/icons-material/Grading';

// Images
import profilePicture from "assets/images/team-3.jpg";
import GTProfile from "layouts/pages/GiglerDashboard/Profile";
import OpenEngagements from "layouts/pages/GiglerDashboard/OpenEngagements";
import CompletedEngagements from "layouts/pages/GiglerDashboard/CompletedEngagements";
import GiglerContractManagement from "layouts/pages/GiglerDashboard/ContractManagement";
import ComingSoon from "components/ComingSoon";
// import CommunityPost from "layouts/pages/GiglerDashboard/CommunityPost";

const userRoutes = [
  // {
  //   type: "collapse",
  //   name: "Brooklyn Alice",
  //   key: "brooklyn-alice",
  //   icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
  //   collapse: [
  //     {
  //       name: "My Profile",
  //       key: "my-profile",
  //       route: "/profile-overview",
  //       component: <ProfileOverview />,
  //     },
  //     {
  //       name: "Settings",
  //       key: "profile-settings",
  //       route: "/account/settings",
  //       component: <Settings />,
  //     },
  //     {
  //       name: "Contract",
  //       key: "Contract",
  //       route: "/account/contracts",
  //       component: <Timeline />,
  //     },
  //   ],
  // },
  //Temporarily disabled for further updates 18/11/2022
  {
    type: "route",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboards",
    icon: <DashboardIcon />,
    component:  <Analytics /> 
    // component: <Analytics />

  },
  {
    type: "route",
    name: "My Profile",
    key: "my-profile",
    //route: "/profile-overview",
    route: "/account/profile",
    icon: <AccountBoxOutlinedIcon />,
    component: <GTProfile />
  },
  // {
  //   type: "route",
  //   name: "Analytics",
  //   key: "analytics",
  //   route: "/dashboards/analytics",
  //   icon: <AnalyticsOutlinedIcon />,
  //   component: <Analytics />

  // },
  {
    type: "route",
    name: "Open Engagements",
    key: "open-engagements",
    route: "/account/open-engagements",
    icon: <WorkOutlineIcon />,
    component: <OpenEngagements />,
  },

  // {
  //   type: "route",
  //   name: "Settings",
  //   key: "profile-settings",
  //   route: "/account/settings",
  //   icon: <SettingsIcon />,
  //   component: <Settings />
  // },
  {
    type: "route",
    name: "Contract",
    key: "Contract",
    route: "/account/contracts",
    icon: <ChromeReaderModeIcon />,
    component: <GiglerContractManagement />
  },
  {
    type: "route",
    name: "Feedback",
    key: "completed-engagements",
    route: "/account/completed-engagements",
    icon: <GradingIcon />,
    component: <CompletedEngagements />,
  },
  // { type: "title", title: "Docs", key: "title-docs" },
  // {
  //   type: "collapse",
  //   name: "Dashboards",
  //   key: "dashboards",
  //   icon: <Icon fontSize="medium">dashboard</Icon>,
  //   component: <Analytics />,
  //   collapse: [
  //     {
  //       name: "Analytics",
  //       key: "analytics",
  //       route: "/dashboards/analytics",
  //       component: <Analytics />,
  //     },
  //   ],
  // },
  // {
  //   type: "route",
  //   name: "Community Post",
  //   key: "communityPost",
  //   route: "/account/community-post",
  //   icon: <GradingIcon />,
  //   component: <CommunityPost />,
  // },
];

export default userRoutes;
