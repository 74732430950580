import React, { useEffect, useState } from 'react';
// mui
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import MDTypography from './MDTypography';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarComponent = ({
  openSnackBar,
  message,
  onClose,
  type,
  vertical,
  horizontal
}) => {
  const [open, setOpen] = useState(false);
  let colorString;
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    onClose();
  };

  useEffect(() => {
    setOpen(openSnackBar);
  }, [openSnackBar]);

  switch (type) {
    case '':
      colorString = 'status.inProgress';
      break;
    case 'success':
      colorString = 'status.completed';
      break;
    case 'info':
      colorString = 'status.inProgress';
      break;
    case 'warning':
      colorString = 'status.abandoned';
      break;
    case 'error':
      colorString = 'status.underConstruction';
      break;
    default:
      colorString = 'status.inProgress';
      break;
  }
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: vertical ? vertical : 'top',
          horizontal: horizontal ? horizontal : 'right',
        }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message="Note archived"
      >
        <Alert
          onClose={handleClose}
          severity={type ? type : 'success'}
          sx={{ backgroundColor: colorString, color: 'light' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default SnackbarComponent;
