import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { SnackbarContext } from "context/SnackbarContext";
import { UserContext } from "context/UserProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { validateYupSchema } from "formik";
import React, { useContext, useEffect, useState } from "react";
import api from "services/api";
import Engagements from "./Engagements";
import SearchableList from "./SearchableList";

const Searchable = () => {
  let id;
  const { setSnack } = useContext(SnackbarContext);
  const { userInfo } = useContext(UserContext);

  const [data, setData] = useState();
  const [gigs, setGigs] = useState();
  const [eng, setEng] = useState();
  // useEffect(() => {
  //   api.engagement
  //     .findAll()
  //     .then((data) => {
  //       // console.log("list of assessors", data);
  //       let temp = data?.data?.result?.data;
  //       setData(temp);
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //     });
  // }, []);

  useEffect(() => {
    const id = userInfo?.id
    api.engagement.findByEngId(id)
      .then((data) => {
        let temp = data?.data?.result?.data;
        setData(temp);
      })
  }, [])

  const handleGiglerList = async (row) => {
    setEng(row);
    const engagerId = row.engagerId;
    const engagementId = row.engagementId;
    // const industry = row.industry; //Tempraryly disabled to support matching only based on Domain
    const domain = row.domain;
    const description = row?.engagementDescriptionText ? row?.engagementDescriptionText.replace(/[^a-zA-Z ]/g, "") : '';
    api.userInfo.search({ engagementId, domain, engagerId, description })
      .then((data) => {
        // let temp = data?.data?.result?.data;
        console.log("Giglers matching with domain,industry not applied", data?.data?.result?.temp);
        setGigs(data?.data?.result?.temp);
      });
  };

  const handleSubmit = (checked) => {
    let id = value.engagementId;
    let updateId = checked;
    api.engagementProcess
      .updateManyApplied(id, { updateId })
      .then((data) => {
        let N = data?.data?.result?.data?.n;
        if (N > 0) {
          setSnack({
            message: `GigTalent has been shortlisted`,
            open: true,
            type: "success",
          });
        }
      })
      .catch((err) => {
        setSnack({
          message: `Problem occurred during shortlisting gigler`,
          open: true,
          type: "success",
        });
      });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={4}>
            <Card>
              <Engagements engagementList={data} setData={setData} handleGiglerList={handleGiglerList} />
            </Card>
          </Grid>
          <Grid item xs={12} xl={8}>
            <Card>
              <SearchableList giglerList={gigs} eng={eng} handleSubmit={handleSubmit} />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default Searchable;
