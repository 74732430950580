// @mui material components
import Card from "@mui/material/Card";

import FormField from "layouts/pages/account/components/FormField";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import { Autocomplete, Grid } from "@mui/material";
import MDDatePicker from "components/MDDatePicker";
import MDInput from "components/MDInput";
import { useContext, useEffect, useState } from "react";

//form
import { useFormik } from "formik";
import * as Yup from "yup";
import api from "services/api";
import { UserContext } from "context/UserProvider";
import { SnackbarContext } from "context/SnackbarContext";
import moment from "moment";

function Education({ userData, updateUserData,onInputListChange }) {
  const { userInfo } = useContext(UserContext);
  const { setSnack } = useContext(SnackbarContext);
  const [inputList, setInputList] = useState([
    {
      qualification: "",
      type: "",
      university: "",
      specialization: "",
      joiningDate: "",
      passOutDate: "",
    },
  ]);

  const initialValues = {
    qualification: "",
    type: "",
    university: "",
    specialization: "",
    joiningDate: "",
    passOutDate: "",
  };

  const validate = (values) => {
    let errors = {};

    if (!values.qualification) {
      errors.qualification = "Required";
    }
    if (!values.type) {
      errors.type = "Required";
    }
    if (!values.university) {
      errors.university = "Required";
    }
    if (!values.specialization) {
      errors.specialization = "Required";
    }
    if (!values.joiningDate) {
      errors.joiningDate = "Required";
    }
    if (!values.passOutDate) {
      errors.passOutDate = "Required";
    }
  };

  const validationSchema = Yup.object({
    qualification: Yup.string().required("qualification is required"),
    type: Yup.string().required("type is required"),
    university: Yup.string().required("university is required"),
    specialization: Yup.string().required("specialization is required"),
    joiningDate: Yup.date().required("joiningDate is required"),
    passOutDate: Yup.date().required("passOutDate is required"),
  });

  useEffect(() => {
    let temp = {
      ...userData,
    };
    formik.setValues(temp);
  },[userData]);

  const formik = useFormik({
    initialValues,
    // validationSchema,
    onSubmit: async (values) => {
      console.log("userInfo values", { education: inputList });

      try {
        let id = ""
        if(userInfo.type == "admin"){
          id = userData?.userId
        }else{
          id = userInfo?.id
        }
        let temp = values;
        temp.education = inputList;
        updateUserData(temp);
        const result = await api.userInfo.update(id, { education: inputList });
        if (result) {
          if (result?.data?.message == "success") {
            setSnack({
              message: "Education details updated!",
              open: true,
              type: "success",
            });
          } else if(result?.data?.message == "error") {
            setSnack({
              message: "Error updating details!",
              open: true,
              type: "error",
            });
          }
         
        } else {
          throw new error("failed to update");
        }
      } catch (error) {
        setSnack({
          message: "Error occurred during update!",
          open: true,
          type: "success",
        });
        console.log("ERROR LOGIN: ", { ...error });
      }
    },
    validate,
  });



  //handle Input Change
  const handleInputChange = (e, index) => {
    console.log("Change", e);
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  //handle click event of the remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    // onInputListChange(list);
  };

  //handle click event of the add button
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        qualification: "",
        type: "",
        university: "",
        specialization: "",
        joiningDate: "",
        passOutDate: "",
      },
    ]);
    // onInputListChange([...inputList,{}])
  };

  useEffect(() => {
    if (userData && userData?.education && userData?.education.length > 0) {
      console.log('EDUCATION',userData?.education);
      setInputList(userData?.education);
    }
  }, [userData])

  return (
    <Card id="Education" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Educational Details</MDTypography>
      </MDBox>
      <form onSubmit={formik.handleSubmit} method="post">
      {inputList.map((x, i) => {
        return (
            <MDBox pb={3} px={3}>
              <Grid container spacing={3} mb={2}>
                <Grid item xs={4} sm={4}>
                  <FormField
                    label="Qualification"
                    placeholder="B.Sc"
                    name="qualification"
                    value={x.qualification}
                    onChange={(e) => handleInputChange(e, i)}
                  // {...formik.getFieldProps("qualification")}
                  // error={
                  //   formik.touched.qualification && formik.errors.qualification ? true : false
                  // }
                  // helperText={
                  //   formik.touched.qualification && formik.errors.qualification
                  //     ? formik.errors.qualification
                  //     : null
                  // }
                  />
                </Grid>
                {x.type && 
                  <Grid item xs={4} sm={4}>
                    <Autocomplete
                      label="Type of Education"
                      name="type"
                      options={["FULL TIME", "PART TIME", "DISTANCE", "OTHERS"]}
                      defaultValue={x.type}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <FormField {...params} label="Type of Education" InputLabelProps={{ shrink: true }} />
                      )}
                      onChange={(e,val) => {
                        const list = [...inputList];
                        list[i]['type'] = val;
                        console.log("change", list);
                        setInputList(list);
                      }}
                    />
                  </Grid>
                }
                {!x.type && 
                  <Grid item xs={4} sm={4}>
                    <Autocomplete
                      label="Type of Education"
                      name="type"
                      options={["FULL TIME", "PART TIME", "DISTANCE", "OTHERS"]}
                      defaultValue={x.type}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <FormField {...params} label="Type of Education" InputLabelProps={{ shrink: true }} />
                      )}
                      onChange={(e,val) => {
                        const list = [...inputList];
                        list[i]['type'] = val;
                        console.log("change", list);
                        setInputList(list);
                      }}
                    />
                  </Grid>
                }
                <Grid item xs={4} sm={4}>
                  <FormField
                    label="College/Institute/University "
                    placeholder="O.U."
                    name="university"
                    value={x.university}
                    onChange={(e) => handleInputChange(e, i)}
                  // {...formik.getFieldProps("university")}
                  // error={formik.touched.university && formik.errors.university ? true : false}
                  // helperText={
                  //   formik.touched.university && formik.errors.university
                  //     ? formik.errors.university
                  //     : null
                  // }
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <FormField
                    label="Area of Specialization "
                    placeholder="Statistics"
                    name="specialization"
                    value={x.specialization}
                    onChange={(e) => handleInputChange(e, i)}
                  // {...formik.getFieldProps("specialization")}
                  // error={
                  //   formik.touched.specialization && formik.errors.specialization ? true : false
                  // }
                  // helperText={
                  //   formik.touched.specialization && formik.errors.specialization
                  //     ? formik.errors.specialization
                  //     : null
                  // }
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <FormField
                    label="Joining Date"
                    name="joiningDate"
                    inputProps={{ type: "month", max: moment().format("YYYY-MM") }}
                    value={x.joiningDate}
                    onChange={(e) => handleInputChange(e, i)}
                  // {...formik.getFieldProps("joiningDate")}
                  // error={formik.touched.joiningDate && formik.errors.joiningDate ? true : false}
                  // helperText={
                  //   formik.touched.joiningDate && formik.errors.joiningDate
                  //     ? formik.errors.joiningDate
                  //     : null
                  // }
                  ></FormField>
                {/* <FormField
                label="Joining Date"
                name="joiningDate"
                inputProps={{ type: "date" , max:moment()}}
                value={x.joiningDate}
                onChange={(e) => handleInputChange(e, i)}
                /> */}
                </Grid>
                <Grid item xs={4} sm={4}>
                  <FormField
                    label="Passed Out Date"
                    name="passOutDate"
                    inputProps={{ type: "month", min: x.joiningDate }}
                    value={x.passOutDate}
                    onChange={(e) => handleInputChange(e, i)}
                  // {...formik.getFieldProps("passOutDate")}
                  // error={formik.touched.passOutDate && formik.errors.passOutDate ? true : false}
                  // helperText={
                  //   formik.touched.passOutDate && formik.errors.firstName
                  //     ? formik.errors.firstName
                  //     : null
                  // }
                  ></FormField>
                {/* <FormField
                label="Passed Out Date"
                name="passOutDate"
                inputProps={{ type: "date" ,  min: x.joiningDate }}
                value={x.passOutDate}
                onChange={(e) => handleInputChange(e, i)}
                /> */}
                </Grid>
              </Grid>
              {inputList.length !== 1 && (
                <MDButton
                  size="small"
                  variant="gradient"
                  sx={{ mr: "10px" }}
                  color="error"
                  onClick={() => handleRemoveClick(i)}
                >
                  Remove
                </MDButton>
              )}
              {inputList.length - 1 === i && inputList.length < 3 && (
                <>
                  <MDButton
                    size="small"
                    variant="gradient"
                    color="info"
                    onClick={() => handleAddClick()}
                    sx={{ mr: "10px" }}
                  >
                    Add More
                  </MDButton>
                </>
              )}
            </MDBox>
        );
      })}
      <MDBox pb={3} px={3}>
        <MDButton size="small" variant="gradient" color="success" type="submit">
          SAVE
        </MDButton>
      </MDBox>
    </form>
    </Card>
  );
}

export default Education;
