
// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import CurrencyFormat from "react-currency-format";


// Data

// import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import { Box, Checkbox, CircularProgress, Divider, FormControlLabel, FormGroup, Modal, Stack } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Checkbox from "assets/theme/components/form/checkbox";

//context
import { SnackbarContext } from "context/SnackbarContext";
import { UserContext } from "context/UserProvider";


//form

import { useFormik } from "formik";
import * as Yup from "yup";
import api from "services/api";
import selectData from "./data/selectData";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MDSnackbar from "components/MDSnackbar";
import { setDirection } from "context";
import { FormatIndentDecreaseTwoTone } from "@mui/icons-material";
import { useStyles } from "./styles";


const EngagementPosting = () => {
  const classes = useStyles();
  let Unq;
  let arr = selectData.cities;
  let city = arr.map((a) => a.name);
  let navigate = useNavigate();
  const { setSnack } = useContext(SnackbarContext);
  const { userInfo } = useContext(UserContext);
  const [masterDomain, setMasterDomain] = useState();
  const [checked, setChecked] = useState([])
  const [details, setDetails] = useState()
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [domain, setDomain] = useState();
  const [data, setData] = useState();
  const [indData, setIndData] = useState([]);
  const [secondaryInd, setSecondaryInd] = useState([]);
  const [skills, setSkills] = useState([]);
  const [tempSkills, setTempSkills] = useState([])
  const [industry, setIndustry] = useState();
  const [id, setId] = useState();
  const [prefix, setPrefix] = useState();
  const [number, setNumber] = useState(true);
  const [contractTemplate, setContractTemplate] = useState({});
  const [validationObj, setValidationObj] = useState({
    engagementModel: false,
    preferredModel: false,
    onBoardingModel: false,
  })
  //useState for engagement desc file
  const [fileText, setFileText] = useState();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [file, setFile] = useState();
  const initialValues = {
    organizationName: "",
    enterprise: "",
    engagerName: '',
    designation: '',
    phoneNumber: '',
    email: '',
    engagementTitle: '',
    engagementDescription: '',
    location: "",
    fullTime: false,
    partTime: false,
    projectBased: false,
    serviceBased: false,
    onSite: false,
    offSite: false,
    both: false,
    needBased: false,
    availability: "",
    // zeroToSevenDays: false,
    // eightToFifteenDays: false,
    // sixteenToThirtyDays: false,
    // moreThanThirtyDays: false,
    engagementDuration: '',
    billing: '',
    numberOfPositions: '',
    indicativeBudget: '',
    degree: '',
    industry: '',
    domain: '',
    primarySkills: '',
    secondarySkills: '',
    otherSkills: '',
    degree: '',
    subject: '',
    passOutYear: '',
    engagementDescriptionText: '',
    contractType:''
  }
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  // /^ ([6 - 9]\d{ 9})$/;
  // /^'[0-9]{10}'/;

  const validationSchema = Yup.object({
    engagerName: Yup.string().required("Engagement Owner Name is required"),
    designation: Yup.string().required("Engagement Owner Designation is required"),
    email: Yup.string().email().required("Engagement Owner Email is required"),
    engagementTitle: Yup.string().required("Engagement Title is required"),
    engagementDescription: Yup.string().required("Engagement Description is required"),
    engagementDuration: Yup.string().required("Engagement Duration is required"),
    billing: Yup.string().required("Mode of billing is required"),
    numberOfPositions: Yup.string().required("Number of Positions is required"),
    indicativeBudget: Yup.string().required("Indicative budget is required"),
    industry: Yup.string().required("Industry is required").nullable(),
    domain: Yup.string().required("Domain is required").nullable(),
    primarySkills: Yup.string().required("Primary skill is required").nullable(),
    secondarySkills: Yup.string().required("Secondary skill is required").nullable(),
    phoneNumber: Yup.string()
      // .required("Phone number is required")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Phone number is not valid")
      .max(10, "Phone number is not valid")
    ,
    // location: Yup.string().required("Location is required"),
    // currentPosition: Yup.string().required("Current position is required"),
    // currentOrganization: Yup.string().required("Current Organization is required"),
    // industry: Yup.string().required("Industry is required"),
    // domain: Yup.string().required("Domain is required"),
    // passOutYear: Yup.number()
    //   .min(1000, "Enter valid pass out year")
    //   .max(9999, "Enter valid pass out year"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (!values.partTime && !values.fullTime && !values.projectBased && !values.serviceBased) {
        setValidationObj({ ...validationObj, engagementModel: true })
        setSnack({
          message: "Choose atleast one option in Engagement Model",
          open: true,
          type: "warning",
        });
      } else if (!values.onSite && !values.offSite && !values.both && !values.needBased) {
        setValidationObj({ ...validationObj, preferredModel: true, engagementModel: false })
        setSnack({
          message: "Choose atleast one option in Preferred Engagement Model",
          open: true,
          type: "warning",
        });
      } else if (!values.availability) {
        setValidationObj({ ...validationObj, onBoardingModel: true, preferredModel: false, engagementModel: false })
        setSnack({
          message: "Please select Engagement Start Option",
          open: true,
          type: "warning",
        })
      } else {
        values.code = id
        values.engagerId = userInfo?.id
        values.contractTemplate = contractTemplate;
        console.log("form data",values)
        try {
          const result = await api.engagement.create(values);
          if (result) {
            console.log("engagement posting data", result)
            // navigate(`/employer/engagement-posting`);
            let Unq = result?.data?.result?.data?.engagementId
            setSnack({
              message: `Engagement Posted successfully with Id ${Unq}`,
              open: true,
              type: 'success',
            });
            handleReset();
          }
          else {

          }
        } catch (error) {
          console.log("erroor", error)
          setSnack({
            message: 'Error occurred during Engagement Posting!',
            open: true,
            type: 'error',
          });
          console.log("ERROR LOGIN: ", { ...error });

        }
      }
    },
  });

  useEffect(() => {
    if (Object.keys(userInfo).length > 0) {
      const id = userInfo.id
      api.engager.findOne(id)
        .then((data) => {
          if (data) {
            let temp = data?.data?.result?.data;
            setUserData(temp);
            formik.setFieldValue("organizationName", temp?.organizationName, false);
            formik.setFieldValue("enterprise", temp?.enterprise, false);
            const post = temp.engagementPostingAccess ? true : false
            setLoading(false);
            // setInitialValues({ ...temp });
            // console.log("engager details in engager dashboard", temp);
          }
        });
    }
  }, [userInfo]);

  useEffect(() => {
    api.contract.getDefaultTemlate().then(data => {
      let temp = data?.data?.result?.data;
      console.log("default Template", temp)
      if (temp) {
        setContractTemplate(temp);
      }
    }).catch(error => {
      console.log("error", error)
    })
  }, [])
  useEffect(() => {
    api.industry.findAll().then(data => {
      let temp = data?.data?.result?.data;
      console.log("industry list", temp)
      setIndData(temp);
      setSecondaryInd(temp);
    }).catch(error => {
      console.log("error", error)
    })
  }, [])

  useEffect(() => {
    api.domain.findAll().then(data => {
      let temp = data?.data?.result?.data;
      setData(temp);
      setMasterDomain(temp);
    }).catch(error => {
      console.log("error", error)
    })
    engagementCount()
  }, [])

  useEffect(() => {
    api.engagement.findByDomain({ domain: domain })
      .then((data) => {
        const temp = data?.data?.result?.data[0]?.engagementId
        setPrefix(temp);
      })

  }, [domain])

  useEffect(() => {
    if (formik.values.serviceBased) {
      console.log("service is clicked", formik.values.serviceBased)
      let serviceIndustry = indData.filter(x => x.Industry == "Services")
      setIndData(indData.filter(x => x.Industry == "Services"))
      formik.setFieldValue("industry", serviceIndustry[0].Industry, false)
    } else {
      setIndData(secondaryInd);
    }
  }, [formik.values.serviceBased])

  const handleReset = () => {
    formik.handleReset();
    window.location.reload(false);
    formik.setFieldValue("organizationName", userData?.organizationName, false);
    formik.setFieldValue("enterprise", userData?.enterprise, false);
    formik.setFieldValue("fullTime", false, false);
    formik.setFieldValue("partTime", false, false);
    formik.setFieldValue("projectBased", false, false);
    formik.setFieldValue("serviceBased", false, false);
    formik.setFieldValue("onSite", false, false);
    formik.setFieldValue("offSite", false, false);
    formik.setFieldValue("both", false, false);
    formik.setFieldValue("needBased", false, false);
    formik.setFieldValue("engagementDuration", '', false);
    formik.setFieldValue("billing", '', false);
    formik.setFieldValue("industry", '', false);
    formik.setFieldValue("domain", '', false);
    formik.setFieldValue("primarySkills", '', false);
    formik.setFieldValue("secondarySkills", '', false);
  }

  const engagementCount = () => {
    const id = userInfo.id
    api.engagement.getCountByEngagerId(id)
      .then(data => {
        if (data?.data?.message == "error") {
          setNumber(false)
        } else {
          setNumber(true);
        }
      })
      .catch(err => {
        console.log("engagement count error", err)
      })
  }

  //function for engagement description file upload
  const handleClick = () => {
    document.getElementById("textFile").click();
  };
  const handleChange = (event) => {
    let temp = event.target.files[0];
    let type = event.target.files[0].type;
    console.log("text file", temp);
    if (type == "application/pdf" || type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      const formData = new FormData();
      formData.append("engagementDesc", temp);
      setFileLoading(true);
      api.engagement.engagementDescUpload(formData).then((result) => {
        if (result.data?.message == "success") {
          setSnack({
            message: "File Uploaded successfully!",
            open: true,
            type: "success",
          });
          console.log("file data", result);
          let temp2 = result.data.result.temp;
          setFile(temp2?.engagementDescriptionLink);
          setFileText(temp2?.engagementDescriptionText);
          formik.setFieldValue("engagementDescriptionText", temp2.engagementDescriptionText, false);
          setFileLoading(false);
        } else {
          setSnack({
            message: "Error occurred while uploading file!",
            open: true,
            type: "error",
          });
          setFileLoading(false);
        }

        // setUrl(temp);
      });
    }
    else {
      setSnack({
        message: "Upload Only .pdf or .docx files",
        open: true,
        type: "error",
      });
    }
  };
  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z]+$/;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!userData?.engagementPostingAccess ? (
        <MDBox>
          <Card sx={{ p: "20px" }}>
            <MDTypography variant="h6">Engagement Posting access is pending...</MDTypography>
          </Card>
        </MDBox>
      ) : (
        <MDBox>
          {loading ? (
            <MDBox>
              <Card>
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    py: 5,
                  }}
                >
                  <CircularProgress color="primary" />
                </MDBox>
              </Card>
            </MDBox>
          ) : (
            <>
              {number ? (
                <MDBox>
                  <form onSubmit={formik.handleSubmit} onReset={formik.handleReset} method="post">
                    <Card id="current-engagement" sx={{ overflow: "visible", mb: "10px" }}>
                      <MDBox p={3}>
                        <MDTypography variant="h5">Engagement Posting</MDTypography>
                      </MDBox>
                      <MDBox pb={3} px={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={8}>
                            <FormField label="Name of the Organization" name="organizationName"
                              inputProps={{ readOnly: true }}
                              {...formik.getFieldProps("organizationName")}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormField label="Enterprise" name="enterprise"
                              inputProps={{ readOnly: true }}
                              {...formik.getFieldProps("enterprise")}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormField label="Name of the Engagement owner" name="engagerName"
                              {...formik.getFieldProps("engagerName")}
                              success={formik.values.engagerName > 0 && !formik.errors.engagerName}
                              error={
                                formik.touched.engagerName && formik.errors.engagerName
                                  ? true
                                  : false
                              }
                              helperText={
                                formik.touched.engagerName && formik.errors.engagerName
                                  ? formik.errors.engagerName
                                  : null
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormField label="Designation" name="designation"
                              {...formik.getFieldProps("designation")}
                              success={formik.values.designation > 0 && !formik.errors.designation}
                              error={
                                formik.touched.designation && formik.errors.designation
                                  ? true
                                  : false
                              }
                              helperText={
                                formik.touched.designation && formik.errors.designation
                                  ? formik.errors.designation
                                  : null
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormField label="Phone Number (Optional)" inputProps={{ type: "number" }} name="phoneNumber"
                              {...formik.getFieldProps("phoneNumber")}
                              success={formik.values.phoneNumber > 0 && !formik.errors.phoneNumber}
                              error={
                                formik.touched.phoneNumber && formik.errors.phoneNumber
                                  ? true
                                  : false
                              }
                              helperText={
                                formik.touched.phoneNumber && formik.errors.phoneNumber
                                  ? formik.errors.phoneNumber
                                  : null
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormField label="Email" name="email"
                              {...formik.getFieldProps("email")}
                              inputProps={{ type: "email" }}
                              success={formik.values.email > 0 && !formik.errors.email}
                              error={
                                formik.touched.email && formik.errors.email
                                  ? true
                                  : false
                              }
                              helperText={
                                formik.touched.email && formik.errors.email
                                  ? formik.errors.email
                                  : null
                              }
                              
                            />
                          </Grid>
                        </Grid>
                      </MDBox>
                      <MDBox p={2}>
                            <MDTypography variant="h6">Contract Type</MDTypography>
                          </MDBox> 
                          <MDBox pb={3} px={3}>
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              {...formik.getFieldProps("contractType")}
                            >
                                <Stack direction="row">
                                    <FormControlLabel
                                      value="company-payroll"
                                      control={<Radio />}
                                      label={
                                        <MDTypography color="text" variant="p" fontWeight="regular">
                                          The GigTalent will be on Company Payroll
                                        </MDTypography>
                                        
                                      }
                                  />
                                   <FormControlLabel
                                      value="gigleji-payroll"
                                      control={<Radio />}
                                      label={
                                        <MDTypography color="text" variant="p" fontWeight="regular">
                                          The GigTalent will be on Gigleji Payroll
                                        </MDTypography>
                                      }
                                    />
                                </Stack>
                              </RadioGroup>
                            </FormControl>
                          </MDBox> 
                    </Card>
                    <Card sx={{ mb: '10px' }}>
                      <MDBox pt={3} pb={3} px={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12}>
                            <FormField 
                              label={<MDTypography variant="h4">Engagement Title</MDTypography>}
                              name="engagementTitle"
                              {...formik.getFieldProps("engagementTitle")}
                              success={formik.values.engagementTitle > 0 && !formik.errors.engagementTitle}
                              error={
                                formik.touched.engagementTitle && formik.errors.engagementTitle
                                  ? true
                                  : false
                              }
                              helperText={
                                formik.touched.engagementTitle && formik.errors.engagementTitle
                                  ? formik.errors.engagementTitle
                                  : null
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <FormField 
                            label={<MDTypography variant="h5">Engagement Description
                            (You may copy paste your engagement description here)</MDTypography>}
                              name="engagementDescription"
                              multiline rows={10}
                              {...formik.getFieldProps("engagementDescription")}
                              success={formik.values.engagementDescription > 0 && !formik.errors.engagementDescription}
                              error={
                                formik.touched.engagementDescription && formik.errors.engagementDescription
                                  ? true
                                  : false
                              }
                              helperText={
                                formik.touched.engagementDescription && formik.errors.engagementDescription
                                  ? formik.errors.engagementDescription
                                  : null
                              }
                            />
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Card>
                    <Card sx={{ mb: "10px" }}>
                      <MDBox p={3}>
                        <MDTypography variant="h5">Engagement Description File</MDTypography>
                        <MDTypography variant="caption">You can upload engagement description file here</MDTypography>
                        {fileLoading ? (
                          <CircularProgress color="info" />
                        ) : (
                          <>
                            <Modal open={open} onClose={handleClose}>
                              <MDBox className={classes.resumeStyle}>

                                <iframe
                                  className={'pdf'}
                                  width="100%"
                                  height="100%"
                                  frameBorder="0"
                                  src={`https://docs.google.com/gview?url=${file}&embedded=true`}
                                ></iframe>
                              </MDBox>
                            </Modal>
                            <MDBox sx={{ alignItems: "center" }}>
                              {file && (
                                <>
                                  <MDTypography variant="button" color="text" sx={{ mr: "10px", mt: "10px" }}>
                                    {decodeURIComponent(file.split('/').pop())}
                                  </MDTypography>
                                  <MDButton
                                    color="info"
                                    size="small"
                                    variant="gradient"
                                    onClick={() => setOpen(true)}
                                    sx={{ mr: "10px", mt: "10px" }}
                                  >
                                    View
                                  </MDButton>
                                </>
                              )}
                              <input
                                type="file"
                                name="textFile"
                                id="textFile"
                                accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                onChange={(e) => handleChange(e)}
                                sx={{ display: "none" }}
                                className={classes.inputStyle}
                              />
                              <MDButton
                                variant="gradient"
                                size="small"
                                color="success"
                                onClick={() => handleClick()}
                                sx={{ mt: "10px" }}
                              >
                                Upload
                              </MDButton>
                            </MDBox>
                            {fileText && (
                              <MDBox mt={2} lineHeight={1}>
                                <MDBox>
                                  <MDTypography variant="h6">Engagement Description Text</MDTypography>
                                  <FormField
                                    name="engagementDescText"
                                    multiline
                                    value={fileText}
                                    defaultValue={fileText}
                                    rows={5}
                                    inputProps={{ type: "text", readOnly: true }}
                                    {...formik.getFieldProps("engagementDescText")}
                                  />
                                </MDBox>
                              </MDBox>
                            )}
                          </>
                        )}
                      </MDBox>
                    </Card>
                    <Card sx={{ mb: '10px' }}>
                      <MDBox p={3}>
                        <MDTypography variant="h5">Engagement Mode <span style={{ color: 'red', fontSize: '13px' }}>(Mandatory Field)</span></MDTypography>
                        {validationObj.engagementModel ? (
                          <MDTypography variant="caption" color="error">Please select atleast one option</MDTypography>
                        ) : ("")}
                      </MDBox>
                      <MDBox pb={3} px={3}>
                        <FormGroup >
                          <Stack direction='row'>
                            <FormControlLabel control={<Checkbox name="fullTime" checked={checked.fullTime}
                              {...formik.getFieldProps("fullTime")} />}
                              label={<MDTypography color='text' variant='p' fontWeight='regular'>Full Time</MDTypography>} />
                            <FormControlLabel control={<Checkbox name="partTime" checked={checked.partTime}
                              {...formik.getFieldProps("partTime")} />}
                              label={<MDTypography color='text' variant='p' fontWeight='regular'>Part Time</MDTypography>} />
                            <FormControlLabel control={<Checkbox name="projectBased" checked={checked.projectBased}
                              {...formik.getFieldProps("projectBased")} />}
                              label={<MDTypography color='text' variant='p' fontWeight='regular'>Engagement Based</MDTypography>} />
                            <FormControlLabel control={<Checkbox name="serviceBased" checked={checked.serviceBased}
                              {...formik.getFieldProps("serviceBased")} />}
                              label={<MDTypography color='text' variant='p' fontWeight='regular'>Service Based</MDTypography>} />
                          </Stack>
                        </FormGroup>
                      </MDBox>
                    </Card>
                    <Card sx={{ mb: "10px" }}>
                      <MDBox p={3}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <Autocomplete
                              options={indData}
                              autoHighlight
                              getOptionLabel={(option) => option.Industry}
                              renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.Industry}
                                </Box>
                              )}
                              onChange={(event, newValue) => {
                                setIndustry(newValue);
                                console.log("industry selected", newValue)
                                let y = masterDomain.filter(x => x.Industry == newValue.Industry)
                                setData(y);
                                console.log("master domain", y)
                                formik.setFieldValue("industry", newValue.Industry, false);
                                formik.setFieldValue("domain", '', false);
                                formik.setFieldValue("primarySkills", '', false);
                                formik.setFieldValue("secondarySkills", '', false);
                              }}
                              renderInput={(params) => (
                                <FormField
                                  {...params}
                                  InputLabelProps={{ shrink: true }}
                                  label="Select Industry"
                                  {...formik.getFieldProps("industry")}
                                  error={formik.touched.industry && formik.errors.industry ? true : false}
                                  helperText={
                                    formik.touched.industry && formik.errors.industry
                                      ? formik.errors.industry
                                      : null
                                  }
                                  name="industry"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Autocomplete
                              options={data}
                              autoHighlight
                              getOptionLabel={(option) => option.Domain}
                              renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.Domain}
                                </Box>
                              )}
                              onChange={(event, newValue) => {
                                setDomain(newValue.Domain);
                                console.log("domain code", newValue.Domain, newValue.code)
                                setId(newValue.code);
                                formik.setFieldValue("domain", newValue.Domain, false);
                                setSkills(newValue.skillSet);
                                console.log("skills", newValue.skillSet);
                              }}
                              renderInput={(params) => (
                                <FormField {...params}
                                  InputLabelProps={{ shrink: true }} label="Select Domain" name="domain"
                                  {...formik.getFieldProps("domain")}
                                  error={formik.touched.domain && formik.errors.domain ? true : false}
                                  helperText={
                                    formik.touched.domain && formik.errors.domain
                                      ? formik.errors.domain
                                      : null
                                  } />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Autocomplete
                              // multiple
                              options={skills}
                              onChange={(event, newValue) => {
                                formik.setFieldValue("primarySkills", newValue, false);
                                var filteredArray = skills.filter(e => e !== newValue)
                                setTempSkills(filteredArray);
                                console.log("primarySkills", newValue)
                                formik.setFieldValue("secondarySkills", "", true);
                              }}
                              renderInput={(params) => (
                                <FormField
                                  {...params}
                                  InputLabelProps={{ shrink: true }}
                                  label="Primary skill set"
                                  name="primarySkills"
                                  {...formik.getFieldProps("primarySkills")}
                                  error={formik.touched.primarySkills && formik.errors.primarySkills ? true : false}
                                  helperText={
                                    formik.touched.primarySkills && formik.errors.primarySkills
                                      ? formik.errors.primarySkills
                                      : null
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Autocomplete
                              options={tempSkills}
                              {...formik.getFieldProps("secondarySkills")}
                              renderInput={(params) => (
                                <FormField
                                  {...params}
                                  InputLabelProps={{ shrink: true }}
                                  label="Secondary skill set"
                                  name="secondarySkills"
                                  {...formik.getFieldProps("secondarySkills")}
                                  error={formik.touched.secondarySkills && formik.errors.secondarySkills ? true : false}
                                  helperText={
                                    formik.touched.secondarySkills && formik.errors.secondarySkills
                                      ? formik.errors.secondarySkills
                                      : null
                                  }
                                />
                              )}
                              onChange={(event, skills) => {
                                formik.setFieldValue("secondarySkills", skills, false);
                              }}

                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FormField label="Other skills" name="otherSkills"
                              {...formik.getFieldProps("otherSkills")} />
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Card>
                    <Card>
                      <MDBox p={3}>
                        <MDTypography variant="h5">Preferred Workspace <span style={{ color: 'red', fontSize: '13px' }}>(Mandatory Field)</span></MDTypography>
                        {validationObj.preferredModel ? (
                          <MDTypography variant="caption" color="error">Please select atleast one option</MDTypography>
                        ) : ("")}
                      </MDBox>
                      <MDBox pb={3} px={3}>
                        <FormGroup>
                          <Stack direction='row'>
                            <FormControlLabel control={<Checkbox checked={checked.onSite}
                              {...formik.getFieldProps("onSite")} />}
                              label={<MDTypography color='text' variant='p' fontWeight='regular'>On site</MDTypography>} />
                            <FormControlLabel control={<Checkbox checked={checked.offSite}
                              {...formik.getFieldProps("offSite")} />}
                              label={<MDTypography color='text' variant='p' fontWeight='regular'>WFH</MDTypography>} />
                            <FormControlLabel control={<Checkbox checked={checked.both}
                              {...formik.getFieldProps("both")} />}
                              label={<MDTypography color='text' variant='p' fontWeight='regular'>Both (HYBRID)</MDTypography>} />
                            <FormControlLabel control={<Checkbox checked={checked.needBased}
                              {...formik.getFieldProps("needBased")} />}
                              label={<MDTypography color='text' variant='p' fontWeight='regular'>Need based</MDTypography>} />
                          </Stack>
                        </FormGroup>
                      </MDBox>
                      <MDBox p={3}>
                        <MDTypography variant="h5">Engagement Start <span style={{ color: 'red', fontSize: '13px' }}>(Mandatory Field)</span></MDTypography>
                        {validationObj.onBoardingModel ? (
                          <MDTypography variant="caption" color="error">Please select atleast one option</MDTypography>
                        ) : ("")}
                      </MDBox>
                      <MDBox pb={3} px={3}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            {...formik.getFieldProps("availability")}
                          >
                            <Stack direction='row'>
                              <FormControlLabel value="zeroToSevenDays" control={<Radio />}
                                label={<MDTypography color='text' variant='p' fontWeight='regular'>0-7 days</MDTypography>} />
                              <FormControlLabel value="eightToFifteenDays" control={<Radio />}
                                label={<MDTypography color='text' variant='p' fontWeight='regular'>8-15 days</MDTypography>} />
                              <FormControlLabel value="sixteenToThirtyDays" control={<Radio />}
                                label={<MDTypography color='text' variant='p' fontWeight='regular'>16-30 days</MDTypography>} />
                              <FormControlLabel value="moreThanThirtyDays" control={<Radio />}
                                label={<MDTypography color='text' variant='p' fontWeight='regular'>More than 30 days</MDTypography>} />
                            </Stack>
                          </RadioGroup>
                        </FormControl>
                      </MDBox>
                    </Card>
                    <Card sx={{ mb: '10px', mt: "10px" }}>
                      <MDBox pt={3} pb={3} px={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={3}>
                            {/* TODO: Dropdown list for engagement duration(no. of days) */}
                            {/* <Autocomplete
                              name="engagementDuration"
                              options={selectData.months}
                              onChange={(event, value) => { formik.setFieldValue("engagementDuration", value, false) }}
                              renderInput={(params) => (
                                <FormField {...params} InputLabelProps={{ shrink: true }} label="Engagement Duration(in Months)"
                                  error={formik.touched.engagementDuration && formik.errors.engagementDuration ? true : false}
                                  helperText={
                                    formik.touched.engagementDuration && formik.errors.engagementDuration
                                      ? formik.errors.engagementDuration
                                      : null
                                  }
                                />
                              )}
                            /> */}
                            <FormField
                              type="number"
                              label={<MDTypography variant="h5">Engagement Duration (in Days)</MDTypography>}
                              name="engagementDuration"
                              {...formik.getFieldProps("engagementDuration")}
                              success={formik.values.engagementDuration > 0 && !formik.errors.engagementDuration}
                              error={
                                formik.touched.engagementDuration && formik.errors.engagementDuration
                                  ? true
                                  : false
                              }
                              helperText={
                                formik.touched.engagementDuration && formik.errors.engagementDuration
                                  ? formik.errors.engagementDuration
                                  : null
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Autocomplete
                              name="billing"
                              options={selectData.pay}
                              onChange={(event, value) => { formik.setFieldValue("billing", value, false) }}
                              renderInput={(params) => (
                                <FormField {...params} InputLabelProps={{ shrink: true }} 
                                  label={<MDTypography variant="h5">Mode of billing</MDTypography>}
                                  success={formik.values.billing > 0 && !formik.errors.billing}
                                  error={
                                    formik.touched.billing && formik.errors.billing
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    formik.touched.billing && formik.errors.billing
                                      ? formik.errors.billing
                                      : null
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FormField 
                              label={<MDTypography variant="h5">Resource Number(No. of positions)*</MDTypography>}
                              name="numberOfPositions"
                              inputProps={{ type: "number" }}
                              {...formik.getFieldProps("numberOfPositions")}
                              success={formik.values.numberOfPositions > 0 && !formik.errors.numberOfPositions}
                              error={
                                formik.touched.numberOfPositions && formik.errors.numberOfPositions
                                  ? true
                                  : false
                              }
                              helperText={
                                formik.touched.numberOfPositions && formik.errors.numberOfPositions
                                  ? formik.errors.numberOfPositions
                                  : null
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Autocomplete
                              name="location"
                              {...formik.getFieldProps("location")}
                              options={city}
                              onChange={(event, city) => {
                                console.log("location", city)
                                formik.setFieldValue("location", city, false);
                              }}
                              renderInput={(params) => (
                                <FormField
                                  {...formik.getFieldProps("location")}
                                  {...params}
                                  onKeyDown={(event) => {
                                    if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  InputLabelProps={{ shrink: true }}
                                  label={<MDTypography variant="h5">Location</MDTypography>}
                                  success={formik.values.location > 0 && !formik.errors.location}
                                  error={
                                    formik.touched.location && formik.errors.location
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    formik.touched.location && formik.errors.location
                                      ? formik.errors.location
                                      : null
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <CurrencyFormat
                              label={<MDTypography variant="h5">Indicative budget</MDTypography>}
                              name="indicativeBudget"
                              thousandSeparator = {true}
                              thousandSpacing = '2s'
                              prefix = '₹'
                              customInput = {FormField}
                              {...formik.getFieldProps("indicativeBudget")}
                              success={formik.values.indicativeBudget > 0 && !formik.errors.indicativeBudget}
                              error={
                                formik.touched.indicativeBudget && formik.errors.indicativeBudget
                                  ? true
                                  : false
                              }
                              helperText={
                                formik.touched.indicativeBudget && formik.errors.indicativeBudget
                                  ? formik.errors.indicativeBudget
                                  : null
                              }
                            />
                          </Grid>

                        </Grid>
                      </MDBox>
                    </Card>
                    {/* <Card>
                <MDBox p={3}>
                  <MDBox mb={2}>
                    <MDTypography variant='h5'>
                      Education
                    </MDTypography>
                  </MDBox>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <FormField label="Degree" name="degree"
                        {...formik.getFieldProps("degree")}
                        success={formik.values.degree > 0 && !formik.errors.degree}
                        error={
                          formik.touched.degree && formik.errors.degree
                            ? true
                            : false
                        }
                        helperText={
                          formik.touched.degree && formik.errors.degree
                            ? formik.errors.degree
                            : null
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormField label="Subject" name="subject"
                        {...formik.getFieldProps("subject")}
                        success={formik.values.subject > 0 && !formik.errors.subject}
                        error={
                          formik.touched.subject && formik.errors.subject
                            ? true
                            : false
                        }
                        helperText={
                          formik.touched.subject && formik.errors.subject
                            ? formik.errors.subject
                            : null
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormField label="Passed out year" name="passOutYear"
                        {...formik.getFieldProps("passOutYear")}
                        inputProps={{ type: "number" }}
                        success={formik.values.number > 0 && !formik.errors.number}
                        error={
                          formik.touched.number && formik.errors.number
                            ? true
                            : false
                        }
                        helperText={
                          formik.touched.number && formik.errors.number
                            ? formik.errors.number
                            : null
                        }
                      />
                    </Grid>
                  </Grid>
                  </Card> */}
                    <Card sx={{ p: "15px" }}>
                      <MDBox pt={1}>
                        <MDButton variant="gradient" size="small" color="success" type="submit">POST</MDButton>
                        <MDButton variant="gradient" size="small" color="info" sx={{ ml: '8px' }}
                          onClick={() => handleReset()}>RESET</MDButton>
                      </MDBox>
                    </Card>
                  </form>
                </MDBox>
              ) : (
                <Card sx={{ p: "20px" }}>
                  <MDTypography variant="h6">
                    Engagement Posting limit has been reached......<br />
                    <MDTypography variant="button" color="info" fontWeight="medium">Upgrade your subscription plan for posting more engagements.</MDTypography>
                  </MDTypography>
                </Card>
              )}
            </>
          )}
        </MDBox>
      )
      }

    </DashboardLayout >
  );
};

export default EngagementPosting;
