
import { useContext, useEffect, useState } from "react";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

//user context
import { UserContext } from "context/UserProvider";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

//form
import * as Yup from "yup";
import { useFormik } from "formik";
import api from "services/api";
import { useNavigate } from "react-router-dom";
import { SnackbarContext } from "context/SnackbarContext";

const initialValues = {
  proRefName: "",
  proRefEmail: "",
  proRefNumber: "",
  proRefPosition: "",
  proRefOrganization: "",
}


const phoneRegExp =
/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object({
  proRefName: Yup.string().required("Name is required"),
  proRefEmail: Yup.string().email().required("Email is required"),
  proRefPosition: Yup.string().required("Position is required"),
  proRefNumber: Yup.string()
  .required("Phone number is required")
  .matches(phoneRegExp, "Phone Number is not valid")
  .min(0, "Phone number is to short")
  .max(12, "Phone number is to long"),
  proRefOrganization: Yup.string().required("Organization is required"),
});




function Professional({ userData,updateUserData }) {
  let navigate = useNavigate();

  const { userInfo } = useContext(UserContext);
  const { setSnack } = useContext(SnackbarContext);

  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState({});
  const [edit, setEdit] = useState(false);
  const handleEdit = () => setEdit(!edit);
  const [cat, setCat] = useState(false);
  const handleCat = () => setCat(!cat);
  const [profile, setProfile] = useState({});
  const toggleSnackbar = () => setShow(!show);
  const [industry, setIndustry] = useState();
  const [indData, setIndData] = useState([]);
  const [details, setDetails] = useState();
  // const [initialValues, setInitialValues] = useState({
  //   // userId: userInfo?.id,
  //   proRefName: "",
  //   proRefEmail: "",
  //   proRefNumber: "",
  //   proRefPosition: "",
  //   proRefOrganization: "",
  // });

  const validate = (values) => {
    let errors = {};

    if (!values.firstName) {
      errors.firstName = "Required";
    }
    if (!values.lastName) {
      errors.lastName = "Required";
    }
    if (!values.email) {
      errors.email = "Required";
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = "Required";
    }
    return errors;
  };
  


  // useEffect(() => {
  //   const id = userInfo.id;
  //   console.log("hello", id);
  //   api.engager.findOne(id).then((data) => {
  //     const temp = data?.data?.result?.data;
  //     // console.log("************************", temp);
  //     setDetails(temp); 
  //   });
  // }, []);

  useEffect(() => {
    api.industry
      .findAll()
      .then((data) => {
        // console.log("list of industries", data);
        let temp = data?.data?.result?.data;
        // console.log(temp);
        setIndData(temp);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  // useEffect(() => {
  //   console.log("inii", initialValues);
  //   setInitialValues(profile);
  // }, [profile])
  // const open = Boolean(anchorEl);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log("userInfo values", values);
      try {
        let id = ""
        if(userInfo.type == "admin"){
          id = userData?.userId
        }else{
          id = userInfo?.id
        }
        updateUserData(values);
        const result = await api.userInfo.update(id, values);
        if (result) {
          // navigate(`/account/settings`);
          if (result?.data?.message == "success") {
            setSnack({
              message: "Professional Reference details updated!",
              open: true,
              type: "success",
            });
          } else if(result?.data?.message == "error") {
            setSnack({
              message: "Error updating details!",
              open: true,
              type: "error",
            });
          }          
        } else {
          throw new error("failed to update");
        }
      } catch (error) {
        setSnack({
          message: "Error occurred during update!",
          open: true,
          type: "success",
        });
        console.log("ERROR LOGIN: ", { ...error });
      }
    },
    // validate,
  });

  //handle click event of the add button
  // const handleAddClick = () => {
  //   setInputList([
  //     ...inputList,
  //     {
  //       proRefName: "",
  //       proRefEmail: "",
  //       proRefNumber: "",
  //       proRefPosition: "",
  //       proRefOrganization: "",
  //     },
  //   ]);
  // };
  useEffect(() => {
    if (userData) {
      // proRefName ||  proRefEmail || proRefNumber ||  proRefPosition || proRefOrganization
      // formik.setValues(userData, false);
      formik.setValues(userData);
      formik.setFieldValue("proRefName" , userData?.proRefName ? userData?.proRefName : '', true );
      formik.setFieldValue("proRefEmail" , userData?.proRefEmail ? userData?.proRefEmail : '', true );
      formik.setFieldValue("proRefNumber" , userData?.proRefNumber ? userData?.proRefNumber : '', true );
      formik.setFieldValue("proRefPosition" , userData?.proRefPosition ? userData?.proRefPosition : '', true );
      formik.setFieldValue("proRefOrganization" , userData?.proRefOrganization ? userData?.proRefOrganization : '', true );
    }
  }, [userData])

  return (
    <Card id="Profile-Reference" sx={{ overflow: "visible" }}>
      <MDBox p={3} lineHeight>
        <MDBox mb={1}>
          <MDTypography variant="h5">Professional Reference </MDTypography>
        </MDBox>
        <MDTypography variant="button" color="text">
          The referee shouldn't be your family member, blood relation or relative.
        </MDTypography>
      </MDBox>
      <form onSubmit={formik.handleSubmit} method="post">
        <MDBox pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              {/* <MDBox pt={3}>
                <MDTypography variant="h5">Sub-User Info</MDTypography>
              </MDBox> */}

              <FormField
                label="Name"
                name="proRefName"
                {...formik.getFieldProps("proRefName")}
                error={formik.touched.proRefName && formik.errors.proRefName ? true : false}
                success={formik.touched.proRefName && !formik.errors.proRefName}
                helperText={
                  formik.touched.proRefName && formik.errors.proRefName ? formik.errors.proRefName : null
                }
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <FormField
                label="Last Name"
                name="lastName"
                {...formik.getFieldProps("lastName")}
                error={formik.touched.lastName && formik.errors.lastName ? true : false}
                helperText={
                  formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : null
                }
              />
            </Grid> */}

            <Grid item xs={12} sm={6}>
              <FormField
                label="Email"
                name="proRefEmail"
                setFieldValue={profile.proRefEmail}
                inputProps={{ type: "proRefEmail" }}
                {...formik.getFieldProps("proRefEmail")}
                error={formik.touched.proRefEmail && formik.errors.proRefEmail ? true : false}
                success={formik.touched.proRefEmail && !formik.errors.proRefEmail}
                helperText={
                  formik.touched.proRefEmail && formik.errors.proRefEmail
                    ? formik.errors.proRefEmail
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Phone Number"
                type="number"
                placeholder="+91 "
                name="proRefNumber"
                inputProps={{ type: "number" }}
                {...formik.getFieldProps("proRefNumber")}
                error={formik.touched.proRefNumber && formik.errors.proRefNumber ? true : false}
                helperText={
                  formik.touched.proRefNumber && formik.errors.proRefNumber
                    ? formik.errors.proRefNumber
                    : null
                }
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormField
                label="Position"
                name="proRefPosition"
                setFieldValue={profile.proRefPosition}
                inputProps={{ type: "proRefPosition" }}
                {...formik.getFieldProps("proRefPosition")}
                error={formik.touched.proRefPosition && formik.errors.proRefPosition ? true : false}
                helperText={
                  formik.touched.proRefPosition && formik.errors.proRefPosition
                    ? formik.errors.proRefPosition
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Organization"
                name="proRefOrganization"
                setFieldValue={profile.proRefOrganization}
                inputProps={{ type: "proRefOrganization" }}
                {...formik.getFieldProps("proRefOrganization")}
                error={
                  formik.touched.proRefOrganization && formik.errors.proRefOrganization
                    ? true
                    : false
                }
                helperText={
                  formik.touched.proRefOrganization && formik.errors.proRefOrganization
                    ? formik.errors.proRefOrganization
                    : null
                }
              />
            </Grid>
          </Grid>

          <MDBox pt={2} pb={3} sx={{ float: "left" }}>
            <MDButton variant="gradient" size="small" color="success" type="submit">
              SAVE
            </MDButton>
            {/* <MDButton variant="gradient" size="small" color="info" sx={{ ml: '8px' }}>EDIT</MDButton> */}
          </MDBox>
        </MDBox>
      </form>
    </Card>
  );
}

export default Professional;
