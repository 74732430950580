import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, Checkbox, FormGroup, Stack } from "@mui/material";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Otis Admin PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/pages/profile/components/Header";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings";

// Data

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import selectData from "./data/selectData";
import AssessorList from "./AssessorList";
import { useContext, useEffect, useState } from "react";
import MDButton from "components/MDButton";

//form
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import api from "services/api";
import FormField from "layouts/pages/account/components/FormField";
import { SnackbarContext } from "context/SnackbarContext";
import CircularProgress from "@mui/material/CircularProgress";

const EditAssessorProfile = ({ details, handleEdit, handleEditAssessor }) => {

  const initialValues = {
    assessorId: "",
    firstName: details?.firstName || "",
    lastName: details?.lastName || "",
    currentPosition: details?.currentPosition || "",
    currentOrganization: details?.currentOrganization || "",
    industry: details?.industry || "",
    domain: details?.domain || "",
    email: details?.email || "",
    phoneNumber: details?.phoneNumber || "",
    description: details?.description || "",
    linkedinProfile: details?.linkedinProfile || "",
  };

  let navigate = useNavigate();
  const { setSnack } = useContext(SnackbarContext);
  const [showForm, setShowForm] = useState(false);
  const handleShow = () => setShowForm(true);
  const handleDontShow = () => setShowForm(false);

  const [data, setData] = useState();
  const [domain, setDomain] = useState();
  const [indData, setIndData] = useState([]);
  const [industry, setIndustry] = useState();
  const [masterDomain, setMasterDomain] = useState();
  const [loading, setLoading] = useState(true);
  const [initialIndustry, setInitialIndustry] = useState();
  const [initialDomain, setInitialDomain] = useState();
  useEffect(() => {
    api.industry
      .findAll()
      .then((data) => {
        let temp = data?.data?.result?.data;
        setIndData(temp);
        let tempInd = temp.find((x) => x.Industry == details?.industry)
        setInitialIndustry(tempInd.Industry);
        formik.setFieldValue("industry", tempInd.Industry, false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    api.domain
      .findAll()
      .then((data) => {
        let temp = data?.data?.result?.data;
        setData(temp.filter((x) => x.Industry == details?.industry));
        let tempDomain = temp.find((x) => x.Domain == details?.domain)
        setInitialDomain(tempDomain.Domain)
        formik.setFieldValue("domain", tempDomain.Domain, false)
        setMasterDomain(temp);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object({
    // phoneNumber: Yup.string().matches(phoneRegExp, "phoneNumber is required"),
    currentPosition: Yup.string().required("Current position is required"),
    currentOrganization: Yup.string().required("Current Organization is required"),
    industry: Yup.string().required("Industry is required"),
    domain: Yup.string().required("Domain is required"),
    linkedProfile:Yup.string(),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log("assessor values", values);
      try {
        let id = details?.assessorId
        const registerAssessor = await api.assessor.update(id, values)
          .then((registerAssessor) => {
            if(registerAssessor?.data?.message == "success"){
              setSnack({
                message: `${values.email} Assessor is edited`,
                open: true,
                type: "success",
              });
              {handleEditAssessor ? handleEditAssessor() : handleEdit()}
            }else{
              setSnack({
                message: `Error occurred while editing Assessor`,
                open: true,
                type: "error",
              });
            }
          })
          .catch((error) => {
            setSnack({
              message: `Error occurred while editing Assessor`,
              open: true,
              type: "error",
            });
            console.log("Assessor error", error);
          });

      } catch (error) {
        // console.log("ERROR LOGIN: ", { ...error });
      }
    },
  });

  const handleReset = () => {
    formik.handleReset();
  };
  // useEffect(() => {
  //   console.log("industry, domain", details?.industry, details?.domain, details)
  //   formik.setFieldValue("industry", details?.industry, false);
  //   formik.setFieldValue("domain", details?.domain, false);
  // }, [])
  return (
    <MDBox>
      {loading ? (
        <Card>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  py: 5,
                }}
              >
                <CircularProgress color="primary" />
              </Box>
            </Grid>
          </Grid>
        </Card>
      ) : (
        <Card>
          <MDBox p={3} display="flex" justifyContent="space-between">
            <MDTypography variant="h5">Assessor Details</MDTypography>
            <MDButton size="small" color="info" 
            onClick={() => {
              handleEditAssessor ? handleEditAssessor() : handleEdit()
            }}>
              back
            </MDButton>
          </MDBox>
          <form onSubmit={formik.handleSubmit} onReset={() => handleReset()} method="post">
            <MDBox pb={3} px={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormField
                    inputProps={{ readOnly: true }}
                    label="First Name"
                    placeholder="Alec"
                    name="firstName"
                    {...formik.getFieldProps("firstName")}
                    success={formik.values.firstName > 0 && !formik.errors.firstName}
                    error={
                      formik.touched.organizationName && formik.errors.organizationName
                        ? true
                        : false
                    }
                    helperText={
                      formik.touched.organizationName && formik.errors.organizationName
                        ? formik.errors.organizationName
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    inputProps={{ readOnly: true }}
                    label="Last Name"
                    placeholder="Marcus"
                    name="lastName"
                    {...formik.getFieldProps("lastName")}
                    success={formik.values.lastName > 0 && !formik.errors.lastName}
                    error={
                      formik.touched.lastName && formik.errors.lastName
                        ? true
                        : false
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                        ? formik.errors.lastName
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    label="Current Position"
                    placeholder="Alec"
                    name="currentPosition"
                    {...formik.getFieldProps("currentPosition")}
                    success={formik.values.currentPosition > 0 && !formik.errors.currentPosition}
                    error={
                      formik.touched.currentPosition && formik.errors.currentPosition
                        ? true
                        : false
                    }
                    helperText={
                      formik.touched.currentPosition && formik.errors.currentPosition
                        ? formik.errors.currentPosition
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    label="Current Organization"
                    placeholder="Alec"
                    name="currentOrganization"
                    {...formik.getFieldProps("currentOrganization")}
                    success={formik.values.currentOrganization > 0 && !formik.errors.currentOrganization}
                    error={
                      formik.touched.currentOrganization && formik.errors.currentOrganization
                        ? true
                        : false
                    }
                    helperText={
                      formik.touched.currentOrganization && formik.errors.currentOrganization
                        ? formik.errors.currentOrganization
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    // multiple
                    id="select-industry"
                    name="industry"
                    options={indData}
                    autoHighlight
                    getOptionLabel={(option) => option.Industry || initialIndustry}
                    defaultValue={initialIndustry}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.Industry}
                      </Box>
                    )}
                    renderInput={(params) => <FormField {...params} label="Select Industry" />}
                    onChange={(event, newValue) => {
                      setIndustry(newValue);
                      formik.setFieldValue("industry", newValue.Industry, false);
                      let y = masterDomain.filter(x => x.Industry == newValue.Industry)
                      console.log("master data", y)
                      setData(y);
                      formik.setFieldValue("industry", newValue.Industry, false);
                      formik.setFieldValue("domain", '', false);
                      formik.setFieldValue("primarySkills", '', false);
                      formik.setFieldValue("secondarySkills", '', false);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    name="domain"
                    options={data}
                    autoHighlight
                    getOptionLabel={(option) => option.Domain || initialDomain}
                    defaultValue={initialDomain}
                    // {...formik.getFieldProps("domain")}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.Domain}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <FormField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        label="Select Domain"
                      />
                    )}
                    onChange={(event, newValue) => {
                      setDomain(newValue);
                      formik.setFieldValue("domain", newValue.Domain, false);
                    }}
                  />

                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    inputProps={{ readOnly: true }}
                    label="Email"
                    placeholder="example@mail.com"
                    name="email"
                    {...formik.getFieldProps("email")}
                    success={formik.values.email > 0 && !formik.errors.email}
                    error={
                      formik.touched.email && formik.errors.email
                        ? true
                        : false
                    }
                    helperText={
                      formik.touched.email && formik.errors.email
                        ? formik.errors.email
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    inputProps={{ readOnly: true }}
                    label="Phone number"
                    placeholder="+910022335545"
                    name="phoneNumber"
                    {...formik.getFieldProps("phoneNumber")}
                    success={formik.values.phoneNumber > 0 && !formik.errors.phoneNumber}
                    error={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                        ? true
                        : false
                    }
                    helperText={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                        ? formik.errors.phoneNumber
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    label="LinkedIn Profile"
                    placeholder=""
                    name="linkedinProfile"
                    {...formik.getFieldProps("linkedinProfile")}
                    success={formik.values.linkedinProfile > 0 && !formik.errors.linkedinProfile}
                    error={
                      formik.touched.linkedinProfile && formik.errors.linkedinProfile
                        ? true
                        : false
                    }
                    helperText={
                      formik.touched.linkedinProfile && formik.errors.linkedinProfile
                        ? formik.errors.linkedinProfile
                        : null
                    }
                  ></FormField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    label="A brief Story"
                    placeholder=""
                    name="description"
                    multiline
                    rows={5}
                    inputProps={{ type: "text", maxLength:250}}
                    {...formik.getFieldProps("description")}
                    success={formik.values.description > 0 && !formik.errors.description}
                    error={
                      formik.touched.description && formik.errors.description
                        ? true
                        : false
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                        ? formik.errors.description
                        : null
                    }
                  ></FormField>
                </Grid>
                <Grid item xs={12} sm={12} display="flex" justifyContent="space-between">
                  <MDButton variant="gradient" color="success" size="small" type="submit">
                    Save
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </form>
        </Card>
      )}
    </MDBox>
  )
}

export default EditAssessorProfile