import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import api from "services/api";
import { useStyles } from "../styles";
import CreateContractTemplate from "./CreateContractTemplate";
import AddIndustry from "../../MasterData/IndustryList/AddIndustry";

const ListContracts = () => {
  const [templates, setTemplates] = useState([]);
  const [open, setOpen] = useState(false);
  const classes = useState();

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
   getContracts();
  }, []);

  const getContracts = () => {
    api.contract.getContractTemplates().then((res) => {
      if (res) {
        console.log("data", res.data.result.data);
        setTemplates(res.data.result.data);
      }
    });
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={12}>
            {/* <Card> */}
            <MDBox p={2} px={1} display="flex" justifyContent="space-between">
              <MDTypography type="title"></MDTypography>
              <MDButton variant="gradient" size="medium" color="success" onClick={() => setOpen(true)}>
                Add Contract Template
              </MDButton>
            </MDBox>
            {/* </Card> */}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {templates?.map((row, index) => (
            <Grid item xs={12} lg={3} key={index}>
              <Card>
                <MDBox p={3}>
                  <MDTypography type="title">Template Name: {row.title}</MDTypography>
                  <MDBox py={1} display="flex" justifyContent="space-between" alignItems="center">
                    <MDTypography sx={{ fontSize: "14px" }}>Gigleji To GigTalent</MDTypography>
                    <MDButton
                      variant="outlined"
                      size="small"
                      color="dark"
                      href={row.giglejiToGigler}
                    >
                      Download
                    </MDButton>
                  </MDBox>
                  <MDBox py={2} display="flex" justifyContent="space-between" alignItems="center">
                    <MDTypography sx={{ fontSize: "14px" }}>Engager To Gigleji</MDTypography>
                    <MDButton
                      variant="outlined"
                      size="small"
                      color="dark"
                      href={row.enagagerToGigleji}
                    >
                      Download
                    </MDButton>
                  </MDBox>
                  <MDBox py={2} display="flex" justifyContent="space-between" alignItems="center">
                    <MDTypography sx={{ fontSize: "14px" }}>Engager To GigTalent</MDTypography>
                    <MDButton
                      variant="outlined"
                      size="small"
                      color="dark"
                      href={row.enagagerToGigler}
                    >
                      Download
                    </MDButton>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          ))}
        </Grid>
      </MDBox>
      <Modal open={open} onClose={handleClose}>
        <MDBox
          sx={{
            position: "absolute",
            top: "30%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            height: "30%",
            margin: "auto",
            boxShadow: 24,
            p: 4,
          }}
        >
          <CreateContractTemplate handleClose={handleClose} updateContracts={getContracts}/>
        </MDBox>
      </Modal>
    </DashboardLayout>
  );
};
export default ListContracts;
