import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useContext, useEffect, useState } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import api from "services/api";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import { SnackbarContext } from "context/SnackbarContext";
import FormControl from '@mui/material/FormControl';
import { Autocomplete, Box, Card, IconButton, Input, InputAdornment, InputLabel } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import FormField from "layouts/pages/account/components/FormField";
import { Navigate, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const RegisterServiceGigler = () => {
  let navigate = useNavigate();
  const { setSnack } = useContext(SnackbarContext);
  const [domain, setDomain] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState();
  const [masterDomain, setMasterDomain] = useState();
  const [skills, setSkills] = useState([]);
  const [tempSkills, setTempSkills] = useState([])
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    industry: "Services",
    domain: "",
    primarySkills: "",
    secondarySkills: "",
    otherSkills: "",
    phoneNumber: "",
    password: "",
    type: "gigler",
    assessed: true,
  };

  const phoneRegExp = /^[0-9]*$/;
  // /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .max(25, "Maximum 25 characters")
      .required("First Name is required"),
    lastName: Yup.string()
      .max(25, "Maximum 25 characters")
      .required("Last Name is required"),
    email: Yup.string().email().required("Email is required"),
    industry: Yup.string()
      .required("Industry is required"),
    domain: Yup.string()
      .required("Domain is required"),
    primarySkills: Yup.string()
      .required("Primary Skill is required"),
    secondarySkills: Yup.string()
      .required("Secondary Skill is required"),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Too short")
      .max(10, "Too long"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Your password must be longer than 8 characters.")
      .max(25)
      .matches(/^(?=.{8,})/, "Must Contain 8 Characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])/,
        "Must Contain One Uppercase, One Lowercase"
      )
      .matches(
        /^(?=.*[!@#\$%\^&\*])/,
        "Must Contain One Special Case Character"
      )
      .matches(/^(?=.{6,20}$)\D*\d/, "Must Contain One Number"),
  });

  useEffect(() => {
    api.domain.findAll().then(data => {
      let temp = data?.data?.result?.data;
      let y = temp.filter(x => x.Industry == "Services")
      setData(y);
    }).catch(error => {
      console.log("error", error)
    })
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log("service gigler values", values)
      const result = await api.users.registerUser(values)
      if (result.data.message == "success") {
        const userId = result?.data?.result?.data?.id
        values.industry = "Services"
        values.userId = userId;
        const result1 = await api.userInfo.create(values)
        if (result1.data.message == "success") {
          setSnack({
            message: `${values.email} Service Gig talent is created`,
            open: true,
            type: "success",
          });
          navigate("/admin/manage-gigTalent");
        }
      } else {
        formik.handleReset();
        setSnack({
          message: `${values.email} already exits`,
          open: true,
          type: "error",
        });
      }

    },
  });

  const handleCancel = () => {
    navigate("/admin/manage-gigTalent")
    formik.handleReset();
  }
 
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <MDTypography px={3.5} py={2}>GigTalent Information</MDTypography>
            <MDBox
              variant="button"
              color="dark"
              align="right"
              // className={css.backIcon}
              sx={{mr:"30px",mt:"-50px",mb:'10px'}}
            >
            <MDButton
                  variant="outlined"
                  color="info"
                  size="small"
            onClick={() => handleCancel()}

            >
                    
            <ArrowBackIcon sx={{ pt: "2px" }} />
            Back
            </MDButton>
            </MDBox>
        <MDBox px={4} py={2}>
          <form onSubmit={formik.handleSubmit} method="post">
            <Grid
              container
              spacing={3}
              direction="row"
              // sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <Grid item xs={12} sm={4}>
                <MDBox mb={1}>
                  <FormField
                    type="text"
                    label="First Name"
                    placeholder="Enter First Name"
                    variant="standard"
                    name="firstName"
                    value=""
                    {...formik.getFieldProps("firstName")}
                    error={formik.touched.firstName && formik.errors.firstName ? true : false}
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                        ? formik.errors.firstName
                        : null
                    }
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDBox mb={1}>
                  <FormField
                    type="text"
                    label="Last Name"
                    name="lastName"
                    variant="standard"
                    placeholder="Enter Last Name"
                    value=""
                    {...formik.getFieldProps("lastName")}
                    error={formik.touched.lastName && formik.errors.lastName ? true : false}
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                        ? formik.errors.lastName
                        : null
                    }
                    fullWidth
                  />
                </MDBox>
              </Grid>

              <Grid item xs={12} sm={4}>
                <MDBox mb={1}>
                  <FormField
                    type="email"
                    label="Email"
                    name="email"
                    placeholder="email@email.com"
                    variant="standard"
                    value=""
                    {...formik.getFieldProps("email")}
                    error={formik.touched.email && formik.errors.email ? true : false}
                    helperText={
                      formik.touched.email && formik.errors.email ? formik.errors.email : null
                    }
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDBox mb={1}>
                  <MDInput
                    label="Industry"
                    name="industry"
                    variant="standard"
                    defaultValue="Services"
                    inputProps={{ readOnly: true }}
                    {...formik.getFieldProps("industry")}
                    error={formik.touched.industry && formik.errors.industry ? true : false}
                    helperText={
                      formik.touched.industry && formik.errors.industry ? formik.errors.industry : null
                    }
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDBox mb={1}>
                  <Autocomplete
                    options={data}
                    autoHighlight
                    getOptionLabel={(option) => option.Domain}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.Domain}
                      </Box>
                    )}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("domain", newValue.Domain, false);
                      setSkills(newValue.skillSet);
                      console.log("skills", newValue.skillSet);
                    }}
                    renderInput={(params) => (
                      <FormField {...params}
                        InputLabelProps={{ shrink: true }}
                        label="Select Domain"
                        name="domain"
                        placeholder="Select Domain"
                        {...formik.getFieldProps("domain")}
                        error={formik.touched.domain && formik.errors.domain ? true : false}
                        helperText={
                          formik.touched.domain && formik.errors.domain
                            ? formik.errors.domain
                            : null
                        } />
                    )}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  // multiple
                  options={skills}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("primarySkills", newValue, false);
                    formik.setFieldValue("secondarySkills", '', false);
                    var filteredArray = skills.filter(e => e !== newValue)
                    setTempSkills(filteredArray);
                    console.log("primarySkills", newValue)
                  }}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label="Primary skill set"
                      name="primarySkills"
                      placeholder="Select One"
                      {...formik.getFieldProps("primarySkills")}
                      error={formik.touched.primarySkills && formik.errors.primarySkills ? true : false}
                      helperText={
                        formik.touched.primarySkills && formik.errors.primarySkills
                          ? formik.errors.primarySkills
                          : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  name="secondarySkills"
                  options={tempSkills}
                  {...formik.getFieldProps("secondarySkills")}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label="Secondary skill set"
                      placeholder="Select One"
                      // name="secondarySkills"
                      error={formik.touched.secondarySkills && formik.errors.secondarySkills ? true : false}
                      helperText={
                        formik.touched.secondarySkills && formik.errors.secondarySkills
                          ? formik.errors.secondarySkills
                          : null
                        }
                        />
                  )}
                  onChange={(event, skills) => {
                    formik.setFieldValue("secondarySkills", skills, false);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  label="Other skills"
                  name="otherSkills"
                  placeholder="Enter Other Relevant Skill"
                  {...formik.getFieldProps("otherSkills")}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDBox mb={1}>
                <FormField
                    type="number" 
                    label="Phone number"
                    name="phoneNumber"
                    variant="standard"
                    placeholder="+91"
                    //value=""
                    onKeyPress = {(event) => {
                      if(!phoneRegExp.test(event.key))
                      event.preventDefault();
                    }}
                    
                    {...formik.getFieldProps("phoneNumber")}
                    success={formik.values.phoneNumber > 0 && !formik.errors.phoneNumber}
                    error={formik.touched.phoneNumber && formik.errors.phoneNumber ? true : false}
                    helperText={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                        ? formik.errors.phoneNumber
                        : null
                    }
                    fullWidth
                  />
                </MDBox>
              </Grid>

              <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <MDBox mb={4} mt={1}>
                  <FormControl sx={{ width: '100%' }} variant="standard">
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      label="Password" 
                      name="password"
                      placeholder='Abcd@123'
                      value=""
                      {...formik.getFieldProps("password")}
                      error={formik.touched.password && formik.errors.password ? true : false}
                      helperText={
                        formik.touched.password && formik.errors.password
                          ? formik.errors.password
                          : null
                      }
                      fullWidth
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }/>
                      {formik.touched.password && formik.errors.password && (
                                <div style={{fontSize:'12px', textAlign:'left'}}
                                    className="text-danger">
                                    {formik.errors.password}
                                    </div>)}
                  </FormControl>
                </MDBox>
              </Grid>
            </Grid>
            <MDBox display='flex' justifyContent = "space-between">
            <MDButton variant="contained" color="success" size="small" type="submit">Create</MDButton>
            <MDButton variant="contained" color="error" size="small" onClick={() => handleCancel()}>cancel</MDButton>
            </MDBox>
          </form>
        </MDBox>
      </Card>
    </DashboardLayout >
  )
}

export default RegisterServiceGigler