import { Avatar, Grid, CircularProgress, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material'
import React, { useContext, useState } from 'react'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import theme from 'assets/theme';
import { UserContext } from 'context/UserProvider';
import api from 'services/api';
// import { useTheme } from '@material-ui/core/styles';

const Engagements = ({ engagementList, handleGiglerList, handleClick, setData }) => {
  const { userInfo } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  // const theme = useTheme();
  const handleSearch = (event) =>{
    // if(event.target.value.length >= 3){
      setLoading(true)
      if(event.target.value){
        setData(engagementList)
      }
      api.engagement.search({searchValue:event.target.value, engagerId:userInfo.id})
      .then(data => {
        if(data?.data?.message == "success"){
          setData(data?.data?.result?.data)
          setLoading(false)
        }else{
          setSearchResult("Not matched with any engagement")
          setLoading(false)
        }
      })
      .catch(err => console.log("search error", err))
    }

  const [selected, setSelected] = useState();
  return (

    <Grid container>
      <Grid item xs={12} md={12}>
        <MDBox display="flex" p={2} sx={{ alignItems: "center", justifyContent: "space-between" }}>
          <MDTypography variant="h6">Engagements's List</MDTypography>
          <MDInput type="search" label="search" onChange={(e) => handleSearch(e)}></MDInput>
        </MDBox>
        {engagementList && engagementList.length > 0 ? (
          <MDBox sx={{ maxHeight: 550, overflow: 'auto' }}>
            <List dense>
            {loading ? (
              <MDBox 
              sx={{display: "flex",
              flexDirection: "column",
              alignItems: "center",
              py: 5,
            }}>
                <CircularProgress color="error" />
              </MDBox>
            ):(
              engagementList?.map((row, index) => (
                <ListItemButton
                  key={index}
                  id={index}
                  sx={{
                    backgroundColor: index == selected ? theme.palette.grey[300] : '#ffffff',
                    '&:hover': {
                      backgroundColor: index == selected ? theme.palette.grey[300] : '#ffffff',
                      "& $addIcon": {
                        color: "purple"
                      }
                    }
                  }}
                >
                  <ListItem
                    secondaryAction={
                      <MDButton variant="gradient" color="info" size="small"
                        onClick={() => {
                          setSelected(index);
                          handleGiglerList(row);
                        }}
                      >
                        Search
                      </MDButton>
                    }
                    divider={index == engagementList.length - 1 ? false : true}
                    sx={{ p: "15px" }}
                  >
                    <ListItemText
                      primary={
                        <MDTypography variant="h6">
                          {row.engagementId}
                        </MDTypography>}
                      // secondary={row.industry}
                      secondary={
                        <>
                          <MDTypography variant="p">
                            {row.domain}
                          </MDTypography>
                          <MDTypography variant="p">
                            {`--`}{row.industry}
                          </MDTypography>
                        </>
                      }
                    />
                  </ListItem>
                </ListItemButton>
              )
              ))}  
            </List>
          </MDBox>
        ) : (
          <MDBox>
            <MDTypography px={2} py={2} color="dark" >No Matching Engagements</MDTypography>
          </MDBox>
        )}
      </Grid>
    </Grid >

  )
}

export default Engagements