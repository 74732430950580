// MUI
import { Card, Grid, Modal } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "services/api";
import { SnackbarContext } from "context/SnackbarContext";
import { UserContext } from "context/UserProvider";
import { useStyles } from "./styles";
import LoadingButton from "@mui/lab/LoadingButton";

const SignContract = () => {
  const { userInfo } = useContext(UserContext);
  const navigate = useNavigate();
  const classes = useStyles();
  const { state } = useLocation();
  const { setSnack } = useContext(SnackbarContext);
  const [gigDetails, setGigDetails] = useState(userInfo);
  const [engDetails, setEngDetails] = useState({});
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const [contractType, setContractType] = useState("");
  const [uploadFileName, setUploadFileName] = useState("");
  const [formData, setFormData] = useState({});
  const handleCloseFile = () => setOpenFile(false);
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState("");
  const [contractDetails, setContractDetails] = useState({});

  useEffect(() => {
    console.log("state values@@@@@@@@@", state);
    setContractDetails(state.contractDetails[0]);
    setTemplate(state.contractDetails[0].documentSent);
    // setGigDetails(userInfo);
    setEngDetails(state.selectedEngagements);
  }, []);

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  const handleClick = () => {
    document.getElementById("resumeFile").click();
  };

  const handleCancel = () => { };
  const handleChange = (event) => {
    setLoading(true);
    console.log("clicked", event.target.files[0].name);
    let temp = event.target.files[0];
    setUploadFileName(event.target.files[0].name);
    // setResume(URL.createObjectURL(temp));
    console.log("resume link", URL.createObjectURL(temp));
    const formData = new FormData();
    formData.append("doc", temp);

    formData.append("engagementId", engDetails.engagementId);
    formData.append("engagerId", engDetails.engagerId);
    formData.append("giglerId", userInfo.id);
    formData.append("id", contractDetails._id);
    formData.append("receiveDate", new Date());
    formData.append("from",contractDetails.from);
    formData.append("contractStatus", contractDetails.from == 'engager' || "gigleji"  ? '1' : '0')

    api.contract.signContract(formData).then((data) => {
      console.log("resume data", data);
      setLoading(false);
      navigate(-1);
    });
  };

  const formatDate = (string) => {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox position="relative" mb={5}>
        <Card
          sx={{
            position: "relative",
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={6}>
              <MDBox pt={2} px={2} pb={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Engagement Information
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Engagement Id&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{engDetails?.engagementId}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Engagement Title&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{engDetails?.engagementTitle}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <MDBox pt={2} px={2} pb={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  GigTalent Information
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Name&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{gigDetails?.firstName}&nbsp;{gigDetails?.lastName}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Email&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{gigDetails?.email}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
        <Card
          sx={{
            position: "relative",
            mt: 2,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} xl={12}>
              <MDBox pt={2} px={2} pb={2} sx={{ display: "flex", justifyContent: "space-between" }}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Download Contract, sign and upload back
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <MDBox px={2}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  color="text"
                  textTransform="capitalize"
                >
                  Download Contract sent by {contractDetails.from}
                </MDTypography>
                <MDButton
                  color="info"
                  size="small"
                  variant="outlined"
                  target="_blank"
                  href={template}
                  sx={{ ml: "10px" }}
                >
                  Download
                </MDButton>
              </MDBox>
              {contractDetails.documentSent ? (
                <MDBox px={2}>
                  {/* <MDTypography
                    variant="button"
                    fontWeight="bold"
                    color="text"
                    textTransform="capitalize"
                  >
                    Download Contract sent to {contractDetails.from}
                  </MDTypography>
                  <MDButton
                    color="info"
                    size="small"
                    variant="outlined"
                    target="_blank"
                    href={contractDetails.documentSent}
                    sx={{ ml: "10px" }}
                  >
                    Download
                  </MDButton> */}
                </MDBox>
              ) : (                            
                <MDBox pt={2} px={2}>
                  <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                    Upload Contract edited with info
                  </MDTypography>
                </MDBox>
              )}
              <MDBox pt={1} px={2} pb={2}>
                <input
                  type="file"
                  name="resumeFile"
                  id="resumeFile"
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  onChange={(e) => handleChange(e)}
                  className={classes.inputStyle}
                  sx={{ display: "none" }}
                />
                {uploadFileName && (
                  <MDTypography variant="button" fontWeight="regular" color="text" pr={2}>
                    {uploadFileName}
                  </MDTypography>
                )}
                {state?.contractStatus == "0" ? (
                  <LoadingButton
                    size="small"
                    onClick={() => handleClick()}
                    loading={loading}
                    variant="outlined"
                    color="dark"
                    sx={{
                      color: "rgb(52, 71, 103)",
                      border: "1px solid rgb(52, 71, 103)",
                    }}
                  >
                    Upload & submit
                  </LoadingButton>
                ) : state?.contractStatus == "4" ? (
                  <MDTypography variant="button" fontWeight="regular" color="text" pr={2}>
                    Contract Started on {formatDate(contractDetails?.startDate)}
                  </MDTypography>
                ) : state?.contractStatus == "1" ? (
                  <MDTypography variant="button" fontWeight="regular" color="text" pr={2}>
                    Contract Signed By GigTalent on {formatDate(contractDetails?.sentDate)}
                  </MDTypography>
                ) : state?.contractStatus && contractDetails.from == "engager" ? (
                  <MDTypography variant="button" fontWeight="regular" color="text" pr={2}>
                    Contract Closed By Engager on {formatDate(contractDetails?.endDate)}
                  </MDTypography> 
                ) :
                  state?.contractStatus == "6" && contractDetails.from == 'gigleji' ? (
                  <MDTypography variant="button" fontWeight="regular" color="text" pr={2}>
                    Contract Closed By Gigleji on {formatDate(contractDetails?.endDate)}
                  </MDTypography> ) :
                (<></>)}
                {/* {state?.contractStatus == "0" &&
                  <MDButton
                    sx={{ ml: 2 }}
                    variant="outlined"
                    size="small"
                    color="warning"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </MDButton>
                } */}
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      <Modal open={openFile} onClose={handleCloseFile}>
        <MDBox className={classes.resumeStyle}>
          <iframe
            className={"docx"}
            width="100%"
            height="100%"
            frameBorder="0"
            src={`https://docs.google.com/gview?url=&embedded=true`}
          ></iframe>
        </MDBox>
      </Modal>
    </DashboardLayout>
  );
};

export default SignContract;
