import { Card, Modal } from '@mui/material';
import MDTypography from 'components/MDTypography';
import { UserContext } from 'context/UserProvider';
import React, { useContext, useEffect, useState } from 'react'
import api from 'services/api'
import DataTable from './DataTable'
import GiglerAssessmentDetails from 'layouts/pages/AssessorDashboard/GiglerAssessmentDetails';
import MDBox from 'components/MDBox';
import { useStyles } from "../../../AssessorDashboard/styles";

const AssessedGtList = ({assrDetails}) => {
  const classes = useStyles();
  const { userInfo } = useContext(UserContext);
  const [assessmentDetails, setAssessmentDetails] = useState();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
    const [details, setDetails] = useState({
        columns: [
          { Header: "Name", accessor: `userInfo[0].firstName` },
          { Header: "Email", accessor: `userInfo[0].email` },
          { Header: "Phone number", accessor: "userInfo[0].phoneNumber" },
          { Header: "Industry", accessor: "userInfo[0].industry" },
          { Header: "Domain", accessor: "userInfo[0].domain" },
          {Header: "Assessed Values", accessor:''}
        ],
        rows: []
      })

    useEffect(() => {
        console.log("asssor details", assrDetails)
        const id = assrDetails?.assessorId
    api.assigned.findAll(id, { assigned: true, assessed: true }).then(data => {
        let temp = data?.data?.result?.data;
        console.log("list of gigTalent assigned", temp);
        if(temp.length > 0){
            setDetails({ ...details, rows: temp });
        }else{
            setDetails("1")
        }
    }).catch(error => {
      console.log("error", error)
    })
    },[])

    const handleView = (values) => {
        console.log("clicked on assesed values", values)
        setAssessmentDetails(values)
        setOpen(true)
    }
  return (
    <>
    {details == "1" ? (
        <Card sx={{p:"15px"}}>
            <MDTypography variant="h5">Assessed List is empty...</MDTypography>
        </Card>
    ):(
        <>
            <Card>
                <MDTypography variant="h5" sx={{p:"15px"}}>Assessed List</MDTypography>
                <DataTable table={details} canSearch handleView={handleView} />
            </Card>
        <Modal
            open={open}
            onClose={handleClose}
        >
            <MDBox className={classes.assessment} >
                <GiglerAssessmentDetails assessmentDetails={assessmentDetails} />
            </MDBox>
        </Modal>
      </>

    )}
    </>
  )
}

export default AssessedGtList