
import { useState, useEffect, useMemo, useContext } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Otis Admin PRO React components
import MDBox from "components/MDBox";

// Otis Admin PRO React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Otis Admin PRO React themes
import theme from "assets/theme";

// Otis Admin PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Otis Admin PRO React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import SignUpCover from "layouts/authentication/sign-up/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";

// Otis Admin PRO React routes
import userRoutes from "userRoutes";
import EmployerRoutes from "EmployerRoutes";
import adminRoutes from "adminRoutes"; 
import assessorRoutes from "assessorRoutes";

import { UserContext } from "context/UserProvider";
// import AppliedList from "layouts/pages/EngagerDashboard/EngagementStage/AppliedList"
import Authentication from "layouts/authentication/sign-up/Authentication";
// import GiglerProfile from "layouts/pages/EngagerDashboard/EngagementStage/Applied/GiglerProfile";
import GiglerProfile from "layouts/pages/common/GiglerProfile";
import GTProfile from "layouts/pages/GiglerDashboard/Profile";
import AssessorProfile from "components/AssessorProfile";
import EngagerProfile from "components/EngagerProfile";
import ManagePlans from "layouts/pages/AdminDashboard/MasterData/ManagePlans";
import ChangePassword from "layouts/pages/common/ChangePassword";
import SendContract from "layouts/pages/EngagerDashboard/EngagementStage/Contract/SendContract";
import CompletedEngagements from "layouts/pages/GiglerDashboard/CompletedEngagements";
import ClosedEngagements from "layouts/pages/EngagerDashboard/ClosedEngagements";
import EngagerFeedback from "layouts/pages/AdminDashboard/ManageFeedback/EngagerFeedback";
import GiglerFeedback from "layouts/pages/AdminDashboard/ManageFeedback/GiglerFeedback";
import SignContract from "layouts/pages/EngagerDashboard/ContractManagement/SignContract";
import EngagementDetailsCommon from "layouts/pages/common/EngagementDetails";
import SignContractAdmin from "layouts/pages/AdminDashboard/ManageContracts/SignContractAdmin";
import SubAdmin from "layouts/pages/AdminDashboard/ManageSubAdmin";
import EngagementTabs from "layouts/pages/EngagerDashboard/EngagementStage/EngagementTabs";
import StartContract from "layouts/pages/EngagerDashboard/ContractManagement/StartContract";
// import { EngagementDetails } from "layouts/pages/AdminDashboard/Engagements/EngagementDetails";
// import PrivateRoute from "PrivateRoute";
import RegisterServiceGigler from "layouts/pages/AdminDashboard/ManageGigler/RegisterServiceGigler";
import SetPassword from "layouts/pages/common/SetPassword";
const RouteInterceptor = ({ element, isAuthenticated, ...rest }) => {
  return isAuthenticated ? element : <Navigate to="/login" replace />;
};

export default function App() {
  const { isLoaded, userInfo, isAuthenticated } = useContext(UserContext);

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        // return <Route exact path={route.route} element={route.component} key={route.key} />;
        return (
          <Route
            exact
            path={route.route}
            element={
              <RouteInterceptor isAuthenticated={isAuthenticated} element={route.component} />
            }
            key={route.key}
          />
        )
      }
      return null;
    });

  const configsButton = (
    <MDBox
    // display="flex"
    // justifyContent="center"
    // alignItems="center"
    // width="3.25rem"
    // height="3.25rem"
    // bgColor="white"
    // shadow="sm"
    // borderRadius="50%"
    // position="fixed"
    // right="2rem"
    // bottom="2rem"
    // zIndex={99}
    // color="dark"
    // sx={{ cursor: "pointer" }}
    // onClick={handleConfiguratorOpen}
    >
      {/* <Icon fontSize="small" color="inherit">
        settings
      </Icon> */}
    </MDBox>
  );

  return (
    // direction === "rtl" ? (
    // <></>
    // <CacheProvider value={rtlCache}>
    //   <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
    //     <CssBaseline />
    //     {layout === "dashboard" && (
    //       <>
    //         <Sidenav
    //           color={sidenavColor}
    //           brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
    //           brandName="Otis Admin PRO"
    //           type={userInfo?.type}
    //           routes={routes}
    //           onMouseEnter={handleOnMouseEnter}
    //           onMouseLeave={handleOnMouseLeave}
    //         />
    //         <Configurator />
    //         {configsButton}
    //       </>
    //     )}
    //     {layout === "vr" && <Configurator />}
    //     <Routes>
    //       {getRoutes(routes)}
    //       <Route path="*" element={<Navigate to="/dashboards/analytics" />} />
    //     </Routes>
    //   </ThemeProvider>
    // </CacheProvider>
    // ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            // brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            // routes={routes}
            routes={
              userInfo?.type == "gigler"
                ? userRoutes
                : userInfo?.type == "employer"
                  ? EmployerRoutes
                  : userInfo?.type == "subEmployer"
                    ? EmployerRoutes.filter(x => x.key != "create-sub-users" && x.key != "sub-users-list")
                    : userInfo?.type == "assessor"
                      ? assessorRoutes
                      : userInfo?.type == "admin"
                        ? adminRoutes
                        : userInfo?.type == "Contracts-admin"
                          ? adminRoutes.filter(x => x.key != "manage-sub-admin" && x.key != "manage-engagers" && x.key != "manage-assessors" && x.key != "manage-gigTalent" && x.key != "assign-gigTalent" && x.key != "manage-financials" && x.key != "engagement-history" && x.key != "master-data" && x.key != "feedback" && x.key != "import-data")
                          : userInfo?.type == "Finance-admin"
                            ? adminRoutes.filter(x => x.key != "manage-sub-admin" && x.key != "manage-engagers" && x.key != "manage-assessors" && x.key != "manage-gigTalent" && x.key != "assign-gigTalent" && x.key != "manage-contracts" && x.key != "engagement-history" && x.key != "master-data" && x.key != "feedback" && x.key != "import-data")
                            : userInfo?.type == "Assessment-admin"
                            ? adminRoutes.filter(x => x.key != "manage-sub-admin" && x.key != "manage-engagers" &&  x.key != "manage-contracts" && x.key != "engagement-history" && x.key != "master-data" && x.key != "feedback" && x.key != "import-data" && x.key != "manage-financials")
                            : userInfo?.type == "General-admin"
                              ? adminRoutes
                              : []
            }
            brandName="GIGLEJI"
            // profileImg={(transparentSidenav && !darkMode) || whiteSidenav ? defaultProfile : defaultProfile}
            type={userInfo?.firstName + " " + userInfo?.lastName}
            onMouseLeave={handleOnMouseLeave}
            onMouseEnter={handleOnMouseEnter}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      {isLoaded && (
        <Routes>
          {userInfo?.type == "gigler"
            ? getRoutes(userRoutes)
            : userInfo?.type == "employer"
              ? getRoutes(EmployerRoutes)
              : userInfo?.type == "subEmployer"
                ? getRoutes(EmployerRoutes.filter(x => x.key != "create-sub-users" && x.key != "sub-users-list"))
                : userInfo?.type == "assessor"
                  ? getRoutes(assessorRoutes)
                  : userInfo?.type == "admin"
                    ? getRoutes(adminRoutes)
                    : userInfo?.type == "Contracts-admin"
                      ? getRoutes(adminRoutes.filter(x => x.key != "manage-sub-admin" && x.key != "manage-engagers" && x.key != "manage-assessors" && x.key != "manage-gigTalent" && x.key != "assign-gigTalent" && x.key != "manage-financials" && x.key != "engagement-history" && x.key != "master-data" && x.key != "feedback" && x.key != "import-data"))
                      : userInfo?.type == "Finance-admin"
                        ? getRoutes(adminRoutes.filter(x => x.key != "manage-sub-admin" && x.key != "manage-engagers" && x.key != "manage-assessors" && x.key != "manage-gigTalent" && x.key != "assign-gigTalent" && x.key != "manage-contracts" && x.key != "engagement-history" && x.key != "master-data" && x.key != "feedback" && x.key != "import-data"))
                        : userInfo?.type == "Assessment-admin"
                        ? getRoutes(adminRoutes.filter(x => x.key != "manage-sub-admin" && x.key != "manage-engagers" &&  x.key != "manage-contracts" && x.key != "engagement-history" && x.key != "master-data" && x.key != "feedback" && x.key != "import-data" && x.key != "manage-financials"))
                        : userInfo?.type == "General-admin"
                          ? getRoutes(adminRoutes)
                          : getRoutes([])}
          {/* {getRoutes(userRoutes)} */}
          <Route path="*" element={<SignInIllustration />} />
          <Route path="/login" element={<SignInIllustration />} />
          <Route path="/register" element={<SignUpCover />} />
          <Route path="/register/verification" element={<Authentication />} />
          {/* <Route path="/account/profile" element={<EngagerProfileOverview />} /> */}
          {/* <Route
            path="/account/profile"
            element={
              <RouteInterceptor
                isAuthenticated={isAuthenticated}
                element={<EngagerProfileOverview />}
              />
            }
          /> */}
          {/* <Route path="/admin/assessor-details/" element={<AssessorProfile />} /> */}
          <Route
            path="/admin/assessor-details"
            element={
              <RouteInterceptor isAuthenticated={isAuthenticated} element={<AssessorProfile />} />
            }
          />
          {/* <Route path="/admin/engager-details/" element={<EngagerProfile />} /> */}
          <Route
            path="/admin/engager-details"
            element={
              <RouteInterceptor isAuthenticated={isAuthenticated} element={<EngagerProfile />} />
            }
          />
          {/* <Route path="/employer/plan-details/" element={<ManagePlans />} /> */}
          <Route
            path="/employer/plan-details"
            element={
              <RouteInterceptor isAuthenticated={isAuthenticated} element={<ManagePlans />} />
            }
          />
          <Route
            path="/gigTalent-details"
            element={
              <RouteInterceptor isAuthenticated={isAuthenticated} element={<GiglerProfile />} />
            }
          />
          <Route
            path="/account/change-password"
            element={
              <RouteInterceptor isAuthenticated={isAuthenticated} element={<ChangePassword />} />
            }
          />
           <Route
            path="/account/set-password"
            element={
              <RouteInterceptor isAuthenticated={isAuthenticated} element={<SetPassword />} />
            }
          />
          <Route
            path="/send-contract"
            element={
              <RouteInterceptor isAuthenticated={isAuthenticated} element={<SendContract />} />
            }
          />
          <Route
            path="/sign-contract"
            element={
              <RouteInterceptor isAuthenticated={isAuthenticated} element={<SignContract />} />
            }
          />
          <Route
            path="/admin/sign-contract"
            element={
              <RouteInterceptor isAuthenticated={isAuthenticated} element={<SignContractAdmin />} />
            }
          />
          <Route
            path="/engager/start-contract"
            element={
              <RouteInterceptor isAuthenticated={isAuthenticated} element={<StartContract />} />
            }
          />
          <Route
            path="/account/completed-engagements"
            element={
              <RouteInterceptor isAuthenticated={isAuthenticated} element={<CompletedEngagements />} />
            }
          />
          <Route
            path="/engagement/:id"
            element={
              <RouteInterceptor isAuthenticated={isAuthenticated} element={<EngagementDetailsCommon />} />
            }
          />
          <Route
            path="/employer/closed-engagements"
            element={
              <RouteInterceptor isAuthenticated={isAuthenticated} element={<ClosedEngagements />} />
            }
          />
          <Route
            path="/admin/feedback/engager-feedback"
            element={
              <RouteInterceptor isAuthenticated={isAuthenticated} element={<EngagerFeedback />} />
            }
          />
          <Route
            path="/admin/feedback/gigTalent-feedback"
            element={
              <RouteInterceptor isAuthenticated={isAuthenticated} element={<GiglerFeedback />} />
            }
          />
          <Route
            path="/admin/manage-sub-admin"
            element={
              <RouteInterceptor isAuthenticated={isAuthenticated} element={<SubAdmin />} />
            }
          />
          <Route
            path="/employer/engagement"
            element={
              <RouteInterceptor isAuthenticated={isAuthenticated} element={<EngagementTabs />} />
            }
          />
          <Route
            path="/admin/register-service-gigTalent"
            element={
              <RouteInterceptor isAuthenticated={isAuthenticated} element={<RegisterServiceGigler />} />
            }
          />
          <Route
            path="/admin/plan-details"
            element={
              <RouteInterceptor isAuthenticated={isAuthenticated} element={<ManagePlans />} />
            }
          />
          <Route
            path="/admin/edit-gigTalent"
            element={
              <RouteInterceptor isAuthenticated={isAuthenticated} element={<GTProfile />} />
            }
          />
        </Routes>
      )}
    </ThemeProvider>
  );
}
