import { Card } from '@mui/material'
import React from 'react'
import MDBox from './MDBox'
import MDButton from './MDButton'
import MDTypography from './MDTypography'

const EngagerTermsAndConditions = ({ showAccept, handleAccept, handleClose }) => {

  return (
    <Card>
      <MDBox px={3} pt={3} pb={2}>
        <MDTypography variant="h5" align="center" sx={{ textDecoration: "underline" }}>
          Engager Terms and Conditions
        </MDTypography>
        {/* <MDTypography variant="button" color="dark" >
          I authorize GIGLEJI to facilitate my engagement with prospective client organization as per the GRC norms as follows:
        </MDTypography> */}
      </MDBox>
      <MDBox px={5} pb={2} sx={{ height: "400px", overflow: "scroll",'&::-webkit-scrollbar': { WebkitAppearance: 'none' } }}>
        <MDTypography variant="button" color="dark">
          {/* <p class="p1"><span class="s1"><strong>TERMS AND CONDITIONS: GIG TALENT</strong></span></p> */}
         <p>Please read these Terms and Conditions (&ldquo;Terms&rdquo;), a legal agreement between <strong>GIGLEJI TEKNET PRIVATE LIMITED</strong>, a Private Limited Company incorporated under the laws of India, having its registered office at No. 1 BC-202, 2<sup>nd</sup> Main Road, Kasturinagar, East of NGEF Layout, Bangalore, Karnataka &ndash; 560043 (Hereinafter referred to as &ldquo;Gigleji&rdquo;, &ldquo;Company&rdquo;, &ldquo;We&rdquo;, &ldquo;Us&rdquo; including its affiliates, subsidiaries and agents) of the one part and &ldquo;<strong>Engager</strong>&rdquo; (defined below), hereinafter referred to as &ldquo;you&rdquo;, &ldquo;yours&rdquo; or similar, of the other part. The domain name <a href="http://www.gigleji.com/">www.gigleji.com</a> is owned by the Company.</p>
<p>The Terms have been drafted in compliance with the (i) the Indian Contract Act, 1872, (ii) the (Indian) Information Technology Act, 2000, and the rules, regulations, guidelines, and clarifications framed there under and any amendments to the same, including the (iii) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (the &ldquo;SPI Rules&rdquo;), and the (iv) Information Technology (Intermediaries Guidelines and Digital Media Ethics Code) Rules, 2021 (the &ldquo;IG Rules&rdquo;).</p>
<p>These Terms shall govern the use and access to the web-based or mobile-based applications provided in connection with the service and access to the website <a href="http://www.gigleji.com/">http://www.gigleji.com</a> (&ldquo;<strong>Website</strong>&rdquo;) and the &ldquo;Gigleji&rdquo; application based technology platform for Engager (&ldquo;<strong>Application</strong>&rdquo;) accessible through desktops, mobile phones, smart phones and tablets which includes the computer software, associated media, collaterals, and online or electronic documentation (the Website and Application hereinafter collectively referred to as the &ldquo;<strong>Gigleji Platform</strong>&rdquo; <strong>or &ldquo;Platform&rdquo;</strong>) which connects the Engager with the <strong>Gig Talent</strong> (defined below) registered on this Platform.</p>
<p>Please carefully go through these Terms and the Privacy Policy available at <a href="http://www.gigleji.com/">http://www.gigleji.com</a> (&ldquo;Privacy Policy&rdquo;) before you decide to access or use the Platform. These Terms and the Privacy Policy together constitute a legal agreement (&ldquo;Agreement&rdquo;) between You and Gigleji in connection with Your access and use of the Platform which is conditioned upon your agreement and compliance with all the terms, conditions and notices contained or referenced in this agreement (the &ldquo;Terms&rdquo;), as well as any other written agreement between us and you as applicable.</p>
<p>In some circumstances, a separate document that provides additional conditions may apply to a service offered via the Platform (&ldquo;Additional Terms&rdquo;). To the extent there is a conflict between these Terms and any Additional Terms, the Additional Terms will prevail unless the Additional Terms expressly state otherwise.</p>
<p>By clicking &ldquo;Sign In&rdquo;, &ldquo;Log in&rdquo; &amp; &ldquo;Sign Up&rdquo; or similar tabs at the time of registration, or by entering into an agreement with Gigleji or through the continued use or accessing the Platform, You accept the Terms and consent your approval to the provisions of the Terms as laid down herein below. You are also responsible for complying with your obligations under applicable laws for visiting, accessing, and using the Gigleji Platform. If you do not agree and do not wish to be bound by the Terms, you have no right to access or use the Gigleji Platform.</p>
<p>We reserve the right to modify, discontinue or terminate the Platform or Services (defined below) offered thereunder or to change these Terms at any time. You acknowledge and agree that it is your responsibility to review the Platform and these Terms from time to time and to familiarize yourself with any modifications. If we modify these Terms, we will post the modification on the Platform and/or Website. Your continued access or use of the Platform after we have posted a modification will constitute acknowledgement of the modified Terms and agreement to abide by and be bound by the modified Terms. We will periodically inform you about the changes made to the Terms and Privacy Policy on the Gigleji Platform, and in case of non-compliance with the Terms and Privacy Policy, we shall have the right to terminate the access or usage rights of the Engager to the Platform immediately or remove non-compliant information, as per the situation. Any such termination of access or usage rights is the subject to the sole discretion of the Company. If the modified Terms are not acceptable to you, you may cease using the Platform and the Services.</p>
<p> </p>
<ol>
<strong>1. </strong><strong>DEFINITIONS </strong>
</ol>
<p>(i)  <strong>&ldquo;Assessor&rdquo;</strong> means an individual who is a domain expert with great experience in their area of expertise. They assess the gig talent on technical and behavioural aspects and mentor the gig talent on a periodic basis to meet the industry expectations.</p>
<p>(ii)   <strong>&ldquo;Company Direct Contract&rdquo; </strong>means the contract model selected by the Engager where upon selection of the Gig Talent, the Engager and the Gig Talent will directly enter into a contract for fulfilling the obligations of the Engagement and the Engager will directly pay the Engagement Fee to the Gig Talent.</p>
<p>(iii) <strong>&ldquo;Effective Date&rdquo;</strong> means the Date on which You accept these Terms by clicking &lsquo;Sign Up,&rsquo; &lsquo;Sign In,&rsquo; &lsquo;Log In&rsquo; or &lsquo;I Accept&rsquo; or enter into an agreement with Gigleji.</p>
<p>(iv)  <strong>&ldquo;Engagement&rdquo;</strong> means contractual obligation between the Engager and the Gig Talent for provision of services by the Gig Talent to fulfil the business goals of the Engager.</p>
<p>(v)   <strong>&ldquo;Engagement Commencement Date&rdquo;</strong> means the date on which the Gig Talent commences performance of engagement services to an Engager.</p>
<p>(vi)  <strong>&ldquo;Engagement Fee&rdquo; </strong>means the fee that will be paid to the Gig Talent either directly by the Engager in the case of a Company Direct Contract or paid by the Platform to the Gig Talent in case of an Off-roll Contract.</p>
<p>(vii)  <strong>&ldquo;Engager&rdquo;</strong> means a Registered User who is seeking to utilize the services provided by the Platform to hire or engage a Gig Talent.</p>
<p>(viii) <strong>&ldquo;Gig Talent&rdquo;</strong> means a Registered User who is a skilled individual seeking for full time / part time engagements by using the Services of the Platform;</p>
<p>(ix)  <strong>&ldquo;Hiring Fee&rdquo;</strong> means the fee (inclusive of goods and service tax, if applicable), as charged by Gigleji to the Engager for successful placement of a Gig Talent pursuant to the terms.</p>
<p>(x)   <strong>&ldquo;Off-roll Contract&rdquo; </strong>means the contract model selected by the Engager where upon selection of the Gig Talent, the Gigleji Platform will manage the payment of the Engagement Fee to the Gig Talent.</p>
<p>(xi)  <strong>&ldquo;Platform Content&rdquo;</strong> means all material, content and information made available on the Platform including but not limited to written text, graphics, images, logos, trademarks, audio/video material, or any other forms of expression;</p>
<p>(xii)  <strong>&ldquo;Registered User&rdquo;</strong> includes an Engager, Gig Talent, Assessors or any third party who signs up and registers by creating an Account on the Gigleji Platform for availing the Services offered by the Platform.  </p>
<p>(xiii) <strong>&ldquo;Registered User Information&rdquo;</strong> refers to any information, document, details mentioned, uploaded, or shared on the Gigleji Platform by the Registered User which includes information such as information pertaining to the Engager such as the Company name, office address, number of employees, GSTIN and PAN details, details about the skills, working status, engagement model, education qualifications, work experience, and other details of the Engagers, Assessors etc.</p>
<p>(xiv) <strong>&ldquo;Services&rdquo;</strong> refers to the service of functioning as an online facilitator on a digital platform that seeks to connect Engagers with Gig Talent to achieve their business goals as per their industry and domain requirement, but not limited to the Services stipulated under Clause 2B of these Terms and such other services as may be provided by the Registered Users.</p>
<p>(xv)  All other capitalized terms shall have the meaning ascribed to them in the Other Terms.</p>
<ol>
<strong>2.    </strong><strong>YOUR USE OF THE SERVICES AVAILABLE ON THE GIGLEJI PLATFORM</strong><ol>
<strong>A.  </strong><strong>Registration as an Engager </strong>
</ol>
</ol>
<p>(i)   In order to access, browse, view, and use the Services available on the Platform, you will need to create an account (&ldquo;Account&rdquo;) and register to avail the Services on the Platform as an Engager.</p>
<p>(ii)   You can register on the Platform by providing detail of the organization such as the name, address, the industry you belong to, first name and last name, e-mail address and designation of the Single Point of Contact (SPOC), phone number and password.</p>
<p>(iii)  To create an Account and register, you represent, warrant and covenant that you will provide accurate and complete registration information (including, but not limited to your entities name, first name and last name of the Single Point of Contact, e-mail address, phone number, address and a username and password you will use to access the Platform &ndash; &ldquo;Registered User Information&rdquo;) and to keep your registration information accurate and up to date. Failure to do so shall constitute a breach of the Terms, which may result in immediate termination of your Account.</p>
<p>(iv)   If you register on the Website/Application through your email address, then You will be sent a link or a code on the email address and phone number provided by you to verify and activate the Account, which is a part of the 2-step authentication process. Upon completion of the 2-step authentication process, you will be given access to the Website/Application.</p>
<p>(v)   By registering as an Engager on the Platform, you represent and warrant that:</p>
<p>a)   The Registered User Information provided by you to register for the Services available on the Platform is accurate, authentic, and up to date. Failure to do so shall constitute a breach of the Terms, which may result in immediate detention/cancellation of the Account and inability to use the Website and/or Application.</p>
<p>b)   All the information provided in connection with your financial details such as credit or debit card information, bank account details are correct, verified, and true.</p>
<p>(vi)   You are responsible for safeguarding your password or other personal/financial information. You agree that you will not disclose your password/financial information to any third party and that you will take sole responsibility for any activities or actions that happen through your Account or on the Website and/or Application, whether or not you have authorized such activities or actions. You will immediately notify Gigleji of any unauthorized use of your Account or any unauthorized use of your personal/financial information.</p>
<p>(vii)  You represent that you shall not use the Website and/or Application in violation of any applicable laws or any other code of conduct.</p>
<ol>
<strong>B.  </strong><strong>Services offered to Engager</strong>
</ol>
<p>(i)   <strong>Setting up an Account</strong>: The Engager shall have to create an Account and complete the registration process to access and use the Website and/or Application. The Engager will login or Sign In through their email ID and password to access and use the Platform and its Services. The Engager should not share their account details and password with any third party and should ensure that their account details and password is maintained in confidence. In case of any actual or suspected breach or improper use or disclosure of account details or password, the Engager should immediately notify the Company.</p>
<p>(ii)   <strong>Creating a Profile</strong>: After setting up the Account, the Engager must create a profile by stating basic information such as name, organization&rsquo;s name, type of enterprise, designation, department, industry, number of employees, location, GSTIN, phone number, email address. There are three subscription plans such as basic, classic, and premium plans and the Engager will have an option to choose the suitable subscription plan based on their requirements.</p>
<p>(iii)  <strong>Creating Sub-Users</strong>: The Engager can also create Sub-Users who can access, browse, and use the Engager&rsquo;s Account and other Services provided on the Platform. While creating Sub-Users, the Engager in addition to mentioning the organization details should also provide personal information of the Sub-User such as their first name, last name, department, designation, email address, phone number and password. The Engager can also access the list of Sub-Users in their Account.</p>
<p>(iv)   <strong>Engagement Posting by Engager</strong>: Upon successful completion of registration and creating a profile on the Platform, the Engager will have access to post Engagements on the Platform depending upon the subscription plan chosen by the Engager. The Engager has to provide few details such as details of the organization, description about the Engagement such as title, model of engagement, preferred mode of Engagement such as on-site/  Work From Home / hybrid / need based, duration of the Engagement, mode of billing, number of positions available, location, indicative budget, industry etc. The Engager can also view and manage all the current Engagements on their Account.</p>
<p>(v)   <strong>Automatic Skill Matching</strong>: Once the Engagement post is published on the Platform, the system will match your requirements with the available Gig Talents and details along with descriptions regarding the Engagement, and link to apply for the  engagement will be sent to the Gig Talent via automatic email alerts based on their skill sets.</p>
<p>(vi)   <strong>Manual Profile Search</strong>: In addition to automatic skill matching, an alternative option to manually search for suitable Gig Talent is also available to the Engager. The Engager can use suitable filters available on the Platform based on their requirements and search for matching Gig Talents.</p>
<p>(vii)  <strong>Talent Selection</strong>: The Engager shall have the option to shortlist the Gig Talents based on their specific requirements through the applied list of Gig Talents or those selected through manual profile search. For ease of shortlisting suitable Gig Talents, the Platform also allows the Engager to export the candidate details to CSV for Applicant Tracking System (&ldquo;ATS&rdquo;) integration.</p>
<p>(viii) <strong>Contract Management</strong>: If the Engager opts for Company Direct Contract, then the Engager will directly pay the Hiring Fee to the Platform and manage the contracts along with payment of Engagement Fee to the Gig Talent. It is a contractual relationship formed directly between the Engager and the Gig Talent and the Company is not responsible for and is not a party to any such Contract directly formed between the Engager and the Gig Talent. Under no circumstances will any such contract create an employment or any service relationship between Gigleji and any Gig Talent. If the Engager opts for Off-roll Contract, then the Gigleji Platform will manage the contracts and the payment of Engagement Fee to the Gig Talent. The Gigleji Platform will pay the Engagement Fee to the Gig Talent only upon receiving the payment for the same from the Engager. The Platform allows the Engager to view and manage all the Contracts signed by them in the form of a list containing details regarding the name of the Gig Talent, contract status, and complete details regarding the contract signed between the parties.</p>
<p>(ix)   <strong>Feedback</strong>: The Engager will have the option to provide feedback to Gig Talents. The Engager agrees and acknowledges that the Gig Talent shall also have the option to provide feedback with respect to the Engager which will be accessible to the Admin of the Platform. You agree and acknowledge that Gigleji is entitled to unrestricted use of any and all comments, suggestions, ideas, concepts, provided through the feedback option on the Platform. We may use the said feedback for any and all purposes whatsoever, commercial, or otherwise, without any obligation to you or any other person involved with the Feedback.</p>
<ol>
<strong>C.  </strong><strong>Relationships with Gig Talents </strong>
</ol>
<p>The Engager acknowledges and agrees that the Company is only providing a Platform to connect Engagers and Gig Talents with each other and that the Company is not directly providing any employment nor is acting as a joint employer for any reason. Thus, the Company is not responsible or liable for the actions or inactions of any Gig Talent with respect to activities related to the Engagement. The Engagers and Gig Talents are solely responsible for any contracts that they form directly with each other or through the Gig Platform. You acknowledge and agree that it is your responsibility to fulfil your contractual obligations as an Engager by complying with the applicable laws, rules, and regulations, and by timely paying the Gig Talent for the work submitted/delivered as per the timeline mentioned in the Company Direct Contract or pay Gigleji as per the terms of the Off-roll Contract.</p>
<ol>
<strong>D.  </strong><strong>Relationships with Company</strong>
</ol>
<p>Gig Talent and You are responsible for any contracts or agreements that you both may form directly with each other or through the Gigleji Platform. The terms of such Engagement contract should not conflict with these Terms and shall not expand Gigleji&rsquo;s obligations or restrict Gigleji&rsquo;s rights hereunder. Gigleji is not a party to any Engagement contract between the Engager and the Gig Talent. Gigleji merely provides a digital platform and acts as an online facilitator who connects Engagers with suitable Gig Talents and provides a Platform for interacting with each other and thus has no responsibility and/or liability in respect of the transactions being conducted on the Platform including any interactions amongst and between all the Registered Users. You acknowledge and agree that it is your responsibility to fulfil all of your contractual obligations as an Engager with the Company if you opt for an Off-roll Contract. You also accept and acknowledge that posting about an Engagement on the Platform does not provide any guarantee or assurance regarding the fulfilment of these Engagements from the Company.</p>
<ol>
<strong>E.  </strong><strong>Payment Terms</strong>
</ol>
<p>(i)   The Company will charge a standard Annual Subscription Fee to the Engager for using the Services available on the Platform. The Company will also charge for posting about Engagements on the Platform which will vary based on the subscription plan chosen by the Engager while creating a Profile on the Platform and a Hiring Fee for every successful appointment. All of the above-mentioned fees except for the Hiring Fee shall be payable by You in advance to the Company.</p>
<p>(ii)   If You opt for a Company Direct Contract, then upon extending an offer to the Gig Talent, You shall pay the Hiring Fee directly to the Company for all such successful appointments. Thus, the Company will invoice You the applicable Hiring Fee, and You shall pay such invoice within the period stipulated in the agreement between the Engager and the Company.You will be responsible for paying the Engagement Fee to the Gig Talent directly as per the Agreement signed by both You and the Gig Talent with respect to the Engagement. In case You opt for Company Direct Contract, then the Company will not be liable or responsible to collect or pay the Engagement Fee on behalf of You to the Gig Talent.</p>
<p>(iii)  If the Engager opts for Off-roll Contract, then upon extending an offer to the Gig Talent, the Company is entitled to charge You a consolidated amount comprising the Hiring Fee and the Engagement Fee. Upon receiving the consolidated amount from You, the Company will deduct the Hiring Fee and transfer the Engagement Fee to the respective Gig Talent. The Company will transfer the Engagement Fee to the Gig Talent only upon receiving the consolidated amount from You and the Company will not be liable or responsible to pay the Engagement Fee to the Gig Talent without receiving the same from You.</p>
<p>(iv)   The Engager acknowledges and agrees that they are required to: (a) complete all tax registration obligations and calculate and remit all tax liabilities related to the provision of services as required by applicable law; and (b) provide the Company with all relevant tax information.</p>
<ol>
<strong>F.   </strong><strong>Dispute between Registered Users </strong>
</ol>
<p>(i)   You are solely responsible for your interaction with other Registered Users on the Platform. We reserve the right, but have no obligation, to monitor disputes between you and other Registered Users. Gigleji will have no liability for your interactions with other Registered Users, or for any Registered User&rsquo;s action or inaction.</p>
<p>(ii)   The provisions regarding disputes between Registered Users amongst each other in connection with offering or receiving Services on the Platform, including payment terms and performance of any Service, and any other terms, conditions, warranties, or representations associated with such transactions or dealings, are solely between the Registered Users. You as a Registered User should take reasonable precautions and make whatever investigation or inquiries you deem necessary or appropriate before seeking Services or entering into any transaction with any third party, including without limitation, Gig Talent or Assessor as the case may be. You understand that the decision to offer any Engagement to a particular Gig Talent is your personal decision for which you alone are responsible. You understand that Gigleji does not warrant and cannot make representations as to the suitability of any Registered User that you may decide to interact with on or through the Website and/or Application and the accuracy of the information they post on the Website or Application. While Gigleji may attempt to seek information about the background of a Registered User through back ground verifications, you understand that any background verification undertaken by Gigleji may not be exhaustive. Accordingly, at the end of the day, Registered User should take an informed decision on his/her own accord and keep in mind the fact that Gigleji only seeks to provide a digital platform wherein Registered Users have an opportunity to interact with each other.</p>
<p>(iii)  Notwithstanding the foregoing, You agree that since Gigleji only seeks to provide a platform wherein the Registered Users can be brought together and Gigleji itself has no role in the execution or provision of services by the Registered Users themselves, Gigleji shall not be responsible or liable for any loss or damage of any sort whatsoever incurred as the result of any such transaction or dealings.</p>
<p>(iv)   If there is a dispute between the Registered Users, you acknowledge and agree that Gigleji is under no obligation to become involved. However, assistance in the dispute resolution process may be offered by Gigleji, which shall be solely at its own discretion.</p>
<p>(v)   In the event that a dispute arises between you and one or more Registered Users, you hereby release Gigleji, its officers, affiliates, managers, members, directors, attorneys, agents and successors in rights from any claims, demands, and damages (actual and consequential) of every kind or nature, known or unknown, suspected or unsuspected, foreseeable or unforeseeable, disclosed or undisclosed, arising out of or in any way related to such disputes and/or the Platform or any other Registered User thereunder.</p>
<ol>
<strong>3.    </strong><strong>YOUR CONDUCT IN USING THE PLATFORM </strong>
</ol>
<p>Your use of the Platform shall be subject to the following restrictions:</p>
<p>(i)   You will not delete or modify any content on the Platform including but not limited to, legal notices, disclaimers, or proprietary notices such as copyright or trademark symbols, logos.</p>
<p>(ii)   You will not decompile, reverse engineer, or disassemble the Website and/or Application, or remove any copyright, trademark registration, or other proprietary notices from the Website and/or Application. You further agree not to access or use Website and/or Application in any manner that may be harmful to the operation of the Website and/or Application or its content.</p>
<p>(iii)  You will not use Website and/or Application and/or Services in any way that is unlawful, or harms Gigleji or any other person or entity.</p>
<p>(iv)   You will immediately notify Us of any breach or suspected breach of the security of the Platform of which You become aware, or any unauthorized use or disclosure of information within or obtained from the Platform, and You will take such action to mitigate the breach or suspected breach as We may direct and will cooperate with Us in investigating and mitigating such breach.</p>
<p>(v)   You will not engage in any form of antisocial, disrupting, or destructive acts, including &ldquo;flaming&rdquo;, &ldquo;spamming&rdquo;, &ldquo;flooding&rdquo;, &ldquo;trolling&rdquo;, &ldquo;phishing&rdquo; and &ldquo;griefing&rdquo;  as those terms are commonly understood and used on the Internet.</p>
<p>(vi)   You will not host, display, upload, modify, publish, transmit, update or share any information that belongs to another person and to which You do not have any right to; is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another&rsquo;s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatsoever; harm minors in any way; infringes any patent, trademark, copyright or other proprietary right(s), violates any law for the time being in force; deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature; impersonate another person; contains viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.</p>
<p>(vii)  You will not forge headers or otherwise manipulate identifiers to disguise the origin of any message or transmittal you send to us or Gig Talent on or through the Platform or when availing any Service offered on or through the Platform. You may not pretend that you are, or that you represent, someone else, or impersonate any other individual or entity.</p>
<p>(viii)        Gigleji does not support and will not tolerate its Services being used to discriminate against others, including when based on age, ancestry, colour, creed, disability, gender identity, marital status, national origin, parental status, political ideology, race, religion, sex, sexual orientation, military status or veteran, or any other status protected by law. You are not permitted to use the Services in a manner which would incite, promote, or support such discrimination and you must not use the Services to incite or promote hostility or violence. If we believe in our sole determination that your use of the Services is being used to discriminate, we may permanently or temporarily terminate or suspend your access to the Services without notice and liability for any reason.</p>
<p>(ix)   You may not have more than one active account at any time without the written consent of Gigleji in any circumstances.</p>
<p>(x)   You further expressly agree not to circumvent our Services by independently attempting to communicate and engage a Gig Talent introduced to you via Gigleji Platform through any alternative means at least for a period of one year from the date of first identifying or meeting the Gig Talent on the Platform.</p>
<p>(xi)   You agree not to use the Platform in any other way that violates (i) these Terms, (ii) applicable laws, or (iii) the rights of any third party.</p>
<ol>
<strong>4.    </strong><strong>GRANT OF RIGHTS </strong>
</ol>
<p>(i)  <strong>Platform and Platform Content:</strong> The Platform is owned and operated by Gigleji. Gigleji owns all rights, title, and interest, including all intellectual property rights such as copyright, trademarks, and other proprietary rights in and to the Platform, software therein, the present or future modifications / upgradations thereof and standard enhancements thereto.</p>
<p>Content on the Platform is solely for your use as per the Terms of this agreement. All content posted on the Platform except the Registered User Information is wholly owned by Gigleji or licensed by it (&ldquo;Gigleji Content&rdquo;). You agree not to reproduce, modify, sell, distribute, mirror, frame, republish, download, transmit, or create derivative works of the Platform and/or Gigleji Content, in whole or in part, by any means. You must not remove or modify any copyright or trademark notice, or other notice of ownership. Modification of the material on the Platform, use of such material on any other Platform or networked computer environment, or use of the materials for any purpose other than those stipulated in these terms is a violation of our intellectual property rights and is prohibited.</p>
<p>(ii)   <strong>Limited License to use the Platform and Gigleji Content</strong>: We grant you a non-exclusive, personal, non-transferable, revocable, and limited right to have access to and make use of the Website and/or Application and Services offered therein only for the duration you are registered as an Engager and in accordance with the Terms contained therein. You are, however, not permitted to reproduce, duplicate, copy, sell or otherwise exploit the Platform or any product image, product listing, product description, price, page layout, page design, trade dress, trademark, logo, or other Gigleji Content for any commercial purpose, except as expressly provided. These Terms do not and shall not transfer any ownership or proprietary interest in the Website and/or Application or Gigleji Content to You, except as may be otherwise expressly provided herein or as may be agreed to by and between the Parties. You also acknowledge that the Registered User Information provided on the Website and/or Application is proprietary to and owned by the Registered User and such Registered User Information of the Gig Talent shall be provided to you under license.</p>
<p>(iii)  <strong>Submission and Grant of License:</strong> Gigleji does not claim ownership of materials you provide, post, upload, input, or submit as part of the Services related to Engagements (&ldquo;Submission&rdquo;). By posting your Submission using the Services, you expressly grant Gigleji a multi-use, sub-licensable, transferable, perpetual, irrevocable, non-exclusive, royalty-free, and worldwide license to use, reproduce, publish, translate, distribute and publicly display your Submission and your name, and/or likeness as contained in your Submission, in whole or in part, and in any form, media, or technology, whether now known or hereafter developed, for use in connection with the Services and Gigleji&rsquo;s (and its successors&rsquo; and affiliates&rsquo;) business. You accept and acknowledge that, you will grant the below mentioned rights to us when you post your Submission on or through our Services, you represent and warrant the following:</p>
<p>(a)   Your Submission and Gigleji&rsquo;s use of the same as contemplated by these Terms and the Services do not violate any relevant law or infringe any rights of a third party, privacy, and publicity rights;</p>
<p>(b)   You have the rights necessary to grant the license to any Submission you post;</p>
<p>(c)   Subject to the license you grant us, Gigleji may use your Submission and publish the same on the Platform;</p>
<p>(d)   Your Submissions are not obscene, defamatory, abusive, racially, or ethnically offensive, infringing, invasive of rights, unlawful libellous, threatening, violent, hostile, discriminatory or otherwise objectionable;</p>
<p>(e)   Your Submission won&rsquo;t create a risk of harm, loss, physical or mental injury, emotional distress, or property damage to you or to any person;</p>
<p>(f)  Your Submissions are truthful and accurate;</p>
<p>(g)   If your Submission contain images, photographs, pictures, logo, video, audio or otherwise graphical in whole or in part, you have the necessary rights to use such materials; and intellectual property rights, or have been granted the necessary rights to use such materials; and</p>
<p>(h)   The Submission does not violate any laws, violate any contracts that you are a party to, violate any fiduciary relationship you have, or these Terms, in any manner.</p>
<p>(iv)  <strong>Registered User Information of the Engager</strong>: It may be defined to mean any materials you upload, post, or otherwise transmit to this Platform including but not limited to digital images, photos, logo, videos, text, data, graphics, or any combination thereof. Engagers must honour the intellectual property rights of others. Engagers shall not upload, post, or distribute any Registered User Content in which they do not own copyright. Engagers shall not misuse, copy, replicate or infringe any intellectual property rights of other Registered Users such as Gig Talents, Assessors, Admins, other Engagers who upload certain job description, mention specific skill set that they possess, or upload few works as sample on the Platform. If you are found copying or replicating or imitating any information that is posted by Registered Users, then the Company shall take serious actions against you.</p>
<ol>
<strong>5.    </strong><strong>ROLE OF THE COMPANY </strong>
</ol>
<p>(i)   Company is a digital platform acting merely as an online facilitator or a marketplace for connecting prospective entities (Engagers) searching for talent in the gig community with skilled gig workers (Gig Talent) who is well equipped with the required skill set to fulfil the work sought by the Engager.  </p>
<p>(ii)   Company does not direct, has no control over, makes no representations and does not guarantee the credibility of the Registered User Information of the Engagers and the Gig Talents or the ability of the Engagers and the Gig Talents to enter into a contract or any transactions or successfully fulfil the contract/transaction.</p>
<p>(iii)  Engagers, Gig Talents, and Assessors are independent parties. Company is not responsible for and disclaims any and all liability related to the actions of Engagers, Gig Talents, and Assessors.</p>
<p>(iv)   Company&rsquo;s role is that of an &lsquo;intermediary&rsquo; as defined under the Information Technology Act, 2000, as amended from time to time and the rules thereunder. Being an intermediary, the Company is merely providing a platform as an online facilitator to Engagers and Gig Talents to connect and interact with each other and thus has no responsibility and/or liability in respect of the transactions being conducted amongst them through the Website and/or Application or outside it.</p>
<p>(v)   If Company suspects any illegal, wrongful, or fraudulent activity on the Platform by the Registered User, notwithstanding any other rights Company may have, Company reserves the right to inform the relevant government or law enforcement authorities. Company will cooperate in all investigative procedures or requests for access/information initiated by any such government or law enforcement authorities.</p>
<ol>
<strong>6.    </strong><strong>DISCLAIMER AND EXCLUSION OF WARRANTIES</strong>
</ol>
<p>(i)   You hereby acknowledge and agree that Gigleji is only offering a Platform where it is acting as an online facilitator to suggest and connect Engagers with Gig Talent on the Platform and it is not a party to any oral or written agreement for service or any contract entered between Engager and Gig Talent in connection with any Services that is sought from the Gig Talent, directly or indirectly, through the Platform.</p>
<p>(ii)   Notwithstanding the foregoing, you agree that since the Company only seeks to provide an online Platform wherein the Engager and the Gig Talents are brought together and the Company itself has no role in the execution, completion and delivery of the work/service by the Gig Talent, the Company shall not be responsible or liable for any loss or damage resulting from any such engagement between the Engagers and the Gig Talent. If there is a dispute between and amongst the Engager and the Gig Talent, you acknowledge and agree that the Company is under no obligation to become involved. In the event that a dispute arises between You and the Gig Talent, or any third party, you hereby release the Company, its officers, affiliates, managers, directors, attorneys, agents, and successors in right from any claims, demands, and damages (actual and consequential) of every kind or nature, known or unknown, suspected or unsuspected, foreseeable or unforeseeable, disclosed or undisclosed, arising out of or in any way related to such disputes and/or any Services provided thereunder.</p>
<p>(iii)  You agree that your use of the Website or Application shall be at your own risk. To the fullest extent permitted by law, the Company and its officers, affiliates, managers, directors, successors, and agents disclaim all warranties, express, implied, statutory or otherwise, or any guarantees in connection with this Website, the Application, the quality, suitability, accuracy or completeness of the Platform, any information or material contained or presented on this Platform by the Gig Talent, the services offered on or through this Platform, or any data, service materials offered by other third parties through any links or other sources through the Platform.</p>
<p>(iv)   Unless otherwise explicitly stated, to the maximum extent permitted by applicable law, this Platform, the Services offered on or through this Platform, data, materials, and any information or material contained or presented on this Platform is provided to you on an &ldquo;as is&rdquo; and &ldquo;as available&rdquo; basis with no implied warranty of merchantability, fitness for a particular purpose, or non-infringement or third-party rights. Company does not provide any warranties against errors, mistakes, or inaccuracies of data, content, information, materials, substance of the Platform or any unauthorized access to or use of our secure servers and/or any and all personal information and/or financial information stored therein, any bugs, viruses, trojan horses, or the like which may be transmitted to or through the Platform by any third party, any interruption or cessation of transmission to or from the Platform, any defamatory, offensive, or illegal conduct of any third party, or Gig Talents, or other Engagers, or any loss or damage of any kind incurred as a result of the use of any data, content, information, materials, substance of the Website or Application or the Engager&rsquo;s or the Gig Talent&rsquo;s information uploaded, shared, posted, transmitted, or otherwise made available via the Platform.</p>
<p>(v)   The Company, affiliates, directors, officers, agents, contributors, third party content providers or licensors do not make any express or implied representation or warranty or endorsements of any kind about the Platform Content or Platform.</p>
<p>(vi)   The Company, affiliates, directors, managers, members, officers, agents, contributors, do not make any representations or warranties that the information (including any instructions) on the services are accurate, truthful, complete, correct, adequate, useful, timely, reliable, or otherwise.</p>
<p>(vii)  The Company does not endorse, warrant, guarantee, or assume responsibility for any service advertised or offered by a third party through the Website or Application or any hyperlinked site or featured in any banner or other advertisement. Company will not be a party to or in any way be responsible for monitoring any transaction between you and any other party, as with the use of any service, and the publishing or posting or sharing of any material/document/information through any medium or in any environment, you should use your best judgement and exercise caution where appropriate.</p>
<p>(viii)        YOU ARE SOLELY RESPONSIBLE FOR ANY AND ALL ACTS OR OMISSIONS TAKEN OR MADE IN RELIANCE ON THE WEBSITE OR APPLICATION OR THE INFORMATION IN THE PLATFORM, INCLUDING INACCURATE OR INCOMPLETE INFORMATION. IT IS EXPRESSLY AGREED THAT IN NO EVENT SHALL THE COMPANY BE LIABLE FOR ANY SPECIAL, INDIRECT, CONSEQUENTIAL, REMOTE OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, LOSS OF PROFITS OR REVENUES, LOSS OF USE, OR LOSS OF INFORMATION OR DATA, WHETHER A CLAIM FOR ANY SUCH LIABILITY DAMAGES IS PREMISED UPON BREACH OF CONTRACT, BREACH OF WARRANTY, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER THEORY OF LIABILITY, EVEN IF THE COMPANY HAS BEEN APPRISED OF THE POSSIBILITY OR LIKELIHOOD OF SUCH DAMAGES, RESULTING FROM (I) THE USE OR THE INABILITY TO USE THE SERVICES/ PLATFORM; (II) ANY DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO, THROUGH OR FROM THE SERVICE; (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRASMISSIONS OR DATA NON-DELIVERY, LOSS, THEFT, MISDELIVERY, CORRUPTION, DESTRUCTION OR OTHER MODIFICATION; (IV) STATEMENTS OF CONDUCT OF ANYONE ON THE SERVICE; (V) MALICIOUS OR CRIMINAL BEHAVIOUR, OR FALSE OR FRAUDULENT TRANSACTIONS OR (VI) NETWORK, TECHNICAL OR OTHER OPERATIONAL LAPSES ON THE PLATFORM OR (VII) INTERRUPTION OF ENGAGEMENT BETWEEN THE ENGAGER AND THE GIG TALENT (VIII) ACCESS DELAYS OR ACCESS INTERRUPTION TO THE WEBSITE OR APPLICATION (IX) ANY OTHER MATTER RELATING TO THE SERVICES OFFERED ON THE PLATFORM. WE DISCLAIM ANY AND ALL LIABILITY FOR ERRONEOUS TRANSMISSIONS AND LOSS OF SERVICE RESULTING FROM COMMUNICATION FAILURES BY TELECOMMUNICATION SERVICE PROVIDERS. INTERNET SERVICE PROVIDERS OR THE SYSTEM.</p>
<p>(ix)   We do not warrant that your use of the Platform and the Services under these Terms will not violate any law or regulations applicable to you.</p>
<ol>
<strong>7.    </strong><strong>LIMITATION OF LIABILITY</strong>
</ol>
<p>(i)   You expressly understand and agree that we shall not be liable for any direct, indirect, incidental, punitive or consequential damages, including without limitation negligence, damages for loss of revenue, profits, use, or data, resulting from the access or use or inability to use our services and/or content on the Website, Application and/or the Services, including without limitation, use of or reliance on information, interruptions, errors, defects, mistakes, omissions, deletions of files, delays in operation or transmission, non-delivery of information, disclosure of communications, or any other failure of performance. In any event, our overall and aggregate liability arising out of these Terms or use of the Website, Application and/or the Services shall not exceed the total value of the dispute or INR 1,000, whichever is lower.</p>
<p>(ii)   You understand and agree that we shall not be liable to you in contract, tort, or equity in relation to any direct, indirect, or consequential loss that you might incur in relation to the contents or use of or reliance on Platform Content or otherwise in connection with the Platform.</p>
<p>(iii)  To the full extent permitted by law, Gigleji will not be responsible and will be excluded from all liability, for any loss or damage whatsoever that you or another person may suffer in connection with the Engagement between the Gig Talent and you.</p>
<p>(iv)   You accept and acknowledge that all acts of the Sub-Users are deemed to be and construed as the act of the Engager who created the Account as the SPOC on the Platform. The Engager will be solely responsible and liable for all acts of the Sub-Users or any other person from your entity who gains access to your account using your credentials, including for any violation of these Terms.</p>
<p>(v)   You acknowledge and agree that some of the Platform Content may be provided by way of blogs or comments made by other Registered Users of the Platform, and Gigleji does not accept any responsibility or make any representation for the accuracy of such information or your reliance on the same.</p>
<p>(vi)   By using this Platform, you agree and accept that the limitations of liability provided in this Clause are reasonable.</p>
<p> </p>
<ol>
<strong>8.    </strong><strong>INDEMNIFICATION</strong>
</ol>
<p>(i)   You agree to defend, indemnify and hold harmless the Company, and its officers, affiliates, managers, members, directors, successors, assigns, subsidiaries, service providers, and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, charge and expenses (including but not limited to attorney's fees) arising from your use of or access to, and participation in the Platform; or arising out of or related to an Engagement between you and the Gig Talent, your violation of any provision of the Terms, including the privacy policy; your violation of any third party right, including without limitation any copyright, trademark, proprietary, intellectual property, or privacy right. Claims also include actions arising on account of false, inaccurate, incomplete information or suppression of any material information, incorrect or misleading statements, defamation, disparagement, breach of contract, misuse of confidential information, breach of trust, breach of privacy, obscenity or any other cause or for violation of any employment-related laws, rules, regulations or orders or notifications which may be brought on account of the information, content, data shared, published, uploaded or displayed by you.</p>
<p>(ii)   By using this Platform, you agree and accept that the provisions related to indemnity provided in this Clause are reasonable.</p>
<ol>
<strong>9.    </strong><strong>THIRD PARTY LINKS</strong>
</ol>
<p>The Website, Application and Services may contain links to third-party websites or resources. You acknowledge and agree that Gigleji is not responsible or liable for: (i) the availability or accuracy of such websites or resources; or (ii) the content, products, or services on or available from such websites or resources; or (iii) privacy practices of such websites or resources. Links to such websites or resources do not imply any endorsement by Gigleji of such websites or resources or the content, products, or services available from such websites or resources. You acknowledge sole responsibility for and assume all risk arising from your use of any such websites or resources or the content, products, or services on or available from such websites or resources. We recommend that you review their terms of service and privacy policies before accessing and using the Third-Party websites through these links.</p>
<ol>
<strong>10.   </strong><strong>TERMINATION / SUSPENSION</strong>
</ol>
<p>(i)   Gigleji may at any time, terminate its legal agreement with you if you have breached any provision of the Terms, or there are suspected illegal, fraudulent, or abusive activity or loss of confidence (or have acted in manner which clearly shows that you do not intend to, or are unable to comply with the provisions of the Terms.)</p>
<p>(ii)   If you wish to cease use of the Website and/or Application, you may deactivate your Account from the Platform and delete the Application.</p>
<p>(iii)  Notwithstanding anything to the contrary in these Terms, We have the right to terminate, suspend, or amend your use of Website, Application or Services offered therein without liability: (a) to comply with any order issued or proposed to be issued by any governmental agency; (b) to comply with any provision of law; or (c) if performance of any provision of these Terms by either Party would cause it to be in violation of law.</p>
<p>(iv)   Upon termination of your Account by Gigleji or deactivation of the Account by you, your right to use the Website, Application or Services offered therein shall automatically terminate. Any such termination by you shall not cancel your duties to fulfil the contractual obligations such as managing the payment or clearing any payment due to the Gig Talent and fulfilling all the contractual obligations with Gigleji.</p>
<p>(v)   You acknowledge and agree that your right to use Website, Application or Services offered herein is conditional upon your adherence to the Terms, and the continuous activation of your Account.</p>
<p>(vi)   Upon Termination, Gigleji shall retain Registered User Information for a period of one hundred and eighty days (180 days) after any cancellation or withdrawal of the registration. Gigleji may use the non-personal information provided by You for internal analysis and archival purposes.</p>
<p>(vii)  All provisions of these Terms which by their nature should survive termination will survive termination, including, without limitation, ownership provisions, provisions related to intellectual property rights, warranty disclaimers, indemnity, and limitations of liability.</p>
<ol>
<strong>11.   </strong><strong>GOVERNING LAW AND JURISDICTION </strong>
</ol>
<p>The Agreement shall be governed by and construed in accordance with the laws of India, without giving effect to the principles of conflict of laws thereunder. The courts at Bangalore, India shall have exclusive jurisdiction, subject to Clause 12 of the Terms.</p>
<ol>
<strong>12.   </strong><strong>ARBITRATION </strong>
</ol>
<p>(i)  Any dispute, claim or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation, or validity thereof, including the determination of the scope or applicability of these Terms to arbitrate, or to Your use of Website, Application or Services offered therein or the information to which it gives access, shall be determined by Arbitration in India before an independent arbitrator who shall be appointed by the Company. The arbitration shall be in accordance with the Arbitration and Conciliation Act, 1996 along with all amendments.</p>
<p>(ii)   The venue and seat of arbitration shall be Bangalore, India. All proceedings of such arbitration, including, without limitation, any awards, shall be in the English language.</p>
<p>(iii)  The governing law of the Agreement shall be the substantive law of India. The award shall be final and binding on the Parties.</p>
<p>(iv)  This provision survives termination of your Account or relationship with Gigleji, bankruptcy, assignment, or transfer. If a portion of this arbitration provision is deemed unenforceable, the remaining portions of this arbitration provision will remain in full force and effect.</p>
<p>(v)   Subject to the aforesaid, the Courts at Bangalore shall have exclusive jurisdiction over any proceedings arising in respect of the Agreement.</p>
<ol>
<strong>13.   </strong><strong>GRIEVANCE REDRESSAL MECHANISM</strong>
</ol>
<p>In accordance with the (i) Information Technology Act, 2000, the (ii) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rule, 2011, and the (iii) Information Technology (Intermediaries Guidelines and Digital Media Ethics Code) Rules, 2021, the name and contact details of the <a href="http://pages.ebay.in/securitycentre/law_enforcement.html">Grievance Officer</a> and Nodal Person who can be contacted for any complaints or concerns pertaining to the Platform, including those pertaining to breach of the Terms or any other policies are published as under:</p>
<p><strong> i.        </strong><strong>Details of the Grievance Officer</strong></p>
<p>Name: Manoj Mahapatra</p>
<p>Designation: Chief Operating Officer</p>
<p>Phone: +91-9342524121</p>
<p>Email Id: manoj.m@gigleji.com</p>
<p>Time: Monday &ndash; Friday (10:00 - 18:00)</p>
<p><strong> </strong></p>
<ol>
<strong>ii. </strong><strong>Details of the Nodal Person </strong>
</ol>
<p>Name: Kajinder Srivastava </p>
<p>Designation: Head, Business Development</p>
<p>Address: No. 1 BC-202, 2<sup>nd</sup> Main Road, Kasturinagar, East of NGEF Layout, Bangalore, 560043</p>
<p>Phone: +91-8073118164</p>
<p>Email Id: Kajinder.Srivastava@gigleji.com</p>
<p><strong> </strong></p>
<ol>
<strong>14.   </strong><strong>MISCELLANEOUS PROVISIONS</strong>
</ol>
<p>(i)   <strong>Severability</strong>: If any provision of this Agreement is held by a court of competent jurisdiction or arbitral tribunal to be unlawful, conflicting with provisions of another Act, or otherwise unenforceable under applicable law, then such provision shall be excluded from the Agreement and the remainder of the Agreement shall be interpreted as if such provision were so excluded and shall be enforceable in accordance with its terms. If two or more provisions of these Terms are deemed to conflict each other&rsquo;s operation, Gigleji shall have the sole right to elect which provision remains in force.</p>
<p>(ii)   <strong>Notices</strong>: All notices and other communications required or permitted hereunder to be given to a Party shall be in writing, in English language, and shall be sent by fax, e-mail, or mailed by prepaid nationally recognized courier, or otherwise delivered by hand or by messenger, addressed to such Party&rsquo;s address as set forth above.</p>
<p>(iii)  <strong>Waiver</strong>: Nothing in these Terms shall be deemed waived, and no breach excused, unless such waiver or consent shall be in writing and signed by the Party claimed to have waived or consented.</p>
<p>(iv)   <strong>Assignment</strong>: You may not assign or sub-license, without the prior written consent of Gigleji, the rights, duties and/or obligations under these Terms, in whole or in part, to any person or entity. We may assign, transfer, or delegate our rights and/or obligations under these Terms to any other party at our discretion.</p>
<p><strong> </strong></p>
<ol>
<strong>15.   </strong><strong>CONTACT DETAILS </strong>
</ol>
<p>Please feel free to reach out to us at our Customer Care helpline: ___________________   and/or send us an email at <a href="mailto:support@gigleji.com">support@gigleji.com</a>.</p>
<p><strong> </strong></p>
<p><strong> </strong></p>
<p><strong>Last updated on</strong> 1<strong>4<sup>th</sup> November, 2022</strong>.</p>
<p><strong>COPYRIGHT &copy; GIGLEJI TEKNET PRIVATE LIMITED, 2022. ALL RIGHTS RESERVED</strong></p>
        </MDTypography>
      </MDBox>
      {showAccept ? (
        <MDBox pb={2}>
          <MDButton
            size="small"
            type="gradient"
            color="success"
            sx={{ ml: "20px" }}
            onClick={() => handleAccept()}
          >
            {" "}
            I Agree
          </MDButton>
        </MDBox>
      ) : (
        <MDBox pb={2}>
          <MDButton
            size="small"
            type="gradient"
            color="success"
            sx={{ ml: "20px" }}
            onClick={() => handleClose()}
          >
            {" "}
            I Agree
          </MDButton>
        </MDBox>
      )}
    </Card>
  );
}

export default EngagerTermsAndConditions;