/**
=========================================================
* Otis Admin PRO - v2.0.0
=========================================================
 
* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
 
Coded by www.creative-tim.com
 
 =========================================================
 
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
 
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
 
// @mui material components
import Grid from "@mui/material/Grid";
 
 
// Otis Admin PRO React components
import Box from "@mui/material/Box";
 
// Otis Admin PRO React example components
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard";
 
// Otis Admin PRO React context
import { useMaterialUIController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import api from "services/api";
import { makeStyles } from '@mui/styles';
import { useContext, useEffect, useState } from "react";
import MDButton from "components/MDButton";
import moment from 'moment';
import { useLocation, useNavigate } from "react-router-dom";
import { SnackbarContext } from "context/SnackbarContext";
import { UserContext } from "context/UserProvider";
import {Dialog,DialogContent,DialogTitle} from "@mui/material";
import Typography from '@mui/material/Typography';
import CircularProgress from "@mui/material/CircularProgress";
import ClearIcon from '@mui/icons-material/Clear';
 
 
import CircularProgressWithLabel from "components/CircularProgress";
 
const useStyles = makeStyles({
 
  qrImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  qrImage:{
   position:'relative',
   left:'42px'
  },
  qrContainer:{
    position:'relative',
    textAlign:'center',
    left:'35px',
   
  },
 
})
 
 
const ManagePlans = ()=> {
  const { userInfo } = useContext(UserContext);
  const classes = useStyles();
  const { state } = useLocation();
  const { setSnack } = useContext(SnackbarContext);
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [plans, setPlans] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [timeLeft, setTimeLeft] = useState(100);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [qrImage, setQrImage] = useState("");
 const[showTryAgainPopup,setShowTryAgainPopup]=useState(false);
 const [blurredView, setBlurredView] = useState(true);
const[buttonVisible,setButtonVisible]=useState(true);
 
 
  const openDialog = async (plan) => {
    setSelectedPlan(plan);
    setShowProgressBar(true);
    setButtonVisible(true);
 
    try {
      const response = await fetch('/api/payment/getqr', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ planId:plan.id }),
      });
      if (response.ok) {
        const data = await response.json();
        console.log("QR",data);
        setQrImage(data.qrurl);
      }
      else {
        response.error("invalid id")
      }
    } catch (error) {
      response.error("400 not found")
    }
     setTimeout(() => {
      setShowProgressBar(false);
      setIsDialogOpen(true);
    }, 3000);
  };
 
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
 
      return () => {
        clearTimeout(timer);
      };
    }
  }, [timeLeft]);
 
  useEffect(() => {
    if (timeLeft === 0 ) {
    setShowTryAgainPopup(true);
    setIsDialogOpen(false);
   
    const closeTryAgainPopup = setTimeout(() => {
        setShowTryAgainPopup(false);  
        setTimeLeft(100);
      },1000);
      return () => {
        clearTimeout(closeTryAgainPopup);
      };
    }
  }, [timeLeft]);
 
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
 
  useEffect(() => {
    api.plan.findAll()
      .then(data => {
        let temp = data?.data?.result?.data
        console.log("plans",temp, state)
        if(userInfo.type == "admin" || userInfo.type=="employer" && state){
          setPlans(temp.filter(x => x.id != state.values.planId ))
          // console.log("plans for admin edit", plans)
        }else{
          setPlans(temp)
        }
      })
  }, [])
 
 
  // const handleApply = (row) => {
  //   // console.log("row selected", row);
  //   let id = state? state.values.engagerId:JSON.parse(localStorage.getItem("user"))
  //   let date = new Date();
  //   const planId = row.id;
  //   const planTitle = row.title;
  //   const planValidFrom = date;
  //   const planValidTo = moment(date).add(364, 'days').toDate();
  //   api.engager.update(id, { planId, planTitle, planValidFrom, planValidTo })
  //     .then(res => {
  //       setSnack({
  //         message: `${row?.title} is Chosen`,
  //         open: true,
  //         type: "success",
  //       });
  //       if(userInfo.type == "admin"){
  //         navigate(-1)
  //       }else{
  //         navigate("/employer/profile");
  //       }
  //     })
  //     .catch(err => {
  //       setSnack({
  //         message: `${err} ocurred`,
  //         open: true,
  //         type: "error",
  //       });
  //     })
  //   // console.log("detailssssss", planValidFrom, planValidTo)
  // }
 
  const handleViewButtonClick = () => {
    setBlurredView(!blurredView);
    setButtonVisible(false);
  };
 
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box position="relative" zIndex={10} mt={8} px={{ xs: 1, sm: 0 }}>
        <Grid container spacing={3} justifyContent="center">
          {plans?.map((row) => (
            <Grid item xs={12} lg={4}>
              <DefaultPricingCard
                key={row?.id}
                color={darkMode ? "dark" : "white"}
                // badge={{ color: darkMode ? "warning" : "light", label: "Basic" }}
                badge={{ color: "info", label: (row?.title) }}
                price={{ currency: "Rs", value: (row?.price), type: "yr" }}
                specifications={[
                  { label: `${row?.numberOfUsers} - Users`, includes: true },
                  { label: `${row?.numberOfSubUsers} - Sub-user`, includes: true },
                  { label: `${row?.numberOfJobPostings} - Job-postings`, includes: true },
                  // { label: "Carry forward option", includes: (row?.carryForwardOption) },
                  { label: "Contact Details", includes: (row?.contactDetails) },
                  { label: "Paid Additional Job Posting Option", includes: true },
                  // { label: `${row?.numberOfProfileView} - Number of profile view`, includes: true },
                  { label: "Automatic profile matching", includes: (row?.automaticProfileMatchingAlert) },
                  // { label: `${row?.accessToProfileCategory} - Access to profile category `, includes: true },
                  // { label: "BGV of Candidates", includes: (row?.bgvOfTheCandidates) },
                  // { label: "Access to Assessor", includes: (row?.accessToAssessors) },
                  // { label: "Mentoring option", includes: (row?.mentoringOption) },
                ]}
                action={{
                  // type: "internal",
                  // route: "/",
                  color: darkMode ? "warning" : "dark",
                  label: `try ${row?.title}`,
                }}
                onClick={() => {
                  openDialog(row)
                  setBlurredView(true);
                  setButtonVisible(true);
                }
                  }
                // onClick={() => {
                //   navigate(`/employer/payment-page/${row.title}${row.id}`, {
                //       state: {
                //         ...row,
                //         Users: row.numberOfUsers,
                //         SubUsers: row.numberOfSubUsers,
                //       JobPostings: row.numberOfJobPostings,
                //       },
                //     });
                // }}
             
               />    
            </Grid>
          ))}
 {showProgressBar && (
  <CircularProgress
              color="primary"
              size={80}
              thickness={4}
              sx={{ position: "absolute", top: "40%", left: "45%" }}
  />
  )}
<Dialog   style={{width:'100%'}} open={isDialogOpen}  disablePortal={true} >
<DialogTitle style={{ position:"relative",textAlign:"center",backgroundColor:"rgb(66,80,245)",color:"whitesmoke",fontFamily: "Roboto,Helvetica,Arial,sans-serif",marginBottom:"5px",padding:'20px'}}>Selected Plan Details</DialogTitle>
<DialogContent>      
<ClearIcon style={{
    position: 'absolute',
    top: '0px',
    right: '0px',
    cursor: 'pointer',
    backgroundColor: 'red',
    width: '26px',
    height: '26px',
    color: 'white'
  }}
  onClick={() => {
    setIsDialogOpen(false);
    setSnack({
      message: 'Payment Cancelled..!',
      open: true,
      type: 'error'
    });
    setTimeLeft(100);
  }}
/>
{selectedPlan &&(
            <table>
              <tbody>
        <tr><td >Plan Name</td>
        <td ><>
           {selectedPlan.title}
          </>
          </td>
          </tr>
          <tr>
            <td>Price</td>
        <td><>
          {selectedPlan.price} Rs/yr
        </>
        </td>
        </tr>
        <tr>
          <td>Users</td>
          <td>
        <>
           {selectedPlan.numberOfUsers}
        </>
        </td>
        </tr>
        <tr>
          <td>Sub-Users</td>
          <td>
        <>
          {selectedPlan.numberOfSubUsers}
        </>
        </td>
        </tr>
        <tr>
          <td>Job-Postings</td>
          <td>
        <>
          {selectedPlan.numberOfJobPostings}
        </>
        </td>
        </tr>
        <Typography className={classes.qrContainer} >Pay here</Typography>
        <Typography className={classes.qrImageContainer}>
           <div style={{ position: 'relative', width: '200px' }}>
{isDialogOpen && buttonVisible &&(
    <button
      style={{ position:'absolute',cursor:'pointer',borderRadius:'10px',borderStyle:'none', backgroundColor:'rgb(205, 208,212)',width:'60px',height:'30px',color:'gray',top: '80px',left:'100px', zIndex: '1' }}
      onClick={handleViewButtonClick}
    >Scan
    </button>
   )}
{blurredView ? (
      <img
        src={qrImage}
        alt="QR Code"
        className={classes.qrImage}
        style={{ filter: 'blur(3px)' }}
      />
    ) : (
      <img
        src={qrImage}
        alt="QR Code"
        className={classes.qrImage}
      />
    )}
  </div>
</Typography>
<CircularProgressWithLabel value={timeLeft} minutes={minutes} seconds={seconds} />
    </tbody>
    </table>
          )}
</DialogContent>
</Dialog>
 
     
<Dialog open={showTryAgainPopup} disablePortal={true}>
<DialogTitle>Try Again</DialogTitle>
  <DialogContent>
    <Typography>
      Your time has run out. Please try again.
    </Typography>
  </DialogContent>
</Dialog>
</Grid>
</Box>
</DashboardLayout>
   
 
  );
}
 
 
export default ManagePlans;