import { Card, Divider, Grid } from '@mui/material'
import MDBadge from 'components/MDBadge'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import React from 'react'
import UserInfo from '../users/new-user/components/UserInfo'
import userInfo from '../AssessorDashboard/assessedGiglers'

const giglerAssessmentDetails = ({ assessmentDetails}) => {
  console.log("assessment values", assessmentDetails)
  return (
    <Card sx={{ py: "25px", pl: "25px", pr: "25px", height: "600px", overflow: "scroll" }}>
      <Grid container>
        <Grid align="center" item xs={12} md={12} xl={12}>
          <MDTypography variant="body" textTransform="capitalize" fontWeight="medium" color="info">
            Assessment Values
          </MDTypography>
          <Grid align="left">
          <MDTypography variant = "h5" color = "warning">
            GigTalent : <MDTypography color="warning" variant="body">{assessmentDetails?.userInfo[0].firstName + " " + assessmentDetails?.userInfo[0].lastName}</MDTypography>
          </MDTypography>
          <MDTypography variant = "h5" color = "warning">
            Domain : <MDTypography color="warning" variant="body">{assessmentDetails?.userInfo[0].domain} </MDTypography>
          </MDTypography>
          </Grid>
          <Divider />
        </Grid>
        <Grid item align="center" xs={1} md={1} xl={1}>
          <MDTypography variant="h6">
            {""}
          </MDTypography>
          <MDTypography variant="h6" color="warning" sx={{ mt: "30px" }}>
            T01
          </MDTypography>
          <MDTypography variant="h6" color="warning" sx={{ mt: "8px" }}>
            T02
          </MDTypography>
          <MDTypography variant="h6" color="warning" sx={{ mt: "8px" }}>
            T03
          </MDTypography>
          <MDTypography variant="h6" color="warning" sx={{ mt: "7px" }}>
            T04
          </MDTypography>
          <MDTypography variant="h6" color="warning" sx={{ mt: "5px" }}>
            T05
          </MDTypography>
        </Grid>
        <Grid item align="left" xs={12} md={8} xl={5}>
          <MDTypography variant="h6" fontWeight="medium" color="warning" sx={{ textDecoration: "underline" }}>
            Technical/Functional observation
          </MDTypography>
          <MDTypography variant="button" fontWeight="medium" color="dark">
            Possesses requisite knowledge to deliver stated expertise
          </MDTypography><br />
          <MDTypography variant="button" fontWeight="medium" color="dark">
            Has skills to deliver stated expertise
          </MDTypography><br />
          <MDTypography variant="button" fontWeight="medium" color="dark">
            Applied HIS/HER knowledge and skill to deliver the stated expertise
          </MDTypography><br />
          <MDTypography variant="button" fontWeight="medium" color="dark">
            Agility of technical learning
          </MDTypography><br />
          <MDTypography variant="button" fontWeight="medium" color="dark">
            Adherence to process and quality
          </MDTypography><br />
        </Grid>
        <Grid align="left" item xs={6} md={3} xl={5}>
          <MDTypography variant="h6" fontWeight="medium" color="warning" sx={{ textDecoration: "underline" }}>
            Assessor Feedback
          </MDTypography>
          {assessmentDetails?.technical[0] &&
            <>
              <MDTypography variant="button" fontWeight="medium">
                {Object.keys(assessmentDetails.technical[0])[0]}&nbsp;
              </MDTypography><br />
            </>
          }
          {assessmentDetails?.technical[1] &&
            <>
              <MDTypography variant="button" fontWeight="medium">
                {Object.keys(assessmentDetails.technical[1])[0]}&nbsp;
              </MDTypography><br />
            </>
          }
          {assessmentDetails?.technical[2] &&
            <>
              <MDTypography variant="button" fontWeight="medium">
                {Object.keys(assessmentDetails.technical[2])[0]}&nbsp;
              </MDTypography><br />
            </>
          }
          {assessmentDetails?.technical[3] &&
            <>
              <MDTypography variant="button" fontWeight="medium">
                {Object.keys(assessmentDetails.technical[3])[0]}&nbsp;
              </MDTypography><br />
            </>
          }
          {assessmentDetails?.technical[4] &&
            <>
              <MDTypography variant="button" fontWeight="medium">
                {Object.keys(assessmentDetails.technical[4])[0]}&nbsp;
              </MDTypography><br />
            </>
          }
        </Grid>
        <Grid align="center" item xs={6} md={3} xl={1}>
          <MDTypography variant="h6" textTransform="capitalize" color="warning" sx={{ textDecoration: "underline" }}>
            Score
          </MDTypography>
          {assessmentDetails?.technical[0] &&
            <>
              <MDTypography variant="button" textTransform="capitalize" color="text">
                {/* {assessmentDetails.technical[0].t1} */}
                {Object.values(assessmentDetails.technical[0])[0]}
              </MDTypography><br />
            </>}
          {assessmentDetails?.technical[1] &&
            <>
              <MDTypography variant="button" textTransform="capitalize" color="text">
                {/* {assessmentDetails.technical[1].t2} */}
                {Object.values(assessmentDetails.technical[1])[0]}

              </MDTypography><br />
            </>}
          {assessmentDetails?.technical[2] &&
            <>
              <MDTypography variant="button" textTransform="capitalize" color="text">
                {/* {assessmentDetails.technical[2].t3} */}
                {Object.values(assessmentDetails.technical[2])[0]}
              </MDTypography><br />
            </>}
          {assessmentDetails?.technical[3] &&
            <>
              <MDTypography variant="button" textTransform="capitalize" color="text">
                {/* {assessmentDetails.technical[3].t4} */}
                {Object.values(assessmentDetails.technical[3])[0]}

              </MDTypography><br />
            </>}
          {assessmentDetails?.technical[4] &&
            <>
              <MDTypography variant="button" textTransform="capitalize" color="text">
                {/* {assessmentDetails.technical[4].t5} */}
                {Object.values(assessmentDetails.technical[4])[0]}
              </MDTypography><br />
            </>}
        </Grid>
        <Grid item xs={12} xl={12} md={12}>
          <Divider />
        </Grid>
        <Grid item align="center" xs={1} md={1} xl={1}>
          <MDTypography variant="h6">
            {""}
          </MDTypography>
          <MDTypography variant="h6" color="warning" sx={{ mt: "30px" }}>
            B01
          </MDTypography>
          <MDTypography variant="h6" color="warning" sx={{ mt: "8px" }}>
            B02
          </MDTypography>
          <MDTypography variant="h6" color="warning" sx={{ mt: "8px" }}>
            B03
          </MDTypography>
          <MDTypography variant="h6" color="warning" sx={{ mt: "8px" }}>
            B04
          </MDTypography>
          <MDTypography variant="h6" color="warning" sx={{ pt: "5px" }}>
            B05
          </MDTypography>
        </Grid>
        <Grid align="left" item xs={12} md={8} xl={5}>
          <MDTypography variant="h6" fontWeight="medium" color="warning">
            Behavioral observation
          </MDTypography>
          <MDTypography variant="button" fontWeight="medium" color="dark">
            Customer Savvy
          </MDTypography><br />
          <MDTypography variant="button" fontWeight="medium" color="dark">
            Result Orientation & Ownership
          </MDTypography><br />
          <MDTypography variant="button" fontWeight="medium" color="dark">
            Time optimization
          </MDTypography><br />
          <MDTypography variant="button" fontWeight="medium" color="dark">
            Problem Solving
          </MDTypography><br />
          <MDTypography variant="button" fontWeight="medium" color="dark">
            Creativity
          </MDTypography><br />
        </Grid>
        <Grid align="left" item xs={6} sm={3} xl={5}>
          <MDTypography variant="button" textTransform="capitalize" fontWeight="medium" color="info">
            { }
          </MDTypography><br />
          <MDTypography variant="button" fontWeight="medium">
            {Object.keys(assessmentDetails.behavioral[0])[0]}&nbsp;
          </MDTypography><br />
          <MDTypography variant="button" fontWeight="medium">
            {Object.keys(assessmentDetails.behavioral[1])[0]}&nbsp;
          </MDTypography><br />
          <MDTypography variant="button" fontWeight="medium">
            {Object.keys(assessmentDetails.behavioral[2])[0]}&nbsp;
          </MDTypography><br />
          <MDTypography variant="button" fontWeight="medium">
            {Object.keys(assessmentDetails.behavioral[3])[0]}&nbsp;
          </MDTypography><br />
          <MDTypography variant="button" fontWeight="medium">
            {Object.keys(assessmentDetails.behavioral[4])[0]}&nbsp;
          </MDTypography><br />
        </Grid>

        <Grid align="center" item xs={6} md={3} xl={1}>
          <MDTypography variant="button" textTransform="capitalize" color="text">
            { }
          </MDTypography><br />
          <MDTypography variant="button" textTransform="capitalize" color="text">
            {Object.values(assessmentDetails.behavioral[0])[0]}
          </MDTypography><br />
          <MDTypography variant="button" textTransform="capitalize" color="text">
            {Object.values(assessmentDetails.behavioral[1])[0]}
          </MDTypography><br />
          <MDTypography variant="button" textTransform="capitalize" color="text">
            {Object.values(assessmentDetails.behavioral[2])[0]}
          </MDTypography><br />
          <MDTypography variant="button" textTransform="capitalize" color="text">
            {Object.values(assessmentDetails.behavioral[3])[0]}
          </MDTypography><br />
          <MDTypography variant="button" textTransform="capitalize" color="text">
            {Object.values(assessmentDetails.behavioral[4])[0]}
          </MDTypography><br />
        </Grid>
        <Grid item xl={12}>
          <Divider />
        </Grid>
        <Grid align="center" item xs={12} md={12} xl={12}>
          <MDBox display="flex" justifyContent="space-evenly" px={5}>
            <MDTypography variant="button" fontWeight="medium" color="info">
              Average of Technical&nbsp;:&nbsp;
              <MDTypography variant="button" textTransform="capitalize" color="text" fontWeight="medium">
                &nbsp;{assessmentDetails.avgTechnical}
              </MDTypography>
            </MDTypography>
            <Divider orientation='vertifcal' />
            <MDTypography variant="button" fontWeight="medium" color="info">
              Average of Behavioral&nbsp;:&nbsp;
              <MDTypography variant="button" textTransform="capitalize" color="text" fontWeight="medium">
                &nbsp;{assessmentDetails.avgBehavioral}
              </MDTypography>
            </MDTypography>
            <Divider orientation='vertifcal' />
            <MDTypography variant="button" textTransform="capitalize" fontWeight="medium" color="info">
              Total&nbsp;:&nbsp;
              <MDTypography variant="button" textTransform="capitalize" color="text" fontWeight="medium">
                &nbsp;{Number(assessmentDetails.total).toFixed(2)}
              </MDTypography>
            </MDTypography>
            <Divider orientation='vertifcal' />
            <MDTypography variant="button" textTransform="capitalize" fontWeight="medium" color="info">
              Percentage&nbsp;:&nbsp;
              <MDTypography variant="button" textTransform="capitalize" color="text" fontWeight="medium">
                &nbsp;{(assessmentDetails.total) * 10}
              </MDTypography>
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xl={12}>
          <Divider />
        </Grid>
        <Grid align="left" item xs={6} md={8} xl={2}>
          <MDTypography variant="button" textTransform="capitalize" fontWeight="medium" color="info">
            Qualitative comments&nbsp;:
          </MDTypography><br />
        </Grid>
        <Grid item xs={8} md={16} xl={10}>
          <MDTypography variant="button" color="text">
            {assessmentDetails.qualitativeComment}
          </MDTypography><br />
        </Grid>
        <Grid item xl={12}>
          <Divider />
        </Grid>
        <Grid align="center" item xs={12} md={12} xl={12}>
          <MDTypography variant="button" textTransform="capitalize" fontWeight="medium" color="info">
            Gig Talent Category&nbsp;:
          </MDTypography>
          &nbsp;
          <MDBadge
            variant="gradient"
            color={
              assessmentDetails?.badge == "Silver" ? "success" :
                assessmentDetails.badge == "Gold" ? "warning" :
                  assessmentDetails.badge == "Diamond" ? "light" : "success"
            }
            size="md"
            badgeContent={assessmentDetails?.badge}
            container
          />
        </Grid>
      </Grid>
    </Card >
  )
}

export default giglerAssessmentDetails