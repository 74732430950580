import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => {
  return {
    checkbox: {
      marginRight: "30px"
    },
    list: {
      width: '100%',
      // maxWidth: 600,
      bgcolor: 'background.paper',
      // marginLeft: "10px"
    },
    listItem: {
      padding: "2px",
      marginBottom: "2px"
    },
    btn: {
      float: "right",
      marginTop: "10px",
      marginBottom: "10px",
      marginRight: "10px"
    }
  };
});