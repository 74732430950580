import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import Authentication from "layouts/authentication/sign-up/Authentication";
import { useContext, useEffect, useState } from "react";
import { SnackbarContext } from "context/SnackbarContext";
import { TextField, Typography } from "@mui/material";
import api from "services/api";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "context/UserProvider";

const initialValues = {
  password: "",
  confirmPassword: "",
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Your password must be longer than 8 characters.")
    .max(25)
    .matches(/^(?=.{8,})/, "Must Contain 8 Characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])/,
      "Must Contain One Uppercase, One Lowercase"
    )
    .matches(
      /^(?=.*[!@#\$%\^&\*])/,
      "Must Contain One Special Case Character"
    )
    .matches(/^(?=.{6,20}$)\D*\d/, "Must Contain One Number"),    
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const ResetPassword = ({ data, type, setOpenPassword }) => {
  const [otpVerified, setOtpVerified] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [button, setButton] = useState(false);
  const [otpMail, setOtpMail] = useState("");
  const [resend, setResend] = useState(true);
  const { setSnack } = useContext(SnackbarContext);
  const { setIsAuthenticated, setUserInfo } = useContext(UserContext);

  let navigate = useNavigate();

  const handleChange = (e) => {
    console.log("otp change", e.target.value);
    setOtpMail(e.target.value);
  };

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds("OTP Expired!");
      setResend(false);
      setButton(true);
    }
  }, [seconds]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        console.log("values", values);
        api.users.updatePassword({ email: data, password: values.password })
          .then((result) => {
            console.log("data", result);
            if (result) {
              let temp = result?.data?.result?.data;
              if (temp) {
                console.log("Login details", temp);
                localStorage.setItem("authToken", temp?.token);
                setIsAuthenticated(true);
                setUserInfo(temp);
                if (temp?.type == "gigler") {
                  navigate(`/account/profile`);
                } else if (temp?.type == "employer") {
                  navigate(`/employer/dashboard`);
                } else if (temp?.type == "assessor") {
                  navigate(`/assessor/dashboard`);
                } else if (temp?.type == "admin") {
                  navigate(`/admin/dashboard`);
                } else {
                  setSnack({
                    message: "Error Login",
                    open: true,
                    type: "error",
                  });
                }
                setSnack({
                  message: "Login success..!",
                  open: true,
                  type: "success",
                });
              }
            }
          });
      } catch (err) {
        console.log("ERROR: ", err);
      }
    },
  });

  const validateOtp = () => {
    event.preventDefault();
    console.log("otpValue", otpMail, data);
    api.verifications
      .validate({
        email: data,
        otp: otpMail,
      })
      .then((result) => {
        let temp = result?.data?.result?.data;
        console.log("new values", temp);
        if (temp) {
          console.log("register", temp);
          setOtpVerified(true);
        } else {
          setSnack({
            message: "Please enter valid OTP",
            open: true,
            type: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <MDBox>
      {type == "forgot" && (
        <MDBox>
          <MDTypography variant="h4" fontWeight="medium" color="text" mb={4} mt={2}>
            Forgot Password
          </MDTypography>
          {!otpVerified ? (
            <form method="post">
              <MDBox>
                <MDTypography sx={{ fontSize: "15px" }}>Enter OTP sent on your email </MDTypography>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="OTP"
                  label="Enter Your email OTP"
                  name="OTP"
                  onChange={handleChange}
                  autoFocus
                  onKeyDownCapture={(event) => event.keyCode === 13 ? event.preventDefault() : "" }
                />
                <MDBox mt={3} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    size="large"
                    type="button"
                    fullWidth
                    disabled={otpMail.length == 6 ? false : true}
                    onClick={() => validateOtp()}
                  >
                    Validate OTP
                  </MDButton>
                </MDBox>
                <MDBox mt={3} textAlign="center">
                  <MDTypography variant="button" color="text">
                    Didn&apos;t Receive code?{" "}
                    <MDTypography
                      component={Link}
                      to="/register"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                      disabled={resend}
                      onClick={(event) => console.log("key pressed")}
                    >
                      Resend
                    </MDTypography>
                    {/* in {seconds} */}
                  </MDTypography>
                </MDBox>
                <MDBox mt={2} textAlign="center">
                  <MDTypography variant="button" color="text">
                    Go back to {" "}
                    <MDTypography
                      sx={{ cursor: "pointer" }}
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                      onClick={() => setOpenPassword(false)}
                    >
                      login screen
                    </MDTypography>
                    {/* in {seconds} */}
                  </MDTypography>
                </MDBox>
                {/* <MDBox sx={{ color: "#000000" }}><Typography>Resend OTP</Typography><Typography>in </Typography>{seconds}</MDBox> */}
                {/* <MDBox sx={{ maxWidth: "170px", float: "right" }}>
                  <MDButton
                    type="button"
                    fullWidth
                    color="info"
                    variant="gradient"
                    // disabled={button}
                    disabled={otpMail.length == 6 ? false : true}
                    onClick={() => validateOtp()}
                  >
                    Validate OTP
                  </MDButton>
                </MDBox>
                <MDBox sx={{ float: "left" }}>
                  <MDButton
                    fullWidth
                    type="submit"
                    color="info"
                    variant="gradient"
                    disabled={resend}
                  >
                    Resend OTP
                  </MDButton>
                </MDBox> */}
              </MDBox>
            </form>
          ) : (
            <form onSubmit={formik.handleSubmit} method="post">
              <MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="password"
                    label="Password"
                    name="password"
                    {...formik.getFieldProps("password")}
                    fullWidth
                    error={formik.touched.password && formik.errors.password ? true : false}
                    helperText={
                      formik.touched.password && formik.errors.password
                        ? formik.errors.password
                        : null
                    }
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="password"
                    label="Confirm Password"
                    name="confirmPassword"
                    {...formik.getFieldProps("confirmPassword")}
                    fullWidth
                    error={
                      formik.touched.confirmPassword && formik.errors.confirmPassword ? true : false
                    }
                    helperText={
                      formik.touched.confirmPassword && formik.errors.confirmPassword
                        ? formik.errors.confirmPassword
                        : null
                    }
                  />
                </MDBox>
                <MDBox mt={3} mb={1}>
                  <MDButton variant="gradient" color="info" size="large" type="submit" fullWidth>
                    sign in
                  </MDButton>
                </MDBox>
              </MDBox>
            </form>
          )}
        </MDBox>
      )}
    </MDBox>
  );
};

export default ResetPassword;
