
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Soft UI Context Provider
import { MaterialUIControllerProvider } from "context";
import UserProvider from "context/UserProvider";
import SnackbarProvider from "context/SnackbarContext";
import { GoogleOAuthProvider } from "@react-oauth/google";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <GoogleOAuthProvider clientId="470305939480-gov14aonag8s2gmarlob2at4sgaj2q7h.apps.googleusercontent.com">
        <MaterialUIControllerProvider>
          <SnackbarProvider>
            <UserProvider>
              <App />
            </UserProvider>
          </SnackbarProvider>
        </MaterialUIControllerProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
