import { Avatar, Checkbox, CircularProgress, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import React, { useState, useEffect } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MDTypographyRoot from 'components/MDTypography/MDTypographyRoot';
import MDInput from 'components/MDInput';
import theme from 'assets/theme';

// import data from 'layouts/applications/kanban/data';
//image
import profileImg from "assets/images/defaultProfile.png"
import api from 'services/api';
import MDAvatar from 'components/MDAvatar';
const AssessorList = ({ assesserList, handleGiglerList, setData }) => {
  const [selected, setSelected] = useState();
  const [select, setSelect] = useState(false);
  const [newList, setNewList] = useState(assesserList)
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    console.log("in child", assesserList);
  }, [assesserList]);

  const handleClick = (index) => { 
    setSelect(true ? index : false);
  }

  const handleSearch = (event) =>{
    // if(event.target.value.length >= 3){
      setLoading(true)
      if(event.target.value){
        setData(assesserList)
      }
      api.assessor.search({searchValue:event.target.value})
      .then(data => {
        if(data?.data?.message == "success"){
          setLoading(false)
          setData(data?.data?.result?.data)
        }else{
          setSearchResult("Not matched with any assessor")
        }
      })
      .catch(err => console.log("search error", err))
    }
    
  // }

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <MDBox display="flex" p={2} sx={{ alignItems: "center", justifyContent: "space-between" }}>
          <MDTypography variant="h6">Assessor's List</MDTypography>
          <MDInput type="search" label="search" onChange={(e) => handleSearch(e)}></MDInput>
        </MDBox>
        <MDBox sx={{ maxHeight: 550, overflow: 'auto' }}>
          <List dense>
            {loading ? (
              <MDBox 
              sx={{display: "flex",
              flexDirection: "column",
              alignItems: "center",
              py: 5,
            }}>
                <CircularProgress color="error" />
              </MDBox>
            ):(
              
            assesserList.map((row, index) => (
              <ListItemButton
                key={row.assessorId}
                id={index}
                onClick={() => {
                  setSelected(index);
                  handleClick(row.index)
                }}
                sx={{
                  backgroundColor: index == selected ? theme.palette.grey[300] : '#ffffff',
                  '&:hover': {
                    backgroundColor: index == selected ? theme.palette.grey[300] : '#ffffff',
                    "& $addIcon": {
                      color: "purple"
                    }
                  }
                }}
              >

                <ListItem
                  secondaryAction={
                    <IconButton >
                      <ArrowForwardIosIcon color="info" fontSize='small' sx={{ cursor: "pointer", mr: "20px" }} />
                    </IconButton>
                  }
                  divider={index == assesserList.length - 1 ? false : true}
                  sx={{ p: "5px" }}
                  onClick={() => {
                    setSelected(index);
                    handleGiglerList(row)
                  }}
                >
                  <ListItemAvatar>
                    <MDAvatar src={row?.avatar || profileImg} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <MDTypography variant="button" textTransform='capitalize' fontWeight="medium">
                        {row.firstName}&nbsp;{row.lastName}
                      </MDTypography>}
                    // secondary={row.industry}
                    secondary={
                      <>
                        <MDTypography variant="caption" fontWeight="medium" color="text">
                          {row.industry}
                        </MDTypography>
                        <MDTypography variant="caption" fontWeight="medium" color="text">
                          {`--`}{row.domain}
                        </MDTypography>
                      </>
                    }
                  />
                </ListItem>
              </ListItemButton>
            )
            ))}
          </List>
        </MDBox>
      </Grid>
    </Grid >

  )
}

export default AssessorList