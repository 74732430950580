import { Card } from '@mui/material';
import MDTypography from 'components/MDTypography';
import { UserContext } from 'context/UserProvider';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import DataTable from '../ClosedEngagements/DataTable'
import EngagerProfile from '../EngagerProfile';

const SubUserList = () => {
  let navigate = useNavigate();
  const { userInfo } = useContext(UserContext);
  const [show, setShow] = useState(true);
  const [subUserDetails, setSubUserDetails] = useState();
  const [details, setDetails] = useState({
    columns: [
      { Header: "Sub-Engager Name", accessor: "firstName", width: "12%" },
      { Header: "Industry", accessor: "industry", width: "20%" },
      { Header: "Organization", accessor: "organizationName", width: "20%" },
      { Header: "Designation", accessor: "designation", width: "15%" },
      { Header: "Department", accessor: "department", width: "10%" },
    ],
    rows: []
  })
  useEffect(() => {
    let id = userInfo.id
    api.engager.findAllSubEmployer(id)
      .then((data) => {
        if (data.length == 0) {
          setDetails(false)
        }else{
          let temp = data?.data?.result?.data
          console.log("sub employer list", temp)
          setDetails({ ...details, rows: temp });
        }
      })
      .catch(err => console.log("error", err))
  }, [])

  const handleClick = (values) => {
    navigate('/admin/engager-details/', {
      state: {
        value: values
      }
    })
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!details ? (
        <Card sx={{p:"20px"}}>
          <MDTypography variant="button" fontWeight="medium"> Sub - user list is empty</MDTypography>
        </Card>
      ) : ( 
        <DataTable table={details} handleClick={handleClick} canSearch />
      )} 
    </DashboardLayout>
  )
}

export default SubUserList