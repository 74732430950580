import React, { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";

import { useNavigate } from "react-router-dom";
import { UserContext } from "context/UserProvider";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { SnackbarContext } from "context/SnackbarContext";
// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
// Assets
// import bgImage from "assets/images/homepage-new.jpg";
// import bgImage from "assets/images/Gigleji-App-Landing-Page.-editjpg.jpg";
import bgImage from "assets/images/Gigleji-App-Landing-Page-300922-modified-1.jpg";

import logo from "assets/images/gigleji-logo-120722-dark.png";

import api from "services/api";
import { Card, Grid, Paper, Typography } from "@mui/material";


const Authentication = ({ userData, type }) => {
  const { state } = useLocation();
  const [seconds, setSeconds] = useState(120);
  const [button, setButton] = useState(false);
  const [otpMail, setOtpMail] = useState(false);
  const [resend, setResend] = useState(true);
  const { setSnack } = useContext(SnackbarContext);
  const [verifyType, setVerifyType] = useState('email');
  const { setIsAuthenticated, setUserInfo } = useContext(UserContext);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds("OTP Expired!");
      setResend(false);
      setButton(true);
    }
  }, [seconds]);

  const handleClick = () => {
    setSeconds(10);
    setResend(true);
    setButton(false);
  };

  useEffect(() => {
    if (otpMail.length < 6) {
      setButton(true);
    } else {
      setButton(false);
    }
  }, [otpMail]);

  const initialValues = {
    otpMail: "",
    type: "gigler",
  };

  const handleResend = () => {
    if (verifyType == 'email') {
      const temp3 = api.verifications.verify({ email: state.values.email, firstName: state.values.firstName });
    } else if (verifyType == 'phone') {
      let reqData = {
        phoneNumber: "+91" + state.values.phoneNumber,
        firstName: state.values.firstName
      }
      api.verifications.sendSMSOtp(reqData).then(data => {
        console.log("send otp", data);
      })
    }
    formik.resetForm();
    setSeconds(120);
    setResend(true);
    setButton(false);
  }

  const convertHMS = (value) => {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return minutes + ':' + seconds; // Return is HH : MM : SS
  }
  let navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    //validationSchema,
    onSubmit: async (values) => {
      try {
        console.log("retrival", values);
        console.log("kkkkkk", { email: state.values.email, otp: values.otpMail });
        if (state.values.type == "gigler") {
          if (verifyType == 'email') {
            const result1 = await api.verifications.validate({
              email: state.values.email,
              otp: values.otpMail,
            });
            let temp = result1?.data?.result?.data;
            console.log("Email otp", result1?.data?.message);
            if (result1?.data?.message == "error") {
              setSnack({
                message: "Please Enter correct OTP",
                open: true,
                type: "error",
              });
            } else {
              if (temp) {
                setSnack({
                  message: "Email verify success..!",
                  open: true,
                  type: "success",
                });
                setVerifyType('phone');
                let reqData = {
                  phoneNumber: "+91" + state.values.phoneNumber,
                  firstName: state.values.firstName
                }
                api.verifications.sendSMSOtp(reqData).then(data => {
                  console.log("send otp", data);
                  if (data?.data?.result) {
                    formik.resetForm();
                    setVerifyType('phone');
                    setSeconds(120);
                  }
                })
              }
            }
          } else if (verifyType == 'phone') {
            const result1 = await api.verifications.smsValidate({
              phoneNumber: "+91" + state.values.phoneNumber,
              otp: values.otpMail,
            });
            let temp = result1?.data?.result?.data;
            if (result1?.data?.message == "error") {
              setSnack({
                message: "Please Enter correct OTP",
                open: true,
                type: "error",
              });
            } else {
              if (temp) {
                console.log("register", temp);
                const result = await api.users.registerUser(state.values);
                console.log("register", result);
                localStorage.setItem("authToken", result?.data?.result?.data?.token);
                const gigId = result?.data?.result?.data?.id;
                console.log(gigId);
                const register = {
                  userId: gigId,
                  firstName: state.values.firstName,
                  lastName: state.values.lastName,
                  email: state.values.email.toLowerCase(),
                  phoneNumber: state.values.phoneNumber,
                  emailVerification: true,
                  phoneVerification: true,
                };
                const result2 = await api.userInfo.create(register);
                console.log(result2?.data?.result?.data);

                let temp2 = result2?.data?.result?.data;
                console.log("after otp verify", temp2);
                if (temp2) {
                  temp2.id = temp2?.userId;
                  setIsAuthenticated(true);
                  temp2.type = 'gigler';
                  setUserInfo(temp2);
                  // localStorage.setItem("authToken", temp2?.token);
                  localStorage.setItem("user", JSON.stringify({ isAuthenticated: true, userInfo: temp }));
                  navigate(`/account/profile`);
                }
                setSnack({
                  message: "Login success..!",
                  open: true,
                  type: "success",
                });
              } else if (state.values.type == "employer") {
                setSnack({
                  message: "Engager registration",
                  open: true,
                  type: "success",
                })
              } else {
                setSnack({
                  message: "Unfortunately user registration failed",
                  open: true,
                  type: "success",
                })
              }

            }
          }
        } else if (state.values.type == "employer") {
          if (verifyType == 'email') {
            const result1 = await api.verifications.validate({
              email: state.values.email,
              otp: values.otpMail,
            });
            let temp = result1?.data?.result?.data;
            console.log("Email otp", result1?.data?.message);
            if (result1?.data?.message == "error") {
              setSnack({
                message: "Please Enter correct OTP",
                open: true,
                type: "error",
              });
            } else {
              if (temp) {
                setSnack({
                  message: "Email verify success..!",
                  open: true,
                  type: "success",
                });
                setVerifyType('phone');
                let reqData = {
                  phoneNumber: "+91" + state.values.phoneNumber,
                  firstName: state.values.firstName
                }
                api.verifications.sendSMSOtp(reqData).then(data => {
                  console.log("send otp", data);
                  if (data?.data?.result) {
                    formik.resetForm();
                    setVerifyType('phone');
                    setSeconds(120);
                  }
                })
              }
            }
          } else if (verifyType == 'phone') {
            const result1 = await api.verifications.smsValidate({
              phoneNumber: "+91" + state.values.phoneNumber,
              otp: values.otpMail,
            });
            let temp = result1?.data?.result?.data;
            if (result1?.data?.message == "error") {
              setSnack({
                message: "Please Enter correct OTP",
                open: true,
                type: "error",
              });
            } else {
              if (temp) {
                console.log("register", temp);
                const result = await api.users.registerUser(state.values);
                console.log("register", result);
                localStorage.setItem("authToken", result?.data?.result?.data?.token);
                const engagerId = result?.data?.result?.data?.id;
                const register = {
                  engagerId: engagerId,
                  organizationName: state.values.organizationName,
                  location: state.values.location,
                  firstName: state.values.firstName,
                  lastName: state.values.lastName,
                  email: state.values.email,
                  industry: state.values.industry,
                  designation: state.values.designation,
                  enterprise: state.values.enterprise,
                  phoneNumber: state.values.phoneNumber,
                  planValidFrom: state.values.planValidFrom,
                  planValidTo: state.values.planValidTo,
                  emailVerification: true,
                  phoneVerification: true,
                  status: 1,
                  engagerType: "owner"
                };
                const result2 = await api.engager.create(register)
                let temp2 = result2?.data?.result?.data;
                console.log("after otp verify", temp2);
                if (temp2) {
                  temp2.id = temp2.engagerId
                  setIsAuthenticated(true);
                  temp2.type = 'employer';
                  setUserInfo(temp2);
                  // localStorage.setItem("authToken", temp2?.token);
                  localStorage.setItem("user", JSON.stringify({ isAuthenticated: true, userInfo: temp }));
                  navigate(`/employer/dashboard`);
                  setSnack({
                    message: "Login success..!",
                    open: true,
                    type: "success",
                  });
                }
              } else {
                setSnack({
                  message: "Unfortunately user registration failed",
                  open: true,
                  type: "success",
                })
              }

            }
          }
        }
      }
      catch (error) {
        setSnack({
          message: "Error occurred during Login!",
          open: true,
          type: "success",
        });
        console.log("ERROR LOGIN: ", { ...error });
      }
    },
  });
  return (
    <IllustrationLayout illustration={bgImage}>
      <Grid>
        <MDBox sx={{ padding: "20px", height: "50vh", maxWidth: "300px", margin: "60px auto" }}>
          <Grid align="center">
            <MDAvatar src={logo} alt="GIGLEJI" sx={{ width: "50%" }} variant="square" />
            <MDTypography variant="h5" fontWeight="medium" color="text" sx={{ mt: "10px" }}>
              Register
            </MDTypography>
            <MDTypography variant="h5" fontWeight="medium" color="text" sx={{ mt: "10px" }}>
              Two Step verification
            </MDTypography>
            <MDBox mt={2}>
              <MDTypography variant="body2">Enter OTP Sent to your {verifyType == "email" ? `Email ${state.values.email}` : verifyType == 'phone' ? `Phone Number ${state.values.phoneNumber}` : ''}</MDTypography>
            </MDBox>
          </Grid>
          <form onSubmit={formik.handleSubmit} method="post">
            <MDBox>
              <TextField
                margin="normal"
                required
                fullWidth
                id="OTP"
                label="OTP"
                name="OTP"
                onChange={(e) => setOtpMail(e.target.value)}
                autoFocus
                {...formik.getFieldProps("otpMail")}
                error={formik.touched.otpMail && formik.errors.otpMail ? true : false}
                helperText={
                  formik.touched.otpMail && formik.errors.otpMail ? formik.errors.otpMail : null
                }
              />
              {seconds == "OTP Expired!" ? (
                ''
              ) : (
                <MDButton type="submit" fullWidth color="info" variant="gradient" sx={{ mt: "20px" }} disabled={button}>
                  verify
                </MDButton>
              )}
            </MDBox>
            <MDBox mt={3}>
              {seconds == "OTP Expired!" ? (
                <MDButton fullWidth color="info" onClick={() => handleResend()} variant="gradient" sx={{ mt: "20px" }}>
                  resend otp
                </MDButton>
              ) : (
                <MDTypography align="center" color="text" variant="body2">
                  OTP Expires in <MDTypography variant="button" color="error" fontWeight="medium">
                    {convertHMS(seconds)}</MDTypography>
                </MDTypography>
              )}
            </MDBox>
          </form>
        </MDBox >
      </Grid >
    </IllustrationLayout >
  );
}

export default Authentication;