import {
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useContext, useEffect, useState } from "react";
// import backgroundImage from "assets/images/bg-profile.jpeg";
import backgroundImage from "assets/images/Gigleji-App-Profile-Background.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import api from "services/api";
import { SnackbarContext } from "context/SnackbarContext";
import select from "assets/theme/components/form/select";
import { useStyles } from "./styles";
import { UserContext } from "context/UserProvider";
import FormField from "layouts/applications/wizard/components/FormField";
import { useFormik } from "formik";
import * as Yup from "yup";
import text from "assets/theme/components/button/text";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const GiglerProfile = () => {
  const { userInfo } = useContext(UserContext);
  const navigate = useNavigate();
  const classes = useStyles();
  const { state } = useLocation();
  // console.log("state values", state.values.engagement)
  const { setSnack } = useContext(SnackbarContext);
  const [resume, setResume] = useState();
  const [report, setReport] = useState(state.values.user.mettlReportLink);
  const [mettlReport, setMettlReport] = useState();
  const [resumeType, setResumeType] = useState();
  const [videoFile, setVideoFile] = useState();
  const [gigDetails, setGigDetails] = useState(state.values.user);
  const [open, setOpen] = useState(false);
  const [shortListed, setShortListed] = useState(state.values.shortListed);
  const [disabled, setDisabled] = useState(state.values.shortListed);
  const [selectDisabled, setSelectDisabled] = useState(state.values.selected);
  const [openFile, setOpenFile] = useState(false);
  const handleOpenFile = () => setOpenFile(true);
  const handleCloseFile = () => setOpenFile(false);
  const formatDate = (string) => {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  };

  useEffect(() => {
    setGigDetails(state.values.user);

    console.log("state values", state);
    const userData = state.values.user;
    console.log("resume", userData?.resume);
    console.log(
      "resume",
      userData?.resume ? userData?.resume.split(/[#?]/)[0].split(".").pop().trim() : ""
    );
    setResumeType(
      userData?.resume ? userData?.resume.split(/[#?]/)[0].split(".").pop().trim() : ""
    );
    const cv = userData?.resume;
    const vd = userData?.video;
    // console.log("video file ", vd)
    setResume(cv);
    setVideoFile(vd);
    formik2.setFieldValue("proRefCheck", state?.values?.user?.proRefCheck, false);
    formik2.setFieldValue("proRefCheckComment", state?.values?.user?.proRefCheckComment, false);
  }, [state.values]);

  const handleShortList = () => {
    if (state.values._id) {
      let id = state.values._id;
      // let shortListed = true
      api.engagementProcess
        .updateOne(id, {
          shortListed: true,
          giglerName: `${gigDetails?.firstName} ${gigDetails?.lastName}`,
          giglerEmail: gigDetails?.email,
          engagementId: state.values?.engagementId,
        })
        .then((data) => {
          // console.log("update data", data)
          setDisabled(!disabled);
          setSnack({
            message: `${gigDetails.firstName + `` + gigDetails.lastName} is shortListed`,
            open: true,
            type: "success",
          });
        })
        .catch((err) => {
          // console.log(err)
          setSnack({
            message: `${gigDetails.firstName + `` + gigDetails.lastName} is not shortListed`,
            open: true,
            type: "error",
          });
        });
    } else {
      const apply = {
        engagementId: state.values.engagement.engagementId,
        engagerId: state.values.engagement.engagerId,
        giglerId: state.values.user.userId,
        status: "open",
        applied: true,
        shortListed: true,
        selected: false,
        createdBy: state.values.engagement.engagerName,
        updatedBy: state.values.engagement.engagerName,
        source: "application",
        active: true,
      };
      api.engagementProcess.create(apply).then((data) => {
        setDisabled(!disabled);
        console.log("engagementProcess success", data);
        if (data) {
          setSnack({
            message: `${state.values.user.firstName + state.values.user.lastName} is short-listed`,
            open: true,
            type: "success",
          });
        } else {
          setSnack({
            message: `Error occurred short listing `,
            open: true,
            type: "Error",
          });
        }
      });
    }
  };

  const handleSelect = () => {
    let id = state.values._id;
    // let shortListed = true
    api.engagementProcess
      .updateOne(id, {
        selected: true,
        giglerName: `${gigDetails?.firstName} ${gigDetails?.lastName}`,
        giglerEmail: gigDetails?.email,
        engagementId: state.values?.engagementId,
      })
      .then((data) => {
        // console.log("update data", data)
        setSelectDisabled(!selectDisabled);
        setSnack({
          message: `${gigDetails.firstName + `` + gigDetails.lastName} is selected`,
          open: true,
          type: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        setSnack({
          message: `${gigDetails.firstName + `` + gigDetails.lastName} is not selected`,
          open: true,
          type: "error",
        });
      });
  };

  const handleDelete = (value) => {
    console.log("gigler id", value, value.userId);
    let id = value.userId;
    let data = {
      status: "0",
    };
    api.userInfo
      .update(id, data)
      .then((res) => {
        let uid = value.userId;
        let data2 = {
          status: "0",
        };
        api.users
          .updateUser(uid, data2)
          .then((res) => {
            if (res?.data?.message == "success") {
              setSnack({
                message: `${value.email} GigTalent is deleted`,
                open: true,
                type: "success",
              });
              navigate("/admin/manage-gigTalent");
            } else if (res?.data?.message == "error") {
              setSnack({
                message: `Error occurred while deleting GigTalent`,
                open: true,
                type: "error",
              });
            }
          })
          .catch((err) => {
            setSnack({
              message: `Error occurred while deleting GigTalent`,
              open: true,
              type: "error",
            });
          });
      })
      .catch((err) => {
        setSnack({
          message: `Error occurred while deleting GigTalent`,
          open: true,
          type: "error",
        });
      });
  };

  const initialValues = {
    mettlAssessmentLink: "",
    mettlReport: "",
    proRefCheck: "",
    proRefCheckComment: "",
  };
  const validationSchema = Yup.object({
    mettlAssessmentLink: Yup.string().required("Mettl Link is required"),
    // mettlReport: Yup.string().required("Mettl Report is required")
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log("values", values);
      try {
        const id = gigDetails.userId;
        const result = await api.userInfo.SendMettlLink(id, values);
        if (result) {
          setSnack({
            message: "Mettl Assessment Link has been sent!",
            open: true,
            type: "success",
          });
        } else {
          throw new error("failed to update");
        }
      } catch (error) {
        setSnack({
          message: "Error occurred during update!",
          open: true,
          type: "error",
        });
        console.log("ERROR update: ", { ...error });
      }
    },
  });

  const handleClick = () => {
    document.getElementById("mettlReportFile").click();
  };
  const handleChange = (event) => {
    let temp = event.target.files[0];
    console.log("report file", temp);
    // setResume(URL.createObjectURL(temp));
    // setUrl(temp);
    const formData = new FormData();
    formData.append("report", temp);
    formData.append("id", gigDetails.userId);
    api.userUploads.gigTalentReport(formData).then((result) => {
      if (result.data?.message == "success") {
        setSnack({
          message: "Mettl report has been uploaded successfully",
          open: true,
          type: "success",
        });
        console.log("report data", result);
        let temp2 = result?.data?.result?.data;
        setReport(temp2?.mettlReportLink);
        setMettlReport(temp2?.mettlReport);
      } else {
        setSnack({
          message: "Error occurred while uploading resume!",
          open: true,
          type: "error",
        });
      }

      // setUrl(temp);
    });
  };

  const validationSchema2 = Yup.object({
    proRefCheck: Yup.string().required("Profession Reference check is required"),
    // proRefComment: Yup.string().required("Professional Reference check comment is reqired")
  });

  const formik2 = useFormik({
    initialValues,
    validationSchema2,
    onSubmit: async (values) => {
      try {
        const id = gigDetails.userId;
        const result = await api.userInfo.update(id, values);
        if (result?.data?.message == "success") {
          setSnack({
            message: `GigTalent ${gigDetails.firstName}${gigDetails.lastName} is updated`,
            open: true,
            type: "success",
          });
        } else {
          throw new error("failed to update");
        }
      } catch (error) {
        setSnack({
          message: "Error occurred during update!",
          open: true,
          type: "error",
        });
        console.log("ERROR update: ", { ...error });
      }
    },
  });

  const handleEdit = (details) => {
    navigate("/admin/edit-gigTalent", {
      state: {
        values: details,
      },
    });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="18.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              ` url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs={4} md={6} xl={1}>
              <MDBox position="relative" height="max-content" mx="auto">
                <MDAvatar
                  src={gigDetails?.avatar}
                  alt="profile picture"
                  size="xl"
                  variant="rounded"
                  shadow="sm"
                />
              </MDBox>
            </Grid>
            <Grid item xs={6} md={6} sm xl={9}>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                  {gigDetails?.firstName}&nbsp;{gigDetails?.lastName}
                </MDTypography>
              </MDBox>
            </Grid>

            {userInfo?.type == "admin" && (
              <Grid item xs={2} md={6} xl={2}>
                <MDBox display="flex" justifyContent="space-evenly">
                  <MDButton
                    size="small"
                    color="warning"
                    variant="gradient"
                    onClick={() => handleEdit(gigDetails)}
                  >
                    Edit
                  </MDButton>
                  <MDButton
                    size="small"
                    color="error"
                    variant="gradient"
                    onClick={() => handleDelete(gigDetails)}
                  >
                    delete
                  </MDButton>
                  <MDButton
                    startIcon={<ArrowBackIcon />}
                    size="small"
                    color="info"
                    variant="gradient"
                    onClick={() => navigate(-1)}
                  >
                    back
                  </MDButton>
                </MDBox>
              </Grid>
            )}
            {userInfo?.type == "employer" && (
              <Grid item xs={2} md={6} xl={1}>
                <MDButton
                  startIcon={<ArrowBackIcon />}
                  sx={{ marginRight: "25px" }}
                  size="small"
                  color="info"
                  variant="gradient"
                  onClick={() => navigate(-1)}
                >
                  back
                </MDButton>
              </Grid>
            )}
          </Grid>
          <MDBox mt={2} />
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} md={6} xl={4}>
              <MDBox alignItems="center" justifyContent="center" pt={2} px={2} pb={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Personal Information
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Name&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{gigDetails?.firstName}&nbsp;{gigDetails?.lastName}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Email&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{gigDetails?.email}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Phone Number&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{gigDetails?.phoneNumber}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Current Location&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{gigDetails?.yourLocation}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Preferred Location&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{gigDetails?.preferredLocation}
                </MDTypography>
              </MDBox>
              {gigDetails?.specialCategory && (
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Special Category&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    {/* {gigDetails?.returningMom && "Returning Mom"} {( gigDetails?.returningMom && (gigDetails?.retiredBureaucrats || gigDetails?.defensePersonnel || gigDetails?.physicallyChallenged)) && ','}
                    {gigDetails?.retiredBureaucrats && "Retired Bureaucrats"} {(gigDetails?.retiredBureaucrats && (gigDetails?.defensePersonnel || gigDetails?.physicallyChallenged)) && ','}
                    {gigDetails?.defensePersonnel && "Defense Personnel"} {(gigDetails?.defensePersonnel && gigDetails?.physicallyChallenged) && ','}
                    {gigDetails?.physicallyChallenged && "Physically Challenged"} */}
                    {gigDetails?.specialCategory == "defensePersonnel"
                      ? "Denfence Personnel"
                      : gigDetails?.specialCategory == "returningMom"
                      ? "Returning Mom"
                      : gigDetails?.specialCategory == "physicallyChallenged"
                      ? "Physically Challenged"
                      : gigDetails?.specialCategory == "retiredBureaucrats"
                      ? "Retired Bureaucrats"
                      : ""}
                  </MDTypography>
                </MDBox>
              )}
              {/* <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Aadhaar&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{gigDetails?.aadhaarNumber}
                </MDTypography>
              </MDBox> */}
              {/* <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Pan&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{gigDetails?.panNumber}
                </MDTypography>
              </MDBox> */}
              {/* <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Passport&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{gigDetails?.passport}
                </MDTypography>
              </MDBox> */}
              {/* <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  GstIN&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{gigDetails?.gstIn}
                </MDTypography>
              </MDBox> */}
            </Grid>
            <Grid item xs={12} xl={4}>
              <MDBox alignItems="center" justifyContent="center" pt={2} px={2} pb={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Skills
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Industry&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{gigDetails?.industry}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Domain&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{gigDetails?.domain}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Skills&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{gigDetails?.primarySkills},{gigDetails?.secondarySkills},
                  {gigDetails?.otherSkills}
                </MDTypography>
              </MDBox>
              <MDBox alignItems="center" justifyContent="center" pt={2} px={2} pb={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Current Engagement
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Organization&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{gigDetails?.currentOrganization}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Current Project Name&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{gigDetails?.currentProjectName}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Current Project From&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  {gigDetails?.currentProjectFrom
                    ? formatDate(gigDetails?.currentProjectFrom)
                    : "-"}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Current Project To&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  {gigDetails?.currentProjectTo ? formatDate(gigDetails?.currentProjectTo) : "-"}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} xl={4}>
              <MDBox alignItems="center" justifyContent="center" pt={2} px={2} pb={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Other Information
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Engagement Model&nbsp;: &nbsp;
                </MDTypography>
                <MDBox mt={-1}>
                  {gigDetails?.fullTime && (
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      &nbsp;Full Time {gigDetails?.partTime || gigDetails?.projectBased ? "," : ""}
                    </MDTypography>
                  )}
                  {gigDetails?.partTime && (
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      &nbsp;Part Time {gigDetails?.projectBased ? "," : ""}
                    </MDTypography>
                  )}
                  {gigDetails?.projectBased && (
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      &nbsp;Engagement Based
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Preferred Engagement&nbsp;: &nbsp;
                </MDTypography>
                <MDBox mt={-1}>
                  {gigDetails?.onSite && (
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      &nbsp;On site{" "}
                      {gigDetails?.offSite || gigDetails?.both || gigDetails?.needBased ? "," : ""}
                    </MDTypography>
                  )}
                  {gigDetails?.offSite && (
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      &nbsp;Off site {gigDetails?.both || gigDetails?.needBased ? "," : ""}
                    </MDTypography>
                  )}
                  {gigDetails?.both && (
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      &nbsp;Both {gigDetails?.offSite || gigDetails?.needBased ? "," : ""}
                    </MDTypography>
                  )}
                  {gigDetails?.needBased && (
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      &nbsp;Need based
                    </MDTypography>
                  )}
                  {gigDetails?.needBased && (
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      &nbsp;Need Based
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  On Boarding&nbsp;: &nbsp;
                </MDTypography>
                <MDBox mt={-1}>
                  {gigDetails?.availability == "zeroToSevenDays" && (
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      &nbsp;0-7 days
                    </MDTypography>
                  )}
                  {gigDetails?.availability == "eightToFifteenDays" && (
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      &nbsp;8-15 days
                    </MDTypography>
                  )}
                  {gigDetails?.availability == "sixteenToThirtyDays" && (
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      &nbsp;16-30 days
                    </MDTypography>
                  )}
                  {gigDetails?.availability == "moreThanThirtyDays" && (
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      &nbsp;More Than 30 days
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
              <MDBox display="flex" py={1} pr={2}px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Years of Experience&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{gigDetails?.experienceYears?gigDetails.experienceYears+' '+'Yrs':''}
                </MDTypography>
                </MDBox>
            </Grid>
          </Grid>
          <MDBox>
            <MDBox alignItems="center" justifyContent="center" pt={2} px={2}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                Education Details
              </MDTypography>
            </MDBox>
            <Grid container>
              <Grid item xs={2}>
                <MDBox pt={2} px={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    color="text"
                    textTransform="capitalize"
                  >
                    Qualification
                  </MDTypography>
                  {/* <MDTypography variant="button" textTransform="capitalize" >
                    1. {gigDetails?.qualification}
                  </MDTypography> */}
                </MDBox>
              </Grid>
              <Grid item xs={2}>
                <MDBox py={2} px={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    color="text"
                    textTransform="capitalize"
                  >
                    Type
                  </MDTypography>
                  {/* <br />
                  <MDTypography variant="button" textTransform="capitalize">
                    {gigDetails?.type}
                  </MDTypography> */}
                </MDBox>
              </Grid>
              <Grid item xs={2}>
                <MDBox py={2} px={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    color="text"
                    textTransform="capitalize"
                  >
                    College/University
                  </MDTypography>
                  {/* <br />
                  <MDTypography variant="button" textTransform="capitalize">
                    {gigDetails?.university}
                  </MDTypography> */}
                </MDBox>
              </Grid>
              <Grid item xs={2}>
                <MDBox py={2} px={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    color="text"
                    textTransform="capitalize"
                  >
                    Area of Specialization
                  </MDTypography>
                  {/* <br />
                  <MDTypography variant="button" textTransform="capitalize">
                    {gigDetails?.specialization}
                  </MDTypography> */}
                </MDBox>
              </Grid>
              <Grid item xs={2}>
                <MDBox py={2} px={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    color="text"
                    textTransform="capitalize"
                  >
                    Joining Date
                  </MDTypography>
                  {/* <br />
                  <MDTypography variant="button" textTransform="capitalize">
                    {formatDate(gigDetails?.joiningDate)}
                  </MDTypography> */}
                </MDBox>
              </Grid>
              <Grid item xs={2}>
                <MDBox py={2} px={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    color="text"
                    textTransform="capitalize"
                  >
                    Passed Out Date
                  </MDTypography>
                  {/* <br />
                  <MDTypography variant="button" textTransform="capitalize">
                    {formatDate(gigDetails?.passOutDate)}
                  </MDTypography> */}
                </MDBox>
              </Grid>
              {/* </Grid> */}
              {/* education details */}
              {/* <Grid container> */}
              {gigDetails?.education.map((element, index) => (
                <>
                  <Grid item xs={2}>
                    <MDBox px={2}>
                      <MDTypography variant="button" textTransform="capitalize">
                        {index + 1}. {element?.qualification}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={2}>
                    <MDBox px={2}>
                      <MDTypography variant="button" textTransform="capitalize">
                        {element?.type}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={2}>
                    <MDBox px={2}>
                      <MDTypography variant="button" textTransform="capitalize">
                        {element?.university}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={2}>
                    <MDBox px={2}>
                      <MDTypography variant="button" textTransform="capitalize">
                        {element?.specialization}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={2}>
                    <MDBox px={2}>
                      <MDTypography variant="button" textTransform="capitalize">
                        {element?.joiningDate ? formatDate(element?.joiningDate) : "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={2}>
                    <MDBox px={2}>
                      <MDTypography variant="button" textTransform="capitalize">
                        {element?.passOutDate ? formatDate(element?.passOutDate) : "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </>
              ))}
            </Grid>
          </MDBox>
          <MDBox>
            <MDBox alignItems="center" justifyContent="center" pt={2} px={2}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                Experience Details
              </MDTypography>
            </MDBox>
            <Grid container>
              <Grid item xs={2}>
                <MDBox py={2} px={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    color="text"
                    textTransform="capitalize"
                  >
                    Employer
                  </MDTypography>

                  {/* <MDTypography variant="button" textTransform="capitalize" >
                    1. {gigDetails?.employer}
                  </MDTypography> */}
                </MDBox>
              </Grid>
              <Grid item xs={2}>
                <MDBox py={2} px={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    color="text"
                    textTransform="capitalize"
                  >
                    Designation
                  </MDTypography>

                  {/* <MDTypography variant="button" textTransform="capitalize">
                    {gigDetails?.designation}
                  </MDTypography> */}
                </MDBox>
              </Grid>
              <Grid item xs={2}>
                <MDBox py={2} px={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    color="text"
                    textTransform="capitalize"
                  >
                    Project From
                  </MDTypography>

                  {/* <MDTypography variant="button" textTransform="capitalize">
                    {formatDate(gigDetails?.from)}
                  </MDTypography> */}
                </MDBox>
              </Grid>
              <Grid item xs={2}>
                <MDBox py={2} px={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    color="text"
                    textTransform="capitalize"
                  >
                    Project To
                  </MDTypography>

                  {/* <MDTypography variant="button" textTransform="capitalize">
                    {formatDate(gigDetails?.to)}
                  </MDTypography> */}
                </MDBox>
              </Grid>
            </Grid>
            {gigDetails?.experience.map((element, index) => (
              <Grid container>
                <Grid item xs={2}>
                  <MDBox px={2}>
                    <MDTypography variant="button" textTransform="capitalize">
                      {index + 1}. {element?.employer}
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={2}>
                  <MDBox px={2}>
                    <MDTypography variant="button" textTransform="capitalize">
                      {element?.designation}
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={2}>
                  <MDBox px={2}>
                    <MDTypography variant="button" textTransform="capitalize">
                      {element?.from ? formatDate(element?.from) : "-"}
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={2}>
                  <MDBox px={2}>
                    <MDTypography variant="button" textTransform="capitalize">
                      {element?.to ? formatDate(element?.to) : "-"}
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
            ))}
          </MDBox>
          <MDBox alignItems="center" justifyContent="center" pt={2} px={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Professional Reference
            </MDTypography>
          </MDBox>
          <Grid container>
            <Grid item xs={2}>
              <MDBox py={2} px={2}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  color="text"
                  textTransform="capitalize"
                >
                  Name
                </MDTypography>
                <br />
                <MDTypography variant="button" textTransform="capitalize">
                  {gigDetails?.proRefName}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={2}>
              <MDBox py={2} px={2}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  color="text"
                  textTransform="capitalize"
                >
                  Email
                </MDTypography>
                <br />
                <MDTypography variant="button" textTransform="capitalize">
                  {gigDetails?.proRefEmail}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={2}>
              <MDBox py={2} px={2}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  color="text"
                  textTransform="capitalize"
                >
                  Phone number
                </MDTypography>
                <br />
                <MDTypography variant="button" textTransform="capitalize">
                  {gigDetails?.proRefNumber}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={2}>
              <MDBox py={2} px={2}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  color="text"
                  textTransform="capitalize"
                >
                  Position
                </MDTypography>
                <br />
                <MDTypography variant="button" textTransform="capitalize">
                  {gigDetails?.proRefPosition}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={2}>
              <MDBox py={2} px={2}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  color="text"
                  textTransform="capitalize"
                >
                  Organization
                </MDTypography>
                <br />
                <MDTypography variant="button" textTransform="capitalize">
                  {gigDetails?.proRefOrganization}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={12} md={12}>
            <MDBox alignItems="center" justifyContent="center" pt={2} px={2} pb={2}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                Career Story
              </MDTypography>
            </MDBox>
            <MDBox display="flex" py={2} px={2} sx={{ border: "10px", width: "800px" }}>
              <MDTypography variant="button" fontWeight="regular" color="text" textAlign="left">
                &nbsp;{gigDetails?.description}
              </MDTypography>
            </MDBox>
          </Grid>

          <Grid>
            <MDBox px={2} mt={1}>
              <MDTypography variant="h6">Resume Text</MDTypography>
              <FormField
                placeholder="Resume text..."
                name="resumetext"
                multiline
                value={gigDetails?.resumeText}
                rows={5}
                inputProps={{ type: "text", readOnly: true }}
              />
            </MDBox>
          </Grid>
          <Grid container>
            {resume && (
              <Grid item xs={4}>
                <MDBox display="flex" alignItems="center" pt={2} px={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Resume
                  </MDTypography>

                  <MDButton
                    color="info"
                    size="small"
                    variant="gradient"
                    href={`https://docs.google.com/viewerng/viewer?url=${resume}`}
                    target="_blank"
                    sx={{ ml: "20px", mr: "10px" }}
                  >
                    View
                  </MDButton>
                  <MDButton
                    color="info"
                    size="small"
                    variant="gradient"
                    href={resume}
                    sx={{ mr: "10px" }}
                  >
                    Download
                  </MDButton>
                </MDBox>
              </Grid>
            )}
            {videoFile && (
              <Grid item xs={4}>
                <MDBox display="flex" alignItems="center" pt={2} px={2} pb={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Personalized video
                  </MDTypography>
                  <MDButton
                    variant="gradient"
                    size="small"
                    onClick={() => setOpen(true)}
                    color="info"
                    sx={{ ml: "20px" }}
                  >
                    View
                  </MDButton>
                </MDBox>
              </Grid>
            )}
          </Grid>
          {userInfo.type == "admin" || userInfo.type == "Assessment-admin" ? (
            <form onSubmit={formik.handleSubmit} method="post">
              <Grid>
                {gigDetails?.mettlAssessmentLink ? (
                  <MDBox p={2}>
                    <MDTypography variant="h6">Mettl Assessment is already sent...</MDTypography>
                    <form method="post">
                      <MDBox display="flex">
                        <Modal open={openFile} onClose={handleCloseFile}>
                          <MDBox className={classes.resumeStyle}>
                            <iframe
                              className={"pdf"}
                              width="100%"
                              height="100%"
                              frameBorder="0"
                              src={`https://docs.google.com/gview?url=${report}&embedded=true`}
                            ></iframe>
                          </MDBox>
                        </Modal>
                        <MDBox sx={{ alignItems: "center" }}>
                          {report && (
                            <>
                              <MDTypography variant="button" color="text" sx={{ mr: "10px" }}>
                                {report.split("/").pop()}
                              </MDTypography>
                              <MDButton
                                color="info"
                                size="small"
                                variant="gradient"
                                // href={`https://docs.google.com/viewerng/viewer?url=${resume}`}
                                // target="_blank"
                                onClick={() => setOpenFile(true)}
                                sx={{ mr: "10px" }}
                              >
                                View
                              </MDButton>
                              <MDButton
                                color="info"
                                size="small"
                                variant="gradient"
                                href={resume}
                                sx={{ mr: "10px" }}
                              >
                                Download
                              </MDButton>
                            </>
                          )}
                          <input
                            type="file"
                            name="mettlReportFile"
                            id="mettlReportFile"
                            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            onChange={(e) => handleChange(e)}
                            className={classes.inputStyle}
                            sx={{ display: "none" }}
                          />
                          {!gigDetails?.mettlReport && !report && (
                            <MDBox display="flex" justifyContent="space-evenly" mt={2}>
                              <MDTypography variant="h6">Upload mettl assessment link</MDTypography>
                              <MDButton
                                sx={{ ml: "10px" }}
                                variant="gradient"
                                size="small"
                                color="success"
                                onClick={() => handleClick()}
                              >
                                Upload
                              </MDButton>
                            </MDBox>
                          )}
                        </MDBox>
                      </MDBox>
                    </form>
                  </MDBox>
                ) : (
                  <MDBox px={2} mb={2} mt={5}>
                    <MDTypography variant="h6">Pre-Assessment Link</MDTypography>
                    <FormField
                      {...formik.getFieldProps("mettlAssessmentLink")}
                      placeholder="Pre-Assessment link"
                      name="mettlAssessmentLink"
                      inputProps={{
                        type: "text",
                        readOnly: gigDetails.mettlAssessmentLink ? true : false,
                      }}
                      error={
                        formik.touched.mettlAssessmentLink && formik.errors.mettlAssessmentLink
                          ? true
                          : false
                      }
                      success={
                        formik.values.mettlAssessmentLink > 0 && !formik.errors.mettlAssessmentLink
                      }
                      helperText={
                        formik.touched.mettlAssessmentLink && formik.errors.mettlAssessmentLink
                          ? formik.errors.mettlAssessmentLink
                          : null
                      }
                    />
                    <MDButton
                      sx={{ mt: "10px" }}
                      disabled={gigDetails.mettlAssessmentLink ? true : false}
                      size="small"
                      color="warning"
                      variant="outlined"
                      type="submit"
                    >
                      Send Pre-Assessment Link
                    </MDButton>
                  </MDBox>
                )}
              </Grid>
              {gigDetails.mettlReport && (
                <Grid>
                  <MDBox px={2}>
                    <MDTypography variant="h6">Mettl Assessment Report</MDTypography>
                    <FormField
                      placeholder="Mettl assessment report..."
                      name="mettlReport"
                      multiline
                      value={gigDetails?.mettlReport || ""}
                      rows={5}
                      inputProps={{ type: "text", readOnly: true }}
                    />
                  </MDBox>
                </Grid>
              )}
            </form>
          ) : (
            ""
          )}
          <Modal open={open} onClose={() => setOpen(false)}>
            <MDBox className={classes.videoStyle}>
              <video width="450" height="300" controls>
                <source src={videoFile} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </MDBox>
          </Modal>
          {!state.values.show && (
            <MDBox>
              <>
                {state.values.shortListed && state.values.selected ? (
                  ""
                ) : (
                  <MDBox>
                    {shortListed ? (
                      <MDButton
                        variant="gradient"
                        size="small"
                        color={selectDisabled ? "dark" : "success"}
                        onClick={() => handleSelect()}
                        disabled={selectDisabled}
                      >
                        {state.values.selected ? "selected" : "select"}
                      </MDButton>
                    ) : (
                      <MDButton
                        variant="gradient"
                        size="small"
                        color={disabled ? "dark" : "success"}
                        onClick={() => handleShortList()}
                        disabled={disabled}
                      >
                        shortList
                      </MDButton>
                    )}
                  </MDBox>
                )}
              </>
            </MDBox>
          )}
          <form onSubmit={formik2.handleSubmit} method="post">
            <MDBox>
              <MDBox px={2} display="flex" alignItems="center">
                <MDTypography variant="h6" fontWeight="medium">
                  Professional Reference check
                </MDTypography>
                <MDBox ml={1}>
                  <FormControl component="fieldset">
                    <RadioGroup {...formik2.getFieldProps("proRefCheck")}>
                      <Stack direction="row">
                        <FormControlLabel
                          disabled={userInfo.type != "admin" ? true : false}
                          value="true"
                          control={<Radio />}
                          label={
                            <MDTypography color="text" variant="p" fontWeight="regular">
                              Yes
                            </MDTypography>
                          }
                        />
                        <FormControlLabel
                          disabled={userInfo.type != "admin" ? true : false}
                          value="false"
                          control={<Radio />}
                          label={
                            <MDTypography color="text" variant="p" fontWeight="regular">
                              No
                            </MDTypography>
                          }
                        />
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </MDBox>
              </MDBox>
              <MDBox px={2}>
                <FormField
                  variant="outlined"
                  placeholder="Profession Reference Check Comments...."
                  multiline
                  value={gigDetails?.proRefCheckComment || ""}
                  rows={2}
                  inputProps={{ type: "text", readOnly: userInfo.type !== "admin" ? true : false }}
                  {...formik2.getFieldProps("proRefCheckComment")}
                />
                {userInfo.type == "admin" && (
                  <MDButton variant="outlined" color="success" sx={{ mt: "10px" }} type="submit">
                    Save
                  </MDButton>
                )}
              </MDBox>
            </MDBox>
          </form>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default GiglerProfile;
