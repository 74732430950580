// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from '@mui/material/Tooltip';

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import { Checkbox, fabClasses, FormControlLabel, FormGroup, Stack, Typography } from "@mui/material";

// Data
import MDButton from "components/MDButton";
// import Checkbox from "assets/theme/components/form/checkbox";

import { useFormik } from "formik";
import api from "services/api";
import * as Yup from "yup";
// import selectData from "./data/selectData";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "context/UserProvider";
import { SnackbarContext } from "context/SnackbarContext";

import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
// import TextField from 'material-ui/TextField';
import TextField from "@mui/material/TextField";

const CurrentEngagement = ({ userData, updateUserData }) => {
  const [currentDate, setCurrentDate] = useState(moment(new Date().valueOf() - 1000 * 60 * 60 * 24).format("YYYY-MM-DD"));
  const { userInfo } = useContext(UserContext);
  const { setSnack } = useContext(SnackbarContext);
  const [minDate, setMinDate] = useState();
  const [checked, setChecked] = useState({});
  const [value, setValue] = useState();
 
  
  
  const initialValues = {
    currentOrganization: "",
    currentProjectName: "",
    currentProjectFrom: "",
    currentProjectTo: "",
    fullTime: "",
    partTime: "",
    projectBased: "",
    onSite: "",
    offSite: "",
    both: "",
    needBased: "",
    availability: "",
    compensation: "",
    compensationAmount: "",
  };

  const validationSchema = Yup.object({
    compensation: Yup.string().required("Compensation is required"),
    compensationAmount: Yup.number().required("Compensation amount is required"),
  });

  const formatter = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });

  const handleChange = (event) => {
    setValue(event.target.value);
  };


  const formik = useFormik({
    initialValues,
    // validationSchema,
    onSubmit: async (values) => {
      if (!values.partTime && !values.fullTime && !values.projectBased) {
        setSnack({
          message: "Choose atleast one option in Engagement Model",
          open: true,
          type: "warning",
        });
      } else if (!values.onSite && !values.offSite && !values.both && !values.needBased) {
        setSnack({
          message: "Choose atleast one option in Preferred Engagement Model",
          open: true,
          type: "warning",
        });
      } else if (!values.availability) {
        setSnack({
          message: "Please select Availability for on-boarding option",
          open: true,
          type: "warning",
        })
      } else {
        console.log("gigler profile values", values);
        try {
          let id = ""
        if(userInfo.type == "admin"){
          id = userData?.userId
        }else{
          id = userInfo?.id
        }
          let temp = values;
          temp.currentProjectFrom = moment(values.currentProjectFrom);
          temp.currentProjectTo = moment(values.currentProjectTo);
          updateUserData(temp);
          const result = await api.userInfo.update(id, temp);
          if (result) {
            if (result?.data?.message == "success") {
              setSnack({
                message: "Current Engagement Details Updated!",
                open: true,
                type: "success",
              });
            } else if (result?.data?.message == "error") {
              setSnack({
                message: "Error updating details!",
                open: true,
                type: "error",
              });
            }
          } else {
            throw new error("failed to update");
          }
        } catch (error) {
          setSnack({
            message: "Error occurred during update!",
            open: true,
            type: "success",
          });
          console.log("ERROR LOGIN: ", { ...error });
        }
      }
    },
  });

  useEffect(() => {
    // formik.setFieldValue("currentProjectTo", formik.values.currentProjectFrom, false)

  }, [formik.values])

  useEffect(() => {
    if (userData) {
      let temp = {
        ...userData,
        currentProjectFrom: userData?.currentProjectFrom ? moment(userData?.currentProjectFrom).format("YYYY-MM-DD") : '',
        currentProjectTo: userData?.currentProjectTo ? moment(userData?.currentProjectTo).format("YYYY-MM-DD") : '',
      };
      formik.setValues(temp);
      formik.setFieldValue("currentProjectFrom", temp?.currentProjectFrom, false);
      formik.setFieldValue("currentProjectTo", temp?.currentProjectTo, false);
      setChecked({
        fullTime: userData?.fullTime,
        partTime: userData?.partTime,
        projectBased: userData?.projectBased,
        onSite: userData?.onSite,
        offSite: userData?.offSite,
        both: userData?.both,
        needBased: userData?.needBased,
      });
    }
  }, [userData]);
  const cEDescription = "Please describe below where and on what you are working.";
  const eMDescription = "Desired job type. Note: 'Engagement Based' means it's contractual";
  const pWDescription = "Please select comfortable jobsite of your choice."
  const aODescription = "Notice Period"
  return (
    <Card id="current-engagement" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">
          Current Engagement
          <Tooltip title={cEDescription} placement="right">
          <InfoIcon fontSize="small"/>
          </Tooltip>
          </MDTypography>
      </MDBox>
      <form onSubmit={formik.handleSubmit} method="post">
        <MDBox pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <FormField
                label="Current Organization"
                name="currentOrganization"
                {...formik.getFieldProps("currentOrganization")}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormField
                label="Current Project Name"
                name="currentProjectName"
                {...formik.getFieldProps("currentProjectName")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                label="Project From"
                // placeholder="29-05-1995"
                name="currentProjectFrom"
                inputProps={{ type: "date", max: currentDate }}
                onChange={(date) => console.log("")}
                {...formik.getFieldProps("currentProjectFrom")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                label="Project To"
                // placeholder="29-05-1995"
                name="currentProjectTo"
                inputProps={{ type: "date", min: formik.values.currentProjectFrom }}
                {...formik.getFieldProps("currentProjectTo")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox p={3}>
          <MDTypography variant="h5">
            Engagement Mode
            <Tooltip title={eMDescription} placement="right">
          <InfoIcon fontSize="small"/>
          </Tooltip>
            </MDTypography>
        </MDBox>
        <MDBox pb={3} px={3}>
          {userData && (
            <FormGroup>
              <Stack direction="row">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked.fullTime ? true : false}
                      name="fullTime"
                      {...formik.getFieldProps("fullTime")}
                      onChange={(e, value) => {
                        setChecked({ ...checked, fullTime: value });
                        formik.setFieldValue("fullTime", value, false);
                      }}
                    />
                  }
                  label={
                    <MDTypography color="text" variant="p" fontWeight="regular">
                      Full Time
                    </MDTypography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked.partTime ? true : false}
                      name="partTime"
                      {...formik.getFieldProps("partTime")}
                      onChange={(e, value) => {
                        setChecked({ ...checked, partTime: value });
                        formik.setFieldValue("partTime", value, false);
                      }}
                    />
                  }
                  label={
                    <MDTypography color="text" variant="p" fontWeight="regular">
                      Part Time
                    </MDTypography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked.projectBased ? true : false}
                      name="projectBased"
                      {...formik.getFieldProps("projectBased")}
                      onChange={(e, value) => {
                        setChecked({ ...checked, projectBased: value });
                        formik.setFieldValue("projectBased", value, false);
                      }}
                    />
                  }
                  label={
                    <MDTypography color="text" variant="p" fontWeight="regular">
                      Engagement Based
                    </MDTypography>
                  }
                />
              </Stack>
            </FormGroup>
          )}
        </MDBox>
        <MDBox p={3}>
          <MDTypography variant="h5">
            Preferred Workspace
            <Tooltip title={pWDescription} placement="right">
          <InfoIcon fontSize="small"/>
          </Tooltip>
            </MDTypography>
        </MDBox>
        <MDBox pb={3} px={3}>
          <FormGroup>
            <Stack direction="row">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.onSite ? true : false}
                    name="onSite"
                    {...formik.getFieldProps("onSite")}
                    onChange={(e, value) => {
                      setChecked({ ...checked, onSite: value });
                      formik.setFieldValue("onSite", value, false);
                    }}
                  />
                }
                label={
                  <MDTypography color="text" variant="p" fontWeight="regular">
                    On site
                  </MDTypography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.offSite ? true : false}
                    name="offSite"
                    {...formik.getFieldProps("offSite")}
                    onChange={(e, value) => {
                      setChecked({ ...checked, offSite: value });
                      formik.setFieldValue("offSite", value, false);
                    }}
                  />
                }
                label={
                  <MDTypography color="text" variant="p" fontWeight="regular">
                    WFH
                  </MDTypography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.both ? true : false}
                    name="both"
                    {...formik.getFieldProps("both")}
                    onChange={(e, value) => {
                      setChecked({ ...checked, both: value });
                      formik.setFieldValue("both", value, false);
                    }}
                  />
                }
                label={
                  <MDTypography color="text" variant="p" fontWeight="regular">
                    Both (HYBRID)
                  </MDTypography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.needBased ? true : false}
                    name="needBased"
                    {...formik.getFieldProps("needBased")}
                    onChange={(e, value) => {
                      setChecked({ ...checked, needBased: value });
                      formik.setFieldValue("needBased", value, false);
                    }}
                  />
                }
                label={
                  <MDTypography color="text" variant="p" fontWeight="regular">
                    Need based
                  </MDTypography>
                }
              />
            </Stack>
          </FormGroup>
        </MDBox>
        <MDBox p={3}>
          <MDTypography variant="h5">
            Availability for On-boarding
            <Tooltip title={aODescription} placement="right">
          <InfoIcon fontSize="small"/>
          </Tooltip>
            </MDTypography>
        </MDBox>
        <MDBox pb={3} px={3}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              {...formik.getFieldProps("availability")}
            >
              <Stack direction="row">
                <FormControlLabel
                  value="zeroToSevenDays"
                  control={<Radio />}
                  label={
                    <MDTypography color="text" variant="p" fontWeight="regular">
                      0-7 days
                    </MDTypography>
                  }
                />
                <FormControlLabel
                  value="eightToFifteenDays"
                  control={<Radio />}
                  label={
                    <MDTypography color="text" variant="p" fontWeight="regular">
                      8-15 days
                    </MDTypography>
                  }
                />
                <FormControlLabel
                  value="sixteenToThirtyDays"
                  control={<Radio />}
                  label={
                    <MDTypography color="text" variant="p" fontWeight="regular">
                      16-30 days
                    </MDTypography>
                  }
                />
                <FormControlLabel
                  value="moreThanThirtyDays"
                  control={<Radio />}
                  label={
                    <MDTypography color="text" variant="p" fontWeight="regular">
                      More than 30 days
                    </MDTypography>
                  }
                />
              </Stack>
            </RadioGroup>
          </FormControl>
        </MDBox>
        <MDBox p={3}>
          <MDTypography variant="h5">Expected Compensation</MDTypography>
        </MDBox>
        <MDBox pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} 
            // md={4}
            >
              <Autocomplete
                name="compensation"
                options={["Per Hour", "Per Day", "Per Month","Per Engagement"]}
                renderInput={(params) => <FormField
                  {...params} label="Expected Compensation"
                  error={formik.touched.compensation && formik.errors.compensation ? true : false}
                  helperText={
                    formik.touched.compensation && formik.errors.compensation
                      ? formik.errors.compensation
                      : null
                  }
                />}
                {...formik.getFieldProps("compensation")}
                onChange={(event, newValue) => {
                  formik.setFieldValue("compensation", newValue, false);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}
            // md={6}
            >
              <CurrencyFormat
                name="compensationAmount"
                label="INR"
                customInput = {FormField}
                thousandSeparator = {true}
                thousandSpacing = '2s'
                prefix="₹"
                {...formik.getFieldProps("compensationAmount")}
              />
            </Grid>
            <Grid item xs={2} md={4}>
             {value && <Typography variant="button" size="small">{formatter.format(value)}</Typography>} 
            </Grid>
          </Grid>
          <MDBox pt={1}>
            <MDButton variant="gradient" size="small" color="success" type="submit">
              SAVE
            </MDButton>
            {/* <MDButton variant="gradient" size="small" color="info" sx={{ ml: '8px' }}>EDIT</MDButton> */}
          </MDBox>
        </MDBox>
      </form>
    </Card>
  );
};

export default CurrentEngagement;
