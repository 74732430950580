import { useContext, useEffect, useState } from "react";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
} from "@mui/material";

//icons
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

//user context
import { UserContext } from "context/UserProvider";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";

//form
import { useFormik } from "formik";
import * as Yup from "yup";
import api from "services/api";
import { SnackbarContext } from "context/SnackbarContext";
import moment from 'moment';
import Skills from "../Skills";
const initialValues = {
  firstName: "",
  lastName: "",
  // returningMom: "",
  // physicallyChallenged: "",
  // retiredBureaucrats: "",
  // defensePersonnel: "",
  gender: "",
  birthDate: "",
  email: "",
  // yourLocation: "",
  // preferredLocation: "",
  phoneNumber: "",
  experienceYears:"",
  // whatsappNumber: "",
  // languages: [""],
  // panNumber: "",
  // aadhaarNumber: "",
  // passport: "",
  // passportValidDate: "",
  // gstIn: "",
  industry: "",
  secondaryIndustry: [""],
  domain: "",
  primarySkills: "",
  secondarySkills: "",
  otherSkills: "",
}
// const phoneRegExp =
//   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const regExp =/^[0-9.]+$/

const validationSchema = Yup.object({
  firstName: Yup.string()
    .max(25, "Maximum 25 characters")
    .required("firstName is required"),
  lastName: Yup.string()
    .max(25, "Maximum 25 characters")
    .required("lastName is required"),
  email: Yup.string().email().required("email is required"),
  phoneNumber: Yup.string()
    // .matches(phoneRegExp)
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .required("Phone Number is required"),
  experienceYears:Yup.string().required("Experience is required"),
  // birthDate: Yup.string().required("birthDate is required"),
  // yourLocation: Yup.string().required("yourLocation is required"),
  // preferredLocation: Yup.string().required("preferredLocation is required"),
  gender: Yup.string().required("gender is required"),
  // whatsappNumber: Yup.string()
  //   .required("WhatsApp number is required")
  //   .min(10, "WhatsApp number is not valid")
  //   .max(10, "WhatsApp number is not valid"),
  // panNumber: Yup.string()
  // .max(10, "Maximum 10 characters")
  // .min(10, "min 10 characters"),
  // aadhaarNumber: Yup.number()
  //   .min(100000000000, 'Must be exactly 12 characters')
  //   .max(999999999999, 'Must be exactly 12 characters'),
  // passport: Yup.string()
  //   .max(12, "Passport Number cannot exceed 12 characters"),
  // gstIn: Yup.string()
  //   .max(15, "GST number cannot exceed 15 characters"),
  industry: Yup.string().nullable(),
  domain: Yup.string().nullable(),
  primarySkills: Yup.string().nullable()
});

function BasicInfo({ userData, updateUserData, domainData }) {
  let arr = selectData.cities;
  let city = arr.map((a) => a.name);
  const { setIsAuthenticated, setUserInfo } = useContext(UserContext);
  const { userInfo } = useContext(UserContext);
  const { setSnack } = useContext(SnackbarContext);

  const [load, setLoad] = useState(false);
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState({});
  const [edit, setEdit] = useState(false);
  const handleEdit = () => setEdit(!edit);
  const [cat, setCat] = useState(false);
  const handleCat = () => setCat(!cat);
  const [profile, setProfile] = useState(userData);
  const toggleSnackbar = () => setShow(!show);
  const [userLanguages, setUserLanguages] = useState(userData?.languages);

  useEffect(() => {
    let temp = {
      ...userData,
    };

    formik.setValues(temp, false);
    if (userData?.birthDate) {
      formik.setFieldValue("birthDate", moment(temp.birthDate).format("YYYY-MM-DD"), false)
    } else {
      formik.setFieldValue("birthDate", '', false)
    }
    if (userData.passportValidDate) {
      formik.setFieldValue("passportValidDate", moment(temp.passportValidDate).format("YYYY-MM-DD"), false)
    } else {
      formik.setFieldValue("passportValidDate", '', false)
    }
    if (userData.experienceYears) {
      formik.setFieldValue("experienceYears", (temp.experienceYears) ,false)
    } else {
      formik.setFieldValue("experienceYears", '', false)
    }
    formik.setFieldValue("yourLocation", userData?.yourLocation, false);
    formik.setFieldValue("languages", userData?.languages, false);
    setUserLanguages(userData?.languages);
    if (
      userData?.returningMom ||
      userData?.physicallyChallenged ||
      userData?.retiredBureaucrats ||
      userData?.defensePersonnel
    ) {
      setCat(true);
      setChecked({
        returningMom: userData?.returningMom,
        physicallyChallenged: userData?.physicallyChallenged,
        retiredBureaucrats: userData?.retiredBureaucrats,
        defensePersonnel: userData?.defensePersonnel,
      });
    } else if(userData?.firstName){
      setCat(true);
    }
  }, [userData]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        let id = ""
        if(userInfo.type == "admin"){
          id = userData?.userId
        }else{
          id = userInfo?.id
        }
        console.log("user info update",values, id);
        updateUserData(values);
        const result = await api.userInfo.update(id, values);
        if (result) {
          console.log("result", result);
          if (result?.data?.message == "success") {
            setLoad(!load);
            setSnack({
              message: "Profile details updated!",
              open: true,
              type: "success",
            });
          } else if (result?.data?.message == "error") {
            setSnack({
              message: "Error updating details!",
              open: true,
              type: "error",
            });
          }
        } else {
          throw new error("failed to update");
        }
      } catch (error) {
        setSnack({
          message: "Error occurred during update!",
          open: true,
          type: "error",
        });
        console.log("ERROR update: ", { ...error });
      }
    },
    // validate
  });
  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3} sx={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography variant="h5" sx={{ float: "left" }}>
          Basic Info
        </MDTypography>
        {/* <DriveFileRenameOutlineIcon
          sx={{ float: "right", cursor: "pointer" }}
          onClick={() => handleEdit()}
        /> */}
      </MDBox>
      <form onSubmit={formik.handleSubmit} method="post" disabled={edit}>
        <MDBox pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                label="First Name"
                name="firstName"
                textTransform="capitalize"
                inputProps={{ readOnly: true }}
                defaultValue={userData?.firstName}
                {...formik.getFieldProps("firstName")}
                error={formik.touched.firstName && formik.errors.firstName ? true : false}
                success={formik.values.firstName > 0 && !formik.errors.firstName}
                helperText={
                  formik.touched.firstName && formik.errors.firstName
                    ? formik.errors.firstName
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Last Name"
                name="lastName"
                {...formik.getFieldProps("lastName")}
                inputProps={{ readOnly: true }}
                error={formik.touched.lastName && formik.errors.lastName ? true : false}
                helperText={
                  formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Autocomplete
                // defaultValue="Male"
                label="Gender"
                name="gender"
                {...formik.getFieldProps("gender")}
                options={selectData.gender}
                renderInput={(params) => (
                  <FormField {...params} label="Gender" InputLabelProps={{ shrink: true }}
                    error={formik.errors.gender ? true : false}
                    helperText={
                      formik.errors.gender ? "Gender is Required" : ''
                    }
                  />
                )}
                onChange={(event, gender) => {
                  formik.setFieldValue("gender", gender, false);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                label="Birth date"
                placeholder="dd-mmm-yyyy"
                name="birthDate"
                inputProps={{ type: "date" }}
                {...formik.getFieldProps("birthDate")}
                error={formik.touched.birthDate && formik.errors.birthDate ? true : false}
                success={formik.values.birthDate > 0 && !formik.errors.birthDate}
                helperText={
                  formik.touched.birthDate && formik.errors.birthDate
                    ? formik.errors.birthDate
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Email"
                name="email"
                setFieldValue={profile.email}
                inputProps={{ type: "email", readOnly: true }}
                {...formik.getFieldProps("email")}
                error={formik.touched.email && formik.errors.email ? true : false}
                helperText={
                  formik.touched.email && formik.errors.email ? formik.errors.email : null
                }
              />
            </Grid>
            {/* <Grid item xs={12} sm={12}>
              <MDTypography color="dark" variant="h6" fontWeight="bold">
                Special Category
              </MDTypography>
              <FormGroup>                
                {cat && (
                <Stack direction="row" ml={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked.returningMom}
                        name="returningMom"
                        {...formik.getFieldProps("returningMom")}
                        onChange={(e, value) => {
                          setChecked({ ...checked, returningMom: value });
                          formik.setFieldValue("returningMom", value, false);
                        }}
                      />
                    }
                    label={
                      <MDTypography color="text" variant="p" fontWeight="regular">
                        Returning Mom
                      </MDTypography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked.physicallyChallenged}
                        name="physicallyChallenged"
                        {...formik.getFieldProps("physicallyChallenged")}
                        onChange={(e, value) => {
                          setChecked({ ...checked, physicallyChallenged: value });
                          formik.setFieldValue("physicallyChallenged", value, false);
                        }}
                      />
                    }
                    label={
                      <MDTypography color="text" variant="p" fontWeight="regular">
                        Specially Abled
                      </MDTypography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked.retiredBureaucrats}
                        name="retiredBureaucrats"
                        {...formik.getFieldProps("retiredBureaucrats")}
                        onChange={(e, value) => {
                          setChecked({ ...checked, retiredBureaucrats: value });
                          formik.setFieldValue("retiredBureaucrats", value, false);
                        }}
                      />
                    }
                    label={
                      <MDTypography color="text" variant="p" fontWeight="regular">
                        Retired Bureaucrat
                      </MDTypography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked.defensePersonnel}
                        name="defensePersonnel"
                        {...formik.getFieldProps("defensePersonnel")}
                        onChange={(e, value) => {
                          setChecked({ ...checked, defensePersonnel: value });
                          formik.setFieldValue("defensePersonnel", value, false);
                        }}
                      />
                    }
                    label={
                      <MDTypography color="text" variant="p" fontWeight="regular">
                        Defence Personnel
                      </MDTypography>
                    }
                  />
                </Stack>
                )}
              </FormGroup>
            </Grid> */}
            {/* <Grid item xs={12} sm={6}>
              <Autocomplete
                name="yourLocation"
                options={city}
                {...formik.getFieldProps("yourLocation")}
                renderInput={(params) => (
                  <FormField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label="Current Location"
                    error={formik.touched.yourLocation && formik.errors.yourLocation ? true : false}
                    success={formik.values.yourLocation > 0 && !formik.errors.yourLocation}
                    helperText={
                      formik.touched.yourLocation && formik.errors.yourLocation
                        ? formik.errors.yourLocation
                        : null
                    }
                  />
                )}
                onChange={(event, city) => {
                  // console.log("city change", city);
                  formik.setFieldValue("yourLocation", city, false);
                }}
              />
            </Grid> */}
            {/* <Grid item xs={12} sm={6}>
              <Autocomplete
                name="preferredLocation"
                {...formik.getFieldProps("preferredLocation")}
                options={city}
                renderInput={(params) => (
                  <FormField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label="Preferred Location"
                    error={formik.touched.preferredLocation && formik.errors.preferredLocation ? true : false}
                    success={formik.values.preferredLocation > 0 && !formik.errors.preferredLocation}
                    helperText={
                      formik.touched.preferredLocation && formik.errors.preferredLocation
                        ? formik.errors.preferredLocation
                        : null
                    }
                  />
                )}
                onChange={(event, city) => {
                  formik.setFieldValue("preferredLocation", city, false);
                }}
              />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <FormField
                // disabled={userData?.phoneNumber ? true : false}
                label="Phone Number"
                placeholder="+91 "
                name="phoneNumber"
                inputProps={{ type: "number", readOnly: userData?.phoneNumber ? true : false }}
                {...formik.getFieldProps("phoneNumber")}
                error={formik.errors.phoneNumber ? true : false}
                success={formik.values.phoneNumber > 0 && !formik.errors.phoneNumber}
                helperText={
                  formik.errors.phoneNumber
                    ? formik.errors.phoneNumber
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
              label = "Relavent Experience"
              variant="standard"
              placeholder="e.g; Years.Month"
              name="experienceYears"
              onKeyPress = {(event) => {
                if(!regExp.test(event.key))
                event.preventDefault();
              }}
              {...formik.getFieldProps("experienceYears")}
              error={formik.touched.experienceYears && formik.errors.experienceYears?true:false}
              success={formik.values.experienceYears>0 && !formik.errors.experienceYears}
              helperText={formik.touched.experienceYears && formik.errors.experienceYears?formik.errors.experienceYears:null}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <FormField
                label="WhatsApp Number"
                placeholder=" "
                name="whatsappNumber"
                inputProps={{ type: "number" }}
                {...formik.getFieldProps("whatsappNumber")}
                error={formik.errors.whatsappNumber ? true : false}
                success={formik.values.whatsappNumber > 0 && !formik.errors.whatsappNumber}
                helperText={
                  formik.errors.whatsappNumber
                    ? formik.errors.whatsappNumber
                    : null
                }
              />
            </Grid>
            {userData?.languages && (
              <Grid item xs={12} md={6}>
                <Autocomplete
                  multiple
                  name="languages"
                  options={selectData.languages}
                  defaultValue={userData?.languages}
                  // value={userData?.languages}
                  renderInput={(params, index) => (
                    <FormField {...params} key={index} InputLabelProps={{ shrink: true }} label="Languages" />
                  )}
                  // {...formik.getFieldProps("languages")}
                  onChange={(event, language) => {
                    setUserLanguages(language);
                    formik.setFieldValue("languages", language, false);
                  }}
                />
              </Grid>
            )}
            {!userData?.languages && (
              <Grid item xs={12} md={6}>
                <Autocomplete
                  multiple
                  name="languages"
                  options={selectData.languages}
                  defaultValue={userData?.languages}
                  // value={userData?.languages}
                  renderInput={(params) => (
                    <FormField {...params} InputLabelProps={{ shrink: true }} label="Languages" />
                  )}
                  // {...formik.getFieldProps("languages")}
                  onChange={(event, language) => {
                    setUserLanguages(language);
                    formik.setFieldValue("languages", language, false);
                  }}
                />
              </Grid>
            )} */}
            {/* <Grid item xs={12} md={6}>
              <FormField
                label="PAN"
                placeholder="Pan number"
                name="panNumber"
                inputProps={{ type: "text" }}
                {...formik.getFieldProps("panNumber")}
                error={formik.touched.panNumber && formik.errors.panNumber ? true : false}
                helperText={
                  formik.touched.panNumber && formik.errors.panNumber
                    ? formik.errors.panNumber
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                label="Aadhaar Number"
                name="aadhaarNumber"
                placeholder="Aadhar number"
                // defaultValue={profile?.aadhaarNumber || ''}
                inputProps={{ type: "number" }}
                {...formik.getFieldProps("aadhaarNumber")}
                error={formik.touched.aadhaarNumber && formik.errors.aadhaarNumber ? true : false}
                success={formik.values.aadhaarNumber > 0 && !formik.errors.aadhaarNumber ? true : false}
                helperText={
                  formik.touched.aadhaarNumber && formik.errors.aadhaarNumber
                    ? formik.errors.aadhaarNumberNumber
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                label="Passport"
                name="passport"
                placeholder="Passport number"
                inputProps={{ type: "text" }}
                {...formik.getFieldProps("passport")}
                error={formik.touched.passport && formik.errors.passport ? true : false}
                helperText={
                  formik.touched.passport && formik.errors.passport ? formik.errors.passport : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                label="Passport valid till"
                placeholder="29-05-1995"
                name="passportValidDate"
                inputProps={{ type: "date" }}
                defaultValue=""
                {...formik.getFieldProps("passportValidDate")}
                error={
                  formik.touched.passportValidDate && formik.errors.passportValidDate ? true : false
                }
                helperText={
                  formik.touched.passportValidDate && formik.errors.passportValidDate
                    ? formik.errors.passportValidDate
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormField
                label="GST IN"
                placeholder="GST IN"
                name="gstIn"
                inputProps={{ type: "text" }}
                value={initialValues.gstIn || ""}
                {...formik.getFieldProps("gstIn")}
                error={formik.touched.gstIn && formik.errors.gstIn ? true : false}
                helperText={
                  formik.touched.gstIn && formik.errors.gstIn ? formik.errors.gstIn : null
                }
              />
            </Grid> */}
          </Grid>
          <Skills userData={userData} domainData={domainData} updateUserData={updateUserData} formik={formik} />
          {(formik.errors?.phoneNumber) && 
            <MDTypography variant="button" fontWeight="medium" color="error">
              Please fill the Required fields
            </MDTypography>
          }
          <MDBox pt={1}>
            <MDButton variant="gradient" size="small" color="success" type="submit">
              SAVE
            </MDButton>
          </MDBox>
        </MDBox>
      </form>
    </Card>
  );
}

export default BasicInfo;
