import axios from "axios";
import data from "layouts/applications/kanban/data";
import setupInterceptorsTo from "./AxiosInterceptor";

setupInterceptorsTo(axios);

const BASE_URL =
  process.env.REACT_APP_STAGE == "Dev"
    ? process.env.REACT_APP_DEV_URL
    : process.env.REACT_APP_STAGE == "Beta"
      ? process.env.REACT_APP_SERVER_URL
      : process.env.REACT_APP_STAGE == "App"
        ? process.env.REACT_APP_APP_URL
        : process.env.REACT_APP_PROD_URL;
const api = {
  users: {
    signIn: (data) => {
      return axios.post(`${BASE_URL}/api/users/login`, data);
    },
    signOut: () => {
      return axios.get(`${BASE_URL}/api/users/sign-out`);
    },
    registerUser: (data) => {
      return axios.post(`${BASE_URL}/api/users/`, data);
    },
    verifySession: () => {
      return axios.get("/api/users/verify-session");
    },
    updatePassword: (data) => {
      return axios.post(`${BASE_URL}/api/users/update-password`, data);
    },
    changePassword: (data) => {
      return axios.post(`${BASE_URL}/api/users/change-password`, data);
    },
    setPassword: (data) => {
      return axios.post(`${BASE_URL}/api/users/set-password`, data);
    },
    getDepartments: (signal) => {
      return axios.get("api/users/departments", { signal });
    },
    getUsersByPermissions: (signal) => {
      return axios.get("/api/users/usersByPermissions", { signal });
    },
    registerGoogle: (data) => {
      return axios.post(`${BASE_URL}/api/users/registerGoogle`, data);
    },
    registerLinkedIn: (data) => {
      return axios.post(`${BASE_URL}/api/users/registerLinkedIn`, data);
    },
    updateUser: (uid, data) => {
      return axios.put(`${BASE_URL}/api/users/updateUser/${uid}`, data);
    },
    findOneUser: (data) => {
      return axios.post(`${BASE_URL}/api/users/findOne`, data);
    },
    getSubAdmin: (data) => {
      return axios.get(`${BASE_URL}/api/users/get-subadmins`, data);
    },
    communityPost: (data) => {
      return axios.post(`${BASE_URL}/api/communityPost/create`, data);
    },
    findAllCommunityPost: (data) => {
      return axios.post(`${BASE_URL}/api/communityPost/findAll`, data);
    }
  },
  userInfo: {
    create: (data) => {
      return axios.post(`${BASE_URL}/api/userInfo/create`, data);
    },
    update: (id, data) => {
      return axios.put(`${BASE_URL}/api/userInfo/${id}`, data);
    },
    findAll: (data) => {
      return axios.get(`${BASE_URL}/api/userInfo/`, data);
    },
    findOne: (id) => {
      return axios.get(`${BASE_URL}/api/userInfo/findOne/${id}`);
    },
    find: (params) => {
      return axios.get(`${BASE_URL}/api/userInfo/find`, { params });
    },
    updateMany: (data) => {
      return axios.post(`${BASE_URL}/api/userInfo/updateMany`, data);
    },
    getEngagements: (id) => {
      return axios.get(`${BASE_URL}/api/userInfo/engagements/${id}`);
    },
    search: (data) => {
      return axios.post(`${BASE_URL}/api/userInfo/search`, data);
    },
    selectedEngagements: (id, params) => {
      return axios.get(`${BASE_URL}/api/engagementProcess/getEngagements/${id}`, { params: params })
    },
    adminEngagements: (params) => {
      return axios.get(`${BASE_URL}/api/engagementProcess/getAdminEngagements`, { params: params })
    },
    getFeedbackEngagement: (data) => {
      return axios.post(`${BASE_URL}/api/userInfo/getFeedbackEngagement`, data)
    },
    NumberOfAppliedEngagements: (data) => {
      return axios.post(`${BASE_URL}/api/userInfo/NumberOfAppliedEngagements`, data)
    },
    NumberOfShortlistedEngagements: (data) => {
      return axios.post(`${BASE_URL}/api/userInfo/NumberOfShortlistedEngagements`, data)
    },
    NumberOfSelectedEngagements: (data) => {
      return axios.post(`${BASE_URL}/api/userInfo/NumberOfSelectedEngagements`, data)
    },
    NumberOfCompetitors: (data) => {
      return axios.post(`${BASE_URL}/api/userInfo/NumberOfCompetitors`, data);
    },
    NumberOfMatchingEngagements: (data) => {
      return axios.post(`${BASE_URL}/api/userInfo/NumberOfMatchingEngagements`, data);
    },
    SendMettlLink: (id, data) => {
      return axios.post(`${BASE_URL}/api/userInfo/SendMettlLink/${id}`, data);
    }
  },
  userUploads: {
    avatarUpdate: (data) => {
      return axios.post(`${BASE_URL}/api/userUploads/avatar`, data);
    },
    resumeUpdate: (data) => {
      return axios.post(`${BASE_URL}/api/userUploads/resume`, data);
    },
    videoUpdate: (data) => {
      return axios.post(`${BASE_URL}/api/userUploads/video`, data);
    },
    assessorAvatar: (data) => {
      return axios.post(`${BASE_URL}/api/userUploads/assessorAvatar`, data);
    },
    assessorVideo: (data) => {
      return axios.post(`${BASE_URL}/api/userUploads/assessorVideo`, data);
    },
    engagerAvatar: (data) => {
      return axios.post(`${BASE_URL}/api/userUploads/engagerAvatar`, data);
    },
    gigTalentReport: (data) => {
      return axios.post(`${BASE_URL}/api/userUploads/gigTalentReport`, data);
    }
  },
  contract: {
    contractUpdate: (data) => {
      return axios.post(`${BASE_URL}/api/contract`, data);
    },
    signContract: (data) => {
      return axios.post(`${BASE_URL}/api/contract/update`, data);
    },
    startContract: (data) => {
      return axios.post(`${BASE_URL}/api/contract/start-contract`, data);
    },
    endContract: (data) => {
      return axios.post(`${BASE_URL}/api/contract/close-contract`, data);
    },
    getContractTemplates: () => {
      return axios.get(`${BASE_URL}/api/contractTemplate`);
    },
    getDefaultTemlate: () => {
      return axios.get(`${BASE_URL}/api/contractTemplate/find-one`);
    },
    CreateContractTemplates: (data) => {
      return axios.post(`${BASE_URL}/api/contractTemplate`, data);
    },
    findOne: (params) => {
      return axios.get(`${BASE_URL}/api/contract/findOne`, { params: params });
    },
    findAll: (params) =>{
      return axios.get(`${BASE_URL}/api/contract/findAll`,{params:params})
    }
  },
  assigned: {
    create: (data) => {
      return axios.post(`${BASE_URL}/api/assigned/create`, data);
    },
    findAll: (id, data) => {
      return axios.post(`${BASE_URL}/api/assigned/findAll/${id}`, data);
    },
    update: (id, data) => {
      return axios.put(`${BASE_URL}/api/assigned/update/${id}`, data);
    },
    delete: (data) => {
      return axios.post(`${BASE_URL}/api/assigned/delete`, data);
    },
  },
  domain: {
    findAll: (data) => {
      return axios.get(`${BASE_URL}/api/domain/`, data);
    },
    create: (data) => {
      return axios.post(`${BASE_URL}/api/domain/create`, data);
    },
    updateOne: (id, data) => {
      return axios.put(`${BASE_URL}/api/domain/updateOne/${id}`, data);
    },
    updateMany: (data) => {
      return axios.put(`${BASE_URL}/api/domain/updateMany/`, data);
    },
  },
  industry: {
    findAll: (data) => {
      return axios.get(`${BASE_URL}/api/industry/`, data);
    },
    create: (data) => {
      return axios.post(`${BASE_URL}/api/industry/create`, data);
    },
    updateOne: (id, data) => {
      return axios.put(`${BASE_URL}/api/industry/updateOne/${id}`, data);
    },
  },
  engager: {
    create: (data) => {
      return axios.post(`${BASE_URL}/api/engager/create`, data);
    },
    findAll: (params) => {
      return axios.get(`${BASE_URL}/api/engager/`, { params: params });
    },
    findAllSubEmployer: (id) => {
      return axios.get(`${BASE_URL}/api/engager/subEmployer/${id}`);
    },
    findOne: (id) => {
      return axios.get(`${BASE_URL}/api/engager/findOne/${id}`);
    },
    update: (id, data) => {
      return axios.put(`${BASE_URL}/api/engager/update/${id}`, data);
    },
    updateEngagmenetPosting: (id, data) => {
      return axios.put(`${BASE_URL}/api/engager/updateEngagmenetPosting/${id}`, data);
    },
    getFeedbackEngagement: (data) => {
      return axios.post(`${BASE_URL}/api/engager/getFeedbackEngagement`, data);
    },

  },
  engagement: {
    create: (data) => {
      return axios.post(`${BASE_URL}/api/engagement/create`, data);
    },
    update: (data) => {
      return axios.put(`${BASE_URL}/api/engagement/update`, data);
    },
    updateTemplate: (data) => {
      return axios.put(`${BASE_URL}/api/engagement/update-template`, data);
    },
    findAll: (data) => {
      return axios.get(`${BASE_URL}/api/engagement/`, data);
    },
    findByDomain: (data) => {
      return axios.post(`${BASE_URL}/api/engagement/domain`, data);
    },
    findByEngId: (id, params) => {
      return axios.get(`${BASE_URL}/api/engagement/${id}`, { params: params });
    },
    findByEngagementId: (id) => {
      return axios.get(`${BASE_URL}/api/engagement/findByEngagementId/${id}`);
    },
    findEngagementMatch: (data) => {
      return axios.post(`${BASE_URL}/api/engagement/findEngagementMatch`, data);
    },
    getCountByEngagerId: (id) => {
      return axios.get(`${BASE_URL}/api/engagement/getCountByEngagerId/${id}`);
    },
    search: (params) => {
      return axios.get(`${BASE_URL}/api/engagement/searchEng/`, { params: params });
    },
    engagementDescUpload: (data) => {
      return axios.post(`${BASE_URL}/api/userUploads/engagementDescUpload`, data);
    },
    delete: (data) => {
      return axios.delete(`${BASE_URL}/api/engagement/delete`, data);
    },
    updateEngagement: (uid, data) => {
      return axios.put(`${BASE_URL}/api/engagement/update/${uid}`, data);
    }
  },
  assessor: {
    create: (data) => {
      return axios.post(`${BASE_URL}/api/assessor/create`, data);
    },
    findAll: (data) => {
      return axios.get(`${BASE_URL}/api/assessor/`, data);
    },
    findOne: (id) => {
      return axios.get(`${BASE_URL}/api/assessor/findOne/${id}`);
    },
    update: (id, data) => {
      return axios.put(`${BASE_URL}/api/assessor/update/${id}`, data);
    },
    search: (params) => {
      return axios.get(`${BASE_URL}/api/assessor/search`, { params: params })
    }
  },
  verifications: {
    validate: (data) => {
      return axios.post(`${BASE_URL}/api/verify/validate`, data);
    },
    smsValidate: (data) => {
      return axios.post(`${BASE_URL}/api/verify/validate-sms`, data);
    },
    verify: (data) => {
      return axios.post(`${BASE_URL}/api/verify`, data);
    },
    sendOtp: (data) => {
      return axios.post(`${BASE_URL}/api/verify/send-otp`, data);
    },
    sendSMSOtp: (data) => {
      return axios.post(`${BASE_URL}/api/verify/send-sms`, data);
    }
  },
  engagementProcess: {
    create: (data) => {
      return axios.post(`${BASE_URL}/api/engagementProcess/`, data);
    },
    findAll: (id, params) => {
      return axios.get(`${BASE_URL}/api/engagementProcess/findAll/${id}`, { params: params });
    },
    updateManyApplied: (id, data) => {
      return axios.put(`${BASE_URL}/api/engagementProcess/updateManyApplied/${id}`, data);
    },
    findAllShortlisted: (id) => {
      return axios.get(`${BASE_URL}/api/engagementProcess/findAllShortlisted/${id}`);
    },
    updateManyShortlisted: (id, data) => {
      return axios.put(`${BASE_URL}/api/engagementProcess/updateManyShortlisted/${id}`, data);
    },
    findAllSelected: (id) => {
      return axios.get(`${BASE_URL}/api/engagementProcess/findAllSelected/${id}`);
    },
    updateOne: (id, data) => {
      return axios.put(`${BASE_URL}/api/engagementProcess/updateOne/${id}`, data);
    },

  },
  plan: {
    findAll: (data) => {
      return axios.get(`${BASE_URL}/api/plan/`, data);
    },
    findOne: (id) => {
      return axios.get(`${BASE_URL}/api/plan/${id}`);
    }
  },
  giglerFeedback: {
    create: (data) => {
      return axios.post(`${BASE_URL}/api/giglerFeedback/create`, data)
    },
    findByUserId: (params) => {
      return axios.get(`${BASE_URL}/api/giglerFeedback/find/`, { params: params })
    }
  },
  engagerFeedback: {
    create: (data) => {
      return axios.post(`${BASE_URL}/api/engagerFeedback/create`, data)
    },
    findByUserId: (params) => {
      return axios.get(`${BASE_URL}/api/engagerFeedback/find/`, { params: params })
    }
  },
  recentSearches: {
    getRecentSearches: () => {
      return axios.get("/api/recent-searches");
    },
    getQuickViews: (toggledQuickViews) => {
      return axios.get("/api/recent-searches/quick-views", {
        toggledQuickViews,
      });
    },
    addQuickView: (id) => {
      return axios.get(`/ api / recent - searches / add - quick - view / ${id}`);
    },
    createRecentSearch: (value) => {
      return axios.post("/api/recent-searches", { value });
    },
    deleteRecentSearch: (id) => {
      return axios.delete(`/ api / recent - searches / ${id}`);
    },
  },
  Reports: {
    userReport: (value) => {
      return axios.get(`/ api / userReport / ${value}`);
    },
  },
qrcode:{
    create: (planId) => {
      return axios.post(`${BASE_URL}/api/payment/getqr`, planId);
    },
  },
};

export default api;
