import { Card, Modal } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { UserContext } from 'context/UserProvider';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import GiglerFeedbackForm from 'layouts/pages/common/GiglerFeedbackForm';
import React, { useContext, useEffect, useState } from 'react'
import api from 'services/api';
import DataTable from './DataTable'
import { useStyles } from './styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


const CompletedEngagements = () => {
  const css = useStyles();
  const { userInfo } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [feedbackDetails, setFeedbackDetails] = useState()
  const handleClose = () => {
    setOpen(false);
  }

  function sliceText(Title,maxLength){
    if(Title.length <= maxLength){
      return Title;
    }else{
      return Title.slice(0,maxLength-3) + '...';
    }
  }
  const [details, setDetails] = useState({
    columns: [
      // { Header: "Engagement Id", accessor: "feedbackEngagements.engagementId", width: "12%" },
      { Header: "Title", accessor:"feedbackEngagements.engagementTitle", width: "20%",  Cell: ({ row }) => (
        <Tooltip title={row.original.feedbackEngagements.engagementTitle} placement='top'>
          {sliceText(row.original.feedbackEngagements.engagementTitle, 10)}
        </Tooltip>),},
      { Header: "Organization", accessor: "feedbackEngagements.organizationName", width: "20%" },
      { Header: "Engager Name", accessor: "feedbackEngagements.engagerName", width: "15%" },
      { Header: "Industry", accessor: "feedbackEngagements.industry", width: "15%" },
      { Header: "Domain", accessor: "feedbackEngagements.domain", width: "15%" },
    ],
    rows: []
  })

  const handleClosedEngagements = () => {
    let id = userInfo.id
    api.userInfo.getFeedbackEngagement({ id: userInfo.id })
      .then((data) => {
        console.log("...................", data)
        if (data?.data?.result?.data == '0') {
          setDetails("zero");
        } else {
          let temp = data?.data?.result?.data
          setDetails({ ...details, rows: temp });
        }
      })
  }
  useEffect(() => {
    handleClosedEngagements()
  }, [])
  const handleClick = (row) => {
    setOpen(true)
    // let data = {
    //   engagementId: row.original.engagementId,
    //   engagerId: row.original.engagerId,
    //   giglerId: row.original.giglerId

    // }
    console.log("feedback details", row.original)
    setFeedbackDetails(row.original);
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {details == "zero" ? (
        <Card sx={{ width: "160vh", height: "75vh", p: "10px" }}>
          <MDTypography variant="h6" color="dark" fontWeight="medium" >Engagements are still open</MDTypography>
        </Card>
      ) : (
        <DataTable table={details} handleClick={handleClick} />
      )}
      <Modal
        open={open}
        onClose={handleClose}
      >
        <MDBox className={css.modal}>
          <GiglerFeedbackForm feedbackDetails={feedbackDetails} handleClose={handleClose} handleClosedEngagements={handleClosedEngagements} />
        </MDBox>
      </Modal>
    </DashboardLayout>
  )
}

export default CompletedEngagements