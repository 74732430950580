import { Card, Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useContext, useEffect, useRef, useState } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import FormField from "layouts/pages/account/components/FormField";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useStyles } from './styles'

//formik
import { useFormik } from "formik";
import { UserContext } from "context/UserProvider";
import { SnackbarContext } from "context/SnackbarContext";
import api from "services/api";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

//icon
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
function AssessmentForm({ details, setOpen, handleChange, handleApi }) {
  const { userInfo } = useContext(UserContext);
  const { setSnack } = useContext(SnackbarContext);
  const [opt, setOpt] = useState([{
    techOne: ["0"],
    techTwo: ["0"],
    techThree: ["0"],
    techFour: ["0"],
    techFive: ["0"],
    behOne: ["0"],
    behTwo: ["0"],
    behThree: ["0"],
    behFour: ["0"],
    behFive: ["0"],
  }
  ])
  let navigate = useNavigate();
  const ref = useRef(null);
  const classes = useStyles();
  const initialValues = {
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    q5: "",
    t1: '',
    t2: '',
    t3: '',
    t4: '',
    t5: '',
    bq1: "",
    bq2: "",
    bq3: "",
    bq4: "",
    bq5: "",
    b1: '',
    b2: '',
    b3: '',
    b4: '',
    b5: '',
    avgTechnical: "",
    avgBehavioral: "",
    // technical: '',
    // behavioral: '',
    qualitativeComment: "",
    total: '',
    assessed: true
  }

  const validationSchema = Yup.object({
    q1: Yup.string().required("Required"),
    q2: Yup.string().required("Required"),
    q3: Yup.string().required("Required"),
    q4: Yup.string().required("Required"),
    q5: Yup.string().required("Required"),
    bq1: Yup.string().required("Required"),
    bq2: Yup.string().required("Required"),
    bq3: Yup.string().required("Required"),
    bq4: Yup.string().required("Required"),
    bq5: Yup.string().required("Required"),
    t1: Yup.string().required("Required"),
    t2: Yup.string().required("Required"),
    t3: Yup.string().required("Required"),
    t4: Yup.string().required("Required"),
    t5: Yup.string().required("Required"),
    b1: Yup.string().required("Required"),
    b2: Yup.string().required("Required"),
    b3: Yup.string().required("Required"),
    b4: Yup.string().required("Required"),
    b5: Yup.string().required("Required"),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log("initial values in assessment form", values)
      try {
        let technical = [];
        let behavioral = [];
        const id = details?.userId;
        technical.push({ [values.q2]: values.t2 })
        technical.push({ [values.q3]: values.t3 })
        technical.push({ [values.q1]: values.t1 })
        technical.push({ [values.q4]: values.t4 })
        technical.push({ [values.q5]: values.t5 })
        behavioral.push({ [values.bq1]: values.b1 })
        behavioral.push({ [values.bq2]: values.b2 })
        behavioral.push({ [values.bq3]: values.b3 })
        behavioral.push({ [values.bq4]: values.b4 })
        behavioral.push({ [values.bq5]: values.b5 })
        values.technical = technical;
        values.behavioral = behavioral;
        const result = await api.assigned.update(id, values);
    
        if (result?.data.message == "success") {
          setSnack({
            message: `${details.firstName + `` + details.lastName} is assessed!`,
            open: true,
            type: 'success',
          });
          handleChange();
          setOpen("1");
          handleApi();
          navigate(`/assessor/assigned-gigTalent`);
        }
        else {
          throw new error("failed to update")
        }
      } catch (error) {
        setSnack({
          message: 'Error occurred during Assessment!',
          open: true,
          type: 'error',
        });
        console.log("ERROR update: ", { ...error });
      }
    },
  });

  useEffect(() => {
    let t = [];
    if (formik.values.t1) t.push(parseInt(formik.values.t1));
    if (formik.values.t2) t.push(parseInt(formik.values.t2));
    if (formik.values.t3) t.push(parseInt(formik.values.t3));
    if (formik.values.t4) t.push(parseInt(formik.values.t4));
    if (formik.values.t4) t.push(parseInt(formik.values.t5));
    const averageOfTechnical = t.reduce((a, b) => a + b, 0) / t.length
    // const t = (((parseInt(formik.values.t1) || 0) + (parseInt(formik.values.t2) || 0) + (parseInt(formik.values.t3) || 0) + (parseInt(formik.values.t4) || 0) + (parseInt(formik.values.t5) || 0)) / 5)
    const b = (((parseInt(formik.values.b1) || 0) + (parseInt(formik.values.b2) || 0) + (parseInt(formik.values.b3) || 0) + ((parseInt(formik.values.b4) || 0)) + ((parseInt(formik.values.b5) || 0))) / 5)
    const total = (((60 / 100) * averageOfTechnical) + ((40 / 100) * b));
    formik.setFieldValue("avgTechnical", averageOfTechnical || 0, false);
    formik.setFieldValue("avgBehavioral", b, false);
    formik.setFieldValue("total", total || 0, false);
    console.log("total of technical", averageOfTechnical);
    console.log("total of behavioral", b);

  }, [formik.values.t1, formik.values.t2, formik.values.t3, formik.values.t4, formik.values.t5, formik.values.b1, formik.values.b2, formik.values.b3, formik.values.b4, formik.values.b5])

  //function for choosing technical and behavioral values
  const handleValues = (index, name) => {
    if (index == 0) {
      setOpt({ ...opt, [name]: ["1", "2", "3"] })
    } else if (index == 1) {
      setOpt({ ...opt, [name]: ["4", "5", "6", "7"] })
    } else {
      setOpt({ ...opt, [name]: ["8", "9", "10"] })
    }
  }

  useEffect(() => {
    const percentage = ((formik.values.total) * 10)
    formik.setFieldValue("percentage", percentage, false)
  }, [formik.values.total])
  return (
    <form onSubmit={formik.handleSubmit} method="post" innerRef={ref}>
      <Card mt={2}>
        <MDBox pr={2}>
          <Grid container spacing={1}>
            <Grid item xs={1} xl={1} md={1}>
              <MDBox p={3}>
                <ArrowBackIcon onClick={() => setOpen("2")} sx={{ cursor: "pointer" }} />
              </MDBox>
            </Grid>
            <Grid align="center" item xs={11} sm={11} xl={11} >
              <MDTypography variant="h4" color="info" fontWeight="medium" textTransform="capitalize" sx={{ py: "30px"}}>
                Assessment Form
              </MDTypography>
              <MDTypography variant="h5" color="warning" fontWeight="medium" textTransform="capitalize" align="left" sx={{ mb: "20px"}}>
                GigTalent : <MDTypography color="dark" variant="body">{details?.domain}</MDTypography>
              </MDTypography>
              <MDTypography variant="h5" color="warning" fontWeight="medium" textTransform="capitalize" align="left" sx={{ mb: "20px" }}>
                GigTalent : <MDTypography color="dark" variant="body">{details?.firstName + " " + details?.lastName}</MDTypography>
              </MDTypography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item align="center" xs={1} md={1} xl={1}>
              <MDTypography variant="h6">
                {""}
              </MDTypography>
              <MDTypography variant="h6" color="warning" sx={{ mt: "30px", py: "20px" }}>
                T01
              </MDTypography>
              <MDTypography variant="h6" color="warning" sx={{ py: "20px" }}>
                T02
              </MDTypography>
              <MDTypography variant="h6" color="warning" sx={{ py: "20px" }}>
                T03
              </MDTypography>
              <MDTypography variant="h6" color="warning" sx={{ py: "20px" }}>
                T04
              </MDTypography>
              <MDTypography variant="h6" color="warning" sx={{ pt: "20px" }}>
                T05
              </MDTypography>
            </Grid>
            <Grid align="left" item xs={12} md={6} xl={6}>
              <MDTypography variant="h5" fontWeight="large" textTransform="capitalize" color="warning">
                Technical/Functional Observations
              </MDTypography>
              <MDTypography variant="h6"  sx={{ py: "20px" }}>Possesses requisite KNOWLEDGE to deliver stated expertise</MDTypography>
              <MDTypography variant="h6"  sx={{ py: "20px" }}>Has SKILLS to deliver stated expertise</MDTypography>
              <MDTypography variant="h6"  sx={{ py: "20px" }}>APPLIED HIS/HER KNOWLEDGE AND SKILL to deliver the stated expertise</MDTypography>
              <MDTypography variant="h6"  sx={{ py: "20px" }}>AGILITY of TECHNICAL LEARNING</MDTypography>
              <MDTypography variant="h6"  sx={{ py: "20px" }}>Adherence to PROCESS and QUALITY</MDTypography>

            </Grid>
            <Grid item align="center" xs={12} md={6} xl={4}>
              <MDTypography variant="h5" color="warning" fontWeight="medium" textTransform="capitalize" sx={{ mb: "10px" }}>
                Assessor Feedback
              </MDTypography>
              <Autocomplete
                size="small"
                sx={{ p: "0" }}
                options={["Has basic (minimum) knowledge to deliver", "Has more than basic skills", "Has extensive and deep knowledge on the subject"]}
                onChange={(event, option) => {
                  handleValues(event.target.dataset.optionIndex, "techOne")
                  formik.setFieldValue("q1", option, false);
                }}
                renderInput={(params) =>
                  <TextField {...params} label="T01" name="q1"
                    error={formik.touched.q1 && formik.errors.q1 ? true : false}
                    success={formik.values.q1 > 0 && !formik.errors.q1}
                    helperText={
                      formik.touched.q1 && formik.errors.q1
                        ? formik.errors.q1
                        : null
                    }
                  />
                }
              />&nbsp;
              <Autocomplete
                size="small"
                sx={{ p: "0" }}
                options={["Has delivered basic / simple assignments", "Has delivered advanced projects with guidance", "Has independently created and delivered complex projects"]}
                onChange={(event, option) => {
                  handleValues(event.target.dataset.optionIndex, "techTwo")
                  formik.setFieldValue("q2", option, false);
                }}
                renderInput={(params) =>
                  <TextField {...params} label="T02" name="q2"
                    error={formik.touched.q2 && formik.errors.q2 ? true : false}
                    success={formik.values.q2 > 0 && !formik.errors.q2}
                    helperText={
                      formik.touched.q2 && formik.errors.q2
                        ? formik.errors.q2
                        : null
                    }
                  />
                }
              />&nbsp;
              <Autocomplete
                size="small"
                sx={{ p: "0" }}
                options={["Delivers at a basic level", "Attempts to excel", "Excellent Track Record of delivery"]}
                onChange={(event, option) => {
                  handleValues(event.target.dataset.optionIndex, "techThree")
                  formik.setFieldValue("q3", option, false);
                }}
                renderInput={(params) =>
                  <TextField {...params} label="T03" name="q3"
                    error={formik.touched.q3 && formik.errors.q3 ? true : false}
                    success={formik.values.q3 > 0 && !formik.errors.q3}
                    helperText={
                      formik.touched.q3 && formik.errors.q3
                        ? formik.errors.q3
                        : null
                    }
                  />
                }
              />&nbsp;
              <Autocomplete
                size="small"
                sx={{ p: "0" }}
                options={["Keeps abreast with technical development", "Contributes to professional forum", "Shares and mentors in the technical front"]}
                onChange={(event, option) => {
                  handleValues(event.target.dataset.optionIndex, "techFour")
                  formik.setFieldValue("q4", option, false);
                }}
                renderInput={(params) =>
                  <TextField {...params} label="T04" name="q4"
                    error={formik.touched.q4 && formik.errors.q4 ? true : false}
                    success={formik.values.q4 > 0 && !formik.errors.q4}
                    helperText={
                      formik.touched.q4 && formik.errors.q4
                        ? formik.errors.q4
                        : null
                    }
                  />
                }
              />&nbsp;
              <Autocomplete
                size="small"
                sx={{ p: "0" }}
                options={["Follows SOP", "Challenges the status quo and looks for improvement opportunity", "Implements disruptive measures"]}
                onChange={(event, option) => {
                  handleValues(event.target.dataset.optionIndex, "techFive")
                  formik.setFieldValue("q5", option, false);
                }}
                renderInput={(params) =>
                  <TextField {...params} label="T05" name="q5"
                    error={formik.touched.q5 && formik.errors.q5 ? true : false}
                    success={formik.values.q5 > 0 && !formik.errors.q5}
                    helperText={
                      formik.touched.q5 && formik.errors.q5
                        ? formik.errors.q5
                        : null
                    }
                  />
                }
              />&nbsp;
            </Grid>
            <Grid item align="center" xs={1} md={1} xl={1}>
              <MDTypography variant="h5" fontWeight="medium" color="warning" textTransform="capitalize" sx={{ mb: "10px" }}>
                Score
              </MDTypography>
              <Autocomplete
                size="small"
                sx={{ p: "0" }}
                options={opt.techOne ? opt.techOne : []}
                onChange={(event, option) => {
                  formik.setFieldValue("t1", option, false);
                }}
                renderInput={(params) => <TextField {...params} label="T1" name="t1"
                  error={formik.touched.t1 && formik.errors.t1 ? true : false}
                  success={formik.values.t1 > 0 && !formik.errors.t1}
                  helperText={
                    formik.touched.t1 && formik.errors.t1
                      ? formik.errors.t1
                      : null
                  }
                />
                }
              />&nbsp;
              <Autocomplete
                sx={{ p: "0" }}
                size="small"
                options={opt.techTwo ? opt.techTwo : []}
                onChange={(event, option) => {
                  formik.setFieldValue("t2", option, false);
                }}
                renderInput={(params) => <TextField {...params} label="T2" name="t2"
                  error={formik.touched.t2 && formik.errors.t2 ? true : false}
                  success={formik.values.t2 > 0 && !formik.errors.t2}
                  helperText={
                    formik.touched.t2 && formik.errors.t2
                      ? formik.errors.t2
                      : null
                  }
                />
                }
              />&nbsp;
              <Autocomplete
                size="small"
                options={opt.techThree ? opt.techThree : []}
                onChange={(event, option) => {
                  formik.setFieldValue("t3", option, false);
                }}
                renderInput={(params) => <TextField {...params} label="T3" name="t3"
                  error={formik.touched.t3 && formik.errors.t3 ? true : false}
                  success={formik.values.t3 > 0 && !formik.errors.t3}
                  helperText={
                    formik.touched.t3 && formik.errors.t3
                      ? formik.errors.t3
                      : null
                  } />}
              />&nbsp;
              <Autocomplete
                size="small"
                options={opt.techFour ? opt.techFour : []}
                onChange={(event, option) => {
                  formik.setFieldValue("t4", option, false);
                }}
                renderInput={(params) => <TextField {...params} label="T4" name="t4"
                  error={formik.touched.t2 && formik.errors.t2 ? true : false}
                  success={formik.values.t4 > 0 && !formik.errors.t4}
                  helperText={
                    formik.touched.t4 && formik.errors.t4
                      ? formik.errors.t4
                      : null
                  } />}
              />&nbsp;
              <Autocomplete
                size="small"
                options={opt.techFive ? opt.techFive : []}
                onChange={(event, option) => {
                  formik.setFieldValue("t5", option, false);
                }}
                renderInput={(params) => <TextField {...params} label="T5" name="t5"
                  error={formik.touched.t5 && formik.errors.t5 ? true : false}
                  success={formik.values.t5 > 0 && !formik.errors.t5}
                  helperText={
                    formik.touched.t5 && formik.errors.t5
                      ? formik.errors.t5
                      : null
                  } />}
              />

            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item align="center" xs={1} md={1} xl={1}>
              <MDTypography variant="h6">
                {""}
              </MDTypography>
              <MDTypography variant="h6" color="warning" sx={{ mt: "30px", py: "20px" }}>
                B01
              </MDTypography>
              <MDTypography variant="h6" color="warning" sx={{ py: "20px" }}>
                B02
              </MDTypography>
              <MDTypography variant="h6" color="warning" sx={{ py: "20px" }}>
                B03
              </MDTypography>
              <MDTypography variant="h6" color="warning" sx={{ py: "20px" }}>
                B04
              </MDTypography>
              <MDTypography variant="h6" color="warning" sx={{ py: "20px" }}>
                B05
              </MDTypography>

            </Grid>
            <Grid item align="left" xs={12} md={5} xl={6}>
              <MDTypography  variant="h5" fontWeight="medium" textTransform="capitalize" color="warning">
                Behavioral Observations
              </MDTypography>
              <MDTypography variant="h6" fontWeight="medium" sx={{ py: "20px" }}>Customer Savvy</MDTypography>
              <MDTypography variant="h6" fontWeight="medium" sx={{ py: "20px" }}>Result Orientation & Ownership</MDTypography>
              <MDTypography variant="h6" fontWeight="medium" sx={{ py: "20px" }}>Time optimization</MDTypography>
              <MDTypography variant="h6" fontWeight="medium" sx={{ py: "20px" }}>Problem Solving</MDTypography>
              <MDTypography variant="h6" fontWeight="medium" sx={{ py: "20px" }}>Creativity</MDTypography>
            </Grid>
            <Grid item align="center" xs={6} md={5} xl={4}>
              <Autocomplete
                sx={{ mt: "40px" }}
                size="small"
                options={["Understands Customers Need", "Delivers as per customers expectation", "Builds trust and engagement with stakeholders"]}
                onChange={(event, option) => {
                  handleValues(event.target.dataset.optionIndex, "behOne")
                  formik.setFieldValue("bq1", option, false);
                }}
                renderInput={(params) =>
                  <TextField {...params} label="B01" name="bq1"
                    error={formik.touched.bq1 && formik.errors.bq1 ? true : false}
                    success={formik.values.bq1 > 0 && !formik.errors.bq1}
                    helperText={
                      formik.touched.bq1 && formik.errors.bq1
                        ? formik.errors.bq1
                        : null
                    }
                  />
                }
              />&nbsp;
              <Autocomplete
                size="small"
                sx={{ p: "0" }}
                options={["Meets expectations", "Makes efforts to exceed expectations with passion", "Exceeds expectations with utmost commitment"]}
                onChange={(event, option) => {
                  handleValues(event.target.dataset.optionIndex, "behTwo")
                  formik.setFieldValue("bq2", option, false);
                }}
                renderInput={(params) =>
                  <TextField {...params} label="B02" name="bq2"
                    error={formik.touched.bq2 && formik.errors.b2 ? true : false}
                    success={formik.values.bq2 > 0 && !formik.errors.bq2}
                    helperText={
                      formik.touched.bq2 && formik.errors.bq2
                        ? formik.errors.bq2
                        : null
                    }
                  />
                }
              />&nbsp;
              <Autocomplete
                size="small"
                sx={{ p: "0" }}
                options={["Plans own time according to the client", "Can cut tasks short as required, can deliver on or before time", "Makes time for most planned and unplanned priorities"]}
                onChange={(event, option) => {
                  handleValues(event.target.dataset.optionIndex, "behThree")
                  formik.setFieldValue("bq3", option, false);
                }}
                renderInput={(params) =>
                  <TextField {...params} label="B03" name="bq3"
                    error={formik.touched.bq3 && formik.errors.bq3 ? true : false}
                    success={formik.values.bq3 > 0 && !formik.errors.bq3}
                    helperText={
                      formik.touched.bq3 && formik.errors.bq3
                        ? formik.errors.bq3
                        : null
                    }
                  />
                }
              />&nbsp;
              <Autocomplete
                size="small"
                sx={{ p: "0" }}
                options={["Looks at superficial information, works only with what is supplied", "Analyses available information, can do RCA, suggests way out", "Identifies & takes into account cause-effect relationships with positive mindset"]}
                onChange={(event, option) => {
                  handleValues(event.target.dataset.optionIndex, "behFour")
                  formik.setFieldValue("bq4", option, false);
                }}
                renderInput={(params) =>
                  <TextField {...params} label="B04" name="bq4"
                    error={formik.touched.bq4 && formik.errors.bq4 ? true : false}
                    success={formik.values.bq4 > 0 && !formik.errors.bq4}
                    helperText={
                      formik.touched.bq4 && formik.errors.bq4
                        ? formik.errors.bq4
                        : null
                    }
                  />
                }
              />&nbsp;
              <Autocomplete
                size="small"
                sx={{ p: "0" }}
                options={["Comfortable with the tried and tested solutions", "Balances experience with new approaches", "Can come up with new and alternative approaches"]}
                onChange={(event, option) => {
                  handleValues(event.target.dataset.optionIndex, "behFive")
                  formik.setFieldValue("bq5", option, false);
                }}
                renderInput={(params) =>
                  <TextField {...params} label="B05" name="bq5"
                    error={formik.touched.bq5 && formik.errors.bq5 ? true : false}
                    success={formik.values.bq5 > 0 && !formik.errors.bq5}
                    helperText={
                      formik.touched.bq5 && formik.errors.bq5
                        ? formik.errors.bq5
                        : null
                    }
                  />
                }
              />&nbsp;
            </Grid>
            <Grid align="center" item xs={12} md={5} xl={1}>
              <Autocomplete
                sx={{ mt: "40px" }}
                size="small"
                options={opt.behOne ? opt.behOne : []}
                onChange={(event, option) => {
                  formik.setFieldValue("b1", option, false);
                }}
                renderInput={(params) => <TextField {...params} label="B1" name="b1"
                  error={formik.touched.b1 && formik.errors.b1 ? true : false}
                  success={formik.values.b1 > 0 && !formik.errors.b1}
                  helperText={
                    formik.touched.b1 && formik.errors.b1
                      ? formik.errors.b1
                      : null
                  }
                />
                }
              />&nbsp;
              <Autocomplete
                size="small"
                options={opt.behTwo ? opt.behTwo : []}
                onChange={(event, option) => {
                  formik.setFieldValue("b2", option, false);
                }}
                renderInput={(params) => <TextField {...params} label="B2" name="b2"
                  error={formik.touched.b2 && formik.errors.b2 ? true : false}
                  success={formik.values.b2 > 0 && !formik.errors.b2}
                  helperText={
                    formik.touched.b2 && formik.errors.b2
                      ? formik.errors.b2
                      : null
                  }
                />
                }
              />&nbsp;
              <Autocomplete
                size="small"
                options={opt.behThree ? opt.behThree : []}
                onChange={(event, option) => {
                  formik.setFieldValue("b3", option, false);
                }}
                renderInput={(params) => <TextField {...params} label="B3" name="b3"
                  error={formik.touched.b3 && formik.errors.b3 ? true : false}
                  success={formik.values.b3 > 0 && !formik.errors.b3}
                  helperText={
                    formik.touched.b3 && formik.errors.b3
                      ? formik.errors.b3
                      : null
                  }
                />
                }
              />&nbsp;
              <Autocomplete
                size="small"
                options={opt.behFour ? opt.behFour : []}
                onChange={(event, option) => {
                  formik.setFieldValue("b4", option, false);
                }}
                renderInput={(params) => <
                  TextField {...params} label="B4" name="b4"
                  error={formik.touched.b4 && formik.errors.b4 ? true : false}
                  success={formik.values.b4 > 0 && !formik.errors.b4}
                  helperText={
                    formik.touched.b4 && formik.errors.b4
                      ? formik.errors.b4
                      : null
                  }
                />
                }
              />&nbsp;
              <Autocomplete
                size="small"
                options={opt.behFive ? opt.behFive : []}
                onChange={(event, option) => {
                  formik.setFieldValue("b5", option, false);
                }}
                renderInput={(params) => <TextField {...params} label="B5" name="b5"
                  error={formik.touched.b5 && formik.errors.b5 ? true : false}
                  success={formik.values.b5 > 0 && !formik.errors.b5}
                  helperText={
                    formik.touched.b5 && formik.errors.b5
                      ? formik.errors.b5
                      : null
                  }
                />
                }
              />&nbsp;

            </Grid>
          </Grid>
          <Grid item xs={12} md={12} xl={12}>
            <MDBox display="flex" justifyContent="space-between" px={3} my={2} sx={{ alignItems: "center", alignContent: "center" }}>
              <MDTypography variant="button" fontWeight="medium">
                Average of Technical&nbsp;:
              </MDTypography>
              <FormField
                sx={{ width: "100px" }}
                color="info"
                size="small"
                variant="outlined"
                // className={classes.field}
                label="Average of Technical"
                inputProps={{ readOnly: true }}
                name='avgTechnical'
                {...formik.getFieldProps("avgTechnical")}
                error={formik.touched.avgTechnical && formik.errors.avgTechnical ? true : false}
                success={formik.values.avgTechnical > 0 && !formik.errors.avgTechnical}
                helperText={
                  formik.touched.avgTechnical && formik.errors.avgTechnical
                    ? formik.errors.avgTechnical
                    : null
                }
              />
              <Divider orientation="vertical" color="dark" />
              <MDTypography variant="button" fontWeight="medium">
                Average of Behavioral&nbsp;:
              </MDTypography>
              <FormField
                sx={{ width: "100px" }}
                size="small"
                variant="outlined"
                // className={classes.field}
                label="Average for Behavioral"
                inputProps={{ readOnly: true }}
                name='avgBehavioral'
                {...formik.getFieldProps("avgBehavioral")}
                error={formik.touched.avgBehavioral && formik.errors.avgBehavioral ? true : false}
                success={formik.values.avgBehavioral > 0 && !formik.errors.avgBehavioral}
                helperText={
                  formik.touched.avgBehavioral && formik.errors.avgBehavioral
                    ? formik.errors.avgBehavioral
                    : null
                }
              />
              <Divider orientation="vertical" color="dark" />
              <MDTypography variant="button" fontWeight="medium">
                Total&nbsp;:
              </MDTypography>
              <FormField
                sx={{ width: "100px" }}
                // className={classes.field}
                variant="outlined"
                size="small"
                label="Total"
                name='total'
                inputProps={{ readOnly: true }}
                {...formik.getFieldProps("total")}
              />
              <Divider orientation="vertical" color="dark" />
              <MDTypography variant="button" fontWeight="medium">
                Percentage&nbsp;:
              </MDTypography>
              <FormField
                sx={{ width: "100px" }}
                // className={classes.field}
                variant="outlined"
                size="small"
                label="Percentage"
                name='percentage'
                inputProps={{ readOnly: true }}
                {...formik.getFieldProps("percentage")}
              />
            </MDBox>
          </Grid>
          <Grid  item xs={12} md={12} xl={12}>
          <MDBox p={2}>
            <FormField
              multiline
              rows={5}
              placeholder="Qualitative comment"
              variant="outlined"
              color="warning"
              label="Qualitative comment"
              name="qualitativeComment"
              {...formik.getFieldProps("qualitativeComment")}
            />
            <MDButton variant="gradient" size="small" color="success" type="submit" sx={{ mt: "10px" }}> save</MDButton>
          </MDBox>
          </Grid>
        </MDBox>
      </Card>
    </form >
  );
}

export default AssessmentForm;
