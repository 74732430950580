import React from 'react'
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Otis Admin PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "examples/Cards/BookingCard";
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';

// Anaytics dashboard components
import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWallet,faArrowsSpin,faMobileButton,faBriefcase,faEnvelope} from '@fortawesome/free-solid-svg-icons'
import EditIcon from '@mui/icons-material/Edit';
// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";
import { Card,CardContent,CardHeader,Chip,Divider,Stack,Typography} from "@mui/material";
import Avatar from "@mui/material/Avatar";
// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "context/UserProvider";
import moment from 'moment';
import api from "services/api";

export default function Dashboard() {

    const { sales, tasks } = reportsLineChartData;
  const { userInfo } = useContext(UserContext);
  const [numberOfCompetitors, setNumberOfCompetitors] = useState();
  const [numberOfEngagements, setNumberOfEngagements] = useState();
  const[completness,setCompletness] = useState('');
  const [engagementBar, setEngagementBar] = useState({
    labels: ["Applied", "short-listed", "selected"],
    datasets: {}
  })
  const [dashboardData,setDashboardData] = useState('');
  const navigate = useNavigate();

  const current = new Date();
  current.toLocaleTimeString();

  const calculateCompleteness=()=>{
    const totalFields=44 ;
    let completedFields=0;
      if(dashboardData?.aadhaarNumber) completedFields++ ;
      if(dashboardData?.panNumber) completedFields++ ;
      if(dashboardData?.firstName) completedFields++ ;
      if(dashboardData?.lastName) completedFields++ ;
      if(dashboardData?.languages){
        let length1=Object.keys(dashboardData?.languages).length;
        if(length1>0)completedFields++
      } 
      if(dashboardData?.education){
        let length2=Object.keys(dashboardData?.education).length;
        if(length2>0)completedFields++
      }
      if(dashboardData?.experience){
        let length3=Object.keys(dashboardData?.experience).length;
        if(length3>0)completedFields++
      }
      if(dashboardData?.secondaryIndustry){
        let length3=Object.keys(dashboardData?.secondaryIndustry).length;
        if(length3>0)completedFields++
      }
      if(dashboardData?.email) completedFields++ ;
      if(dashboardData?.phoneNumber) completedFields++ ;
      if(dashboardData?.emailVerification) completedFields++ ;
      if(dashboardData?.phoneVerification) completedFields++ ;
      if(dashboardData?.avatar) completedFields++ ;
      if(dashboardData?.birthDate) completedFields++ ;
      if(dashboardData?.domain) completedFields++ ;
      if(dashboardData?.gender) completedFields++ ;
      if(dashboardData?.industry) completedFields++ ;
      if(dashboardData?.otherSkills) completedFields++ ;
      if(dashboardData?.primarySkills) completedFields++ ;
      if(dashboardData?.secondarySkills) completedFields++ ;
      if(dashboardData?.resume) completedFields++ ;
      if(dashboardData?.resumeText) completedFields++ ;
      if(dashboardData?.preferredLocation) completedFields++ ;
      if(dashboardData?.specialCategory) completedFields++ ;
      if(dashboardData?.whatsappNumber) completedFields++ ;
      if(dashboardData?.yourLocation) completedFields++ ;
      if(dashboardData?.video) completedFields++ ;
      if(dashboardData?.availability) completedFields++ ;
      if(dashboardData?.compensation) completedFields++ ;
      if(dashboardData?.compensationAmount) completedFields++ ;
      if(dashboardData?.currentOrganization) completedFields++ ;
      if(dashboardData?.currentProjectFrom) completedFields++ ;
      if(dashboardData?.currentProjectTo) completedFields++ ;
      if(dashboardData?.currentProjectName) completedFields++ ;
      if(dashboardData?.description) completedFields++ ;
      if(dashboardData?.proRefEmail) completedFields++ ;
      if(dashboardData?.proRefName) completedFields++ ;
      if(dashboardData?.proRefNumber) completedFields++ ;
      if(dashboardData?.proRefOrganization) completedFields++ ;
      if(dashboardData?.proRefPosition) completedFields++ ;
      if(dashboardData?.linkedin)completedFields++;
      if(dashboardData?.experienceYears)completedFields++;
      if(dashboardData?.fullTime || dashboardData?.partTime || dashboardData?.projectBased) completedFields++ ;
      if(dashboardData?.offSite || dashboardData?.onSite || dashboardData?.needBased || dashboardData?.both) completedFields++ ;
      // console.log("completedfields2",completedFields);
      return(completedFields/totalFields)*100
      }

  useEffect(()=>{
    calculateCompleteness()
    // console.log("Percentege",completenessPersentage);
  },[])
  useEffect(() => {
    let temp = []
    api.userInfo.NumberOfAppliedEngagements({ id: userInfo.id })
      .then((data) => {
        temp.push(data?.data?.result?.data)
        api.userInfo.NumberOfShortlistedEngagements({ id: userInfo.id })
          .then((data1) => {
            temp.push(data1?.data?.result?.data)
            api.userInfo.NumberOfSelectedEngagements({ id: userInfo.id })
              .then((data2) => {
                temp.push(data2?.data?.result?.data);
                const datasets1 = { label: "Sales", data: temp }
                setEngagementBar({ ...engagementBar, datasets: datasets1 })
              })
          })
      })
    }, [])

useEffect(()=>{

  const uid = userInfo?.id;
  console.log("userInfo",userInfo?.type);
    api.userInfo.findOne(uid).then((data) => {
      if (data) {
        let temp = data?.data?.result?.data;
        // console.log("Temp",temp);
        setDashboardData(temp)
        api.userInfo.NumberOfCompetitors({ industry: temp.industry, domain: temp.domain })
          .then((result) => {
            setNumberOfCompetitors(result?.data?.result?.data)
          })
        api.userInfo.NumberOfMatchingEngagements({ industry: temp.industry, domain: temp.domain })
          .then((resultData) => {
            setNumberOfEngagements(resultData?.data?.result?.data)
          })
      }
    });
},[])

  return (
    <>
      {userInfo?.type=='gigler'?(<MDBox py={3}>
        <MDBox mt={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={3} mx={12} >
                <Card elevation={3}
                sx={{minWidth:500}}>
                      <CardHeader
                      avatar={
                       <>
                       <Avatar 
                        src={dashboardData?.avatar}
                        sx={{ width: 65, height: 65}}/>

                        <CircularProgress variant="determinate"
                        value={calculateCompleteness()} 
                        size={65}
                        thickness={2}
                        color="success"
                        sx={{position:"absolute", zIndex:1}}/>
                        </>
                      }
                      title={
                        <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        alignItems="center"
                        justifyContent="space-between">
                      <Stack>
                      <MDTypography variant="h4" textTransform="capitalize" fontWeight="medium">
                            {dashboardData?.firstName + " " + dashboardData?.lastName}
                            <IconButton aria-label="edit" size="small" sx={{mx:1}} onClick={()=>navigate(`/account/profile`)}>
                              <EditIcon fontSize="inherit"/>
                            </IconButton>
                      </MDTypography>

                      <MDTypography variant="h6" fontWeight="regular">
                          Working At {dashboardData?.currentOrganization}
                      </MDTypography>
                      </Stack>
                      
                      <MDTypography variant="h6" fontWeight="light" >
                        Profile last updated - {moment(dashboardData?.updatedAt).calendar()}
                        </MDTypography>
                        </Stack>
                      }
                      />
                      <Divider/>
                    <CardContent>
                      <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      alignItems="center"
                      justifyContent="space-between"
                      mx={3}>
                      <Stack>
                      <MDTypography
                      variant="body2"
                      fontWeight="regular">
                      <FontAwesomeIcon icon={faWallet} size="xs" /> {dashboardData?.compensationAmount+" "+dashboardData.compensation}
                        </MDTypography>

                        <MDTypography 
                        variant="body2"
                        fontWeight="regular">
                        <FontAwesomeIcon icon={faBriefcase} size="xs"  /> {dashboardData?.experienceYears} Yrs.
                        </MDTypography>
                      </Stack>
                      <Divider orientation="vertical">|</Divider>
                      <Stack >
                      <MDTypography
                      variant="body2"
                      fontWeight="regular">
                       <FontAwesomeIcon icon={faMobileButton} size="xs" /> {dashboardData?.phoneNumber}
                        </MDTypography>
                        <MDTypography 
                        variant="body2"
                        fontWeight="regular">
                      <FontAwesomeIcon icon={faEnvelope} size="xs" />  {dashboardData?.email}
                        </MDTypography>
                      </Stack>

                      <Divider orientation="vertical">|</Divider>

                      <Stack  spacing={0.5} >
                            <MDTypography variant="h6" fontWeight="regular" >
                                Skill Sets
                            </MDTypography>
                          <Chip label={dashboardData?.primarySkills} color="primary"/>
                          <Chip label={dashboardData?.secondarySkills} color="primary"/>
                          {/* <Chip label={dashboardData.otherSkills}/> */}
                      </Stack>
                      </Stack>
                    </CardContent>
                    <Divider/>
                    <CardContent>
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        alignItems="center"
                        justifyContent="space-between"
                        mx={3}>

                          <Stack spacing={0.5}>
                            <MDTypography variant="h6" fontWeight="regular">
                                Engagement Mode
                            </MDTypography>
                            {dashboardData?.fullTime?(<Chip label="Full Time" color="primary"/>):("")}
                            {dashboardData?.partTime?(<Chip label="Part Time" color="primary"/>):("")}
                            {dashboardData?.projectBased?(<Chip label="Engagement Based" color="primary"/>):("")}
                          </Stack>
                        

                        <Divider variant="inset" orientation="vertical">|</Divider>
                            
                          <Stack spacing={0.5}>
                            <MDTypography variant="h6" fontWeight="regular">
                                Prefered Workspace
                            </MDTypography>
                            {dashboardData?.onSite?(<Chip label="On Site" color="primary"/>):("")}
                            {dashboardData?.offSite?(<Chip label="WFH or Remote" color="primary"/>):("")}
                            {dashboardData?.both?(<Chip label="Hybrid" color="primary"/>):("")}
                            {dashboardData?.needBased?(<Chip label="Need Based" color="primary"/>):("")}
                          </Stack>

                        <Divider orientation="vertical">|</Divider>

                          <Stack spacing={0.5}>
                            <MDTypography variant="h6" fontWeight="regular">
                                Notice Period
                            </MDTypography>
                              {dashboardData?.availability=="zeroToSevenDays"?(<Chip label="0 To 7 Days" color="primary"/>):('')}
                              {dashboardData?.availability=="eightToFifteenDays"?(<Chip label="8 To 15 Days" color="primary"/>):('')}
                              {dashboardData?.availability=="sixteenToThirtyDays"?(<Chip label="16 To 30 Days" color="primary"/>):('')}
                              {dashboardData?.availability=="moreThanThirtyDays"?(<Chip label="More Than 30 Days" color="primary"/>):('')}
                          </Stack>

                      </Stack>
                    </CardContent>
                </Card>
            </MDBox>
            </Grid>
            
          </Grid>
        </MDBox>
      </MDBox>):('Gigler DashBoard Comming Soon')}
    </>
  )
}
