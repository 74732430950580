/**
=========================================================
* Otis Admin PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, Checkbox, FormGroup, Icon, Stack } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Otis Admin PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/pages/profile/components/Header";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings";

// Data

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import selectData from "./data/selectData";
import EngagerList from "./EngagerList";
import MDButton from "components/MDButton";
import { useContext, useEffect, useState } from "react";
import OutlinedInput from '@mui/material/OutlinedInput';
import Input from "@mui/material/Input";

//form
import { useFormik } from "formik";
import * as Yup from "yup";
import api from "services/api";
import { useNavigate } from "react-router-dom";
import { Field, Form, ErrorMessage } from "formik";
import MDSnackbar from "components/MDSnackbar";
import MDAlert from "components/MDAlert";
import FormField from "layouts/pages/account/components/FormField";
import { SnackbarContext } from "context/SnackbarContext";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import MDInput from "components/MDInput";
import InputLabel from '@mui/material/InputLabel';
import moment from 'moment';




const initialValues = {
  engagerId: '',
  organizationName: '',
  industry: '',
  enterprise: '',
  location: '',
  websiteAddress: '',
  gstIn: '',
  firstName: '',
  lastName: '',
  designation: '',
  department: '',
  email: '',
  phoneNumber: '',
  password: '',
  numberOfEmployees: '',
  engagerType: "owner",
  panNumber:"",
  status: 1
}

const validate = (values) => {
  let errors = {};

  if (!values.organizationName) {
    errors.organizationName = "Required";
  }
  if (!values.location) {
    errors.location = "Required";
  }
  if (!values.websiteAddress) {
    errors.websiteAddress = "Required";
  }
  if (!values.gstIn) {
    errors.gstIn = "Required";
  }
  if (!values.firstName) {
    errors.firstName = "Required";
  }
  if (!values.lastName) {
    errors.lastName = "Required";
  }
  if (!values.designation) {
    errors.designation = "Required";
  }
  if (!values.department) {
    errors.department = "Required";
  }
  if (!values.email) {
    errors.email = "Required";
  }
  if (!values.phoneNumber) {
    errors.mobileNumber = "Required";
  }
  if (!values.numberOfEmployees) {
    errors.numberOfEmployees = "Required";
  }
  return errors;
};

const phoneRegExp = /^[0-9]*$/;
  // /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object({
  organizationName: Yup.string().required("Organization Name is required"),
  location: Yup.string().required("Location is required"),
  // numberOfEmployees: Yup.number().required("Number of employees is required"),
  // websiteAddress: Yup.string().required("websiteAddress is required"),
  email: Yup.string().email().required("Email is required"),
  // gstIn: Yup.string().required("GSTIN is required")
  //   .min(15, "GSTIN should contain minimum of 15 characters")
  //   .max(15, "GSTIN should contain only 15 characters"),
  firstName: Yup.string().required("First Name is required"),
  industry: Yup.string().required("Industry is required"),
  enterprise: Yup.string().required("Enterprise is required"),
  lastName: Yup.string().required("Last Name is required"),
  designation: Yup.string().required("Designation is required"),
  department: Yup.string().required("Department is required"),
  panNumber:Yup.string(),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Phone number is too short")
    .max(10, "Phone number is too long")
  ,
  password: Yup.string()
    .required("Password is required")
    .min(8, "Your password must be longer than 8 characters.")
    .max(25)
    .matches(/^(?=.{8,})/, "Must Contain 8 Characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])/,
      "Must Contain One Uppercase, One Lowercase"
    )
    .matches(
      /^(?=.*[!@#\$%\^&\*])/,
      "Must Contain One Special Case Character"
    )
    .matches(/^(?=.{6,20}$)\D*\d/, "Must Contain One Number"),
});

const ManageEngager = () => {
  let navigate = useNavigate();
  const { setSnack } = useContext(SnackbarContext);
  const [indData, setIndData] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const handleShow = () => setShowForm(true);
  const [industry, setIndustry] = useState();
  const [showPassword, setShowPassword] = useState(false);
  


  const handleDontShow = () => {
    setShowForm(false);
    handleReset();
  };
  // const handleChange = event => {
  //   const result = event.target.value.replace(/[^a-z]/gi, '');
  // };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    api.industry.findAll().then(data => {
      let temp = data?.data?.result?.data;
      console.log(temp);
      setIndData(temp);
    }).catch(error => {
      console.log("error", error)
    })
  }, [])
 
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        let register = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: (values.email).toLowerCase(),
          phoneNumber: values.phoneNumber,
          password: values.password,
          type: "employer"
        }
        const userEmail = values.email
      const split = userEmail.split('@');
      console.log("spliy", split);
      const regularEmail = ['gmail.com', 'yahoo.com', 'hotmail.com', 'aol.com', 'hotmail.co.uk', 'hotmail.fr', 'msn.com', 'yahoo.fr', 'wanadoo.fr', 'orange.fr', 'comcast.net', 'yahoo.co.uk', 'rediffmail.com', 'live.com'];
      api.users.findOneUser({ userEmail: userEmail })
        .then(data => {
          if (data?.data?.message == "success") {
            setSnack({
              message: `${values.email} Already exits`,
              open: true,
              type: "error",
            });
          }
          else if (regularEmail.indexOf(split[1]) >= 0) {
            setSnack({
              message: `General Email domain are not allowed for Engager registration.`,
              open: true,
              type: "error",
            });
          }
          else {

            let date = new Date();
            values.planValidFrom = date;
            values.planValidTo = moment(date).add(364, 'days').toDate();
            values.type = "employer";
            // console.log("engager registration", values)
            const temp3 = api.verifications.verify({ email: values.email, firstName: values.firstName });
            console.log(temp3);
            // navigate("/register/verification", {
            //   state: {
            //     values: values,
            //   },
            // });
            const registerEngager =  api.users.registerUser(register)
          .then((response) => {
            const userId = response?.data?.result?.data?.id
            values.engagerId = userId;
            const result = api.engager.create(values)
              .then(
                (result) => {
                  navigate(`/admin/manage-engagers`);
                  setSnack({
                    message: `${register?.email} Engager Created Successfully`,
                    open: true,
                    type: 'success',
                  });
                  handleReset();
                  setShowForm(false);
                }
              ).catch((error) => {
                setSnack({
                  message: 'Error occurred during Engager Creation  !',
                  open: true,
                  type: 'error',
                });
                console.log("Engager error", error)
              })

          })
          }
        }).catch(err => console.log("error", err))


        
      } catch (error) {
        // console.log("ERROR LOGIN: ", { ...error });

      }
    },
    // validate,

  });

  const handleReset = () => {
    formik.handleReset();
  };

  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card id="EngagerProfile" sx={{ overflow: "visible" }}>
        {!showForm &&
        <>
        <MDBox p={3} display='flex' justifyContent="space-between">
          <MDTypography variant='h5'>
            Engager List
          </MDTypography>
          
          <MDButton variant='gradient' size='medium' color='success' onClick={() => handleShow()}>
            Add Engager
          </MDButton>
        </MDBox>
          <MDBox>
            <EngagerList />
          </MDBox>
         </>
        }
        {showForm &&
          <MDBox>
            <MDBox p={3}>
              <MDTypography py={-2} px={1} variant="h4">Engager Information</MDTypography>
              <MDBox px={-1} mt={1}>
              <MDBox mt={200}
                variant="button"
                color="dark"
                align="right"
                // className={css.backIcon}
                sx={{mb:"2px",mt:"-50px"}}
              >
              <MDButton
                    variant="outlined"
                    color="info"
                    size="small"
              onClick={() => handleDontShow()}

              >
              
              <ArrowBackIcon sx={{ pt: "2px" }} />
              Back
                  </MDButton>
              </MDBox>
              </MDBox>
            </MDBox>
            <form onSubmit={formik.handleSubmit} onReset={() => handleReset()} method='post'>
              <MDBox pb={2} pt={-5} px={4.3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <FormField label="Organization name" placeholder="Name of the organization" name='organizationName'
                      {...formik.getFieldProps("organizationName")}
                      error={formik.touched.organizationName && formik.errors.organizationName ? true : false}
                      helperText={
                        formik.touched.organizationName && formik.errors.organizationName
                          ? formik.errors.organizationName
                          : null
                      } />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      id="select-industry"
                      options={indData}
                      autoHighlight
                      getOptionLabel={(option) => option.Industry}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.Industry}
                        </Box>
                      )}
                      onChange={(event, newValue) => {
                        setIndustry(newValue);
                        formik.setFieldValue("industry", newValue.Industry, false);
                      }}
                      renderInput={(params) => (
                        <FormField
                          {...params}
                          label="Industry"
                          placeholder="Select Industry"
                          {...formik.getFieldProps("industry")}
                          name="industry"
                          InputLabelProps={{shrink:true}}
                          type="text"
                          error={formik.touched.industry && formik.errors.industry ? true : false}
                          helperText={
                            formik.touched.industry && formik.errors.industry
                              ? formik.errors.industry
                              : null
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      options={selectData.enterprise}
                      onChange={(event, option) => {
                        console.log("enterprise", option)
                        formik.setFieldValue("enterprise", option, false);
                      }}
                      renderInput={(params) => (
                        <FormField 
                          {...params}
                          label="Enterprise"
                          name="enterprise"
                          InputLabelProps={{ shrink: true }}
                          placeholder="Select Enterprise"
                          {...formik.getFieldProps("enterprise")}
                          error={formik.touched.enterprise && formik.errors.enterprise ? true : false}
                          helperText={
                            formik.touched.enterprise && formik.errors.enterprise
                              ? formik.errors.enterprise
                              : null
                          } />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField label="Location of HO" placeholder="Hyderabad" name="location" inputProps={{ type: "text" }}
                      {...formik.getFieldProps("location")}
                      error={formik.touched.location && formik.errors.location ? true : false}
                      helperText={
                        formik.touched.location && formik.errors.location
                          ? formik.errors.location
                          : null
                      } />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField label="Number of employees" placeholder="20" name='numberOfEmployees' inputProps={{ type: "number" }}
                      {...formik.getFieldProps("numberOfEmployees")}
                      error={formik.touched.numberOfEmployees && formik.errors.numberOfEmployees ? true : false}
                      helperText={
                        formik.touched.numberOfEmployees && formik.errors.numberOfEmployees
                          ? formik.errors.numberOfEmployees
                          : null
                      } />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField label="Website address" placeholder="www.gigleJi.com" name="websiteAddress" inputProps={{ type: "text" }}
                      {...formik.getFieldProps("websiteAddress")}
                      error={formik.touched.websiteAddress && formik.errors.websiteAddress ? true : false}
                      helperText={
                        formik.touched.websiteAddress && formik.errors.websiteAddress
                          ? formik.errors.websiteAddress
                          : null
                      }></FormField>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField label="GSTIN" placeholder="GSTIN97924678979465" maxLength={12} name="gstIn" inputProps={{ type: "text" }}
                      {...formik.getFieldProps("gstIn")}
                      error={formik.touched.gstIn && formik.errors.gstIn ? true : false}
                      helperText={
                        formik.touched.gstIn && formik.errors.gstIn
                          ? formik.errors.gstIn
                          : null
                      }></FormField>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField
                    label="Pan Number"
                    name="panNumber"
                    inputProps={{ type: "text" }}
                    placeholder="DAAPH1331R"
                      {...formik.getFieldProps("panNumber")}
                      error={formik.touched.panNumber && formik.errors.panNumber ? true : false}
                      helperText={
                        formik.touched.panNumber && formik.errors.panNumber
                          ? formik.errors.panNumber
                          : null
                      }></FormField>
                  </Grid>
                </Grid>
                  <MDBox pr={50} py={3}>
                    <MDTypography variant="h5">SPOC Details</MDTypography>
                  </MDBox>
                <Grid container spacing={3} pr={2}>
                  <Grid item xs={12} sm={4}>
                    <FormField label="First Name" placeholder="Engager first name" name='firstName' inputProps={{ type: "text" }}
                      {...formik.getFieldProps("firstName")}
                      error={formik.touched.firstName && formik.errors.firstName ? true : false}
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                          ? formik.errors.firstName
                          : null
                      } />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField label="Last Name" placeholder="Engager last Name" name='lastName' inputProps={{ type: "text" }}
                      {...formik.getFieldProps("lastName")}
                      error={formik.touched.lastName && formik.errors.lastName ? true : false}
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                          ? formik.errors.lastName
                          : null
                      } />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField label="Designation" placeholder="Engager designation" name='designation' inputProps={{ type: "text" }}
                      {...formik.getFieldProps("designation")}
                      error={formik.touched.designation && formik.errors.designation ? true : false}
                      helperText={
                        formik.touched.designation && formik.errors.designation
                          ? formik.errors.designation
                          : null
                      } />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField label="Department" placeholder="Engager department" name='department' inputProps={{ type: "text" }}
                      {...formik.getFieldProps("department")}
                      error={formik.touched.department && formik.errors.department ? true : false}
                      helperText={
                        formik.touched.department && formik.errors.department
                          ? formik.errors.department
                          : null
                      } />
                  </Grid>   
                  <Grid item xs={12} sm={4}>
                    <FormField label="Email" placeholder="email@email.com" name='email' inputProps={{ type: "text", style: { textTransform: "lowercase" } }}
                      {...formik.getFieldProps("email")}
                      // success={formik.values.email > 0 && !formik.errors.email ? true : false}
                      error={formik.touched.email && formik.errors.email ? true : false}
                      helperText={
                        formik.touched.email && formik.errors.email
                          ? formik.errors.email
                          : null
                      }
                       />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField 
                    label="Phone Number" 
                    placeholder="+91"
                    name='phoneNumber'
                    inputProps={{ type: "number" }}
                    
                    onKeyPress = {(event) => {
                      if(!phoneRegExp.test(event.key))
                      event.preventDefault();
                    }}
                      {...formik.getFieldProps("phoneNumber")}
                      error={formik.touched.phoneNumber && formik.errors.phoneNumber ? true : false}
                      success={formik.values.phoneNumber > 0 && !formik.errors.phoneNumber ? true : false}

                      helperText={
                        formik.touched.phoneNumber && formik.errors.phoneNumber
                          ? formik.errors.phoneNumber
                          : null
                      } />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                        <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                    <MDBox mb={1} mt={-1}>
                      <FormControl sx={{ width: '100%' }} variant="standard">
                      <Input
                          type={showPassword ? 'text' : 'password'}
                          label="Password"
                          placeholder="Abcd@123"
                          name="password"
                          value=""
                          {...formik.getFieldProps("password")}
                          error={formik.touched.password && formik.errors.password ? true : false}
                          helperText={
                            formik.touched.password && formik.errors.password
                              ? formik.errors.password
                              : null
                          }
                          fullWidth
                            endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}>
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>}/>
                                {formik.touched.password && formik.errors.password && (
                                <div style={{fontSize:'12px', textAlign:'left'}}
                                    className="text-danger">
                                    {formik.errors.password}
                                    </div>)}
                      </FormControl>  
                      </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} display='flex' justifyContent='space-between'>
                    <MDButton variant="gradient" color="error" size="small" onClick={handleDontShow}>
                      CANCEL
                    </MDButton>
                    <MDButton variant="gradient" color="success" size="small" type="submit">
                      SUBMIT
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </form>
          </MDBox>
        }
      </Card >
    </DashboardLayout>
  );
}

export default ManageEngager;
