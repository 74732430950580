/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";


// Soft UI Dashboard PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import TimelineList from "examples/Timeline/TimelineList";
import TimelineItem from "examples/Timeline/TimelineItem";

// Data
import timelineData from "layouts/pages/projects/timeline/data/timelineData";
import { useContext, useEffect, useState } from "react";
import api from "services/api";
import ProfilesList from "examples/Lists/ProfilesList";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

//components
import GiglerList from "./giglerList";
import AssessorList from "./assessorList";
import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { SnackbarContext } from "context/SnackbarContext";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const Assigning = () => {
  const { setSnack } = useContext(SnackbarContext);
  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [gigs, setGigs] = useState([]);
  const [asrIndustry, setAsrIndustry] = useState();
  const [astDomain, setAsrDomain] = useState();
  const [selectedAssessor, setSelectedAssessor] = useState({});
  const [loading, setLoading] = useState(true);
  const [btnLoad, setBtnLoad] = useState(false);

  const [list, setList] = useState({
    assessorId: '',
    assessorName: '',
    assessorEmail: '',
    assignList: [],
    assigned: true
  });

  useEffect(() => {
    api.assessor.findAll().then(data => {
      setLoading(true)
      console.log("list of assessors", data);
      if (data?.data?.message == "success") {
        let temp = data?.data?.result?.data;
        console.log("list of assessors", temp);
        setData(temp);
        setLoading(false);
      } else if (data?.data?.message == "error") {
        setData("0");
        setLoading(false);
      }
    }).catch(error => {
      console.log("error", error)
    })
  }, [])


  const handleGiglerList = async (listValues) => {
    console.log("onClick assessor", listValues)
    setSelectedAssessor(listValues);
    let temp = list;
    temp['assessorId'] = listValues.assessorId;
    temp['assessorName'] = `${listValues.firstName} ${listValues.lastName}`;
    temp['assessorEmail'] = listValues.email;
    setList({ ...temp });
    // let assessorId = listValues?.assessorId
    let domain = listValues?.domain
    let industry = listValues?.industry
    setAsrIndustry(listValues?.industry);
    setAsrDomain(listValues?.domain);
    // console.log("domain & industry", domain, industry);
    api.userInfo.find({ domain: domain })
      .then(data => {
        if (data) {
          let temp = data?.data?.result?.data;
          console.log("gigTalent list", temp)
          setGigs(temp);
          console.log("giglist", data?.data?.result?.data);
        }
      })
  }

  const handleList = (data) => {
    console.log("assigned gigs list", data);
    if (data.length <= 0) {
      setSnack({
        message: `GigTalents assgined to Assessor`,
        open: true,
        type: "success",
      });
    } else if (data.length > 0) {
      let temp = list;
      temp['assignList'] = data;
      temp['assigned'] = true;
      temp['assessed'] = false;
      // console.log('list', temp)
      setList({ ...temp });
      const namesToDeleteSet = new Set(data)
      setGigs(gigs.filter((name) => !namesToDeleteSet.has(name)))
      api.assigned.create(temp)
        .then((data) => {
          if (data?.data?.message == "success") {
            let temp = data?.data?.result?.data;
            setSnack({
              message: `GigTalents assgined to Assessor`,
              open: true,
              type: "success",
            });
            handleGiglerList(selectedAssessor);
            setBtnLoad(false)
          } else if (data?.data?.message == "error") {
            setSnack({
              message: `GigTalents not assgined to Assessor`,
              open: true,
              type: "error",
            });
            setBtnLoad(false)
          }
          // navigate("/admin/dashboard")
        })
        .catch((err) => {
          setSnack({
            message: `GigTalents not assgined to Assessor`,
            open: true,
            type: "error",
          });
          setBtnLoad(false)
        })
      let updateId = data
      console.log("gigler id's selected", updateId);
      api.userInfo.updateMany({ updateId })
        .then((data) => {
          // let tempData = data?.data?.result?.data;
          // console.log("list with assigned value", data)
        })
    }
  }

  // useEffect(() => {
  //   setGigs(gigs)
  // }, [handleList])
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox >
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <Card sx={{ px: "3px" }}>
              {loading ? (
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    py: 5,
                  }}>
                  <CircularProgress color="info" />
                </MDBox>
              ) : (
                <AssessorList assesserList={data} setData={setData} handleGiglerList={handleGiglerList} />
              )}
            </Card>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Card>
              <GiglerList asrIndustry={asrIndustry} asrDomain={astDomain} giglerList={gigs} handleList={handleList} btnLoad={btnLoad} setBtnLoad={setBtnLoad} />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout >
  );
}

export default Assigning;
