/**
=========================================================
* Otis Admin PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useContext, useEffect, useState } from "react";

// @material-ui core components
import Card from "@mui/material/Card";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  snackbarClasses,
  Stack,
} from "@mui/material";

//icons
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

//user context
import { UserContext } from "context/UserProvider";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import selectData from "./data/selectData";
import MDDatePicker from "components/MDDatePicker";
import MDInput from "components/MDInput";

//form
import { useFormik } from "formik";
import * as Yup from "yup";
import api from "services/api";
import { useNavigate } from "react-router-dom";
import { Field, Form, ErrorMessage } from "formik";
import { SnackbarContext } from "context/SnackbarContext";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function CreateSubUser() {
  let navigate = useNavigate();

  let arr = selectData.cities;
  // let city = arr.map(a => a.name);
  const { userInfo } = useContext(UserContext);
  const { setSnack } = useContext(SnackbarContext);
  const [userData, setUserData] = useState({})
  const [loading, setLoading] = useState(true)
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState({});
  const [edit, setEdit] = useState(false);
  const handleEdit = () => setEdit(!edit);
  const [cat, setCat] = useState(false);
  const handleCat = () => setCat(!cat);
  const [profile, setProfile] = useState({});
  const toggleSnackbar = () => setShow(!show);
  const [industry, setIndustry] = useState();
  const [indData, setIndData] = useState([]);
  const [details, setDetails] = useState()
  const [subUserPermission, setSubUserPermission] = useState(false);
  const [initialValues, setInitialValues] = useState({
    // userId: userInfo?.id,
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNumber: "",
    organizationName: "",
    industry: "",
    enterprise: "",
    location: "",
    designation: "",
    department: "",
    numberOfEmployees: "",
    gstIn: "",
    type: "subEmployer",
    engagerType: "subEmployer",
    createdBy: userInfo?.id,
    status: 1
  });

  const validate = (values) => {
    let errors = {};

    if (!values.firstName) {
      errors.firstName = "Required";
    }
    if (!values.lastName) {
      errors.lastName = "Required";
    }
    if (!values.email) {
      errors.email = "Required";
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = "Required";
    }
    return errors;
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email().required("Email is required"),
    department: Yup.string().required("Department is required"),
    designation: Yup.string().required("Designation is required"),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Phone number should be minimum 10 numbers")
      .max(10, "Phone number should be maximum 10 numbers")
    ,
    password: Yup.string()
      .required("Password is required")
      .min(8, "Your password must be longer than 8 characters.")
      .max(25)
      .matches(/^(?=.{8,})/, "Must Contain 8 Characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])/,
        "Must Contain One Uppercase, One Lowercase"
      )
      .matches(
        /^(?=.*[!@#\$%\^&\*])/,
        "Must Contain One Special Case Character"
      )
      .matches(/^(?=.{6,20}$)\D*\d/, "Must Contain One Number"),
    // phoneNumber: Yup.string().matches(phoneRegExp, "phoneNumber is required"),
  });


  // useEffect(() => {
  //   const id = userInfo.id
  //   console.log("hello", id)
  //   api.engager.findOne(id)
  //     .then((data) => {
  //       const temp = data?.data?.result?.data
  //       console.log("************************", temp)
  //       setDetails(temp);
  //     })
  // }, []);

  useEffect(() => {
    api.industry
      .findAll()
      .then((data) => {
        let temp = data?.data?.result?.data;
        setIndData(temp);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);
  useEffect(() => {
    if (Object.keys(userInfo).length > 0) {
      console.log("###################here", userInfo);
      const id = userInfo.id
      api.engager.findOne(id)
        .then((data) => {
          if (data) {
            let temp = data?.data?.result?.data
            console.log("engager information ", temp)
            setUserData(temp);
            formik.setFieldValue("organizationName", temp?.organizationName, false);
            formik.setFieldValue("enterprise", temp?.enterprise, false);
            formik.setFieldValue("industry", temp?.industry, false);
            formik.setFieldValue("location", temp?.location, false);
            formik.setFieldValue("numberOfEmployees", temp?.numberOfEmployees, false);
            formik.setFieldValue("gstIn", temp?.gstIn, false);
            setSubUserPermission(temp.subUserAccess)
            setLoading(false);
          }
        });
    }
  }, [userInfo])
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log("hello", values.organizationName);
      try {
        let register = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: (values.email).toLocaleLowerCase(),
          phoneNumber: values.phoneNumber,
          password: values.password,
          type: "subEmployer",
          status: 1
        };
        api.users.registerUser(register)
          .then((response) => {
            let temp = response?.data;
            console.log("temp", temp);
            if (temp.message == 'error') {
              setSnack({
                message: temp?.result,
                open: true,
                type: "error",
              });
            } else {
              const userId = response?.data?.result?.data?.id;
              values.engagerId = userId;
              const result = api.engager
                .create(values)
                .then((result) => {
                  if(result?.data?.message == "success"){
                    setSnack({
                      message: `${values.email} sub-user is created`,
                      open: true,
                      type: "success",
                    });
                    navigate("/employer/sub-users-list")
                  }else{
                    setSnack({
                      message: "Error occurred during update!",
                      open: true,
                      type: "error",
                    });
                  }
                })
                .catch((error) => {
                  setSnack({
                    message: "Error occurred during update!",
                    open: true,
                    type: "error",
                  });
                  console.log("Engager error", error);
                });
            }
          });
      } catch (error) {
        setSnack({
          message: "Error occurred during sub-user creation!",
          open: true,
          type: "error",
        });
      }
    },
  });
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!subUserPermission ? (
        <Card sx={{p:"20px"}}>
          <MDTypography variant="button" fontWeight="medium"> Sub-user creation limit is reached as per plan.</MDTypography>
        </Card>
      ):(
        <>
      {loading ? (
        <Card>
          <MDBox
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              py: 5,
            }}
          >
            <CircularProgress color="primary" />
          </MDBox>
        </Card>
      ) : (
        <Card id="basic-info" sx={{ overflow: "visible" }}>
          <MDBox p={3} sx={{ display: "flex", justifyContent: "space-between" }}>
            <MDTypography variant="h5" sx={{ float: "left" }}>
              Organization Details
            </MDTypography>
          </MDBox>
          <form onSubmit={formik.handleSubmit} method="post">
            <MDBox pb={3} px={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <MDBox pt={3}>
                    <FormField
                      inputProps={{ type: "text", readOnly: true }}
                      label="Organization name"
                      placeholder="Name of the organization"
                      name="organizationName"
                      {...formik.getFieldProps("organizationName")}
                      error={
                        formik.touched.organizationName && formik.errors.organizationName
                          ? true
                          : false
                      }
                      helperText={
                        formik.touched.organizationName && formik.errors.organizationName
                          ? formik.errors.organizationName
                          : null
                      }
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    label="Industry"
                    // placeholder="Public"
                    name="industry"
                    inputProps={{ type: "text", readOnly: true }}
                    {...formik.getFieldProps("industry")}
                    error={formik.touched.industry && formik.errors.industry ? true : false}
                    helperText={
                      formik.touched.industry && formik.errors.industry
                        ? formik.errors.industry
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    label="Enterprise"
                    placeholder="Public"
                    name="enterprise"
                    inputProps={{ type: "text", readOnly: true }}
                    {...formik.getFieldProps("enterprise")}
                    error={formik.touched.enterprise && formik.errors.enterprise ? true : false}
                    helperText={
                      formik.touched.enterprise && formik.errors.enterprise
                        ? formik.errors.enterprise
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    label="Location of HO"
                    placeholder="Hyderabad"
                    name="location"
                    inputProps={{ type: "text", readOnly: true }}
                    {...formik.getFieldProps("location")}
                    error={formik.touched.location && formik.errors.location ? true : false}
                    helperText={
                      formik.touched.location && formik.errors.location
                        ? formik.errors.location
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormField
                    label="Number of employees"
                    placeholder="20"
                    name="numberOfEmployees"
                    inputProps={{ type: "number", readOnly: true }}
                    {...formik.getFieldProps("numberOfEmployees")}
                    error={
                      formik.touched.numberOfEmployees && formik.errors.numberOfEmployees
                        ? true
                        : false
                    }
                    helperText={
                      formik.touched.numberOfEmployees && formik.errors.numberOfEmployees
                        ? formik.errors.numberOfEmployees
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <FormField
                    label="GSTIN"
                    placeholder="GSTIN97924678979465"
                    name="gstIn"
                    inputProps={{ type: "text", readOnly: true }}
                    {...formik.getFieldProps("gstIn")}
                    error={formik.touched.gstIn && formik.errors.gstIn ? true : false}
                    helperText={
                      formik.touched.gstIn && formik.errors.gstIn ? formik.errors.gstIn : null
                    }
                  ></FormField>
                </Grid>
              </Grid>
              <MDBox py={3}>
                <MDTypography variant="h5">Sub-User Information</MDTypography>
              </MDBox>
              <Grid container spacing={3} >
                <Grid item xs={12} sm={4}>
                  <FormField
                    label="First Name"
                    name="firstName"
                    {...formik.getFieldProps("firstName")}
                    error={formik.touched.firstName && formik.errors.firstName ? true : false}
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                        ? formik.errors.firstName
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    label="Last Name"
                    name="lastName"
                    {...formik.getFieldProps("lastName")}
                    error={formik.touched.lastName && formik.errors.lastName ? true : false}
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                        ? formik.errors.lastName
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    label="Department"
                    name="department"
                    {...formik.getFieldProps("department")}
                    error={formik.touched.department && formik.errors.department ? true : false}
                    helperText={
                      formik.touched.department && formik.errors.department
                        ? formik.errors.department
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    label="Designation"
                    name="designation"
                    {...formik.getFieldProps("designation")}
                    error={formik.touched.designation && formik.errors.designation ? true : false}
                    helperText={
                      formik.touched.designation && formik.errors.designation
                        ? formik.errors.designation
                        : null
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={8}>
                  <FormField
                    label="Email"
                    name="email"
                    setFieldValue={profile.email}
                    inputProps={{ type: "email",style: { textTransform: "lowercase" }}}
                    {...formik.getFieldProps("email")}
                    error={formik.touched.email && formik.errors.email ? true : false}
                    helperText={
                      formik.touched.email && formik.errors.email ? formik.errors.email : null
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Phone Number"
                    name="phoneNumber"
                    inputProps={{ type: "number" }}
                    {...formik.getFieldProps("phoneNumber")}
                    error={formik.touched.phoneNumber && formik.errors.phoneNumber ? true : false}
                    helperText={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                        ? formik.errors.phoneNumber
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Password"
                    name="password"
                    setFieldValue={profile.password}
                    inputProps={{ type: "password" }}
                    {...formik.getFieldProps("password")}
                    error={formik.touched.password && formik.errors.password ? true : false}
                    helperText={
                      formik.touched.password && formik.errors.password
                        ? formik.errors.password
                        : null
                    }
                  />
                </Grid>
              </Grid>
              <MDBox pt={2} pb={3} sx={{ float: "left" }}>
                <MDButton variant="gradient" size="small" color="success" type="submit">
                  SAVE
                </MDButton>
              </MDBox>
            </MDBox>
          </form>
        </Card>
      )}
      </>
      )}
    
    </DashboardLayout>
  );
}

export default CreateSubUser;
