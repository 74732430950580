import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// components
import EngagementDetails from "components/EngagementDetails";
// layout
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// services
import api from "services/api";
import MDBox from "components/MDBox";
import { UserContext } from "context/UserProvider";
import { SnackbarContext } from "context/SnackbarContext";

const EngagementDetailsCommon = ({}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showApply, setShowApply] = useState(false);
  const [status, setStatus] = useState("");
  const [engDetails, setEngDetails] = useState({});
  const { userInfo } = useContext(UserContext);
  const { setSnack } = useContext(SnackbarContext);

  useEffect(() => {
    getDetails();
    // console.log("enagagement id", id);
    // if (userInfo.type == "gigler") {
    //   api.engagement
    //     .findByEngagementId(id)
    //     .then((data) => {
    //       console.log("data", data?.data?.result.data);
    //       setEngDetails(data?.data?.result.data);
    //       console.log("user info", userInfo);
    //       const uid = userInfo?.id;
    //       let reqData = {
    //         engId: id,
    //         userId: uid,
    //       };
    //       api.engagement.findEngagementMatch(reqData).then((data) => {
    //         if (data) {
    //           let temp = data?.data?.result;
    //           console.log("temp", temp);
    //           if (temp?.message == "not applied") {
    //             setShowApply(true);
    //             // console.log("matches");
    //           } else if (temp?.message == "applied") {
    //             setShowApply(false);
    //             setStatus("Applied");
    //           } else if (temp?.message == "not match") {
    //             setShowApply(false);
    //             setSnack({
    //               message: `Engagement Doesn't match your profile`,
    //               open: true,
    //               type: "error",
    //             });
    //             navigate(`/account/profile`);
    //           }
    //           // setLoading(false);
    //           // setUserData({ ...temp });
    //         }
    //       });
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // } else {
    //   navigate(-1);
    // }
  }, []);

  const getDetails = () => {
    console.log("enagagement id", id);
    if (userInfo.type == "gigler") {
      api.engagement
        .findByEngagementId(id)
        .then((data) => {
          console.log("data", data?.data?.result.data);
          setEngDetails(data?.data?.result.data);
          console.log("user info", userInfo);
          const uid = userInfo?.id;
          let reqData = {
            engId: id,
            userId: uid,
          };
          api.engagement.findEngagementMatch(reqData).then((data) => {
            if (data) {
              let temp = data?.data?.result;
              console.log("temp", temp);
              if (temp?.message == "not applied" && temp?.data?.status == "closed") {
                setShowApply(false);
                setStatus("Closed")
                // console.log("matches");
              }
                else if (temp?.message == "not applied" && temp?.data?.status == "open"){
                  setShowApply(true)
              } else if (temp?.message == "applied") {
                setShowApply(false);
                setStatus("Applied");
              } else if (temp?.message == "not match") {
                setShowApply(false);
                setSnack({
                  message: `Engagement Doesn't match your profile`,
                  open: true,
                  type: "error",
                });
                navigate(`/account/profile`);
              }
              // setLoading(false);
              // setUserData({ ...temp });
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      navigate(-1);
    }
  }

  const handleBack = () => {
    navigate(-1);
  };

  const handleApply = (row) => {
    const apply = {
      engagementId: row.engagementId,
      engagerId: row.engagerId,
      giglerId: userInfo.id,
      status: "open",
      applied: true,
      shortListed: false,
      selected: false,
      createdBy: row.engagerName,
      updatedBy: row.engagerName,
      source: "application",
      active: true,
    };
    api.engagementProcess.create(apply).then((data) => {
      console.log("engagementProcess success", data);
      if (data) {
        setSnack({
          message: `Applied to engagement successfully`,
          open: true,
          type: "success",
        });
        getDetails();
      } else {
        setSnack({
          message: `Error occurred while applying `,
          open: true,
          type: "Error",
        });
      }
    }).catch(err => {
      console.log(err);
    })
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox position="relative" mb={5}>
        <EngagementDetails
          showApply={showApply}
          status={status}
          engDetails={engDetails}
          handleApply={handleApply}
          handleBack={handleBack}
        />
      </MDBox>
    </DashboardLayout>
  );
};
export default EngagementDetailsCommon;
