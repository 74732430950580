import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React, { useContext, useEffect, useState } from 'react';
import DomainList from "./DomainList";
import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
import { SnackbarContext } from "context/SnackbarContext";
import { UserContext } from "context/UserProvider";
import api from "services/api";
import SearchableList from "../EngagementStage/Searchable/SearchableList";

const ProfileSearch = () => {
  const { setSnack } = useContext(SnackbarContext);
  const { userInfo } = useContext(UserContext);
  const [data, setData] = useState();
  const [gigs, setGigs] = useState();
  const [eng, setEng] = useState();
  const [allDomains, setAllDomains] = useState([]);
  const [searchAllData, setSearchAllData] = useState([]);
  const [machingIndustry, setMachingIndustry] = useState([]);
  
  useEffect(() => {
    api.domain
        .findAll()
        .then((result) => {
          let domains = result?.data?.result?.data;
          console.log("domain",domains);
          setAllDomains(domains);
          const tempDomain = domains.filter((value, index, self) =>
          index === self.findIndex((t) => (
            (t.Domain).trim() === (value.Domain).trim()
          ))
        )
          setData(tempDomain);
        })
        .catch((error) => {
          console.log("error", error);
        });

    // const id = userInfo?.id
    // api.engagement.findByEngId(id)
    //   .then((data) => {
    //     let temp = data?.data?.result?.data;
    //     setData(temp);
    //   })
  }, [])
  
  const handleGiglerList = async (row) => {
    setEng(row);    
    const domain = row.Domain;
    api.userInfo.search({domain})
      .then((data) => {
        // let temp = data?.data?.result?.data;
        console.log("Giglers matching with domain,industry not applied", data?.data?.result?.temp);
        setGigs(data?.data?.result?.temp);
        setSearchAllData(data?.data?.result?.temp);
        let t = allDomains.filter(x => x.Domain == row.Domain) || [];
        console.log("filter domains",t);
        setMachingIndustry(t)
      });
  };

  const onIndustryChange = (industry) => {
    console.log("industry",industry);
    if(!industry){
      setGigs(searchAllData);
    } else{
      let t = searchAllData.filter(x => x.industry == industry);
      console.log("gigs", t);
      setGigs(t);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={4}>
            <Card>
              <DomainList engagementList={data} setData={setData} handleGiglerList={handleGiglerList} />
            </Card>
          </Grid>
          <Grid item xs={12} xl={8}>
            <Card>
              <SearchableList eng={eng} giglerList={gigs} showFilters={true} industryList={machingIndustry}  type="Domain" changeIndustry={onIndustryChange} />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
    // <DashboardLayout>
    //   <DashboardNavbar />
    //   <Card sx={{ height: "600px" }}>
    //     <MDTypography type="title" px={2} py={2}>
    //       Profile Search
    //     </MDTypography>
    //   </Card>
    // </DashboardLayout>
  )
}

export default ProfileSearch