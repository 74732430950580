import React, { useContext } from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";


// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FormControl, Modal, Radio, RadioGroup, Stack } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// Otis Admin PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
// import backgroundImage from "assets/images/bg-profile.jpeg";
import backgroundImage from "assets/images/Gigleji-App-Profile-Background.jpg";

import { UserContext } from "context/UserProvider";
import api from "services/api";
import MDButton from "components/MDButton";
import { Input } from "@mui/material";
import { useStyles } from "../AssessorDashboard/styles";
import CircularProgress from "@mui/material/CircularProgress";

//form
import { useFormik } from "formik";
import * as Yup from "yup";
import { SnackbarContext } from "context/SnackbarContext";
import FormField from "layouts/pages/account/components/FormField";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import EditAssessorProfile from "../AdminDashboard/ManageAssessors/EditAssessorProfile";


function AssessorProfile({ info }) {
  const classes = useStyles();
  let navigate = useNavigate();
  const [showProfile, setShowProfile] = useState(true);
  const { setSnack } = useContext(SnackbarContext);
  const [details, setDetails] = useState();
  const [userAvatar, setUserAvatar] = useState(details?.avatar);
  const [videoFile, setVideoFile] = useState(details?.video || "");
  const [open, setOpen] = useState(false);
  const [vurl, setVurl] = useState();
  const [select, setSelect] = useState();
  const { userInfo } = useContext(UserContext);
  const handleCloseFile = () => setOpenFile(false);
  const handleClose = () => setOpen(false);
  const [checked, setChecked] = useState("");
  const [loading, setLoading] = useState(true);
  const [imageLoading, setimageLoading] = useState(false);
  // const [initialValues, setInitialValues] = useState({
  //   giglejiWebsite: "",
  //   displayName: false
  // });

  const handleClick = (event) => {
    document.getElementById("profile-img").click();
  };

  const handleVideoClick = () => {
    document.getElementById("video").click();
  };

  const handleChangeVideo = (event) => {
    let tempVideo = event.target.files[0];
    let size = event.target.files[0].size;
    // console.log("video data", tempVideo);
    // console.log("video data", event.target.files[0]);
    let type = event.target.files[0].type;
    if(type == "video/mp4"){
    if (size >= 8000000) {
      setSnack({
        message: "Upload file size is larger than 8MB",
        open: true,
        type: "error",
      });
    } else if (size < 8000000) {
      setVurl(tempVideo);
      const videoFormData = new FormData();
      videoFormData.append("video", tempVideo);
      videoFormData.append("id", userInfo.id);
      setLoading(true);
      api.userUploads.assessorVideo(videoFormData).then((data) => {
        if (data?.data?.message == "success") {
          setSnack({
            message: "Video uploaded successfully!",
            open: true,
            type: "success",
          });
          console.log("aaa###################3", data?.data?.result?.data?.video);
          setVideoFile(data?.data?.result?.data?.video);
          setLoading(false)
        } else if (data?.data?.message == "error") {
          setSnack({
            message: "Error occurred while uploading video!",
            open: true,
            type: "error",
          });
          setLoading(false)
        }
      });
    }
  }
  else {
    setSnack({
      message: "Upload Only Video files",
      open: true,
      type: "error",
    });
  }
};

  // const uploadVideo = (vurl) => {
  //   console.log("video data", vurl);
  //   const videoFormData = new FormData();
  //   videoFormData.append("video", vurl);
  //   videoFormData.append("id", userInfo.id);
  //   api.userUploads.videoUpdate(videoFormData).then((data) => {
  //     console.log("video data", data);
  //   });
  // };

  const handleChange = (event) => {
    let temp = event.target.files[0];
    // setUserAvatar(URL.createObjectURL(temp));
    const formData = new FormData();
    formData.append("avatar", temp);
    formData.append("id", userInfo.id);
    // console.log("assessor avatar details",event.target.files[0]);
    let type = event.target.files[0].type;
    if(type == "image/jpeg" || type == "image/jpg" || type == "image/png"){
      setimageLoading(true);
          api.userUploads
      .assessorAvatar(formData) //api call for send image data
      .then((data) => {
        if (data?.data?.message == "success") {
          setSnack({
            message: "Profile Picture updated!",
            open: true,
            type: "success",
          });
          setUserAvatar(data?.data?.result?.data?.avatar);
          setimageLoading(false);
        } else {
          setSnack({
            message: "Error occurred while changing profile picture!",
            open: true,
            type: "error",
          });
          setimageLoading(false);
        }
        console.log("image data", data);
      });
      // setimageLoading(false);
    }
    else {
      setSnack({
        message: "Upload Only PNG or JPG or JPEG files",
        open: true,
        type: "error",
      });

    }
  };

  const handleAssessorProfile = () => {
    if (userInfo.type == "assessor") {
      const id = userInfo.id;
      api.assessor.findOne(id).then((data) => {
        const temp = data?.data?.result?.data;
        setDetails(temp);
        setUserAvatar(temp.avatar);
        setVideoFile(temp.video);
        setChecked();
        formik.setFieldValue("displayName", temp?.displayName, false);
        formik.setFieldValue("phoneNumber", temp?.phoneNumber, false)
        formik.setFieldValue("description", temp?.description, false)
        formik.setFieldValue("linkedinProfile", temp?.linkedinProfile, false)
        setLoading(false)
      });
    }
  }
  useEffect(() => {
    handleAssessorProfile()
  }, []);


  const handleChanged = (event) => {
    setChecked(event.target.checked);
  };

  const removeImage = async () =>{
    const result = await api.assessor.update (userInfo.id,{avatar:""});
    if (result){
      console.log("result",result);
      setUserAvatar(" ")
    }
  }

  const initialValues = {
    phoneNumber: details?.phoneNumber || "",
    linkedinProfile: details?.linkedinProfile || "",
    description: details?.description || "",
    displayName: details?.displayName || ""
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object({
    phoneNumber: Yup.string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .required("Phone number is required")
    .min(10, "Too short")  
    .max(10, "Too long"),
  })

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      console.log("assessor profile values", values);
      try {
        const id = details?.assessorId;
        const result = await api.assessor.update(id, values);
        if (result?.data?.message == "success") {
          setSnack({
            message: "Assessor Profile updated!",
            open: true,
            type: "success",
          });
        } else if (result?.data?.message == "error") {
          setSnack({
            message: "Error occurred while updating assessor profile",
            open: true,
            type: "error",
          });
        }
      }
      catch (error) {
        setSnack({
          message: "Error occurred during update!",
          open: true,
          type: "error",
        });
        console.log("ERROR", { ...error });
      }
    },
  });

  const handleEditAssessor = () => {
    setShowProfile(!showProfile)
    handleAssessorProfile()
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {showProfile ? (
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="18.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={1}>
              <MDBox position="relative" height="max-content" mx="auto">
              {imageLoading ? (
                <MDBox 
                sx={{display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p:1,
              }}>
                  <CircularProgress color="error" />
                </MDBox>
              ) : (
                <>
                
                <MDBox alt="sportify logo" 
                           align="right" 
                           right={100} 
                           bottom={20}
                           />
                    <MDButton 
                           variant="gradient" 
                           color="error" 
                           size="small"  
                           iconOnly onClick={() => removeImage()}
                           >
                      <Icon >remove</Icon>
                    </MDButton> 
                <MDAvatar
                  src={userAvatar}
                  alt="profile picture"
                  size="xl"
                  variant="rounded"
                  shadow="sm"
                />
              
                <MDBox
                  alt="spotify logo"
                  position="absolute"
                  right={0}
                  bottom={0}
                  mr={-1}
                  mb={-1}
                  type="input"
                >
                  <MDButton
                    variant="gradient"
                    color="info"
                    size="small"
                    iconOnly
                    onClick={() => handleClick(event)}
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                  <Input
                    type="file"
                    id="profile-img"
                    name="profile-img"
                    accept="image/*"
                    onChange={(e) => handleChange(e)}
                    sx={{ display: "none" }}
                  />
                </MDBox>
                  </>
                  )}
              </MDBox>  
            </Grid>
            <Grid item xs={10}>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                  {details?.firstName}&nbsp;{details?.lastName}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={1}>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDButton varitn="outlined" color="warning" size="small" onClick={() => handleEditAssessor()}>edit</MDButton>
              </MDBox>
            </Grid>
          </Grid>
          <form onSubmit={formik.handleSubmit} method="post">
            <MDBox mt={1}>
              <Grid item xs={12} md={6} xl={4}>
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Name&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.firstName}&nbsp;{details?.lastName}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Industry&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.industry}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Domain&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.domain}
                  </MDTypography>
                </MDBox>
              </Grid>
            </MDBox>
            <MDBox mt={2} />
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} xl={6}>
                <MDBox alignItems="center" justifyContent="center" pt={2} px={2} pb={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Personal Information
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Email&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.email}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={2} alignItems="baseline">
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" >
                    Phone Number&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    {details?.phoneNumber}
                  </MDTypography>
                </MDBox>
              </Grid>

              <Grid item xs={12} xl={6}>
                <MDBox alignItems="center" justifyContent="center" pt={2} px={2} pb={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Organizational Information
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Current Organization&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.currentOrganization}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Current Position&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.currentPosition}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
            <MDBox display="flex" py={1} pr={2} px={2} alignItems="baseline">
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Linkedin Profile&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    {details?.linkedinProfile}
                  </MDTypography>
                </MDBox>
            <MDBox sx={{ display: "flex", borderRadius: "5px", boxShadow: 1, aliginContent:"left", minHeight:"100px" }}>
                <MDTypography sx={{ px: "15px" }} variant="h6" fontWeight="medium" textTransform="capitalize">
                  Career Story :
                </MDTypography>
                <MDTypography sx={{paddingTop:"3px"}} variant="button" fontWeight="regular" color="text">
                  {details?.description}
                </MDTypography>
            </MDBox>
            <Modal open={open} onClose={handleClose}>
              <MDBox className={classes.videoStyle}>
                <video width="450" height="300" controls>
                  <source src={videoFile} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </MDBox>
            </Modal>
            {loading ? (
              <MDBox my={1}>
                <CircularProgress color="primary" />
              </MDBox>
              // <LoadingButton loading variant="outlined">
              //   Loading...
              // </LoadingButton>
            ) : (
              <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }} pt={2} px={2} pb={2}>
                {videoFile ? (
                  <>
                    <MDTypography color="text" variant="button" sx={{ mr: "10px" }}>
                      {/* {videoFile.split('/').pop()} */}
                      {decodeURIComponent(videoFile.split('/').pop())}
                    </MDTypography>
                    <MDButton
                      variant="gradient"
                      size="small"
                      onClick={() => setOpen(true)}
                      color="info"
                      sx={{ mr: "10px" }}
                    >
                      View
                    </MDButton>
                    <Input
                      type="file"
                      name="video"
                      id="video"
                      accept=".mp4"
                      onChange={(e) => handleChangeVideo(e)}
                      sx={{ display: "none", }}
                    />
                    <MDButton
                      variant="gradient"
                      size="small"
                      onClick={() => handleVideoClick()}
                      color="info"
                      sx={{ mr: "10px" }}
                    >
                      Upload
                    </MDButton>
                  </>
                ) : (
                  <>
                    <MDBox >
                      <MDTypography variant="h6" fontWeight="medium">
                        Upload your personalized video : &nbsp;
                      </MDTypography>
                      <MDTypography variant="button" color="info" fontWeight="medium">Note: File size should be less than 8MB</MDTypography>
                    </MDBox>
                    <MDBox>
                      <Input
                        type="file"
                        name="video"
                        id="video"
                        accept=".mp4"
                        onChange={(e) => handleChangeVideo(e)}
                        sx={{ display: "none", }}
                      />

                      <MDButton
                        variant="gradient"
                        size="small"
                        onClick={() => handleVideoClick()}
                        color="info"
                        sx={{ mr: "10px" }}
                      >
                        Upload
                      </MDButton>
                    </MDBox>
                  </>
                )}
              </MDBox>
            )}

            <MDBox px={2} pb={2} display="flex" alignItems="center">
              <MDTypography variant="h6" fontWeight="medium">
                Should Gigleji share your details for the Gig community and it's stakeholders
              </MDTypography>
              <MDBox ml={1}>
                <FormControl component="fieldset">
                  <RadioGroup
                    {...formik.getFieldProps("displayName")}
                  >
                    <Stack direction="row">
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label={
                          <MDTypography color="text" variant="p" fontWeight="regular">
                            Yes
                          </MDTypography>
                        }
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label={
                          <MDTypography color="text" variant="p" fontWeight="regular">
                            No
                          </MDTypography>
                        }
                      />
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </MDBox>
            </MDBox>
            <MDBox ml={2}>
              <MDButton variant="outlined" color="success" size="small" type="submit">save</MDButton>
            </MDBox>
          </form>
        </Card>
      </MDBox>
      ) : (
        <EditAssessorProfile details={details} handleEditAssessor={handleEditAssessor}/>
      )}

    </DashboardLayout>
  );
}

export default AssessorProfile;
