import { createContext, useState } from 'react';
import SnackbarComponent from 'components/SnackbarComponent';

export const SnackbarContext = createContext({});

export const SnackbarProvider = ({ children }) => {
  const [snack, setSnack] = useState({
    message: '',
    type: '',
    open: false,
    vertical: '',
    horizontal: ''
  });

  const onClose = () => {
    setSnack((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  return (
    <SnackbarContext.Provider value={{ setSnack }}>
      {children}
      <SnackbarComponent
        openSnackBar={snack.open}
        message={snack.message}
        type={snack.type}
        vertical={snack.vertical}
        horizontal={snack.horizontal}
        onClose={() => onClose()}
      />
    </SnackbarContext.Provider>
  );
};
export default SnackbarProvider;
