import React, { createContext, useState, useEffect } from "react";
// import API from "../services/api";
// import strings from "../utils/Strings";

const initUserContext = {
  userInfo: {
    userId: "",
    userName: "",
    emailAddress: "exampleEmail@gmail.com",
    srfSiteId: "",
    lastlogin: "",
    abbreviatedName: "",
    type: "",
  },
  setUserInfo: () => {},
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  isLoaded: false,
  setIsLoaded: () => {},
};

export const UserContext = createContext(initUserContext);

const UserProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const verifySession = async () => {
    try {
      // const result = await API.users.verifySession();
      const result = await JSON.parse(localStorage.getItem("user"));
      console.log("Data inside context", result);

      // eslint-disable-next-line no-unused-expressions
      if (result?.isAuthenticated) {
        setIsAuthenticated(true);
        const userData = {
          ...result,
        };
        setUserInfo(userData);
        setIsLoaded(true);
      } else {
        setIsAuthenticated(false);
        setIsLoaded(true);
      }
    } catch (error) {
      setIsAuthenticated(false);
      setIsLoaded(true);
      console.log("Verify session error:", error);
    }
  };

  useEffect(() => {
    verifySession();
  }, []);

  useEffect(() => {
    if (userInfo && Object.keys(userInfo).length > 0) {
      let data = {...userInfo, isAuthenticated: true};
      setIsAuthenticated(true);
      setIsLoaded(true);
      console.log("Inside if context......", data)
      localStorage.setItem("user", JSON.stringify(data));
    }
  }, [userInfo])

  const userContext = {
    ...initUserContext,
    userInfo,
    setUserInfo,
    isAuthenticated,
    setIsAuthenticated,
    isLoaded,
    setIsLoaded,
  };

  return (
    <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
  )
};

export default UserProvider;
