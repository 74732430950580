import { getUserSpecificHeaders } from "./headers";

const onRequest = (config) => {
  const userAgnosticHeaders = getUserSpecificHeaders();
  config.headers = {
    ...config.headers,
		...userAgnosticHeaders
  };
  // console.info("[interceptor request]", config);
  return config;
};

const onRequestError = (error) => {
  // console.error("[request error]", error.response);

  return error;
};

const onResponse = (response) => {
  // console.info("[response]", response, response?.data);

  return response;
};

const onResponseError = (error) => {
  // console.error("[response error]", error.response);

  return error.response;
};

const setupInterceptorsTo = (axiosInstance) => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);

  return axiosInstance;
};

export default setupInterceptorsTo;
