import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "context/UserProvider";
import api from "services/api";
import DataTable from "./DataTable";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import EngagementDetails from "components/EngagementDetails";
import { SnackbarContext } from "context/SnackbarContext";
// import EngagementTabs from './EngagementTabs'

const AssignContractTemplates = () => {
  let navigate = useNavigate();
  const { userInfo } = useContext(UserContext);
  const [show, setShow] = useState(true);
  const handleBack = () => setShow(!show);
  const [engDetails, setEngDetails] = useState();
  const [templates, setTemplates] = useState();
  const [templateNames, setTemplateNames] = useState();
  const { setSnack } = useContext(SnackbarContext);
  const [details, setDetails] = useState({
    columns: [
      { Header: "Engagement Id", accessor: "engagementId", width: "20%" },
      { Header: "Title", accessor: "engagementTitle", width: "20%" },
      { Header: "Organization", accessor: "organizationName", width: "20%" },
      { Header: "Engagement Owner Name", accessor: "engagerName", width: "20%" },
      { Header: "Contract Template", accessor: "template", width: "20%" },
      // { Header: "Industry", accessor: "industry", width: "10%" },
      // { Header: "Domain", accessor: "domain", width: "10%" },
      // { Header: "Skills", accessor: "primarySkills", width: "15%" },
    ],
    rows: [],
  });
  useEffect(() => {
    api.contract.getContractTemplates().then((res) => {
      if (res) {
        console.log("data", res.data.result.data);
        let temps = res.data.result.data;
        if (temps) {
          console.log("tesss", temps);
          setTemplates(temps);
          console.log(
            "template names",
            temps.map((x) => x.title)
          );
          setTemplateNames(temps.map((x) => x.title));
        }
      }
    });
    api.engagement.findAll().then((data) => {
      let temp = data?.data?.result?.data;
      console.log("aaa", temp);
      if (temp) {
        setDetails({ ...details, rows: temp });
      }
    });
  }, []);

  const handleClick = (engId) => {
    // console.log("eng id", engId)
    // let id = engId;
    // api.engagement.findByEngagementId(id).then((data) => {
      // console.log("result", data?.data?.result?.data)
      // setEngDetails(data?.data?.result?.data)
      // navigate("/employer/engagement", {
      //   state: {
      //     engDetails: data?.data?.result?.data,
      //   },
      // });
      // console.log("eng details", engDetails)
      // setShow(!show)
    // });
  };
  
  const selectTemplate = (row,temp) => {
    let t = templates.find(x => x.title == temp);
    console.log("selected template",temp, t);
    let reqData = {
      engagerId: row.engagerId,
      engagementId: row.engagementId,
      contractTemplate: t
    }
    console.log("contract reqData",reqData);
    api.engagement.updateTemplate(reqData).then((data) => {
      console.log("engagement",data);      
      if(data?.data?.message== "success"){
        setSnack({
          message: `Engagement Contract Updated`,
          open: true,
          type: "success",
        });
      }
    }).catch(err =>{
      console.log("error",err);
      setSnack({
        message: `Error occurred while updating Engagement`,
        open: true,
        type: "error",
      });
    })
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        {details?.length <= 0 ? (
          <Card>
            <MDTypography px={2} py={2}>
              No Engagement's posted
            </MDTypography>
          </Card>
        ) : (
          <DataTable table={details} templateNames={templateNames} handleClick={handleClick} selectTemplate={selectTemplate} canSearch />
        )}
      </MDBox>
    </DashboardLayout>
  );
};

// export default EngagementList
export default AssignContractTemplates;
