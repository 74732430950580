import { Card, Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import React from 'react'
import FormField from "layouts/applications/wizard/components/FormField";
import { useStyles } from './styles'

const EngagementDescription = ({ engDetails }) => {
  const css = useStyles();

  return (
    <Card>
      <MDBox px={2} py={2}>
        <MDBox>
          <MDTypography variant="subtitle2" fontWeight="medium" textTransform="capitalize" className={css.title}>
            Engagement Description
          </MDTypography>
          <MDTypography variant="button" color="text" className={css.desc}>{engDetails?.engagementDescription}</MDTypography>
        </MDBox>
        {engDetails?.engagementDescriptionText &&
          <MDBox>
            <MDTypography variant="subtitle2" fontWeight="medium" textTransform="capitalize" className={css.title}>
              Engagement Text File
            </MDTypography>
            {/* <MDTypography variant="button" color="text" className={css.desc}>{engDetails?.engagementDescriptionText}</MDTypography> */}
            <FormField
              sx={{ ml: "20px" }}
              placeholder="Engagement text..."
              multiline
              value={engDetails?.engagementDescriptionText}
              rows={10}
              inputProps={{ type: "text", readOnly: true }}
            />
          </MDBox>
        }
        <MDBox>
          <MDTypography variant="body2" fontWeight="medium" textTransform="capitalize" className={css.title}>
            Engagement Owner Details
          </MDTypography>
          <MDTypography variant="button" color="text" className={css.desc}>{engDetails?.engagerName}</MDTypography>
        </MDBox>
        <MDBox className={css.box}>
          <MDTypography variant="button" color="text" sx={{ pl: "17px" }}>Email:</MDTypography>
          <MDTypography variant="button" color="text">&nbsp;{engDetails?.email}</MDTypography>
        </MDBox>
        {/* <MDBox className={css.box}>
          <MDTypography variant="button" color="text" sx={{ pl: "17px" }}>Visit us at:</MDTypography>
          <MDTypography variant="button" color="text">&nbsp;{engDetails?.webAddress}</MDTypography>
        </MDBox> */}
        <MDBox className={css.role}>
          <Grid container>
            <Grid item xs={2}>
              <MDTypography variant="button" color="text">Role</MDTypography><br />
              <MDTypography variant="button" color="text">Industry</MDTypography><br />
              <MDTypography variant="button" color="text">Domain</MDTypography><br />
              <MDTypography variant="button" color="text">Skills</MDTypography><br />
              <MDTypography variant="button" color="text">Type of employment</MDTypography><br />
              <MDTypography variant="button" color="text">Preferred Engagement</MDTypography><br />
              <MDTypography variant="button" color="text">Engagement On-boarding</MDTypography><br />
            </Grid>
            <Grid item xs={10}>
              <MDTypography variant="button" color="dark">{engDetails?.engagementTitle}</MDTypography><br />
              <MDTypography variant="button" color="dark">{engDetails?.industry}</MDTypography><br />
              <MDTypography variant="button" color="dark">{engDetails?.domain}</MDTypography><br />
              <MDTypography variant="button" color="dark">{engDetails?.primarySkills},{engDetails?.secondarySkills}</MDTypography><br />
              <MDBox>
                {engDetails?.fullTime &&
                  <MDTypography variant="button" color="dark">Full Time{engDetails?.fullTime && engDetails?.partTime ? (",") : ("")}</MDTypography>
                }
                {engDetails?.partTime &&
                  <MDTypography variant="button" color="dark"> Part Time{engDetails?.partTime && engDetails?.projectBased ? (",") : ("")}</MDTypography>
                }
                {engDetails?.projectBased &&
                  <MDTypography variant="button" color="dark"> Project Based{engDetails?.projectBased && engDetails?.serviceBased ? (",") : ("")}</MDTypography>
                }
                {engDetails?.serviceBased &&
                  <MDTypography variant="button" color="dark"> ,Service Based</MDTypography>
                }
              </MDBox>
              <MDBox>
                {engDetails?.onSite &&
                  <MDTypography variant="button" color="dark">On Site{engDetails?.onSite && engDetails?.offSite ? (",") : ("")}</MDTypography>
                }
                {engDetails?.offSite &&
                  <MDTypography variant="button" color="dark"> Off Site{engDetails?.offSite && engDetails?.both ? (",") : ("")}</MDTypography>
                }
                {engDetails?.both &&
                  <MDTypography variant="button" color="dark"> Both (Hybrid){engDetails?.both && engDetails?.needBased ? (",") : ("")}</MDTypography>
                }
                {engDetails?.needBased &&
                  <MDTypography variant="button" color="dark"> Need Based</MDTypography>
                }
              </MDBox>
              {/* <MDBox>
                {engDetails?.zeroToSevenDays &&
                  <MDTypography variant="button" color="dark">0-7 days</MDTypography>
                }
                {engDetails?.eightToFifteenDays &&
                  <MDTypography variant="button" color="dark"> 8-15 days</MDTypography>
                }
                {engDetails?.sixteenToThirtyDays &&
                  <MDTypography variant="button" color="dark"> 16-30 days</MDTypography>
                }
                {engDetails?.moreThanThirtyDays && 
                  <MDTypography variant="button" color="dark"> More Than 30 Days</MDTypography>
                }
              </MDBox> */}
              <MDBox>
                <MDTypography variant="button" color="dark">
                  {engDetails.availability == "zeroToSevenDays" ? "0-7 days" :
                    engDetails.availability == "eightToFifteenDays" ? "8-15 days" :
                      engDetails.availability == "sixteenToThirtyDays" ? "16-30 days" :
                        engDetails.availability == "moreThanThirtyDays" ? "More Than 30 Days" : ""}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        {/* <MDBox mt={4}>
          <MDTypography variant="subtitle2" fontWeight="medium" textTransform="capitalize" className={css.title}>
            Education
          </MDTypography>
          <MDBox className={css.edu}>
            <Grid container>
              <Grid item xs={2}>
                <MDTypography variant="button" color="text">Degree</MDTypography><br />
                <MDTypography variant="button" color="text">Subject</MDTypography><br />
                <MDTypography variant="button" color="text">Passed Out </MDTypography><br />
              </Grid>
              <Grid item xs={2}>
                <MDTypography variant="button" color="dark">{engDetails?.degree}</MDTypography><br />
                <MDTypography variant="button" color="dark">{engDetails?.subject}</MDTypography><br />
                <MDTypography variant="button" color="dark">{engDetails?.passOutYear}</MDTypography><br />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox> */}
      </MDBox>
    </Card>
  )
}

export default EngagementDescription