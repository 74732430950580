/**
=========================================================
* Otis Admin PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Otis Admin PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "examples/Cards/BookingCard";

// Anaytics dashboard components
import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";
import { useContext, useState } from "react";
import { UserContext } from "context/UserProvider";

function Engager() {
  const { userInfo } = useContext(UserContext);
  console.log("user context of sub user", userInfo);
  const { sales, tasks } = reportsLineChartData;
  const [dashboardData, setDashboardData] = useState({
    total_engagements: 5,
    closed_engagements: 1,
    active_engagements: 2,
    gt_hired: 10,
  });
  const current = new Date();
  current.toLocaleTimeString();

  const [barData, setBarData] = useState({
    labels: ["QLT000001", "ENG000001", "MFG000001", "QLT000002", "PRC000001"],
    datasets: {
      label: "Engagement",
      data: [20, 50, 40, 35, 50],
    },
  });

  // const [graphData, setGraphData] = useState({
  //   labels: ["QLT000001", "ENG000001", "MFG000001", "QLT000002", "PRC000001"],
  //   datasets: [
  //     {
  //       label: "value 1",
  //       data: [20000, 65410, 25220, 35100, 58000],
  //       backgroundColor: "rgba(255, 99, 132, 0.5)",
  //     },
  //     {
  //       label: "value 2",
  //       data: [20000, 65410, 25220, 35100, 58000],
  //       backgroundColor: 'rgba(53, 162, 235, 0.5)',
  //     },
  //   ],
  // });
  const labels = ['January', 'February', 'March', 'April', 'May'];

  const [graphData, setGraphData] = useState(
    {
      labels,
      datasets: [
        {
          label: 'Dataset 1',
          data: [20000, 65410, 25220, 35100, 58000],
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: 'Dataset 2',
          data: [15000, 55410, 35220, 45100, 48000],
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    }  
  );

  const [graphDataTwo, setGraphDataTwo] = useState({
    labels: ["Jun", "May", "Apr", "Mar", "Feb", "Jan"],
    datasets: { label: "value", data: [1, 2, 5, 6, 2, 4] },
  });
  const actionButtons = (
    <>
      <Tooltip title="Refresh" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title="Edit" placement="bottom">
        <MDTypography variant="body1" color="info" lineHeight={1} sx={{ cursor: "pointer", mx: 3 }}>
          <Icon color="inherit">edit</Icon>
        </MDTypography>
      </Tooltip>
    </>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container>{/* <SalesByCountry /> */}</Grid>
        <MDBox mt={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="info"
                  title="Engagement wise GigTalent number"
                  // description={ <>Engagement wise Gigler number</>
                  // }
                  date="Updated 2 min ago"
                  chart={barData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                {/* <ReportsLineChart
                  color="success"
                  title="Engagement wise value"
                  // description={
                  //   <>Engagement wise value
                  //   </>
                  // }
                  date="Updated 2 min ago"
                  chart={graphData}
                /> */}
                <ReportsBarChart
                  color="success"
                  title="Engagement wise value"
                  // description={<>Less than 3 days<br />More than 3 days<br /> Total</>}
                  date={current.toLocaleTimeString()}
                  chart={graphData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Engagements posted in last 6 months"
                  // description={<>Engagements posted in last 6 months</>}
                  date="Updated 2 min ago"
                  chart={graphDataTwo}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="weekend"
                  title="Engagements Posted"
                  count={dashboardData?.total_engagements}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="Active Engagements"
                  count={dashboardData?.active_engagements}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="Closed Engagements"
                  count={dashboardData?.closed_engagements}
                />
              </MDBox>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="Total Engagement Value"
                  count={85000}
                // percentage={{
                //   color: "success",
                //   amount: "+3%",
                //   label: "than last month",
                // }}
                />
              </MDBox>
            </Grid> */}
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="group_add"
                  title="Number of GigTalents hired"
                  count={dashboardData?.gt_hired}
                />
              </MDBox>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={3}>
              {userInfo?.type == "employer" &&
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="primary"
                    icon="currency_rupee"
                    title="Revenue earned so far"
                    count="500000"
                  // percentage={{
                  //   color: "success",
                  //   amount: "",
                  //   label: "Just updated",
                  // }}
                  />
                </MDBox>
              }
            </Grid> */}
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Engager;
