/**
=========================================================
* Otis Admin PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, Checkbox, FormGroup, Stack } from "@mui/material";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Otis Admin PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/pages/profile/components/Header";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings";

// Data

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import selectData from "./data/selectData";
import AssessorList from "./AssessorList";
import { useContext, useEffect, useState } from "react";
import MDButton from "components/MDButton";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';



//form
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import api from "services/api";
import FormField from "layouts/pages/account/components/FormField";
import { SnackbarContext } from "context/SnackbarContext";

const initialValues = {
  assessorId: "",
  firstName: "",
  lastName: "",
  currentPosition: "",
  currentOrganization: "",
  industry: "",
  domain: "",
  email: "",
  password: "",
  phoneNumber: "",
  description: "",
  status: 1
};

const ManageAssessor = () => {
  let navigate = useNavigate();
  const { setSnack } = useContext(SnackbarContext);
  const [showForm, setShowForm] = useState(false);
  const handleShow = () => setShowForm(true);
  const handleDontShow = () => {
    formik.handleReset();
    setShowForm(false);
  }

  const [data, setData] = useState();
  const [domain, setDomain] = useState();
  const [indData, setIndData] = useState([]);
  const [industry, setIndustry] = useState();
  const [masterDomain, setMasterDomain] = useState();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    api.industry
      .findAll()
      .then((data) => {
        console.log("list of industries", data);
        let temp = data?.data?.result?.data;
        console.log(temp);
        setIndData(temp);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    api.domain
      .findAll()
      .then((data) => {
        console.log("list of domains", data);
        let temp = data?.data?.result?.data;
        console.log(temp);
        setData(temp);
        setMasterDomain(temp);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);


  const phoneRegExp = /^[0-9]*$/;
  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email().required("Email is required"),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Too short")
      .max(10, "Too long")
      .max(10, "Too long")
    ,
    password: Yup.string()
      .required("Password is required")
      .min(8, "Your password must be longer than 8 characters.")
      .max(25)
      .matches(/^(?=.{8,})/, "Must Contain 8 Characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])/,
        "Must Contain One Uppercase, One Lowercase"
      )
      .matches(
        /^(?=.*[!@#\$%\^&\*])/,
        "Must Contain One Special Case Character"
      )
      .matches(/^(?=.{6,20}$)\D*\d/, "Must Contain One Number"),
    // phoneNumber: Yup.string().matches(phoneRegExp, "phoneNumber is required"),
    currentPosition: Yup.string().required("Current position is required"),
    currentOrganization: Yup.string().required("Current Organization is required"),
    industry: Yup.string().required("Industry is required"),
    domain: Yup.string().required("Domain is required"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log("assessor values", values);
      try {
        let register = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: (values.email).toLowerCase(),
          phoneNumber: values.phoneNumber,
          password: values.password,
          type: "assessor",
        };
        const registerAssessor = await api.users.registerUser(register).then((response) => {
          const userId = response?.data?.result?.data?.id;
          values.assessorId = userId;
          const result = api.assessor
            .create(values)
            .then((result) => {
              if (result?.data.message == "success") {
                navigate(`/admin/manage-assessors`);
                setSnack({
                  message: `${values.email} Assessor is created`,
                  open: true,
                  type: "success",
                });
                handleReset();
                setShowForm(false);
              } else if (result?.data?.message == "error") {
                setSnack({
                  message: `Error occurred while creating new assessor`,
                  open: true,
                  type: "error",
                });
              } else {
                setSnack({
                  message: `Error occurred while creating new assessor`,
                  open: true,
                  type: "error",
                });
              }
            })
            .catch((error) => {
              setSnack({
                message: `Error occurred while creating Assessor`,
                open: true,
                type: "error",
              });
              console.log("Assessor error", error);
            });
        });
      } catch (error) {
        // console.log("ERROR LOGIN: ", { ...error });
      }
    },
  });

  const handleReset = () => {
    formik.handleReset();
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card id="basic-info" sx={{ overflow: "visible" }}>
        {!showForm &&
          <>
            <MDBox p={3} display="flex" justifyContent="space-between">
              <MDTypography variant="h5">Assessor List</MDTypography>
              <MDButton variant="gradient" size="medium" color="success" onClick={() => handleShow()}>
                Add Assessor
              </MDButton>
            </MDBox>
            <MDBox>
              <AssessorList />
            </MDBox>
          </>
        }

        {showForm && (
          <MDBox>
            <MDBox p={4}>
              <MDTypography variant="h4" px={1}>Assessor Info</MDTypography>
            <MDBox p={2} md={5}>
              <MDBox
                variant="button"
                color="dark"
                align="right"
                // className={css.backIcon}
                sx={{mb:"10px",mt:"-65px"}}
              >
                <MDButton
                      variant="outlined"
                      color="info"
                      size="small"
                onClick={() => handleDontShow()}

                    >
                
                <ArrowBackIcon sx={{ pt: "2px" }} />
                Back
                    </MDButton>
              </MDBox>
              </MDBox>
            </MDBox>
            <form onSubmit={formik.handleSubmit} onReset={() => handleReset()} method="post">
              <MDBox pb={-10} px={5.5} mb={2} mt={-10}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4} mt={1}>
                    <FormField
                      label="First Name"
                      placeholder="Enter First Name"
                      name="firstName"
                      {...formik.getFieldProps("firstName")}
                      success={formik.values.firstName > 0 && !formik.errors.firstName}
                      error={
                        formik.touched.firstName && formik.errors.firstName
                          ? true
                          : false
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                          ? formik.errors.firstName
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} mt={1}>
                    <FormField
                      label="Last Name"
                      placeholder="Enter Last Name"
                      name="lastName"
                      {...formik.getFieldProps("lastName")}
                      success={formik.values.lastName > 0 && !formik.errors.lastName}
                      error={
                        formik.touched.lastName && formik.errors.lastName
                          ? true
                          : false
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                          ? formik.errors.lastName
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} mt={1}>
                    <FormField
                      label="Current Position"
                      placeholder="Enter Current Position"
                      name="currentPosition"
                      {...formik.getFieldProps("currentPosition")}
                      success={formik.values.currentPosition > 0 && !formik.errors.currentPosition}
                      error={
                        formik.touched.currentPosition && formik.errors.currentPosition
                          ? true
                          : false
                      }
                      helperText={
                        formik.touched.currentPosition && formik.errors.currentPosition
                          ? formik.errors.currentPosition
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} mt={1}>
                    <FormField
                      label="Current Organization"
                      placeholder="Enter Current Organization"
                      name="currentOrganization"
                      {...formik.getFieldProps("currentOrganization")}
                      success={formik.values.currentOrganization > 0 && !formik.errors.currentOrganization}
                      error={
                        formik.touched.currentOrganization && formik.errors.currentOrganization
                          ? true
                          : false
                      }
                      helperText={
                        formik.touched.currentOrganization && formik.errors.currentOrganization
                          ? formik.errors.currentOrganization
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} mt={1}>
                    <Autocomplete
                      options={indData}
                      autoHighlight
                      getOptionLabel={(option) => option.Industry}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.Industry}
                        </Box>
                      )}
                      onChange={(event, newValue) => {
                        setIndustry(newValue);
                        formik.setFieldValue("industry", newValue.Industry, false);
                        let y = masterDomain.filter(x => x.Industry == newValue.Industry)
                        setData(y);
                        formik.setFieldValue("domain", "", false);
                      }}
                      renderInput={(params) => (
                        <FormField
                          {...params}
                          InputLabelProps={{ shrink: true }}
                          label="Industry"
                          placeholder="Select Industry"
                          {...formik.getFieldProps("industry")}
                          error={formik.touched.industry && formik.errors.industry ? true : false}
                          helperText={
                            formik.touched.industry && formik.errors.industry
                              ? formik.errors.industry
                              : null
                          }
                          name="industry"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} mt={1}>
                    <Autocomplete
                      options={data}
                      autoHighlight
                      getOptionLabel={(option) => option.Domain}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.Domain}
                        </Box>
                      )}
                      onChange={(event, newValue) => {
                        setDomain(newValue);
                        formik.setFieldValue("domain", newValue.Domain, false);
                      }}
                      renderInput={(params) => (
                        <FormField
                          {...params}
                          InputLabelProps={{ shrink: true }}
                          label="Domain"
                          placeholder="Select Domain"
                          name="domain"
                          {...formik.getFieldProps("domain")}
                          success={formik.values.domain > 0 && !formik.errors.domain}
                          error={
                            formik.touched.domain && formik.errors.domain
                              ? true
                              : false
                          }
                          helperText={
                            formik.touched.domain && formik.errors.domain
                              ? formik.errors.domain
                              : null
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} mt={1}>
                    <FormField
                      label="Email"
                      placeholder="example@mail.com"
                      inputProps={{ type: "email", style: { textTransform: "lowercase" } }}
                      name="email"
                      {...formik.getFieldProps("email")}
                      success={formik.values.email > 0 && !formik.errors.email}
                      error={
                        formik.touched.email && formik.errors.email
                          ? true
                          : false
                      }
                      helperText={
                        formik.touched.email && formik.errors.email
                          ? formik.errors.email
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} mt={1}>
                    <FormField
                      type="number"
                      label="Phone number"
                      placeholder="+91"
                      name="phoneNumber"
                      inputProps={{ type: "number" }}
                      onKeyPress = {(event) => {
                        if(!phoneRegExp.test(event.key))
                        event.preventDefault();
                      }}
                      {...formik.getFieldProps("phoneNumber")}
                      success={formik.values.phoneNumber > 0 && !formik.errors.phoneNumber ? true : false}
                      error={formik.touched.phoneNumber && formik.errors.phoneNumber ? true : false }
                      helperText={
                        formik.touched.phoneNumber && formik.errors.phoneNumber
                          ? formik.errors.phoneNumber
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                        <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                    <MDBox mb={1} mt={1}>
                      <FormControl sx={{ width: '100%' }} variant="standard">
                      <Input
                          type={showPassword ? 'text' : 'password'}
                          label="Password"
                          placeholder="Abcd@123"
                          name="password"
                          value=""
                          {...formik.getFieldProps("password")}
                          error={formik.touched.password && formik.errors.password ? true : false}
                          helperText={
                            formik.touched.password && formik.errors.password
                              ? formik.errors.password
                              : null
                          }
                          fullWidth
                            endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}>
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>}/>
                                {formik.touched.password && formik.errors.password && (
                                <div style={{fontSize:'12px', textAlign:'left'}}
                                    className="text-danger">
                                    {formik.errors.password}
                                    </div>)}
                      </FormControl>  
                      </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} mt={-2}>
                    <InputLabel htmlFor="brief">A Brief Story</InputLabel>
                    <MDBox mb={1} mt={1}>
                      <FormControl sx={{width:'100%'}} variant="outlined">
                    <OutlinedInput
                      id="brief"
                      label="A Brief Story"
                      placeholder="Maximun 250 Character"
                      name="description"
                      multiline
                      rows={4}
                      inputProps = {{type:"text"}}
                      {...formik.getFieldProps("description")}
                      success={formik.values.description > 0 && !formik.errors.description}
                      error={
                        formik.touched.description && formik.errors.description
                          ? true
                          : false
                      }
                      helperText={
                        formik.touched.description && formik.errors.description
                          ? formik.errors.description
                          : null
                      }
                    />
                    </FormControl>
                    </MDBox>
                  </Grid>
                  <Grid item mt={-1} xs={12} sm={12} display="flex" justifyContent="space-between">
                    <MDButton
                      variant="gradient"
                      color="error"
                      size="small"
                      onClick={handleDontShow}
                    >
                      CANCEL
                    </MDButton>
                    <MDButton variant="gradient" color="success" size="small" type="submit">
                      SUBMIT
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </form>
          </MDBox>
        )}
      </Card>
    </DashboardLayout>
  );
};

export default ManageAssessor;
