import { Card, Divider, Grid, Modal } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React, { useEffect, useState } from 'react'
import api from 'services/api';
import ManageGigler from '../../ManageGigler'
import DataTable from './DataTable';
import { useStyles } from './styles'
import GiglerFeedbackForm from 'layouts/pages/common/GiglerFeedbackForm';


const GiglerFeedback = () => {
  const css = useStyles();
  const [show, setShow] = useState(true);
  const [open, setOpen] = useState(false);
  const [feedbackDetails, setFeedbackDetails] = useState();
  const handleClose = () => {
    setOpen(false);
  }
  const handleGetEngagement = (id) => {
    console.log("gigler row id", id);
    setShow(!show);
  }
  const [details, setDetails] = useState({
    columns: [
      { Header: "GigTalent Name", accessor: "giglerName", width: "25%" },
      { Header: "Engagement ID", accessor: "engagementId", width: "25%" },
      { Header: "Engagement Title", accessor: "engagementTitle", width: "25%" },
      { Header: "Engagement Owner Name", accessor: "engagerName", width: "25%" },

    ],
    rows: []
  })

  // useEffect(() => {
  //   const id = userInfo?.id
  //   api.engagement.findByEngId(id, { status: "open" })
  //     .then((data) => {
  //       if (data.length <= 0) {
  //         setDetails(null)
  //       }
  //       let temp = data?.data?.result?.data
  //       setDetails({ ...details, rows: temp });
  //     })
  // }, [])

  useEffect(() => {
    api.giglerFeedback.findByUserId()
      .then((data) => {
        console.log("feedback list", data?.data?.result?.data)
        let temp = data?.data?.result?.data || [];
        setDetails({ ...details, rows: temp });
      })
      .catch(err => console.log("error", err))
  }, [])


  const handleClick = (row) => {
    setOpen(true)
    console.log("feedback details", row.original)
    setFeedbackDetails(row.original);
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {details && details.length < 0 ? (
        <Card sx={{ minHeight: "100%", minWidth: "100%" }}>
          <MDTypography px={2} py={2}>GigTalent Feedback list is empty</MDTypography>
        </Card>
      ) : (
        <Card>
          <DataTable table={details} handleClick={handleClick} canSearch />
        </Card>
      )
      }
      <Modal
        open={open}
        onClose={handleClose}
      >
        <MDBox className={css.modal}>
          <GiglerFeedbackForm feedbackDetails={feedbackDetails} handleClose={handleClose} />
        </MDBox>
      </Modal>
    </DashboardLayout >
  )
}

export default GiglerFeedback