/**
=========================================================
* Otis Admin PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import AssessorProfile from "layouts/pages/AssessorDashboard/assessorProfile";

// Otis Admin PRO React example components
// import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "services/api";
import DataTable from './DataTable'

// Data

const AssessorList = () => {
  let navigate = useNavigate();
  const [show, setShow] = useState(true)
  const [info, setInfo] = useState()
  const [details, setDetails] = useState({
    columns: [
      { Header: "name", accessor: "firstName", width: "20%" },
      { Header: "Creation Date", accessor: "createdAt", width: "20%" },
      { Header: "Organization", accessor: "currentOrganization", width: "25%" },
      { Header: "Position", accessor: "currentPosition" },
      { Header: "Industry", accessor: "industry", width: "7%" },
      { Header: "Domain", accessor: "domain" },
      { Header: "Email", accessor: "email" },
      { Header: "Phone number", accessor: "phoneNumber" },
      // { Header: "salary", accessor: "salary" },
    ],
    rows: []
  })

  useEffect(() => {
    api.assessor.findAll().then(data => {
      let temp = data?.data?.result?.data;
      if (temp.length <= 0) {
        setDetails("0")
      } else {
        console.log(temp);
        setDetails({ ...details, rows: temp });
      }
    }).catch(error => {
      console.log("error", error)
    })
  }, [])

  const handleClick = (Id) => {
    let id = Id
    api.assessor.findOne(id)
      .then((data) => {
        let temp = data?.data?.result?.data
        console.log("result", data?.data?.result?.data)
        navigate('/admin/assessor-details/', {
          state: {
            value: temp
          }
        })
      })
  }

  return (
    // <DashboardLayout>
    <>
      {show && details != "0" ? (
        <MDBox>
          <DataTable table={details} canSearch handleClick={handleClick} />
        </MDBox>
      ) : (
        <MDBox>
          <MDTypography variant="button" fontWeight="medium"> Assessor list is empty</MDTypography>
        </MDBox>
      )
      }
    </>
    // </DashboardLayout>
  );
}

export default AssessorList;
