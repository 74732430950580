import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useContext } from "react";
import FormField from "layouts/pages/account/components/FormField";
import { useFormik } from "formik";
import MDButton from "components/MDButton";
import { SnackbarContext } from "context/SnackbarContext";
import api from "services/api";
import * as Yup from "yup";

const AddIndustry = ({ handleClose, handleList }) => {
  const initialValues = {
    Industry: "",
    status: 1,
    Domain: "",
    skillSet: "",
    code: ""
  };

  const validationSchema = Yup.object({
    Industry: Yup.string().required("Industry is required"),
    Domain: Yup.string().required("Domain is required"),
    code: Yup.string()
      .min(3, "Series should be minimum 3 character")
      .max(3, "Series should be maximum 3 character")
      .required("Series is required"),
  })

  const { setSnack } = useContext(SnackbarContext);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const result = await api.industry.create(values);
        if (result) {
          let obj = {
            Industry: values.Industry,
            Domain: values.Domain,
            skillSet: "",
            status: values.status,
            code: (values.code).toUpperCase()
          }
          const domainRes = await api.domain.create(obj);
          if (domainRes) {
            setSnack({
              message: `New Industry ${values.Industry} Added!`,
              open: true,
              type: "success",
            });
            handleList();
            handleClose();

          } else {
            setSnack({
              message: "some error occurred while creating new industry",
              open: true,
              type: "error"
            })
          }
        } else {
          throw new error("failed to update");
        }
      } catch (error) {
        setSnack({
          message: "Error occurred during Addition!",
          open: true,
          type: "error",
        });
        console.log("ERROR update: ", { ...error });
      }
    },
    // validate,
  });

  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z\s]+$/;

  return (
    <Card>
      <MDBox px={2} py={2}>
        <MDTypography variant="h6" color="dark">
          Add Industry
        </MDTypography>
        <form onSubmit={formik.handleSubmit} method="post">
          <MDBox px={2} py={2}>
            <MDBox>
              <FormField
                variant="outlined"
                label="Industry"
                name="Industry"
                onKeyDown={(event) => {
                  if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                {...formik.getFieldProps("Industry")}
                error={formik.touched.Industry && formik.errors.Industry ? true : false}
                success={formik.values.Industry > 0 && !formik.errors.Industry}
                helperText={
                  formik.touched.Industry && formik.errors.Industry
                    ? formik.errors.Industry
                    : null
                }
              />
            </MDBox>
            <MDTypography variant="caption" fontWeight="medium" >(Note:- Add atleast one domain initially)</MDTypography>
            <MDBox mt={1}>
              <FormField
                variant="outlined"
                label="Domain"
                name="Domain"
                onKeyDown={(event) => {
                  if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                {...formik.getFieldProps("Domain")}
                error={formik.touched.Domain && formik.errors.Domain ? true : false}
                success={formik.values.Domain > 0 && !formik.errors.Domain}
                helperText={
                  formik.touched.Domain && formik.errors.Domain
                    ? formik.errors.Domain
                    : null
                }
              />
            </MDBox>
            <MDTypography variant="caption" fontWeight="medium">(Note:- Series is three letter capital word which matches with domain)</MDTypography>
            <MDBox mt={1} display="flex" justifyContent="space-between">
              <FormField
                sx={{ maxWidth: "150px" }}
                variant="outlined"
                label="Series"
                name="code"
                onKeyDown={(event) => {
                  if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
                {...formik.getFieldProps("code")}
                error={formik.touched.code && formik.errors.code ? true : false}
                success={formik.values.code > 0 && !formik.errors.code}
                helperText={
                  formik.touched.code && formik.errors.code
                    ? formik.errors.code
                    : null
                }
              />
              <MDButton variant="gradient" color="success" size="small" type="submit">
                Add
              </MDButton>
            </MDBox>
          </MDBox>
        </form>
      </MDBox >
    </Card >
  );
};

export default AddIndustry;
