import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => {
  return {
    btn: {
      position: "absolute",
      bottom: "20px",
      right: "25px",
    },
    showBtn: {
      position: "absolute",
      bottom: "20px",
      right: "120px",
    },
    previewBtn: {
      position: "absolute",
      bottom: "20px",
      right: "240px",
    },
    field: {
      position: "absolute",
      left: "1000px",
      top: "940px",
      width: "120px",
    },
    fieldBtn: {
      position: "absolute",
      left: "1000px",
      top: "1000px",
      width: "120px",
      mb: "10px",
    },
    resumeStyle: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "75%",
      height: "90%",
      margin: "auto",
      bgColor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    },
    videoStyle: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      background: "rgba(255, 255, 255, 0.7)",
      bgColor: "background.white",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    },
    assessment: {
      position: "absolute",
      top: "50%",
      width: "90%",
      height: "75%",
      left: "50%",
      bgColor: 'background.paper',
      transform: "translate(-50%, -50%)",
      boxShadow: 24,
      p: 4,
    },
  };
});
