import * as React from "react";
import MDBox from "components/MDBox";
import { Card, Divider, Grid, TabPanelUnstyled } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useStyles } from "./styles";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
//components
import EngagementDescription from "./EngagementDescription";
import MDBadge from "components/MDBadge";
//icons
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MDButton from "components/MDButton";
import { useState, useEffect } from "react";
import AppliedList from "../Applied/AppliedList";
import api from "services/api";
import SelectedList from "../Selected/SelectedList";
import ShortListedList from "../ShortListed/ShortListedList";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useContext } from "react";
import { SnackbarContext } from "context/SnackbarContext";
import FormField from "layouts/pages/account/components/FormField";


const EngagementTabs = () => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const css = useStyles();
  const { setSnack } = useContext(SnackbarContext);
  const [value, setValue] = useState("1");
  const [applied, setApplied] = useState();
  const [shortlist, setShortList] = useState();
  const [selected, setSelected] = useState();
  const [engDetails, setEngDetails] = useState({});
  const [endDate, setEndDate] = useState();
  const [closeEngagementEligible, setCloseEngagementEligible] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    console.log("state values", state?.engDetails);
    if (state) {
      setEngDetails(state?.engDetails);
    }
  }, []);

  const handleBack = () => {
    navigate("/employer/engagement-details/engagements");
  };

  const formatDate = (string) => {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  };

  useEffect(() => {
    let id = state?.engDetails?.engagementId;
    api.engagementProcess.findAll(id).then((data) => {
      if (data) {
        let temp = data?.data?.result?.data;
        setApplied(temp);
      }
    });
    api.engagementProcess.findAllShortlisted(id).then((data) => {
      if (data) {
        let temp = data?.data?.result?.data;
        setShortList(temp);
      }
    });
    api.engagementProcess.findAllSelected(id).then((data) => {
      if (data) {
        let temp = data?.data?.result?.data;
        console.log("selected@@@@@@", temp);
        let checker = temp.every(v => v?.contractStatus == "5" || v?.contractStatus == "6");
        console.log("zzzzzzzzz", checker);
        setCloseEngagementEligible(state?.engDetails?.status == 'closed' ? false : checker);
        setSelected(temp);
      }
    });
  }, []);

  const closeEngagement = () => {
    console.log("close engagement", engDetails);
    let reqData = {
      engagementId: engDetails?.engagementId,
      engagerId: engDetails?.engagerId,
      status: 'closed',
      endDate: endDate,
    }
    api.engagement.update(reqData).then(data => {
      console.log("data", data);
      if (data?.data?.message == 'success') {
        setCloseEngagementEligible(false);
        setEngDetails({ ...engDetails, status: 'closed' })
        setSnack({
          message: `Engagement Closed`,
          open: true,
          type: "success",
        });
      } else if (data?.data?.message == 'error') {
        setSnack({
          message: data?.data?.result?.message,
          open: true,
          type: "error",
        });
      }
    })
  }

  const dateChange = (e) => {
    console.log("date", e.target.value);
    setEndDate(e.target.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox sx={{ width: "100%" }}>
        <Card>
          <MDBox px={2} py={3}>
            <MDBox className={css.box}>
              <MDTypography
                variant="subtitle1"
                color="info"
                fontWeight="medium"
                textTransform="capitalize"
                className={css.engId}
              >
                {engDetails?.engagementId}
              </MDTypography>
              <MDTypography
                variant="subtitle1"
                fontWeight="medium"
                textTransform="capitalize"
                className={css.title}
              >
                {`-`}&nbsp;&nbsp;&nbsp;{engDetails?.engagementTitle}
              </MDTypography>
              <MDTypography
                variant="button"
                color="dark"
                className={css.backIcon}
                onClick={() => handleBack()}
              >
                <ArrowBackIcon sx={{ pt: "2px" }} />
                Back
              </MDTypography>
            </MDBox>
            <MDTypography variant="body2" textTransform="capitalize" className={css.orgName}>
              {engDetails?.organizationName}
            </MDTypography>
            <MDBox className={css.box}>
              <MDTypography variant="button" color="text" className={css.icon}>
                <AccountBalanceWalletIcon fontSize="string" />
              </MDTypography>
              <MDTypography variant="button" textTransform="capitalize" className={css.txt}>
                {engDetails?.indicativeBudget}(INR)
              </MDTypography>
            </MDBox>
            <MDBox className={css.box}>
              <MDTypography variant="button" color="text" className={css.icon}>
                <LocationOnIcon fontSize="string" />
              </MDTypography>
              <MDTypography variant="button" textTransform="capitalize" className={css.txt}>
                {engDetails?.location}
              </MDTypography>
            </MDBox>
            <MDBox className={css.box}>
              <MDBox>
                <MDTypography variant="button" color="text" className={css.icon}>
                  Posted :
                </MDTypography>
                <MDTypography variant="button" color="dark" fontWeight="medium" className={css.txt}>
                  &nbsp;{formatDate(engDetails?.createdAt)}{" "}
                </MDTypography>
              </MDBox>
              <Divider
                orientation="vertical"
                variant="string"
                color="dark"
                sx={{ color: "dark" }}
              />
              <MDBox>
                <MDTypography variant="button" color="text" className={css.icon}>
                  Billing :
                </MDTypography>
                <MDTypography variant="button" color="dark" fontWeight="medium" className={css.txt}>
                  &nbsp;{engDetails?.billing}
                </MDTypography>
              </MDBox>
              <Divider
                orientation="vertical"
                variant="string"
                color="dark"
                sx={{ color: "dark" }}
              />
              <MDBox>
                <MDTypography variant="button" color="text" className={css.icon}>
                  No. of Positions :
                </MDTypography>
                <MDTypography variant="button" color="dark" fontWeight="medium" className={css.txt}>
                  &nbsp;{engDetails?.numberOfPositions}
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDTypography variant="button" color="text" className={css.icon}>
                  Duration :
                </MDTypography>
                <MDTypography variant="button" color="dark" fontWeight="medium" className={css.txt}>
                  &nbsp;{engDetails?.engagementDuration}&nbsp;(Days )
                </MDTypography>
              </MDBox>
              <MDBox>
                {engDetails?.status == "closed" && (
                  <MDBadge color="error" size="md" badgeContent="closed" />
                )}
              </MDBox>
            </MDBox>
            {closeEngagementEligible && (
              <MDBox>
                <Grid container spacing={2} my={1} mx={1}>
                  <Grid item xs={12} sm={4}>
                    <FormField
                      label="End Date"
                      placeholder="dd-mmm-yyyy"
                      name="endDate"
                      inputProps={{ type: "date", shrink: true }}
                      onChange={dateChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <MDButton
                      color="warning"
                      size="small"
                      variant="outlined"
                      target="_blank"
                      // href={template}
                      disabled={endDate ? false : true}
                      onClick={closeEngagement}
                      sx={{ ml: "10px" }}
                    >
                      Close Engagement
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            )}
          </MDBox>
        </Card>
        <TabContext value={value}>
          <MDBox sx={{ marginTop: "10px", padding: "0" }}>
            <TabList onChange={handleChange} textColor="light" indicatorColor="info">
              <Tab label="Description" value="1" />
              <Tab label="GigTalent Applied" value="2" />
              <Tab label="GigTalent Short-listed" value="3" />
              <Tab label="GigTalent Selected" value="4" />
            </TabList>
          </MDBox>
          <TabPanel value="1" sx={{ padding: "0px" }}>
            <EngagementDescription engDetails={engDetails} />
          </TabPanel>
          <TabPanel value="2" sx={{ padding: "0px", justifyContent: "center" }}>
            <Card>
              <AppliedList giglerList={applied} sx={{ justifyContent: "center" }} />
            </Card>
          </TabPanel>
          <TabPanel value="3" sx={{ padding: "0px" }}>
            <Card>
              <ShortListedList giglerList={shortlist} sx={{ justifyContent: "center" }} />
            </Card>
          </TabPanel>
          <TabPanel value="4" sx={{ padding: "0px" }}>
            <Card>
              <SelectedList giglerList={selected} engDetails={engDetails} />
            </Card>
          </TabPanel>
        </TabContext>
      </MDBox>
    </DashboardLayout>
  );
};

export default EngagementTabs;
