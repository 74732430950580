import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => {
  return {
    resumeStyle: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
      height: '90%',
      margin: 'auto',
      // bgColor: 'background.paper',
      // border: '1px solid #000',
      boxShadow: 24,
    },
    title: {
      position: 'absolute',
      left: "37%"
    },
    icon: {
      position: 'absolute',
      left: "95%"
    },
    engTitle: {
      paddingLeft: "20px",
      paddingTop: "5px"
    },
    engId: {
      paddingLeft: "20px",
      paddingTop: "5px"
    },
    orgName: {
      paddingLeft: "20px",
    },
    budget: {
      display: "flex"
    },
    icon: {
      float: "left",
      paddingLeft: "18px",
      paddingTop: "6px"
    },
    txt: {
      float: "left",
      paddingLeft: "5px",
      paddingTop: "6px"
    },
    box: {
      display: "flex",
      alignItem: "center",
    },
    desc: {
      paddingLeft: "18px",
      paddingTop: "6px"
    },
    role: {
      marginLeft: "20px",
      marginTop: "30px"
    },
    edu: {
      marginLeft: "20px"
    },
    backIcon: {
      position: "absolute",
      right: "10px",
      paddingTop: "10px",
      float: "right",
      paddingRight: "18px",
      cursor: "pointer"
    },

  }
});