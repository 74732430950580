import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
// import backgroundImage from "assets/images/bg-profile.jpeg";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MDBox from './MDBox';
import { Card, CircularProgress, Divider, Grid } from '@mui/material';
import MDAvatar from './MDAvatar';
import MDTypography from './MDTypography';
import moment from "moment";
import { useStyles } from './styles'
import EditEngagerProfile from 'layouts/pages/AdminDashboard/ManageEngager/EditEngagerProfile';
import MDButton from './MDButton';
import { SnackbarContext } from 'context/SnackbarContext';
import api from 'services/api';
import SubUserList from 'layouts/pages/AdminDashboard/ManageEngager/SubUserList';
import backgroundImage from "assets/images/Gigleji-App-Profile-Background.jpg";
import { UserContext } from 'context/UserProvider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MDBadge from './MDBadge';
import { setDocumentLoading } from 'react-doc-viewer/build/state/actions';

const EngagerProfile = () => {
  const { userInfo } = useContext(UserContext);
  const css = useStyles();
  const { state } = useLocation();
  let navigate = useNavigate();
  const { setSnack } = useContext(SnackbarContext);
  // console.log("state values", state)
  const [details, setDetails] = useState(state.value)
  const [planDetails, setPlanDetails] = useState();
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const handleEdit = () => {
    setShow(!show);
  }
  const handleBack = () => setShow(!show)
  const handleDelete = (value) => {
    console.log("engager id", value.engagerId)
    let id = value.engagerId
    let data = {
      status: '0'
    }
    api.engager.update(id, data)
      .then(res => {
        let uid = value.engagerId
        let data2 = {
          status: '0'
        }
        api.users.updateUser(uid, data2)
          .then((res) => {
            setSnack({
              message: `${value.email} Engager is deleted`,
              open: true,
              type: "success",
            });
            if (userInfo.type == "admin") {
              navigate("/admin/manage-engagers");
            } else {
              navigate("/employer/sub-users-list");
            }
          })
          .catch((err) => {
            setSnack({
              message: `Error occurred while deleting engager`,
              open: true,
              type: "error",
            });
          })
      })
      .catch((err) => {
        setSnack({
          message: `Error occurred while deleting assessor`,
          open: true,
          type: "error",
        });
      })
  }

  useEffect(() => {
    let id = state.value.planId
      api.plan.findOne(id)
        .then((response) => {
          const temp2 = response?.data?.result?.data;
          // console.log("plan details", temp2)
          setPlanDetails(temp2)
        })
        .catch((err) => console.log("error", err))
  },[])

  useEffect(() => {
    if(userInfo.type == "admin" || userInfo.type == "employer"){
    // console.log(userInfo.type, "engager")
      setLoading(true)
      let id = state.value.engagerId
      api.engager.findOne(id)
      .then((engagerData) => {
        // console.log("engager data", engagerData?.data?.result?.data?.planId)
        setDetails(engagerData?.data?.result?.data)
        let id = engagerData?.data?.result?.data?.planId
        api.plan.findOne(id)
        .then((response) => {
          const temp2 = response?.data?.result?.data;
          // console.log("plan details", response) 
          setPlanDetails(temp2)
          setLoading(false)
        })
        .catch((err) => console.log("error", err))
      })
    }
  },[])

  // useEffect(() => {
  //   if(userInfo.type == "admin"){
  //     let id = state.value.engagerId
  //     setDetails();
  //     api.engager.findOne(id)
  //       .then((data) => {
  //         let temp = data?.data?.result?.data
  //         console.log("mmmmmmmmmmmmmmmmm", temp)
  //         setDetails(temp)
  //         setLoading(false);
  //       })
  //   }
  //   if(userInfo.type == "employer" || userInfo.type == "subemployer"){
  //     setLoading(false)
  //   }
  // },[])

  const handleChangePlan = () => {
    navigate("/admin/plan-details",{
      state: {
        values: details,
      },
    });
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      
      <>
      {show ? (
        <MDBox position="relative" mb={5}>
          <MDBox
            display="flex"
            alignItems="center"
            position="relative"
            minHeight="18.75rem"
            borderRadius="xl"
            sx={{
              backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                ` url(${backgroundImage})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
              overflow: "hidden",
            }}
          />
          <Card
            sx={{
              position: "relative",
              mt: -8,
              mx: 3,
              py: 2,
              px: 2,
            }}
          >
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={1}>
                <MDBox position="relative" height="max-content" mx="auto">
                  <MDAvatar
                    src={details?.avatar}
                    alt="profile picture"
                    size="xl"
                    variant="rounded"
                    shadow="sm"
                  />
                </MDBox>
              </Grid>
              <Grid item xs={7}>
                <MDBox height="100%" mt={0.5} lineHeight={1}>
                  <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                    {details?.firstName}&nbsp;{details?.lastName}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={4}>
              <MDButton sx={{ mr: "5px" }} variant="gradient" color="warning" startIcon={<ArrowBackIcon/>} size="small" onClick={() => navigate(-1)}>Back</MDButton>
                <MDButton sx={{ mr: "5px" }} variant="gradient" color="error" startIcon={<DeleteIcon/>} size="small" onClick={() => handleDelete(state.value)}>delete</MDButton>
                <MDButton size="small" color="info" variant="gradient" startIcon={<EditIcon/>} onClick={() => handleEdit()}>edit </MDButton>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={6} xl={4}>
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Name&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.firstName}&nbsp;{details?.lastName}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Organization Name&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.organizationName}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Enterprise&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.enterprise}
                  </MDTypography>
                </MDBox>
              </Grid>
              <MDBox mt={2} />
              <Grid item xs={12} xl={4}>
              </Grid>
                {loading ? (
                <MDBox 
                  sx={{display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  py: 5,
                }}>
                  <CircularProgress color="error" />
                </MDBox>
              ):(
                <Grid item xs={12} xl={4} align="left">
                  {/* {planDetails?.planTitle && */}
                    <Card sx={{ padding: "8px", mt:"20px" }}>
                      <MDBox>
                        <MDBox justifyContent="center">
                          <MDTypography variant="h5" color="info" fontWeight="bold" sx={{ mx: "35%" }}>
                            &nbsp;{planDetails?.title}
                          </MDTypography>
                        </MDBox>
                        <MDBox display="flex" alignItems="center">
                          <MDTypography variant="button" color="text" fontWeight="bold" sx={{ px: "10px" }}>
                            Job Postings :
                          </MDTypography>
                          <MDTypography variant="h6" color="text" fontWeight="regular">
                            &nbsp;{planDetails?.numberOfJobPostings}
                          </MDTypography>
                        </MDBox>
                        {/* <MDBox display="flex" alignItems="center">
                          <MDTypography variant="button" color="text" fontWeight="bold" sx={{ px: "10px" }}>
                            Profile View :
                          </MDTypography>
                          <MDTypography variant="h6" color="text" fontWeight="regular">
                            &nbsp;{planDetails?.numberOfProfileView}
                          </MDTypography>
                        </MDBox> */}
                        <MDBox display="flex" alignItems="center">
                          <MDTypography variant="button" color="text" fontWeight="bold" sx={{ px: "10px" }}>
                            Sub User :
                          </MDTypography>
                          <MDTypography variant="h6" color="text" fontWeight="regular">
                            &nbsp;{planDetails?.numberOfSubUsers}
                          </MDTypography>
                        </MDBox>
                        {/* <MDBox display="flex" alignItems="center">
                          <MDTypography variant="button" color="text" fontWeight="bold" sx={{ px: "10px" }}>
                            Access to Assessors :
                          </MDTypography>
                          <MDBadge size="small" badgeContent={planDetails?.accessToAssessors ? "Yes" : "NO"} variant="gradient" color={planDetails?.accessToAssessors ? "success" : "error"} circular />
                        </MDBox> */}
                        {/* <MDBox display="flex" alignItems="center" mt={1}>
                          <MDTypography variant="button" color="text" fontWeight="bold" sx={{ px: "10px" }}>
                            Profile Category Access :
                          </MDTypography>
                          <MDBadge size="small" badgeContent={planDetails?.accessToProfileCategory} variant="gradient" color="light" />
                        </MDBox> */}
                        <MDBox display="flex" alignItems="center" mt={1}>
                          <MDTypography variant="button" color="text" fontWeight="bold" sx={{ px: "10px" }}>
                            Profile Matching alert :
                          </MDTypography>
                          <MDBadge size="small" badgeContent={planDetails?.automaticProfileMatchingAlert ? "Yes" : "No"} variant="gradient" color={planDetails?.automaticProfileMatchingAlert ? "success" : "error"} circular />
                        </MDBox>
                        {/* <MDBox display="flex" alignItems="center" mt={1}>
                          <MDTypography variant="button" color="text" fontWeight="bold" sx={{ px: "10px" }}>
                            Background verifications :
                          </MDTypography>
                          <MDBadge size="small" badgeContent={planDetails?.bgvOfTheCandidates ? "Yes" : "No"} variant="gradient" color={planDetails?.bgvOfTheCandidates ? "success" : "error"} circular />
                        </MDBox> */}
                        <MDBox display="flex" alignItems="center" mt={1}>
                          <MDTypography variant="button" color="text" fontWeight="bold" sx={{ px: "10px" }}>
                            Carry Forward Option :
                          </MDTypography>
                          <MDBadge size="small" badgeContent={planDetails?.carryForwardOption ? "Yes" : "No"} variant="gradient" color={planDetails?.carryForwardOption ? "success" : "error"} circular />
                        </MDBox>
                        <MDBox display="flex" alignItems="center" mt={1}>
                          <MDTypography variant="button" color="text" fontWeight="bold" sx={{ px: "10px" }}>
                            Contact Details:
                          </MDTypography>
                          <MDBadge size="small" badgeContent={planDetails?.contactDetails ? "Yes" : "No"} variant="gradient" color={planDetails?.contactDetails ? "success" : "error"} circular />
                        </MDBox>
                        {/* <MDBox display="flex" alignItems="center" mt={1}>
                          <MDTypography variant="button" color="text" fontWeight="bold" sx={{ px: "10px" }}>
                            Mentoring Option:
                          </MDTypography>
                          <MDBadge size="small" badgeContent={planDetails?.mentoringOption ? "Yes" : "No"} variant="gradient" color={planDetails?.mentoringOption ? "success" : "error"} circular />
                        </MDBox> */}
                      </MDBox>
                      {userInfo.type == "admin" && 
                      <MDBox m={2} align="center" >
                        <MDButton variant="outlined" color="dark" size="small" onClick={() => handleChangePlan()}>
                          Change Plan
                        </MDButton>
                      </MDBox>
                      }
                    </Card>
                  {/* } */}
                </Grid>
              )}
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} xl={4}>
                <MDBox alignItems="center" justifyContent="center" pt={2} px={1} pb={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Personal Information
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={1}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Designation&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.designation}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={1}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Department&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.department}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={1}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Phone Number&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.phoneNumber}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={1}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Pan Number&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.panNumber}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} xl={6}>
                <MDBox alignItems="center" justifyContent="center" pt={2} px={2} pb={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Organization Details
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Industry&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.industry}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Number of Employees&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.numberOfEmployees}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    location&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.location}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    GstIN&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.gstIn}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
            <MDBox  py={1} pr={2} px={1}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Email&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.email}
                  </MDTypography>
                </MDBox>
            <Divider />
            {userInfo.type == "admin" &&
              <MDBox>
                <MDTypography mx={2} my={2} variant="h6" color="info">List of Sub-users</MDTypography >
                <SubUserList data={details} />
              </MDBox>
            }
          </Card>
        </MDBox>
      ) : (
        <EditEngagerProfile details={details} handleEdit={handleEdit} handleBack={handleBack}/>
      )}
      </>
      

    </DashboardLayout>
  )
}

export default EngagerProfile