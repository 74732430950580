import { Checkbox, Drawer, Icon, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, ListSubheader, MenuItem, Select } from '@mui/material';
import MDAvatar from 'components/MDAvatar';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import React, { useEffect, useState } from 'react'
import api from 'services/api';
import { Box } from "@mui/system";
import { useStyles } from './styles';
import { useNavigate } from 'react-router-dom';
import MDBadge from 'components/MDBadge';
import ShortListed from '../ShortListed';
import MDInput from 'components/MDInput';
import SearchFilters from './SearchFilters';
import DataTable from './DataTable';
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "layouts/pages/account/components/FormField";

const SearchableList = ({ giglerList, eng, handleSubmit, showFilters=true, industryList, type="engagement", changeIndustry }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [checked, setChecked] = useState([]);
  const [open, setOpen] = useState(false);
  const [list, setList] = useState()
  const [searchFilterState, setSearchFilterState] = useState({});
  const [details, setDetails] = useState({
    columns: [
      { Header: "name", accessor: "firstName", width: "20%" },
      { Header: "Industry", accessor: "industry", width: "15%" },
      { Header: "Domain", accessor: "domain", width: "15%" },
      { Header: "Email", accessor: "email", width: "25%" },
      { Header: "Phone Number", accessor: "phoneNumber" },
      // { Header: "salary", accessor: "salary" },
    ],
    rows: []
  })
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    // console.log("checked", newChecked)
    setChecked(newChecked);
  };

  const handleClick = (row) => {
    navigate("/gigTalent-details", {
      state: {
        values: {
          user: row,
          engagement: eng
        }
      }
    });
  }

  useEffect(() => {
    if (giglerList && giglerList.length > 0) {
      console.log("searchable list..............", details);
      setDetails({ ...details, rows: giglerList });
    }
  }, [giglerList])
  const toggleDrawer = () => {
    setOpen(!open)
  }

  useEffect(() => {
    if(list && list.length > 0){
      setDetails({...details, rows:list})
    }
  },[list])

  const searchedGiglerList = (data, searchState) => {
    console.log("searched list..............", data);
    setSearchFilterState(searchState);
    setDetails({ ...details, rows: data });
    toggleDrawer();
  }

  return (
    <>
      <MDBox >
        <MDBox display="flex" p={2} sx={{ alignItems: "center", justifyContent: "space-between" }}>
          <MDTypography type="title"> Search for Matching GigTalent</MDTypography>
          {giglerList && giglerList.length > 0 && showFilters &&(
            <MDButton variant="gradient" color="info" size="small" onClick={() => toggleDrawer()}>
              Search Filters&nbsp;
              <Icon>search</Icon>
            </MDButton>
          )}
          {/* { !showFilters && industryList && industryList.length > 0 &&(      
            <Autocomplete        
              sx={{width: '30%'}}   
              name="industries"
              options={industryList}
              getOptionLabel={(option) => option.Industry}
              renderOption={(props, option) => (
                <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.Industry}
                </Box>
              )}
              renderInput={(params) => <FormField {...params} label="Select Industry" />}       
              onChange={(event, industry) => {
                console.log("industry", industry);
                changeIndustry(industry?.Industry);
              }}          
            />
          )} */}
        </MDBox>
        {giglerList && giglerList.length > 0 ? (
          <DataTable table={details} canSearch handleClick={handleClick} />
        ) : (
          <MDBox mb={2} sx={{ width: 500, height: 500 }}>
            <MDTypography px={2} py={2}>No {type} selected to search</MDTypography>
          </MDBox>

        )}
      </MDBox>
      <MDBox>
        <Drawer
          anchor={'right'}
          open={open}
          onClose={() => toggleDrawer()}
        >
          <SearchFilters eng={eng} searchedList={searchedGiglerList} industryList={industryList} changeIndustry={changeIndustry} searchFilterState={searchFilterState} setList={setList} />
        </Drawer>
      </MDBox>
    </>
  )
}

export default SearchableList