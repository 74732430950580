import { HowToVoteRounded } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => {
  return {
    addDomain: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "30%",
      height: "30%",
      margin: "auto",
      boxShadow: 24,
      p: 4,
    },

    videoStyle: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      background: "rgba(255, 255, 255, 0.7)",
      bgColor: "background.white",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    },
    addSkill: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "30%",
      height: "30%",
      margin: "auto",
      boxShadow: 24,
      p: 4,
    },
    editIcon: {
      "&:hover": {
        backgroundColor: "info",
        fontSize: "medium"
      },
      cursor: "pointer",
    },
    list: {
      paddingTop: "10px",
      paddingBottom: "10px",
      paddingLeft: "20px",
    },
    delIcon: {
      cursor: "pointer"
    }

  };
});
