import { useContext, useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Otis Admin PRO React components
import { Grid } from "@mui/material";
import FormField from "layouts/pages/account/components/FormField";

//form
import { useFormik } from "formik";
import api from "services/api";
import { UserContext } from "context/UserProvider";
import { SnackbarContext } from "context/SnackbarContext";

function Experience({ userData, updateUserData,onInputListChange }) {
  const { userInfo } = useContext(UserContext);
  const { setSnack } = useContext(SnackbarContext);
  const [inputList, setInputList] = useState([
    {
      employer: "",
      designation: "",
      from: "",
      to: "",
    },
  ]);

  const initialValues = {
    employer: "",
    designation: "",
    from: "",
    to: "",
  };
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      console.log("userInfo values", values);
      try {
        let id = ""
        if(userInfo.type == "admin"){ 
          id = userData?.userId
        }else{
          id = userInfo?.id
        }
        let temp = values;
        temp.experience = inputList;
        updateUserData(temp);
        const result = await api.userInfo.update(id, { experience: inputList });
        if (result) {
          if (result?.data?.message == "success") {
            setSnack({
              message: "Experience details updated!",
              open: true,
              type: "success",
            });
          } else if(result?.data?.message == "error") {
            setSnack({
              message: "Error updating details!",
              open: true,
              type: "error",
            });
          }          
        } else {
          throw new error("failed to update");
        }
      } catch (error) {
        setSnack({
          message: "Error occurred during update!",
          open: true,
          type: "success",
        });
        console.log("ERROR LOGIN: ", { ...error });
      }
    },
  });
  
  useEffect(() => {
    let temp = {
      ...userData,
    };
    formik.setValues(temp);
  },[userData]);

  //handle Input Change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  //handle click event of the remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    // onInputListChange(list);
  };

  //handle click event of the add button
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        employer: "",
        designation: "",
        from: "",
        to: "",
      },
    ]);
    // onInputListChange ([...inputList,{}]);
  };
  useEffect(() => {
    if (userData && userData?.experience && userData?.experience.length > 0) {
      setInputList(userData?.experience);
    }
  }, [userData]);
  return (
    <Card id="Experience">
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">Experience</MDTypography>
        </MDBox>
        <MDTypography variant="button" color="text">
          Here you can add previous experience.
        </MDTypography>
      </MDBox>
      <form onSubmit={formik.handleSubmit} method="post">
        {inputList.map((x, i) => {
          return (
            <MDBox pb={3} px={3}>
              <Grid container spacing={3} mb={2}>
                <Grid item xs={4} sm={4}>
                  <FormField
                    label="Employer"
                    placeholder="Amazon"
                    name="employer"
                    value={x.employer}
                    onChange={(e) => handleInputChange(e, i)}
                    // {...formik.getFieldProps("employer")}
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <FormField
                    label="Designation"
                    placeholder="Sr. Developer"
                    name="designation"
                    value={x.designation}
                    onChange={(e) => handleInputChange(e, i)}
                    // {...formik.getFieldProps("designation")}
                  />
                </Grid>
                <Grid item xs={4} sm={2}>
                  <FormField
                    label="From"
                    name="from"
                    inputProps={{ type: "date" }}
                    value={x.from}
                    onChange={(e) => handleInputChange(e, i)}
                    // {...formik.getFieldProps("from")}
                  ></FormField>
                </Grid>
                <Grid item xs={4} sm={2}>
                  <FormField
                    label="To"
                    name="to"
                    inputProps={{ type: "date", min: x.from }}
                    value={x.to}
                    onChange={(e) => handleInputChange(e, i)}
                    // {...formik.getFieldProps("to")}
                  ></FormField>
                </Grid>
              </Grid>
              {inputList.length !== 1 && (
                <MDButton
                  size="small"
                  variant="gradient"
                  sx={{ mr: "10px" }}
                  color="error"
                  onClick={() => handleRemoveClick(i)}
                >
                  Remove
                </MDButton>
              )}
              {inputList.length - 1 === i && inputList.length < 3 && (
                <>
                  <MDButton
                    size="small"
                    variant="gradient"
                    color="info"
                    onClick={() => handleAddClick()}
                    sx={{ mr: "10px" }}
                  >
                    Add More
                  </MDButton>
                </>
              )}
            </MDBox>
          );
        })}
        <MDBox pb={3} px={3}>
          <MDButton size="small" variant="gradient" color="success" type="submit">
            SAVE
          </MDButton>
        </MDBox>
      </form>
    </Card>
  );
}

export default Experience;
