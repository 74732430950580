import { Card, Grid, Modal } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react'
import api from 'services/api'
import { UserContext } from 'context/UserProvider'
import profile from "assets/images/profile.jpg";
import defaultProfile from "assets/images/defaultProfile.png"
import { useStyles } from "./styles";
import GiglerAssessmentDetails from './GiglerAssessmentDetails';
import MDButton from 'components/MDButton'



const AssessedGiglers = () => {
  const classes = useStyles();
  const { userInfo } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [assessmentDetails, setAssessmentDetails] = useState();
  const [gtDetails, setGtDetails] = useState()
  const handleClose = () => setOpen(false);
  const [list, setList] = useState();
  useEffect(() => {
    const id = userInfo?.id
    api.assigned.findAll(id, { assessed: true, assigned: true })
      .then(data => {
        // console.log("list of assessors", data);
        let temp = data?.data?.result?.data;
        console.log("list of assigned giglers", temp);
        setList(temp);
      }).catch(error => {
        console.log("error", error)
      })
  }, [])

  const formatDate = (string) => {
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(string).toLocaleDateString([], options);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={2} direction="row">
        {list?.length <= 0 ?
          (<MDBox>
            <Card sx={{ height: 700, width: 1210, m: 2 }}>
              <MDTypography variant="h5" p={2}>
                No gigTalent's assessed
              </MDTypography>
            </Card>
          </MDBox>
          ) : (
            list?.map((row) => (
              <Grid item xs={12} sm={2}>
                {console.log(list)}
                <MDBox >
                  <Card sx={{ maxWidth: 250, maxHeight: 450 }} key={row.id}>
                    <CardMedia
                      component="img"
                      height="140"
                      image={row.userInfo[0]?.avatar || defaultProfile}
                      alt={defaultProfile}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div" textTransform="capitalize">
                        {row.userInfo[0]?.firstName} {row.userInfo[0]?.lastName}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {row.userInfo[0]?.industry}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {row.userInfo[0]?.domain}
                      </Typography>
                      <Typography variant="body2" color="info" fontWeight= "medium">
                      {formatDate(row.userInfo[0]?.createdAt)}
                      </Typography>
                    </CardContent>
                    <CardActions
                      disableSpacing={false}>
                      <MDButton 
                      size="small"
                      color = "info"
                      variant = "gradient" 
                      onClick={() => {
                        setOpen(true)
                        setAssessmentDetails(row)
                        setGtDetails(row.userInfo[0])
                      }}>Details
                      </MDButton>
                        <MDButton
                        color="success"
                        size="small"
                        variant="gradient"
                        href={assessmentDetails}
                        sx={{ mr: "10px" }} >
                        Download
                      </MDButton>
                    </CardActions>
                    {/* <MDButton variant="outlined" color="dark" size="small" sx={{ m: "10px" }}
                      onClick={() => {
                        setOpen(true)
                        setAssessmentDetails(row)
                      }}>Assessed values
                    </MDButton> */}
                  </Card>
                </MDBox>
              </Grid>
            )
            ))}
        <Modal
          open={open}
          onClose={handleClose}
        >
          <MDBox className={classes.assessment} >
            <GiglerAssessmentDetails gtDetails={gtDetails} assessmentDetails={assessmentDetails} />
          </MDBox>
        </Modal>
      </Grid>
    </DashboardLayout >
  )
}

export default AssessedGiglers