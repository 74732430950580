import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => {
  return {
    resumeStyle: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '75%',
      height: '90%',
      margin: 'auto',
      bgColor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    },
    inputStyle: {
      display: 'none',
    }
  };
});