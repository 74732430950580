import { List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material'
import MDAvatar from 'components/MDAvatar'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DataTable from './DataTable'
import { useStyles } from './styles'

const SelectedList = ({ giglerList, engDetails }) => {

  const navigate = useNavigate();
  const [details, setDetails] = useState({
    columns: [
      { Header: "name", accessor: `user.firstName`, width: "20%" },
      { Header: "Industry", accessor: `user.industry`, width: "15%" },
      { Header: "Domain", accessor: `user.domain`, width: "15%" },
      { Header: "Email", accessor: `user.email`, width: "25%" },
      { Header: "Phone Number", accessor: `user.phoneNumber` },
      { Header: "status", accessor: `contract.status` },
      { Header: "Actions", accessor: `user.actions` },
    ],
    rows: giglerList
  })
  const handleClick = (row) => {
    console.log("row", row);
    navigate("/gigTalent-details", {
      state: {
        values: row,
      },
    });
  }
  const sendContract = (row) => {
    console.log("row", row);
    navigate("/send-contract", {
      state: {
        gigler: row,
        engagement: engDetails
      },
    });
  }
  useEffect(() => {
    console.log("gigler list", giglerList);
  }, [])
  const classes = useStyles()
  return (
    <MDBox>
      <MDTypography type="title" px={2} py={2}> Selected List</MDTypography>
      {giglerList && giglerList.length <= 0 ? (
        <MDBox mb={2} sx={{ wdith: 500, height: 500 }}>
          <MDTypography px={2} py={2}>No GigTalent selected for this engagement</MDTypography>
        </MDBox>
      ) : (
        // <>
        //   <List dense className={classes.list} >
        //     {giglerList?.map((row, index) => (
        //       <ListItemButton
        //         key={row.user.userId}
        //         id={index}
        //         onClick={() => handleClick(row)}
        //       >
        //         <ListItem
        //           className={classes.listItem}
        //           divider={true}
        //         >
        //           <ListItemAvatar>
        //             <MDAvatar src={row.user.avatar} alt="remy sharp" size="sm" >{row.user.firstName}{row.user.lastName}</MDAvatar>
        //           </ListItemAvatar>
        //           <ListItemText
        //             primary={
        //               <MDTypography variant="h6">
        //                 {row.user.firstName + ` ` + row.user.lastName}
        //               </MDTypography>
        //             }
        //           />
        //         </ListItem>
        //       </ListItemButton>
        //     ))}
        //   </List>
        // </>
        <DataTable table={details} canSearch handleClick={handleClick} sendContract={sendContract} />

      )}
    </MDBox>
  )
}

export default SelectedList