import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useContext } from "react";
import FormField from "layouts/pages/account/components/FormField";
import { useFormik } from "formik";
import MDButton from "components/MDButton";
import { SnackbarContext } from "context/SnackbarContext";
import api from "services/api";
// import { indexof } from "stylis";

const EditSkillList = ({ row, editSkill, handleClose }) => {
  // console.log("editskill", editSkill, index)
  const { setSnack } = useContext(SnackbarContext);

  const initialValues = {
    industry: row.Industry || "",
    domain: row.Domain || '',
    skillSet: editSkill || ""
  };
  const formik = useFormik({
    initialValues,
    // validationSchema,
    onSubmit: async (values) => {
      // const id = values.id;
      // console.log("hello", row);
      try {
        const update = row.skillSet;
        const index = update.indexOf(editSkill)
        console.log("index of ediskill", index)
        update.splice(index, 1, values.skillSet);
        console.log("after update", update)
        // update.push(values.skillSet);
        const result = await api.domain.updateOne(row.id, { skillSet: update });
        if (result) {
          setSnack({
            message: `Skill ${editSkill} is edited..!`,
            open: true,
            type: "success",
          });
          handleClose();
        } else {
          throw new error("failed to update");
        }
      } catch (error) {
        setSnack({
          message: "Error occurred during Addition!",
          open: true,
          type: "error",
        });
        console.log("ERROR update: ", { ...error });
      }
    },
    // validate,
  });
  return (
    <Card>
      <MDBox px={2} py={2}>
        <MDTypography variant="h6" color="dark">
          Edit Skill
        </MDTypography>
        <form onSubmit={formik.handleSubmit} method="post">
          <MDBox px={2} pb={2}>
            <MDBox py={2}>
              <FormField
                disabled
                variant="outlined"
                label="Industry"
                name="industry"
                {...formik.getFieldProps("industry")}
              />
            </MDBox>
            <MDBox pb={2}>
              <FormField
                disabled
                variant="outlined"
                label="Domain"
                name="domain"
                {...formik.getFieldProps("domain")}
              />
            </MDBox>
            <MDBox pb={2}>
              <FormField
                variant="outlined"
                label="Skill"
                name="skillSet"
                {...formik.getFieldProps("skillSet")}
              />
            </MDBox>

            <MDBox py={1} display="flex" justifyContent="space-between">
              <MDButton variant="gradient" color="success" size="small" type="submit">
                Save
              </MDButton>
            </MDBox>
          </MDBox>
        </form>
      </MDBox>
    </Card>
  );
};

export default EditSkillList;
