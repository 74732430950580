import { Card, Divider, Grid } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import MDBox from './MDBox'
import MDTypography from './MDTypography'
import { useStyles } from './styles'
import FormField from "layouts/applications/wizard/components/FormField";
import api from 'services/api'
import { SnackbarContext } from 'context/SnackbarContext';
import { useLocation, useNavigate } from "react-router-dom";



//icons
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MDButton from './MDButton'
import MDBadge from './MDBadge'
import { UserContext } from 'context/UserProvider'

const EngagementDetails = ({ engDetails, handleBack, handleApply, showApply, status }) => {
  const { userInfo } = useContext(UserContext)
  const {setSnack} = useContext(SnackbarContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const css = useStyles();

  const formatDate = (string) => {
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(string).toLocaleDateString([], options);
  }

  // const handleDelete = (values) => {
  //   console.log("engagement id", values, values.engagementId);
  //   api.engagement.delete({engagementId:values.engagementId})
  //   .then((data) => {
  //     if(data?.data?.message == "success"){
  //       setSnack({
  //         message: `${values.engagementTitle} is deleted`,
  //         open: true,
  //         type: "success",
  //       });
  //     }else{
  //       setSnack({
  //         message: `some error occured while deleting`,
  //         open: true,
  //         type: "error",
  //       });
  //     }
  //   })
  //   .catch((err) => {
  //     setSnack({
  //       message: `some error occured whle deleting`,
  //       open: true,
  //       type: "error",
  //     });
  //   })
  // }

  const handleDelete = (values) => {
    console.log("Engagement id", values, values.engagementId);
    let uid = values.engagementId;
    let data = {
      isDeleted: "1",
    };
    api.engagement
       .updateEngagement(uid, data)
        .then((res) => {
            if (res?.data?.message == "success") {
              setSnack({
                message: `engagement is deleted`,
                open: true,
                type: "success",
              });
              navigate("/admin/engagements");
            } else if (res?.data?.message == "error") {
              setSnack({
                message: `Engagement already deleted!!!!!!!`,
                open: true,
                type: "error",
              });
            }
          })
          .catch((err) => {
            setSnack({
              message: `Error occurred while deleting engagement`,
              open: true,
              type: "error",
            });
          });
      // })
      // .catch((err) => {
      //   setSnack({
      //     message: `Error occurred while deleting engagement`,
      //     open: true,
      //     type: "error",
      //   });
      // });
  };


  return (
    <>
      <Card >
        <MDBox px={2} py={3}>
          <MDBox className={css.box}>
            {/* <MDTypography variant="subtitle1" color="info" fontWeight="medium" textTransform="capitalize" className={css.engId}>
              {engDetails?.engagementId}
            </MDTypography> */}
            <MDTypography variant="subtitle1" fontWeight="medium" textTransform="capitalize" className={css.title}>
              {/* {`-`}&nbsp;&nbsp;&nbsp; */}
              {engDetails?.engagementTitle}
            </MDTypography>
            <MDButton size="small"
                    color="error"
                    variant="button"
                    onClick={() => handleDelete(engDetails) }
                    // sx={{ml:'580px'}}
                  >delete
                  </MDButton>
            <MDTypography variant="button" color="dark" className={css.backIcon} onClick={() => handleBack()}><ArrowBackIcon sx={{ pt: "2px" }} />Back</MDTypography>
          </MDBox>
          <MDTypography variant="body2" textTransform="capitalize" className={css.orgName}>
            {engDetails?.organizationName}
          </MDTypography>
          {userInfo.type == "admin" || userInfo.type == "Finance-admin" || userInfo.type == "General-admin" ? (
            <>
              <MDBox className={css.box}>
                <MDTypography variant="button" color="text" className={css.icon}><AccountBalanceWalletIcon fontSize="string" /></MDTypography>
                <MDTypography variant="button" textTransform="capitalize" className={css.txt} >
                  {engDetails?.indicativeBudget}(INR)
                </MDTypography>
              </MDBox>
            </>
          ) : (
            ""
          )}
          <MDBox className={css.box}>
            <MDTypography variant="button" color="text" className={css.icon}><LocationOnIcon fontSize="string" /></MDTypography>
            <MDTypography variant="button" textTransform="capitalize" className={css.txt} >
              {engDetails?.location}
            </MDTypography>
          </MDBox>
          <MDBox className={css.box}>
            <MDBox>
              <MDTypography variant="button" color="text" className={css.icon}>Posted :</MDTypography>
              <MDTypography variant="button" color="dark" fontWeight="medium" className={css.txt}>&nbsp;{formatDate(engDetails?.createdAt)} </MDTypography>
            </MDBox>
            <Divider orientation="vertical" variant='string' color="dark" sx={{ color: "dark" }} />
            <MDBox>
              <MDTypography variant="button" color="text" className={css.icon}>Billing :</MDTypography>
              <MDTypography variant="button" color="dark" fontWeight="medium" className={css.txt}>&nbsp;{engDetails?.billing}
              </MDTypography>
            </MDBox>
            <Divider orientation="vertical" variant='string' color="dark" sx={{ color: "dark" }} />
            <MDBox>
              <MDTypography variant="button" color="text" className={css.icon}>No. of Positions :</MDTypography>
              <MDTypography variant="button" color="dark" fontWeight="medium" className={css.txt}>&nbsp;{engDetails?.numberOfPositions}
              </MDTypography>
            </MDBox>
            <MDBox>
              <MDTypography variant="button" color="text" className={css.icon}>Duration :</MDTypography>
              <MDTypography variant="button" color="dark" fontWeight="medium" className={css.txt}>&nbsp;{engDetails?.engagementDuration}&nbsp;(Days)
              </MDTypography>
            </MDBox>
            {showApply ? (
              <MDBox className={css.apply}>
                <MDButton variant="outlined" color="info" size="medium" onClick={() => handleApply(engDetails)}>apply</MDButton>
              </MDBox>
            ) : (
              <MDBox className={css.apply}>
                <MDBadge color="info" size="md" badgeContent={status} />
              </MDBox>
            )
            }
          </MDBox>
        </MDBox>
      </Card>
      <Card sx={{ mt: "10px" }}>
        <MDBox px={2} py={2}>
          <MDBox>
            <MDTypography variant="subtitle2" fontWeight="medium" textTransform="capitalize" className={css.title}>
              Engagement Description
            </MDTypography>
            <MDTypography variant="button" color="text" className={css.desc}>{engDetails?.engagementDescription}</MDTypography>
          </MDBox>
          {engDetails?.engagementDescriptionText &&
            <MDBox>
              <MDTypography variant="subtitle2" fontWeight="medium" textTransform="capitalize" className={css.title}>
                Engagement Text File
              </MDTypography>
              {/* <MDTypography variant="button" color="text" className={css.desc}>{engDetails?.engagementDescriptionText}</MDTypography> */}
              <FormField
                sx={{ ml: "20px" }}
                placeholder="Engagement text..."
                multiline
                value={engDetails?.engagementDescriptionText}
                rows={10}
                inputProps={{ type: "text", readOnly: true }}
              />
            </MDBox>
          }
          {userInfo.type == "gigler" ? ("") : (
            <>
              <MDBox>
                <MDTypography variant="body2" fontWeight="medium" textTransform="capitalize" className={css.title}>
                  Regards,
                </MDTypography>
                <MDTypography variant="button" color="text" className={css.desc}>{engDetails?.engagerName}</MDTypography>
              </MDBox>
              <MDBox className={css.box}>
                <MDTypography variant="button" color="text" sx={{ pl: "17px" }}>Email:</MDTypography>
                <MDTypography variant="button" color="text">&nbsp;{engDetails?.email}</MDTypography>
              </MDBox>
            </>
          )}

          {/* <MDBox className={css.box}>
            <MDTypography variant="button" color="text" sx={{ pl: "17px" }}>Visit us at:</MDTypography>
            <MDTypography variant="button" color="text">&nbsp;{engDetails?.webAddress}</MDTypography>
          </MDBox> */}
          <MDBox className={css.role}>
            <Grid container>
              <Grid item xs={2}>
                <MDTypography variant="button" color="text">Role</MDTypography><br />
                <MDTypography variant="button" color="text">Industry</MDTypography><br />
                <MDTypography variant="button" color="text">Domain</MDTypography><br />
                <MDTypography variant="button" color="text">Skills</MDTypography><br />
                <MDTypography variant="button" color="text">Type of employment</MDTypography><br />
                <MDTypography variant="button" color="text">Preferred Engagement</MDTypography><br />
                <MDTypography variant="button" color="text">Engagement On-boarding</MDTypography><br />
              </Grid>
              <Grid item xs={6}>
                <MDTypography variant="button" color="dark">{engDetails?.engagementTitle}</MDTypography><br />
                <MDTypography variant="button" color="dark">{engDetails?.industry}</MDTypography><br />
                <MDTypography variant="button" color="dark">{engDetails?.domain}</MDTypography><br />
                <MDTypography variant="button" color="dark">{engDetails?.primarySkills},{engDetails?.secondarySkills}</MDTypography><br />
                <MDBox>
                  {engDetails?.fullTime &&
                    <MDTypography variant="button" color="dark">Full Time {engDetails?.fullTime && engDetails?.partTime ? (",") : ("")}</MDTypography>
                  }
                  {engDetails?.partTime &&
                    <MDTypography variant="button" color="dark"> Part Time{engDetails?.partTime && engDetails?.projectBased ? (",") : ("")}</MDTypography>
                  }
                  {engDetails?.projectBased &&
                    <MDTypography variant="button" color="dark"> Project Based{engDetails?.projectBased && engDetails?.serviceBased ? (",") : ("")}</MDTypography>
                  }
                  {engDetails?.serviceBased &&
                    <MDTypography variant="button" color="dark"> Service Based</MDTypography>
                  }
                </MDBox>
                <MDBox>
                  {engDetails?.onSite &&
                    <MDTypography variant="button" color="dark">On Site{engDetails?.onSite && engDetails?.offSite ? (",") : ("")}</MDTypography>
                  }
                  {engDetails?.offSite &&
                    <MDTypography variant="button" color="dark"> Off Site{engDetails?.offSite && engDetails?.both ? (",") : ("")}</MDTypography>
                  }
                  {engDetails?.both &&
                    <MDTypography variant="button" color="dark"> Both (Hybrid){engDetails?.both && engDetails?.needBased ? (",") : ("")}</MDTypography>
                  }
                  {engDetails?.needBased &&
                    <MDTypography variant="button" color="dark"> Need Based</MDTypography>
                  }
                </MDBox>
                {/* <MDBox>
                  {engDetails?.zeroToSevenDays &&
                    <MDTypography variant="button" color="dark">0-7 days</MDTypography>
                  }
                  {engDetails?.eightToFifteenDays &&
                    <MDTypography variant="button" color="dark"> 8-15 days</MDTypography>
                  }
                  {engDetails?.sixteenToThirtyDays &&
                    <MDTypography variant="button" color="dark"> 16-30 days</MDTypography>
                  }
                  {engDetails?.moreThanThirtyDays &&
                    <MDTypography variant="button" color="dark"> More Than 30 Days</MDTypography>
                  }
                </MDBox> */}
                <MDBox>
                  <MDTypography variant="button" color="dark">
                    {engDetails.availability == "zeroToSevenDays" ? "0-7 days" :
                      engDetails.availability == "eightToFifteenDays" ? "8-15 days" :
                        engDetails.availability == "sixteenToThirtyDays" ? "16-30 days" :
                          engDetails.availability == "moreThanThirtyDays" ? "More Than 30 Days" : ""}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          {/* <MDBox mt={4}>
            <MDTypography variant="subtitle2" fontWeight="medium" textTransform="capitalize" className={css.title}>
              Education
            </MDTypography>
            <MDBox className={css.edu}>
              <Grid container>
                <Grid item xs={2}>
                  <MDTypography variant="button" color="text">Degree</MDTypography><br />
                  <MDTypography variant="button" color="text">Subject</MDTypography><br />
                  <MDTypography variant="button" color="text">Passed Out </MDTypography><br />
                </Grid>
                <Grid item xs={2}>
                  <MDTypography variant="button" color="dark">{engDetails?.degree}</MDTypography><br />
                  <MDTypography variant="button" color="dark">{engDetails?.subject}</MDTypography><br />
                  <MDTypography variant="button" color="dark">{engDetails?.passOutYear}</MDTypography><br />
                </Grid>
              </Grid>
            </MDBox>
          </MDBox> */}
        </MDBox>
      </Card>
    </>
  )
}

export default EngagementDetails