import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => {
  return {
    resumeStyle: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
      height: '80%',
      margin: 'auto',
      // bgColor: 'background.paper',
      // border: '1px solid #000',
      boxShadow: 24,
    },
    title: {
      position: 'absolute',
      left: "37%"
    },
    icon: {
      position: 'absolute',
      left: "95%"
    }

  };
});