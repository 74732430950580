import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useContext } from "react";
import FormField from "layouts/pages/account/components/FormField";
import { useFormik } from "formik";
import MDButton from "components/MDButton";
import { SnackbarContext } from "context/SnackbarContext";
import api from "services/api";
import * as Yup from "yup";

const AddDomain = ({ handleClose, mainIndustry, domainList, handleDomainList }) => {
  console.log("Passed domain list", domainList)
  const initialValues = {
    Industry: mainIndustry?.Industry || "",
    Domain: "",
    skillSet: "",
    code: "",
    status: 1,
  };
  const { setSnack } = useContext(SnackbarContext);
  const validationSchema = Yup.object({
    Industry: Yup.string().required("Industry is required"),
    Domain: Yup.string().required("Domain is required"),
    code: Yup.string()
      .min(3, "Series should be minimum 3 character")
      .max(3, "Series should be maximum 3 character")
      .required("Series is required"),
  })
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        values.code = values.code.toUpperCase();
        const result = await api.domain.create(values);
        if (result) {
          setSnack({
            message: `New Domain ${values.Domain} Added!`,
            open: true,
            type: "success",
          });
          handleDomainList();
          handleClose();
        } else {
          throw new error("failed to update");
        }
      }
      catch (error) {
        setSnack({
          message: "Error occurred during Addition!",
          open: true,
          type: "error",
        });
        console.log("ERROR update: ", { ...error });
      }
    },
  });
  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z\s]+$/;
  return (
    <Card>
      <MDBox px={2} py={2}>
        <MDTypography variant="h6" color="dark">
          Add Domain
        </MDTypography>
        <form onSubmit={formik.handleSubmit} method="post">
          <MDBox px={2} py={2}>
            <MDBox>
              <FormField
                inputProps={{ type: "text", readOnly: true }}
                variant="outlined"
                label="Industry"
                name="Industry"
                {...formik.getFieldProps("Industry")}
                onKeyDown={(event) => {
                  if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                error={formik.touched.Industry && formik.errors.Industry ? true : false}
                success={formik.values.Industry > 0 && !formik.errors.Industry}
                helperText={
                  formik.touched.Industry && formik.errors.Industry
                    ? formik.errors.Industry
                    : null
                }
              />
            </MDBox>
            <MDBox py={1}>
              <FormField
                variant="outlined"
                label="Domain"
                name="Domain"
                {...formik.getFieldProps("Domain")}
                onKeyDown={(event) => {
                  if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                error={formik.touched.Domain && formik.errors.Domain ? true : false}
                success={formik.values.Domain > 0 && !formik.errors.Domain}
                helperText={
                  formik.touched.Domain && formik.errors.Domain
                    ? formik.errors.Domain
                    : null
                }
              />
            </MDBox>
            <MDTypography variant="caption" fontWeight="medium">(Note:- Add atleast one skill initially)</MDTypography>
            <MDBox py={1}>
              <FormField
                variant="outlined"
                label="Skill Set"
                name="skillSet"
                {...formik.getFieldProps("skillSet")}
                onKeyDown={(event) => {
                  if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                error={formik.touched.skillSet && formik.errors.skillSet ? true : false}
                success={formik.values.skillSet > 0 && !formik.errors.skillSet}
                helperText={
                  formik.touched.skillSet && formik.errors.skillSet
                    ? formik.errors.skillSet
                    : null
                }
              />
            </MDBox>
            <MDTypography variant="caption" fontWeight="medium">(Note:- Series is three letter capital word which matches with domain)</MDTypography>
            <MDBox py={1} display="flex" justifyContent="space-between">
              <FormField
                sx={{ width: "150px" }}
                variant="outlined"
                label="Series"
                name="code"
                {...formik.getFieldProps("code")}
                onKeyDown={(event) => {
                  if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
                error={formik.touched.code && formik.errors.code ? true : false}
                success={formik.values.code > 0 && !formik.errors.code}
                helperText={
                  formik.touched.code && formik.errors.code
                    ? formik.errors.code
                    : null
                }
              />
              <MDButton variant="gradient" color="success" size="small" type="submit">
                Add
              </MDButton>
            </MDBox>
          </MDBox>
        </form>
      </MDBox>
    </Card>
  );
};

export default AddDomain;
