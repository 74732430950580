
import { Modal } from '@mui/material';
import MDBox from 'components/MDBox';
import { UserContext } from 'context/UserProvider';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import EngagerFeedbackForm from 'layouts/pages/common/EngagerFeedbackForm';
import React, { useContext, useEffect, useState } from 'react'
import api from 'services/api';
import DataTable from './DataTable'
import { useStyles } from './styles'

const ClosedGiglerList = ({ gigDetails, engagementDesc, callAgainHandleClick }) => {
  const css = useStyles();
  const { userInfo } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [feedbackDetails, setFeedbackDetails] = useState()
  const handleClose = () => {
    setOpen(false);
  }
  const [details, setDetails] = useState({
    columns: [
      { Header: "Name", accessor: "users.firstName", width: "20%" },
      { Header: "Email", accessor: "users.email", width: "20%" },
      { Header: "Industry", accessor: "users.industry", width: "20%" },
      { Header: "Domain", accessor: "users.domain", width: "20%" },
    ],
    rows: gigDetails
  })
  const handleClick = (row) => {
    setOpen(true)
    console.log("feedback details", row.original)
    setFeedbackDetails(row.original);
  }

  return (
    <>
      {gigDetails.length > 0 &&
        <DataTable table={details} handleClick={handleClick} />
      }
      <Modal
        open={open}
        onClose={handleClose}
      >
        <MDBox className={css.modal}>
          <EngagerFeedbackForm feedbackDetails={feedbackDetails} engagementDesc={engagementDesc} handleClose={handleClose} callAgainHandleClick={callAgainHandleClick} />
        </MDBox>
      </Modal>
    </>
  )
}

export default ClosedGiglerList