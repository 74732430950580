import { Button, Card, Grid, Modal } from "@mui/material";
// MUI
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";

import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useContext, useEffect, useState } from "react";
import backgroundImage from "assets/images/bg-profile.jpeg";
import { useLocation, useNavigate } from "react-router-dom";
import api from "services/api";
import { SnackbarContext } from "context/SnackbarContext";
import select from "assets/theme/components/form/select";
import MDDropzone from "components/MDDropzone";
// import { useStyles } from './styles'
import { UserContext } from "context/UserProvider";
import { useStyles } from "./styles";
import LoadingButton from "@mui/lab/LoadingButton";

const SendContract = () => {
  const { userInfo } = useContext(UserContext);
  const navigate = useNavigate();
  const classes = useStyles();
  const { state } = useLocation();
  const { setSnack } = useContext(SnackbarContext);
  const [gigDetails, setGigDetails] = useState({});
  const [engDetails, setEngDetails] = useState({});
  const [value, setValue] = useState("gigleji-payroll");
  const [open, setOpen] = useState(true);
  const [openFile, setOpenFile] = useState(false);
  const [contractType, setContractType] = useState("gigleji");
  const [uploadFileName, setUploadFileName] = useState("");
  const [formData, setFormData] = useState({});
  const handleCloseFile = () => setOpenFile(false);
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState('https://giglejiusers.s3.ap-south-1.amazonaws.com/contract_templates/ENGAGEMENT+AGREEMENT+-+Engager+and+GIGLEJI.docx');

  useEffect(() => {
    api.engagement.findByEngagementId(state?.engagement?.engagementId)
    .then(data => {
      console.log(data.data.result.data?.contractTemplate)
      setTemplate(
        data.data.result.data.contractType == "gigleji-payroll" ? 
        data.data.result.data?.contractTemplate.enagagerToGigleji : 
        data.data.result.data?.contractTemplate.enagagerToGigler
      );
      setEngDetails(data.data.result.data);
    })
    console.log("state values!!!!!!!!!", "state values", state?.engagement?.contractTemplate);
    setGigDetails(state.gigler.user);
    // setContractType(value);
    // setTemplate(state?.engagement?.contractTemplate?.enagagerToGigleji);
    // setEngDetails(state.engagement);
  }, []);

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   console.log("value", engDetails?.contractTemplate);
  //   setTemplate(value =="gigleji" ? engDetails?.contractTemplate?.enagagerToGigleji : engDetails?.contractTemplate?.enagagerToGigler);
    // setTemplate(value =="gigleji" ? "https://giglejiusers.s3.ap-south-1.amazonaws.com/contract_templates/ENGAGEMENT+AGREEMENT+-+Engager+and+GIGLEJI.docx" : "https://giglejiusers.s3.ap-south-1.amazonaws.com/contract_templates/ENGAGEMENT+AGREEMENT+-+Engager+and+Gigler.docx");
  //   setOpen(true);
  // };

  // const handleRadioChange = (event) => {
  //   setValue(event.target.value);
  // };

  const handleClick = () => {
    document.getElementById("resumeFile").click();
  };

  const handleChange = (event) => {
    setLoading(true);
    console.log("clicked", event.target.files[0].name);
    let temp = event.target.files[0];
    setUploadFileName(event.target.files[0].name);
    // setResume(URL.createObjectURL(temp));
    console.log("resume link", URL.createObjectURL(temp));
    const formData = new FormData();
    formData.append("doc", temp);

    formData.append("engagementId", engDetails.engagementId);
    formData.append("engagerId", engDetails.engagerId);
    formData.append("giglerId", gigDetails.userId);
    formData.append("sentDate", new Date());
    formData.append("from", "engager");
    formData.append("to", engDetails?.contractType == "gigleji-payroll" ? "gigleji" : "gigler");

    console.log("resume file", engDetails?.contractType == "gigleji-payroll" ? "gigleji" : "gigler");

    api.contract.contractUpdate(formData).then((data) => {
      console.log("resume data", data);
      setLoading(false);
      navigate(-1);
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox position="relative" mb={5}>
        <Card
          sx={{
            position: "relative",
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={6}>
              <MDBox pt={2} px={2} pb={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Engagement Information
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Engagement Id&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{engDetails?.engagementId}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Engagement Title&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{engDetails?.engagementTitle}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <MDBox pt={2} px={2} pb={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  GigTalent Information
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Name&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{gigDetails?.firstName}&nbsp;{gigDetails?.lastName}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Email&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{gigDetails?.email}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          {/* <Grid container spacing={1}>
            <Grid item xs={12} md={12} xl={12}>
              <MDBox pt={2} px={2} pb={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Selected Contract Type
                </MDTypography>
              </MDBox> */}
              {/* <MDBox px={2} pb={2}>
                <form onSubmit={handleSubmit}>
                  <FormControl variant="standard" sx={{ display: "flex", flexDirection: "row" }}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-error-radios"
                      name="quiz"
                      value={value}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        value="gigleji"
                        control={<Radio />}
                        label="Engager to Gigleji"
                      />
                      <FormControlLabel
                        value="gigler"
                        control={<Radio />}
                        label="Engager to GigTalent"
                      />
                    </RadioGroup>
                    <MDButton variant="outlined" color="dark" size="small" type="submit">
                      Details
                    </MDButton>
                  </FormControl>
                </form>
              </MDBox> */}
            {/* </Grid>
          </Grid> */}
        </Card>
          <Card
            sx={{
              position: "relative",
              mt: 2,
              mx: 3,
              py: 2,
              px: 2,
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} xl={12}>
                <MDBox
                  pt={2}
                  px={2}
                  pb={2}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    {engDetails?.contractType == "gigleji-payroll" ? "Contract Between Engager and Gigleji Platform" : "Contract Between Engager and GigTalent" }
                  </MDTypography>
                  <MDBox>
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      color="text"
                      textTransform="capitalize"
                    >
                      Download Template
                    </MDTypography>
                    <MDButton
                      color="info"
                      size="small"
                      variant="outlined"
                      target="_blank"
                      href={template}
                      sx={{ ml: "10px" }}
                    >
                      Download
                    </MDButton>
                  </MDBox>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <MDBox pt={2} px={2}>
                  <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                    Upload Contract edited with info
                  </MDTypography>
                </MDBox>
                <MDBox pt={1} px={2} pb={2}>
                  <input
                    type="file"
                    name="resumeFile"
                    id="resumeFile"
                    accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    onChange={(e) => handleChange(e)}
                    className={classes.inputStyle}
                    sx={{ display: "none" }}
                  />
                  {uploadFileName && (
                    <MDTypography variant="button" fontWeight="regular" color="text" pr={2}>
                      {uploadFileName}
                    </MDTypography>
                  )}
                  <LoadingButton
                    size="small"
                    onClick={() => handleClick()}
                    loading={loading}                    
                    variant="outlined" 
                    color="dark"
                    sx= {{ 
                      color: 'rgb(52, 71, 103)',
                      border : '1px solid rgb(52, 71, 103)'
                    }}                    
                  >
                    Upload & submit
                  </LoadingButton>
                  {/* <MDButton
                    variant="outlined"
                    size="small"
                    color="success"
                    onClick={() => handleClick()}
                  >
                    Upload & submit
                  </MDButton> */}
                </MDBox>
              </Grid>
            </Grid>
          </Card>
      </MDBox>
      <Modal open={openFile} onClose={handleCloseFile}>
        <MDBox className={classes.resumeStyle}>
          <iframe
            className={"docx"}
            width="100%"
            height="100%"
            frameBorder="0"
            src={`https://docs.google.com/gview?url=&embedded=true`}
          ></iframe>
        </MDBox>
      </Modal>
    </DashboardLayout>
  );
};

export default SendContract;
