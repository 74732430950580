import { Autocomplete, Card, Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useContext, useState } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import MDInput from 'components/MDInput'
import SubAdminList from './SubAdminList'
import MDTypography from 'components/MDTypography'
import FormField from "layouts/pages/account/components/FormField";
import MDButton from 'components/MDButton'
import api from 'services/api'
import { SnackbarContext } from 'context/SnackbarContext'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';






const SubAdmin = () => {
  const { setSnack } = useContext(SnackbarContext);
  const [showForm, setShowForm] = useState(false);
  const handleShow = () => setShowForm(!showForm);
  const [showPassword, setShowPassword] = useState(false);

  
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    type: "",
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  const phoneRegExp = /^[0-9]*$/;
    // /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required("First Name is required"),
    lastName: Yup.string()
      .required("Last Name is required"),
    email: Yup.string().email().required("Email is required"),
    type: Yup.string().required("Role is required"),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Too short")
      .max(10, "Too long"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Your password must be longer than 8 characters.")
      .max(25)
      .matches(/^(?=.{8,})/, "Must Contain 8 Characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])/,
        "Must Contain One Uppercase, One Lowercase"
      )
      .matches(
        /^(?=.*[!@#\$%\^&\*])/,
        "Must Contain One Special Case Character"
      )
      .matches(/^(?=.{6,20}$)\D*\d/, "Must Contain One Number"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const userEmail = values.email
        api.users.findOneUser({ userEmail: userEmail })
          .then(data => {
            if (data?.data?.message == "success") {
              setSnack({
                message: `${values.email} Email already exists`,
                open: true,
                type: "error",
              });
            } else {
              const result = api.users.registerUser(values)
                .then(response => {
                  console.log("sub admin details", response)
                  if (response?.data?.message == "success") {
                    setSnack({
                      message: `Sub-Admin ${values.type} created successfully`,
                      open: true,
                      type: "success",
                    });
                    formik.handleReset();
                  }
                  else {
                    setSnack({
                      message: `Sub-Admin ${values.type} is not created`,
                      open: true,
                      type: "error",
                    });
                    formik.handleReset();
                  }
                })
                .catch(err => {
                  setSnack({
                    message: `Sub-Admin ${values.type} is not created`,
                    open: true,
                    type: "error",
                  });
                })
            }
          })
          .catch((err) => {
            setSnack({
              message: `Sub-Admin ${values.type} is not created`,
              open: true,
              type: "error",
            });
          })
      }
      catch (err) {
        setSnack({
          message: `error occurred while creating sub-admin`,
          open: true,
          type: "error",
        });
      }
    },
  });

  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z]+$/;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        {!showForm &&
          <>
            <MDBox p={3} display='flex' justifyContent="space-between">
              <MDTypography variant='h5'>
                Sub Admin List
              </MDTypography>
              <MDButton variant='gradient' size='medium' color='success' onClick={() => handleShow()}>
                Add Sub Admin
              </MDButton>
            </MDBox>
            <MDBox>
              <SubAdminList />
            </MDBox>
          </>
        }
        {showForm &&

          <MDBox py={-1} px={10} mb={1} mt={1} alignItems="center" justifyContent="center" maxWidth="100%">
            <MDBox
              variant="button"
              color="dark"
              align="right"
              // className={css.backIcon}
              sx={{mr:"18px",mt:"5px",mb:"10px"}}
            >
            <MDButton
                  variant="outlined"
                  color="info"
                  size="small"
            onClick={() => handleShow()}

            >
                    
            <ArrowBackIcon sx={{ pt: "2px" }} />
            Back
            </MDButton>
            </MDBox>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              // mt={-3}
              p={2}
              mb={2}
              textAlign="center"
            ><MDTypography color="white">Create Sub Admin</MDTypography></MDBox>
            <MDBox width="100%" py={2} px={5}>
              <form onSubmit={formik.handleSubmit} method="post">
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  sx={{ alignItems: "center", justifyContent: "center" }}
                >
                  <Grid item xs={12} sm={6}>
                    <MDBox mb={1}>
                      <FormField
                        label="First Name"
                        placeholder="Enter first name"
                        name="firstName"
                        onKeyDown={(event) => {
                          if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{ type: "text" }}
                        {...formik.getFieldProps("firstName")}
                        error={formik.touched.firstName && formik.errors.firstName ? true : false}
                        success={formik.values.firstName > 0 && !formik.errors.firstName ? true : false}
                        helperText={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : null}
                      // ErrorMessage={formik.touched.firstName && formik.errors.lastName ? formik.errors.lastName : null}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDBox mb={1}>
                      <FormField
                        label="Last Name"
                        placeholder="Enter last name"
                        name="lastName"
                        inputProps={{ type: "text" }}
                        {...formik.getFieldProps("lastName")}
                        onKeyDown={(event) => {
                          if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        error={formik.touched.lastName && formik.errors.lastName ? true : false}
                        success={formik.values.lastName > 0 && !formik.errors.lastName ? true : false}
                        helperText={
                          formik.touched.lastName && formik.errors.lastName
                            ? formik.errors.lastName
                            : null
                        }
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDBox mb={1}>
                      <FormField
                        label="Email"
                        placeholder="example@email.com"
                        name="email"
                        inputProps={{ type: "text" }}
                        {...formik.getFieldProps("email")}
                        error={formik.touched.email && formik.errors.email ? true : false}
                        success={formik.values.email > 0 && !formik.errors.email ? true : false}
                        helperText={
                          formik.touched.email && formik.errors.email
                            ? formik.errors.email
                            : null
                        }
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDBox mb={1}>
                      <FormField
                        type= "number"
                        label="Phone Number"
                        placeholder="+91"
                        name="phoneNumber"
                        inputProps={{ type: "number" }}
                        onKeyPress = {(event) => {
                          if(!phoneRegExp.test(event.key))
                          event.preventDefault();
                        }}
                        {...formik.getFieldProps("phoneNumber")}
                        error={formik.touched.phoneNumber && formik.errors.phoneNumber ? true : false}
                        success={formik.values.phoneNumber > 0 && !formik.errors.phoneNumber ? true : false}
                        helperText={
                          formik.touched.phoneNumber && formik.errors.phoneNumber
                            ? formik.errors.phoneNumber
                            : null
                        }
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDBox mb={15}>
                      <Autocomplete
                        label="Role"
                        name="type"
                        {...formik.getFieldProps("type")}
                        options={["Contracts-admin", "Finance-admin", "Services-admin", "General-admin", "Assessment-admin"]}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            label="Role"
                            placeholder="Select One"
                            InputLabelProps={{ shrink: true }}
                            error={formik.touched.type && formik.errors.type ? true : false}
                            success={formik.values.type > 0 && !formik.errors.type}
                            helperText={
                              formik.touched.type && formik.errors.type ? formik.errors.type : null
                            }
                          />
                        )}
                        onChange={(event, type) => {
                          formik.setFieldValue("type", type, false);
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <MDBox mb={14} py={1}>
                  <FormControl sx={{ width: '100%' }} variant="standard">
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      label="Password" 
                      name="password"
                      placeholder='Abcd@123'
                      value=""
                      {...formik.getFieldProps("password")}
                      error={formik.touched.password && formik.errors.password ? true : false}
                      helperText={
                        formik.touched.password && formik.errors.password
                          ? formik.errors.password
                          : null
                      }
                      fullWidth
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }/>
                      {formik.touched.password && formik.errors.password && (
                                <div style={{fontSize:'12px', textAlign:'left'}}
                                    className="text-danger">
                                    {formik.errors.password}
                                    </div>)}
                  </FormControl>
                </MDBox>
              </Grid>
                </Grid>
                <MDBox mt={-11} justifyContent="space-between" display="flex">
                  <MDButton size="small" variant="contained" color="success" type="submit">create</MDButton>
                  <MDButton size="small" variant="contained" color="error" onClick={() => handleShow()} sx={{ ml: "15px" }}>Cancel</MDButton>
                </MDBox>

              </form>
            </MDBox>
          </MDBox>
        }
      </Card>
    </DashboardLayout>
  )
}

export default SubAdmin