import { useContext, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-in-cover.jpeg";

// react-router-dom components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import api from "services/api";
import { SnackbarContext } from "context/SnackbarContext";
import { useNavigate } from "react-router-dom";
const initialValues = {
  currentPassword: "",
  password: "",
  confirmPassword: "",
};

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Your password must be longer than 8 characters.")
    .max(25)
    .matches(/^(?=.{8,})/, "Must Contain 8 Characters")
    .matches(/^(?=.*[a-z])(?=.*[A-Z])/, "Must Contain One Uppercase, One Lowercase")
    .matches(/^(?=.*[!@#\$%\^&\*])/, "Must Contain One Special Case Character")
    .matches(/^(?=.{6,20}$)\D*\d/, "Must Contain One Number"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const ChangePassword = () => {
  
  let navigate = useNavigate();
  const { setSnack } = useContext(SnackbarContext);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log("password", values);
      if(values.currentPassword === values.password){
        setSnack({
          message: "New password shouldn't be same as Current Password",
          open: true,
          type: "error",
        });
      } else {      
        api.users.changePassword(values)
          .then((result) => {
            console.log(result?.data);
            if (result?.data?.message === 'success') {
              console.log("result ::",result?.data?.result?.message);
              setSnack({
                message: result?.data?.result?.message,
                open: true,
                type: "success",
              });
              navigate(-1);
            }else if(result?.data?.message === 'error'){
              console.log("message", result?.data?.result?.message);
              setSnack({
                message: result?.data?.result?.message,
                open: true,
                type: "error",
              });
            }
          }).catch(err => {
            console.log("ERROR  ::",err);
          })
      }
    },
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={2} style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={5}>
          <Card>
            <MDBox sx={{ textAlign: "center" }}>
              <MDTypography variant="h4" fontWeight="medium" color="text" mb={4} mt={4}>
                Change Password
              </MDTypography>
              <form onSubmit={formik.handleSubmit} method="post">
                <MDBox pt={4} pb={3} px={3}>
                  <MDBox mb={2}>
                    <MDInput
                      name="currentPassword"
                      type="password"
                      label="Type your current password"
                      variant="standard"
                      fullWidth
                      placeholder="************"
                      InputLabelProps={{ shrink: true }}
                      {...formik.getFieldProps("currentPassword")}
                      error={
                        formik.touched.currentPassword && formik.errors.currentPassword
                          ? true
                          : false
                      }
                      helperText={
                        formik.touched.currentPassword && formik.errors.currentPassword
                          ? formik.errors.currentPassword
                          : null
                      }
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="password"
                      label="Type your new password"
                      variant="standard"
                      fullWidth
                      placeholder="************"
                      InputLabelProps={{ shrink: true }}
                      {...formik.getFieldProps("password")}
                      error={formik.touched.password && formik.errors.password ? true : false}
                      helperText={
                        formik.touched.password && formik.errors.password
                          ? formik.errors.password
                          : null
                      }
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      name="confirmPassword"
                      type="password"
                      label="Retype your new password"
                      variant="standard"
                      fullWidth
                      placeholder="************"
                      InputLabelProps={{ shrink: true }}
                      {...formik.getFieldProps("confirmPassword")}
                      error={
                        formik.touched.confirmPassword && formik.errors.confirmPassword
                          ? true
                          : false
                      }
                      helperText={
                        formik.touched.confirmPassword && formik.errors.confirmPassword
                          ? formik.errors.confirmPassword
                          : null
                      }
                    />
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton type="submit" variant="gradient" color="info" fullWidth>
                      Change Password
                    </MDButton>
                  </MDBox>
                </MDBox>
              </form>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};
export default ChangePassword;
