import { IconButton, ListItem, ListItemButton, ListItemText } from "@mui/material";
import MDTypography from "components/MDTypography";
import React, { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import List from "@mui/material/List";
import Modal from "@mui/material/Modal";

import ListItemIcon from "@mui/material/ListItemIcon";
import InboxIcon from "@mui/icons-material/Inbox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MDButton from "components/MDButton";
import { UserContext } from "context/UserProvider";
import { Fragment, useContext, useEffect } from "react";
import api from "services/api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useStyles } from "../styles";
// import AddIndustry from "./IndustryLIst/AddIndustry";
import { SnackbarContext } from "context/SnackbarContext";
import AddIndustry from "./AddIndustry";
import EditIndustry from "./EditIndustry";
import { setIn } from "formik";
import { Rowing } from "@mui/icons-material";
import DataTable from "./DataTable";
import DomainList from "../DomainList";

// import api from "../services/api";

const IndustryList = () => {
  const { setSnack } = useContext(SnackbarContext);
  const { userInfo } = useContext(UserContext);
  const [ind, setInd] = useState();
  const [indData, setIndData] = useState([]);
  const [open, setOpen] = useState(false);
  const handleEditOpen = () => setEditOpen(true);
  const [masterDomain, setMasterDomain] = useState();
  const [editOpen, setEditOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setEditOpen(false);
  };
  const [showDomain, setShowDomain] = useState(true);
  const [details, setDetails] = useState({
    columns: [
      { Header: "Name", accessor: "Industry", width: "20%" },
    ],
    rows: []
  })

  const handleOpen = () => setOpen(true);
  const classes = useStyles();

  const [selectedIndex, setSelectedIndex] = useState();
  // const handleListItemClick = (event, index) => {
  //   setSelectedIndex(index);
  // };


  const handleList = () => {
    api.industry
      .findAll()
      .then((data) => {
        console.log("list of industries", data);
        let temp = data?.data?.result?.data;
        console.log(temp);
        setDetails({ ...details, rows: temp });
        setIndData(temp);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    handleList()
  }, []);

  const handleClick = (row, index) => {
    console.log("row of Industry with index", row.Industry, index);
    // setSelectedIndex(index);
    setMasterDomain(row);
  };
  const handleDelete = (row) => {
    console.log("row details", row);
    let id = row.id;
    api.industry
      .updateOne(id, { status: "0" })
      .then((data) => {
        // console.log("..............", data);
        if (data?.data?.message == "success") {
          setSnack({
            message: `Industry ${row.Industry} deleted!`,
            open: true,
            type: "success",
          });
          handleList();
          setShowDomain(false);
        } else if (data?.data?.message == "error") {
          setSnack({
            message: "Some error occurred while deleting",
            open: true,
            type: "error",
          });
        } else {
          setSnack({
            message: "Some error occurred while deleting",
            open: true,
            type: "error",
          });
        }
      })
      .catch((err) => {
        setSnack({
          message: `Industry ${row.Industry} is not deleted!`,
          open: true,
          type: "error",
        });
      });
  };

  const handleEdit = (row) => {
    setEditOpen(true);
    setInd(row);
    console.log("row details", row);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox sx={{ display: "flex" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={4}>
            <Card>
              <MDBox p={3} display="flex" justifyContent="space-between">
                <MDTypography variant="h5" color="dark">
                  Industry List
                </MDTypography>
                <MDButton variant="gradient" size="small" color="info" onClick={() => handleOpen()}>
                  Add Industry
                </MDButton>
              </MDBox>
              <Modal open={open} onClose={handleClose}>
                <MDBox className={classes.addDomain}>
                  <AddIndustry handleList={handleList} handleClose={handleClose} />
                </MDBox>
              </Modal>
              <Modal open={editOpen} onClose={handleClose}>
                <MDBox className={classes.addDomain}>
                  <EditIndustry ind={ind} handleClose={handleClose} handleList={handleList} />
                </MDBox>
              </Modal>
              <DataTable table={details} handleClick={handleClick} handleEdit={handleEdit} handleDelete={handleDelete} canSearch />
            </Card>
          </Grid>
          <Grid item xs={12} xl={8}>
            <DomainList handleList={handleList} type={masterDomain} showDomain={showDomain} />
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default IndustryList;
