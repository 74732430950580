import { useContext, useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";

// @mui material components
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

//user context
import { UserContext } from "context/UserProvider";

//formik and form
import { useFormik } from "formik";
import api from "services/api";

// Image
// import logo from "assets/images/gigleji-logo-120722-dark.png";
import logo from "assets/images/gigleji_dark_new_logo.png"


// Icon
import LinkedIn from "assets/images/linkedin_icon.png";
import google from "assets/images/google_icon.png";

// import bgImage from "assets/images/Gigleji-App-Landing-Page.-editjpg.jpg";
import bgImage from "assets/images/Gigleji-App-Landing-Page-Edit-301122.jpg";
import MDAvatar from "components/MDAvatar";

//formik and form
// import { useFormik } from "formik";
// import api from "services/api";
import { Divider } from "@mui/material";
import { SnackbarContext } from "context/SnackbarContext";
// import MDAvatar from "components/MDAvatar";
// import { GoogleLogin } from "@react-oauth/google";
import { useGoogleLogin } from '@react-oauth/google';
import { useLinkedIn } from 'react-linkedin-login-oauth2';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


// import jwt_decode from "jwt-decode";
// import Cover from "layouts/authentication/reset-password/cover";
import ResetPassword from "layouts/authentication/sign-in/reset-password";
import axios from "axios";
import * as Yup from "yup";
import theme from "assets/theme";

function Illustration() {
  const [rememberMe, setRememberMe] = useState(false);
  const { setSnack } = useContext(SnackbarContext);
  const { setIsAuthenticated, setUserInfo } = useContext(UserContext);
  const [passwordType, setPasswordType] = useState("new");
  const [openPassword, setOpenPassword] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // let { code } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleBackdropClose = () => {
    setOpenBackdrop(false);
  };
  const handleBackdropOpen = () => {
    setOpenBackdrop(true);
  };

  useEffect(() => {
    console.log('url',window.location.origin);
    let code = searchParams.get("code")
    console.log("code", code);
    if(code){
      handleBackdropOpen();
      let reqData = {
        grant_type: 'authorization_code',
        code: code,
        // client_id: '78v9aardd73e5v',
        // client_secret: 'wgb0Z3ZmY1a3AH65',
        redirect_uri: `${window.location.origin}/`
      }
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
      api.users.registerLinkedIn(reqData).then(result => {
        console.log("register", result);
        if(result?.data?.message == "success"){


        if (result?.data?.result?.status === "old-user") {
          console.log("old user");
          let temp = result?.data?.result?.userData;
          setIsAuthenticated(true);
          setUserInfo(temp);
          console.log("user info", temp);
          localStorage.setItem("authToken", temp?.token);
          localStorage.setItem("user", JSON.stringify({ isAuthenticated: true, userInfo: temp }));
          handleBackdropClose();
          setSnack({
            message: "Login success..!",
            open: true,
            type: "success",
          });
          navigate(`/account/profile`);
        } else if(result?.data?.result?.status === "new-user"){
          const gigId = result?.data?.result?.data?.id;
          let userData = result?.data?.result?.data;
          console.log(gigId);
          const register = {
            userId: gigId,
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            avatar: userData.avatar,
            type: "gigler"
          };
          api.userInfo.create(register).then((res) => {
            console.log(result?.data?.result?.data);
            let temp = result?.data?.result?.data;
            if (temp) {
              setIsAuthenticated(true);
              setUserInfo(temp);
              console.log("user info", temp);
              localStorage.setItem("authToken", temp?.token);
              localStorage.setItem("user", JSON.stringify({ isAuthenticated: true, userInfo: temp }));
              handleBackdropClose();
              setSnack({
                message: "Account Created successfully..!",
                open: true,
                type: "success",
              });
              navigate(`/account/profile`);
            }
          });
        }
      } else if(result?.data?.message == "error") {
        handleBackdropClose();
        setSnack({
          message: "Some Error occured While login. Please try again",
          open: true,
          type: "error",
        });
      }
      })
    }
  },[]);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const initialValues = {
    email: "",
    password: "",
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: '78v9aardd73e5v',
    scope: 'r_liteprofile,r_emailaddress',
    redirectUri: `${window.location.origin}/`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: (code) => {
      console.log(code);
    },
    onError: (error) => {
      console.log(error);
    },
  });


  const validationSchema = Yup.object({
    email: Yup.string().email().required("Email cannot be empty"),
    password: Yup.string()
      .required("Password is required"),
  })
  let navigate = useNavigate();
  let location = useLocation();

  let from;
  // console.log("location from", location);
  if (location.state && location.state.from && location.state.from.pathname) {
    from = location.state.from.pathname;
  } else {
    from = '/account/profile';
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      // try {
      // const result = await 
      api.users.signIn({
        email: (values.email).toLowerCase(),
        password: values.password,
      })
        .then((result) => {
          console.log("data", result);
          if (result.data.message == "success") {
            let temp = result?.data?.result?.data;
            // console.log("Login details", temp);
            localStorage.setItem("authToken", temp?.token);
            setIsAuthenticated(true);
            setUserInfo(temp);
            // localStorage.setItem("user", JSON.stringify({ isAuthenticated: true, userInfo: temp }));
            if (temp?.type == "gigler") {
              // navigate(`/account/profile`);
              navigate(from, { replace: true });
              setSnack({
                message: "Login success..!",
                open: true,
                type: "success",
              });
            } else if (temp?.type == "employer") {
              navigate(`/employer/profile`);
              setSnack({
                message: "Login success..!",
                open: true,
                type: "success",
              });
            } else if (temp?.type == "subEmployer") {
              navigate(`/employer/profile`);
              setSnack({
                message: "Login success..!",
                open: true,
                type: "success",
              });
            } else if (temp?.type == "assessor") {
              navigate(`/assessor/dashboard`);
              setSnack({
                message: "Login success..!",
                open: true,
                type: "success",
              });
            } else if (temp?.type == "admin") {
              navigate(`/admin/dashboard`);
              setSnack({
                message: "Login success..!",
                open: true,
                type: "success",
              });
            } else if (temp?.type == "Contracts-admin") {
              navigate(`/admin/dashboard`);
              setSnack({
                message: "Login success..!",
                open: true,
                type: "success",
              });
            } else if (temp?.type == "Finance-admin") {
              navigate(`/admin/dashboard`);
              setSnack({
                message: "Login success..!",
                open: true,
                type: "success",
              });
            } else if (temp?.type == "Assessment-admin") {
              navigate(`/admin/dashboard`);
              setSnack({
                message: "Login success..!",
                open: true,
                type: "success",
              });
            } else if (temp?.type == "General-admin") {
              navigate(`/admin/dashboard`);
              setSnack({
                message: "Login success..!",
                open: true,
                type: "success",
              });
            } else {
              setSnack({
                message: "Error Login",
                open: true,
                type: "error",
              });
            }
          }
          else if (result.data.result == "wrong password") {
            setSnack({
              message: "Wrong password",
              open: true,
              type: "error",
            });
          } else if (result.data.result == "User not found") {
            setSnack({
              message: "User not found",
              open: true,
              type: "error",
            });
          }
          else {
            setSnack({
              message: "Some error occurred while signin",
              open: true,
              type: "error",
            });
          }
        })
        .catch((err) => {
          // console.log("ERROR LOGIN: ", err?.response?.data);
          if (err?.response?.data?.result === "wrong password") {
            setSnack({
              message: "Wrong password entered!",
              open: true,
              type: "error",
            });
          } else if (err?.response?.data?.result === "User not found") {
            setSnack({
              message: "User not found",
              open: true,
              type: "error",
            });
          } else {
            setSnack({
              message: "Some Error occurred during Login",
              open: true,
              type: "error",
            });
          }
        })
    },
  });

  // const responseGoogle = async (response) => {
  //   var decoded = jwt_decode(response?.credential);
  //   console.log(decoded);
  //   let userData = {
  //     firstName: decoded.given_name,
  //     lastName: decoded.family_name,
  //     email: decoded.email,
  //     password: "",
  //     type: "gigler",
  //   };
  //   console.log("signup details:::", userData);
  //   api.users.registerGoogle(userData).then((result) => {
  //     console.log("register", result?.data?.result?.status);

  //     if (result?.data?.result?.status === "old-user") {
  //       let temp = result?.data?.result?.data[0];
  //       setIsAuthenticated(true);
  //       setUserInfo(temp);
  //       console.log("user info", temp);
  //       localStorage.setItem("authToken", temp?.token);
  //       localStorage.setItem("user", JSON.stringify({ isAuthenticated: true, userInfo: temp }));
  //       navigate(`/account/profile`);
  //     } else {
  //       const gigId = result?.data?.result?.data?.id;
  //       console.log(gigId);
  //       const register = {
  //         userId: gigId,
  //         firstName: userData.firstName,
  //         lastName: userData.lastName,
  //         email: userData.email,
  //         avatar: userData.picture,
  //         type: "gigler"
  //       };
  //       api.userInfo.create(register).then((res) => {
  //         console.log(result?.data?.result?.data);
  //         let temp = result?.data?.result?.data;
  //         if (temp) {
  //           setIsAuthenticated(true);
  //           setUserInfo(temp);
  //           console.log("user info", temp);
  //           localStorage.setItem("authToken", temp?.token);
  //           localStorage.setItem("user", JSON.stringify({ isAuthenticated: true, userInfo: temp }));
  //           navigate(`/account/profile`);
  //         }
  //       });
  //     }
  //   });
  // };

  const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      // console.log(tokenResponse);
      // fetching userinfo can be done on the client or the server
      if (tokenResponse) {
        console.log("token", tokenResponse);
        // console.log("token in local",localStorage.getItem('authToken'));

        const uninterceptedAxiosInstance = axios.create();
        const userInfo = await uninterceptedAxiosInstance
          .get('https://www.googleapis.com/oauth2/v3/userinfo', {
            headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
          })
          .then(res => res.data);
        console.log("Google login@@@@@@@", userInfo);
        if (userInfo?.email && userInfo.given_name) {


          console.log(userInfo);
          let userData = {
            firstName: userInfo.given_name,
            lastName: userInfo.family_name,
            email: userInfo.email,
            password: "",
            type: "gigler",
          };
          console.log("signup details:::", userData);
          api.users.registerGoogle(userData).then((result) => {
            console.log("register", result?.data?.result?.status);

            if (result?.data?.result?.status === "old-user") {
              let temp = result?.data?.result?.data[0];
              setIsAuthenticated(true);
              setUserInfo(temp);
              console.log("user info", temp);
              localStorage.setItem("authToken", temp?.token);
              localStorage.setItem("user", JSON.stringify({ isAuthenticated: true, userInfo: temp }));
              navigate(`/account/profile`);
            } else {
              const gigId = result?.data?.result?.data?.id;
              console.log(gigId);
              const register = {
                userId: gigId,
                firstName: userData.firstName,
                lastName: userData.lastName,
                email: userData.email,
                avatar: userData.picture,
                type: "gigler"
              };
              api.userInfo.create(register).then((res) => {
                console.log(result?.data?.result?.data);
                let temp = result?.data?.result?.data;
                if (temp) {
                  setIsAuthenticated(true);
                  setUserInfo(temp);
                  console.log("user info", temp);
                  localStorage.setItem("authToken", temp?.token);
                  localStorage.setItem("user", JSON.stringify({ isAuthenticated: true, userInfo: temp }));
                  navigate(`/account/profile`);
                }
              });
            }
          });
        } else {
          setSnack({
            message: "Error Login using Google please try again",
            open: true,
            type: "error",
          });
        }
      } else {
        setSnack({
          message: "Error Login using Google please try again",
          open: true,
          type: "error",
        });
      }
    },
  });

  const openForgotPassword = () => {
    let email = formik.getFieldProps("email").value;
    if (email != "") {
      // console.log("value", email);
      api.verifications
        .sendOtp({
          email: email,
        })
        .then((result) => {
          let temp = result?.data?.result?.data;
          // console.log("new values", temp);
          if (temp) {
            setUserEmail(email);
            setPasswordType("forgot");
            setOpenPassword(true);
          } else {
            setSnack({
              message: "User Does not exist",
              open: true,
              type: "error",
            });
          }
          console.log("data", data);
        })
        .catch((err) => {
          // console.log(err);
        });
    } else {
      setSnack({
        message: "Please Enter Email Address",
        open: true,
        type: "error",
      });
    }
  };

  return (
    <IllustrationLayout illustration={bgImage}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox sx={{
        textAlign: "center", maxWidth: "350px", maxHeight: "550px", mx: "auto", my: "17%"
      }}>
        <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <MDAvatar src={logo} alt="GIGLEJI" sx={{ width: "40%" }} variant="square" />
        </MDBox>
        {openPassword ? (
          <ResetPassword data={userEmail} type={passwordType} setOpenPassword={setOpenPassword} />
        ) : (
          // <Cover type={passwordType} />
          <>
            <MDTypography variant="h4" fontWeight="medium" color="text" mb={4} mt={2}>
              Sign in
            </MDTypography>
            <MDBox
              sx={{
                display: "flex",
                mt: "20px",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <MDBox>
                <MDButton
                  // disabled
                  size="small"
                  color="text"
                  variant="text"
                  type="button"
                  sx={{ float: "left", border: "0px" }}
                  onClick={linkedInLogin}
                >
                  <MDAvatar
                    src={LinkedIn}
                    sx={{ height: "30px", width: "30px" }}
                    variant="square"
                  />
                </MDButton>
              </MDBox>
              <MDTypography variant="button" color="text" mx={2}>
                Or
              </MDTypography>
              <MDButton
                size="small"
                color="text"
                variant="text"
                type="button"
                sx={{ float: "left", border: "0px" }}
                onClick={() => googleLogin()}
              >
                <MDAvatar
                  src={google}
                  sx={{ height: "30px", width: "30px" }}
                  variant="square"
                />
              </MDButton>
              {/* <MDButton size="small" color="text" variant="text" onClick={() => login()} sx={{ float: "right", p: "10px" }}>              
                <GoogleLogin
                  type="icon"
                  theme="outline"
                  size="large"
                  render={(renderProps) => (
                    <button onClick={renderProps.onClick} disabled={renderProps.disabled}>
                      <MDAvatar src={google} sx={{ height: "30px", width: "30px" }} />
                    </button>
                  )}
                  buttonText="Login"
                  onSuccess={(credentialResponse) => responseGoogle(credentialResponse)}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                />
              </MDButton> */}
            </MDBox>
            <Divider textAlign="center"></Divider>

            <form onSubmit={formik.handleSubmit} method="post">
              <MDBox>
                <MDBox mb={3}>
                  <MDInput
                    type="email"
                    label="Email"
                    fullWidth
                    name="email"
                    {...formik.getFieldProps("email")}
                    inputProps={{ style: { textTransform: "lowercase" } }}
                    error={formik.touched.email && formik.errors.email ? true : false}
                    success={formik.values.email > 0 && !formik.errors.email}
                    helperText={
                      formik.touched.email && formik.errors.email ? formik.errors.email : null
                    }
                  />
                </MDBox>
                <MDBox mb={2}>
                  <FormControl sx={{ width: '100%' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                      type={showPassword ? 'text' : 'password'}
                      label="Password"
                      name="password"
                      id="outlined-adornment-password"
                      fullWidth
                      {...formik.getFieldProps("password")}
                      error={formik.touched.password && formik.errors.password ? true : false}
                      success={formik.values.password > 0 && !formik.errors.password}
                      helperText={
                        formik.touched.password && formik.errors.password ? formik.errors.password : null
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </MDBox>
                <MDBox
                  display="flex"
                  alignItems="center"
                  ml={-1}
                  sx={{ justifyContent: "space-between" }}
                >
                  <MDBox>
                    {/* <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={handleSetRememberMe}
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Remember me
                    </MDTypography> */}
                  </MDBox>
                  <MDTypography
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    // textGradient
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      openForgotPassword();
                      // setPasswordType('forgot');
                      // setOpenPassword(true);
                    }}
                  >
                    Forgot Password
                  </MDTypography>
                </MDBox>
                <MDBox mt={3} mb={1}>
                  <MDButton
                    // component={Link}
                    // to="/authentication/sign-up/cover"
                    // variant="gradient"
                    color="info"
                    size="large"
                    type="submit"
                    fullWidth
                  >
                    sign in
                  </MDButton>
                </MDBox>
                <MDBox mt={3} textAlign="center">
                  <MDTypography variant="button" color="text">
                    Don&apos;t have an account?{" "}
                    <MDTypography
                      component={Link}
                      to="/register"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      // textGradient
                    >
                      Sign up
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </MDBox>
            </form>
          </>
        )}
      </MDBox>
    </IllustrationLayout >
  );
}

export default Illustration;
