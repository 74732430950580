import { Card, Grid, Modal } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import EngagerFeedbackForm from 'layouts/pages/common/EngagerFeedbackForm';
import React, { useEffect, useState } from 'react'
import api from 'services/api';
import Engagements from '../../Engagements';
import ManageEngager from '../../ManageEngager';
import EngagerList from '../../ManageEngager/EngagerList';
import DataTable from './DataTable';
import { useStyles } from './styles'

const EngagerFeedback = () => {
  const css = useStyles();
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(!open);
  const handleBack = () => setShow(!show)
  const [feedbackDetails, setFeedbackDetails] = useState();
  const [details, setDetails] = useState({
    columns: [
      { Header: "Engagement Id", accessor: "engagementId", width: "30%" },
      { Header: "Engagement Owner Name", accessor: "engagementOwnerName", width: "30%" },
      { Header: "Organization Name", accessor: "organizationName", width: "30%" },
      { Header: "GigTalent Name", accessor: "giglerName", width: "30%" },
    ],
    rows: []
  })
  useEffect(() => {
    api.engagerFeedback.findByUserId()
      .then((data) => {
        if (data?.data?.message == "error") {
          setDetails("zero");
        } else {
          console.log("engager feedbacks", data)
          let temp = data?.data?.result?.data
          setDetails({ ...details, rows: temp });
        }
      })
  }, [])

  const handleClick = (row) => {
    setFeedbackDetails(row.original);
    setOpen(true);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {details == "zero" ? (
        <Card sx={{ width: "160vh", height: "75vh", p: "15px" }}>
          <MDTypography variant="button" fontWeight="medium">No Engager Feedbacks</MDTypography>
        </Card>
      ) : (
        <DataTable table={details} handleClick={handleClick} canSearch />
      )}
      <Modal
        open={open}
        onClose={handleClose}
      >
        <MDBox className={css.modal}>
          <EngagerFeedbackForm feedbackDetails={feedbackDetails} handleClose={handleClose} />
        </MDBox>
      </Modal>
    </DashboardLayout >
  )
}

export default EngagerFeedback