/**
=========================================================
* Otis Admin PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Dashboard from "components/Dashboard";

// Otis Admin PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "examples/Cards/BookingCard";
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';

// Anaytics dashboard components
import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWallet,faArrowsSpin,faMobileButton,faBriefcase,faEnvelope} from '@fortawesome/free-solid-svg-icons'
import EditIcon from '@mui/icons-material/Edit';
// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";
import { Card,CardContent,CardHeader,Chip,Divider,Stack,Typography} from "@mui/material";
import Avatar from "@mui/material/Avatar";
// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "context/UserProvider";
import moment from 'moment';
import api from "services/api";

function Analytics() {
  const { sales, tasks } = reportsLineChartData;
  const { userInfo } = useContext(UserContext);
  const [numberOfCompetitors, setNumberOfCompetitors] = useState();
  const [numberOfEngagements, setNumberOfEngagements] = useState();
  const[completness,setCompletness] = useState('');
  const [engagementBar, setEngagementBar] = useState({
    labels: ["Applied", "short-listed", "selected"],
    datasets: {}
  })
  const [dashboardData,setDashboardData] = useState('');
  const navigate = useNavigate();

  const current = new Date();
  current.toLocaleTimeString();

  const calculateCompleteness=()=>{
    const totalFields=44 ;
    let completedFields=0;
      if(dashboardData?.aadhaarNumber) completedFields++ ;
      if(dashboardData?.panNumber) completedFields++ ;
      if(dashboardData?.firstName) completedFields++ ;
      if(dashboardData?.lastName) completedFields++ ;
      if(dashboardData?.languages){
        let length1=Object.keys(dashboardData?.languages).length;
        if(length1>0)completedFields++
      } 
      if(dashboardData?.education){
        let length2=Object.keys(dashboardData?.education).length;
        if(length2>0)completedFields++
      }
      if(dashboardData?.experience){
        let length3=Object.keys(dashboardData?.experience).length;
        if(length3>0)completedFields++
      }
      if(dashboardData?.secondaryIndustry){
        let length3=Object.keys(dashboardData?.secondaryIndustry).length;
        if(length3>0)completedFields++
      }
      if(dashboardData?.email) completedFields++ ;
      if(dashboardData?.phoneNumber) completedFields++ ;
      if(dashboardData?.emailVerification) completedFields++ ;
      if(dashboardData?.phoneVerification) completedFields++ ;
      if(dashboardData?.avatar) completedFields++ ;
      if(dashboardData?.birthDate) completedFields++ ;
      if(dashboardData?.domain) completedFields++ ;
      if(dashboardData?.gender) completedFields++ ;
      if(dashboardData?.industry) completedFields++ ;
      if(dashboardData?.otherSkills) completedFields++ ;
      if(dashboardData?.primarySkills) completedFields++ ;
      if(dashboardData?.secondarySkills) completedFields++ ;
      if(dashboardData?.resume) completedFields++ ;
      if(dashboardData?.resumeText) completedFields++ ;
      if(dashboardData?.preferredLocation) completedFields++ ;
      if(dashboardData?.specialCategory) completedFields++ ;
      if(dashboardData?.whatsappNumber) completedFields++ ;
      if(dashboardData?.yourLocation) completedFields++ ;
      if(dashboardData?.video) completedFields++ ;
      if(dashboardData?.availability) completedFields++ ;
      if(dashboardData?.compensation) completedFields++ ;
      if(dashboardData?.compensationAmount) completedFields++ ;
      if(dashboardData?.currentOrganization) completedFields++ ;
      if(dashboardData?.currentProjectFrom) completedFields++ ;
      if(dashboardData?.currentProjectTo) completedFields++ ;
      if(dashboardData?.currentProjectName) completedFields++ ;
      if(dashboardData?.description) completedFields++ ;
      if(dashboardData?.proRefEmail) completedFields++ ;
      if(dashboardData?.proRefName) completedFields++ ;
      if(dashboardData?.proRefNumber) completedFields++ ;
      if(dashboardData?.proRefOrganization) completedFields++ ;
      if(dashboardData?.proRefPosition) completedFields++ ;
      if(dashboardData?.linkedin)completedFields++;
      if(dashboardData?.experienceYears)completedFields++;
      if(dashboardData?.fullTime || dashboardData?.partTime || dashboardData?.projectBased) completedFields++ ;
      if(dashboardData?.offSite || dashboardData?.onSite || dashboardData?.needBased || dashboardData?.both) completedFields++ ;
      // console.log("completedfields2",completedFields);
      return(completedFields/totalFields)*100
      }

  useEffect(()=>{
    calculateCompleteness()
    // console.log("Percentege",completenessPersentage);
  },[])
  useEffect(() => {
    let temp = []
    api.userInfo.NumberOfAppliedEngagements({ id: userInfo.id })
      .then((data) => {
        temp.push(data?.data?.result?.data)
        api.userInfo.NumberOfShortlistedEngagements({ id: userInfo.id })
          .then((data1) => {
            temp.push(data1?.data?.result?.data)
            api.userInfo.NumberOfSelectedEngagements({ id: userInfo.id })
              .then((data2) => {
                temp.push(data2?.data?.result?.data);
                const datasets1 = { label: "Sales", data: temp }
                setEngagementBar({ ...engagementBar, datasets: datasets1 })
              })
          })
      })
    }, [])

useEffect(()=>{

  const uid = userInfo?.id;
    api.userInfo.findOne(uid).then((data) => {
      if (data) {
        let temp = data?.data?.result?.data;
        // console.log("Temp",temp);
        setDashboardData(temp)
        api.userInfo.NumberOfCompetitors({ industry: temp.industry, domain: temp.domain })
          .then((result) => {
            setNumberOfCompetitors(result?.data?.result?.data)
          })
        api.userInfo.NumberOfMatchingEngagements({ industry: temp.industry, domain: temp.domain })
          .then((resultData) => {
            setNumberOfEngagements(resultData?.data?.result?.data)
          })
      }
    });
},[])
    // console.log("DahboardData",dashboardData);
  // Action buttons for the BookingCard
  // const actionButtons = (
  //   <>
  //     <Tooltip title="Refresh" placement="bottom">
  //       <MDTypography
  //         variant="body1"
  //         color="primary"
  //         lineHeight={1}
  //         sx={{ cursor: "pointer", mx: 3 }}
  //       >
  //         <Icon color="inherit">refresh</Icon>
  //       </MDTypography>
  //     </Tooltip>
  //     <Tooltip title="Edit" placement="bottom">
  //       <MDTypography variant="body1" color="info" lineHeight={1} sx={{ cursor: "pointer", mx: 3 }}>
  //         <Icon color="inherit">edit</Icon>
  //       </MDTypography>
  //     </Tooltip>
  //   </>
  // );

  return (
    <DashboardLayout>
      <DashboardNavbar />
        <Grid container>
          {/* <SalesByCountry /> */}
        </Grid>
            <Dashboard/>
            
            {/* <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="Engagements"
                  // description={<>Engagements applied for<br /> Engagements shortlisted<br /> Engagements selected</>}
                  date={current.toLocaleTimeString()}
                  chart={engagementBar}
                />
              </MDBox>
            </Grid> */}
            {/* <Grid item xs={12} md={6} lg={4} sx={{position: 'relative'}}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="Revenue earned from Gigleji"
                  // description="Engagement wise Revenue details"
                  date={current.toLocaleTimeString()}
                  chart={sales}
                />
              </MDBox>
              <MDBox 
                sx={{
                  position: 'absolute',
                  color: 'black',
                  opacity: 1,
                  background: '#ffffff73',
                  color: '#344767',
                  top: '5px',
                  height: '300px',
                  width: '400px'
                }}
              >
                <MDTypography sx={{textAlign: 'center'}}>                
                  ComingSoon
                </MDTypography> 
              </MDBox>
            </Grid> */}
            {/* <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Profile Search, View Numbers"
                  // description={<>Profile search appearance - monthwise <br /> Profile view numbers - monthwise</>}
                  date={current.toLocaleTimeString()}
                  chart={tasks}
                />
              </MDBox>
            </Grid> */}
        <MDBox mt={1.5} mx={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="how_to_reg"
                  title="Registration Date"
                  count={moment(userInfo?.createdAt).format("DD-MM-YYYY")}
                  percentage={{
                    color: "success",
                    // amount: "+55%",
                    // label: "than lask week",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="Number of Peers"
                  count={numberOfCompetitors}
                // percentage={{
                //   color: "success",
                //   amount: "+3%",
                //   label: "than last month",
                // }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="work_outline"
                  // title="Open Engagements" old code
                  title="Engagements matching" //New code
                  count={numberOfEngagements}
                // percentage={{
                //   color: "success",
                //   amount: "+1%",
                //   label: "than yesterday",
                // }}
                />
              </MDBox>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  // icon="person_add"
                  icon="currency_rupee"
                  title="Revenue earned so far"
                  count=""
                // percentage={{
                //   color: "success",
                //   amount: "",
                //   label: "Just updated",
                // }}
                />
              </MDBox>
            </Grid> */}
          </Grid>
        </MDBox>
        {/* <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mt={3}>
                <BookingCard
                  image={booking1}
                  title="Cozy 5 Stars Apartment"
                  description='The place is close to Barceloneta Beach and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the main night life in Barcelona.'
                  price="$899/night"
                  location="Barcelona, Spain"
                  action={actionButtons}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mt={3}>
                <BookingCard
                  image={booking2}
                  title="Office Studio"
                  description='The place is close to Metro Station and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the night life in London, UK.'
                  price="$1.119/night"
                  location="London, UK"
                  action={actionButtons}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mt={3}>
                <BookingCard
                  image={booking3}
                  title="Beautiful Castle"
                  description='The place is close to Metro Station and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the main night life in Milan.'
                  price="$459/night"
                  location="Milan, Italy"
                  action={actionButtons}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox> */}
      
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Analytics;
