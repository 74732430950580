import { Avatar, Card, Checkbox, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import React, { useEffect } from 'react'
import { useState } from 'react';
import Divider from '@mui/material/Divider';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import { check } from 'prettier';
import CircularProgress from "@mui/material/CircularProgress";

//image
import profileImg from "assets/images/defaultProfile.png"
import MDAvatar from 'components/MDAvatar';

const GiglerList = ({ asrIndustry, asrDomain, giglerList, handleList, setBtnLoad, btnLoad }) => {
  const [checked, setChecked] = useState([]);
  const [close, setClose] = useState(true);
  const [blur, setBlur] = useState(true);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    console.log("checked and length", newChecked, newChecked.length);
    setChecked(newChecked);

    //Enabling button if one or more gigTalent checked true
    if (newChecked.length > 0) {
      setBlur(false)
    } else {
      setBlur(true)
    }
  };

  const handleSubmit = () => {
    handleList(checked)
    // setClose(false);
  }

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        {giglerList.length <= 0 ?
          (<MDBox height="550px" width="400px">
            <MDTypography type="button" variant="h6" px={2} py={2}> GigTalent list is empty...</MDTypography>
          </MDBox>) : (
            close &&
            <>
              <MDBox display="flex" p={2} sx={{ alignItems: "center", justifyContent: "space-between" }}>
                <MDTypography variant="h6">GigTalent List with Domain : <MDTypography variant="p" fontWeight="medium" color="info" textTransform="capitalize">{asrDomain}</MDTypography></MDTypography>
              </MDBox>
              <MDBox p={2} sx={{ maxHeight: 550, overflow: 'auto' }}>
                <List dense
                  sx={{
                    width: '100%',
                    maxWidth: 720,
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: 500
                  }}>
                  {giglerList?.map((row, index) => (
                    <>
                      <ListItem
                        key={row.id}
                        secondaryAction={
                          <IconButton >
                            <Checkbox
                              key={row.userId}
                              checked={checked.indexOf(row.userId) !== -1}
                              onChange={handleToggle(row?.userId)}
                            />
                          </IconButton>
                        }
                        divider={true}
                        sx={{ pb: "10px" }}
                      >
                        <ListItemAvatar>
                          <MDAvatar src={row.avatar || profileImg} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <MDTypography variant="body" textTransform="capitalize" fontWeight="medium">
                              {row.firstName} {row.lastName}
                            </MDTypography>
                          }
                          secondary={
                            <>
                              <MDTypography variant="caption" fontWeight="medium" color="info">
                                Primary skill&nbsp;
                              </MDTypography>
                              <MDTypography variant="caption" color="dark" fontWeight="small">
                                {`-`}{row.primarySkills}
                              </MDTypography> &nbsp;
                              <MDTypography variant="caption" fontWeight="medium" color="info">
                                Secondary skill&nbsp;
                              </MDTypography>
                              <MDTypography variant="caption" color="dark" fontWeight="small">
                                {`-`}{row.secondarySkills}&nbsp;&nbsp;
                              </MDTypography>
                              <MDTypography variant="caption" fontWeight="medium" color="info">
                                Industry&nbsp;
                              </MDTypography>
                              <MDTypography variant="caption" color="dark" fontWeight="small">
                                {`-`}{row.industry}
                              </MDTypography>
                            </>
                          }
                        />
                      </ListItem>
                    </>
                  ))}
                </List>
                {btnLoad ? (
                  <MDBox sx={{ display: "flex", float: "right", mr: "30px", mb: "10px", mt: "10px" }}>
                    <CircularProgress />
                  </MDBox>
                ) : (
                  <MDButton disabled={blur} variant="gradient" color="success" size="small" onClick={() => {
                    handleSubmit()
                    setBtnLoad(true);
                  }
                  } sx={{ display: "flex", float: "right", mr: "30px", mb: "10px", mt: "10px" }}>Assign</MDButton>
                )}
              </MDBox>
            </>
          )}
      </Grid>
    </Grid >

  )
}

export default GiglerList

