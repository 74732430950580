import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import InboxIcon from "@mui/icons-material/Inbox";
import MDButton from "components/MDButton";
import Modal from "@mui/material/Modal";
import { useStyles } from "./styles";
import AddSkillList from "./AddSkillList";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import api from "services/api";
import { useContext } from "react";

import { SnackbarContext } from "context/SnackbarContext";
import EditSkillList from "./EditSkillList";

const SkillList = ({ row }) => {
  // const [list, setList] = useState(row.skillSet);
  // console.log("skilll set ", row);
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);
  const [editSkill, setEditSkill] = useState();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const handleEditOpen = (list, index) => {
    setEditSkill(list, index)
    setOpenEdit(true);
  }
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
  }

  const [selectedIndex, setSelectedIndex] = useState();
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    // console.log(row);
  }, []);

  const handleDelete = (list, index) => {
    console.log("row details", row.id, list, index);
    let currentList = row.skillSet
    currentList.splice(index, 1)
    let del = row.delArray
    del.push(list)
    api.domain.updateOne(row.id, { delArray: del, skillSet: currentList })
      .then(res => {
        if (res) {
          setSnack({
            message: `SkillSet ${list} deleted!`,
            open: true,
            type: "success",
          });
        }
      })
      .catch(err => {
        setSnack({
          message: `SkillSet ${list} is not deleted!`,
          open: true,
          type: "error",
        });
      })
  };

  return (
    <Card>
      <MDBox p={2} display="flex" justifyContent="space-between">
        <MDTypography variant="h5" color="dark">
          <MDTypography variant="h5" color="info">{row.Domain}</MDTypography>Skill Set</MDTypography>
        <MDButton variant="gradient" size="small" color="info" onClick={() => handleOpen()}>
          Add Skill
        </MDButton>
      </MDBox>
      <Modal open={open} onClose={handleClose}>
        <MDBox className={classes.addSkill}>
          <AddSkillList row={row} handleClose={handleClose} />
        </MDBox>
      </Modal>
      <Modal open={openEdit} onClose={handleClose}>
        <MDBox className={classes.addSkill}>
          <EditSkillList row={row} editSkill={editSkill} handleClose={handleClose} />
        </MDBox>
      </Modal>

      <List component="nav" aria-label="main mailbox folders">
        {row.skillSet?.map((row, index) => (
          <ListItemButton
            key={row.skillSet}
            id={index}
            selected={selectedIndex === index}
            onClick={(event) => handleListItemClick(event, index)}
          >
            <ListItemText primary={<MDTypography variant="body2">{row}</MDTypography>} />
            <MDBox sx={{ marginRight: "16px" }}>
              <EditIcon className={classes.editIcon} onClick={() => handleEditOpen(row, index)} />
            </MDBox>
            <MDBox sx={{ marginRight: "16px" }}>
              <DeleteIcon className={classes.editIcon} onClick={() => handleDelete(row, index)} />
            </MDBox>
          </ListItemButton>
        ))}
      </List>
    </Card>
  );
};

export default SkillList;
