import { Card } from '@mui/material';
import MDTypography from 'components/MDTypography';
import { SnackbarContext } from 'context/SnackbarContext';
import { UserContext } from 'context/UserProvider';
import React, { useContext, useEffect, useState } from 'react'
import api from 'services/api'
import DataTable from './DataTable'

const AssignedGtList = ({assrDetails}) => {
  const { userInfo } = useContext(UserContext);
  const {setSnack} = useContext(SnackbarContext);
  const [details, setDetails] = useState({
      columns: [
        { Header: "Name", accessor: `userInfo[0].firstName` },
        { Header: "Email", accessor: `userInfo[0].email` },
        { Header: "Phone number", accessor: "userInfo[0].phoneNumber" },
        { Header: "Industry", accessor: "userInfo[0].industry" },
        { Header: "Domain", accessor: "userInfo[0].domain" },
        {Header: "Re-Assign", accessor:""}
      ],
      rows: []
    })

  const handleAssignList = () => {
    const id = assrDetails?.assessorId
    api.assigned.findAll(id, { assigned: true, assessed: false }).then(data => {
    let temp = data?.data?.result?.data;
    console.log("list of gigTalent assigned", temp);
    if(temp.length > 0){
    setDetails({ ...details, rows: temp });
    }else{
        setDetails("1")
    }
  }).catch(error => {
    console.log("error", error)
  })
    }
  useEffect(() => {
      console.log("asssor details", assrDetails)
      handleAssignList()
  },[])

    const handleDelete = (values) => {
      console.log("gtvalues", values)
      api.assigned.delete({assessorId:values.assessorId, userId:values.userId})
      .then((data) => {
        if(data?.data?.message == "success"){
          const id = values.userId
          api.userInfo.update(id,{assigned:false})
          .then(data => {
            if(data?.data?.message == "success"){
              setSnack({
                message: `${values.userInfo[0].firstName} is removed `,
                open: true,
                type: "success",
              });
              handleAssignList();
            }else{
              setSnack({
                message: `Some error occured while deleting `,
                open: true,
                type: "error",
              });
            }
          })
          .catch((err) => {
            setSnack({
              message: `Some error occured while deleting `,
              open: true,
              type: "error",
            });
          })
        }else{
          setSnack({
            message: `Some error occured while deleting `,
            open: true,
            type: "error",
          });
        }
      })
      .catch((err) => {
        setSnack({
          message: `Some error occured while deleting `,
          open: true,
          type: "error",
        });
      })
    }
  return (
    <>
    {details == '1' ? (
        <Card sx={{p:"15px"}}>
            <MDTypography variant="h5">Assigned List is empty...</MDTypography>
        </Card>
        ):(
            <Card>
                <MDTypography variant="h5" sx={{p:"15px"}}>Assigned List</MDTypography>
                <DataTable table={details} handleDelete={handleDelete} canSearch />
            </Card>
        )}
    </>
  )
}

export default AssignedGtList