import {
  Box,
  Card,
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { CSVLink, CSVDownload } from "react-csv";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//styles
import { useStyles } from "./styles";
import DataTable from "./DataTable";

const ShortListedList = ({ giglerList, handleSubmit }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [checked, setChecked] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [details, setDetails] = useState({
    columns: [
      { Header: "name", accessor: `user.firstName`, width: "20%" },
      { Header: "Industry", accessor: `user.industry`, width: "15%" },
      { Header: "Domain", accessor: `user.domain`, width: "15%" },
      { Header: "Email", accessor: `user.email`, width: "25%" },
      { Header: "Phone Number", accessor: `user.phoneNumber` },
      // { Header: "salary", accessor: "salary" },
    ],
    rows: giglerList
  })
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    // console.log("checked", newChecked)
    setChecked(newChecked);
  };

  const handleClick = (row) => {
    console.log("row", row);
    navigate("/gigTalent-details", {
      state: {
        values: row,
      },
    });
  };

  useEffect(() => {
    if (giglerList && giglerList.length > 0) {
      let temp = giglerList.map((x) => x.user);
      // console.log("users",temp);

      const keys_to_keep = [
        "firstName",
        "lastName",
        "email",
        "phoneNumber",
        "industry",
        "domain",
        "primarySkills",
        "secondarySkills",
      ];
      const redux = (array) =>
        array.map((o) =>
          keys_to_keep.reduce((acc, curr) => {
            // acc[curr] = o[curr];
            if (curr == "education" || curr == "experience") {
              acc[curr] = JSON.stringify(o[curr]);
            } else {
              acc[curr] = o[curr];
            }
            return acc;
          }, {})
        );

      console.log("users data", redux(temp));
      setUsersList(redux(temp));
    }
  }, [giglerList]);
  return (
    <MDBox>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", pr: 2 }}>
        <MDTypography type="title" px={2} py={2}>
          {" "}
          ShortListed List
        </MDTypography>
        {usersList && usersList.length > 0 && (
          <CSVLink data={usersList}>
            <MDButton variant="outlined" color="dark">
              <Icon>description</Icon>
              &nbsp;export details
            </MDButton>
          </CSVLink>
        )}
      </Box>
      {giglerList && giglerList.length <= 0 ? (
        <MDBox mb={2} sx={{ height: 500 }}>
          <MDTypography px={2} py={2}>
            No GigTalent ShortListed for this engagement
          </MDTypography>
        </MDBox>
      ) : (
        // <List dense className={classes.list}>
        //   {giglerList?.map((row, index) => (
        //     <ListItemButton key={row.user.userId} id={index} onClick={() => handleClick(row)}>
        //       <ListItem
        //         className={classes.listItem}
        //         divider={index == giglerList.length - 1 ? false : true}
        //       >
        //         <ListItemAvatar>
        //           <MDAvatar src={row.user.avatar} alt="remy sharp" size="sm">
        //             {row.user.firstName}
        //             {row.user.lastName}
        //           </MDAvatar>
        //         </ListItemAvatar>
        //         <ListItemText
        //           primary={
        //             <MDBox display="flex" justifyContent="space-between">
        //               <MDTypography variant="h6">
        //                 {row.user.firstName + ` ` + row.user.lastName}
        //               </MDTypography>
        //               <MDBadge
        //                 badgeContent={row.selected ? "selected" : "not selected"}
        //                 color={row.selected ? "success" : "light"}
        //                 size="small"
        //               />
        //             </MDBox>
        //           }
        //         />
        //       </ListItem>
        //     </ListItemButton>
        //   ))}
        // </List>
        <DataTable table={details} canSearch handleClick={handleClick} />
      )}
    </MDBox>
  );
};

export default ShortListedList;
