// react-routers components
import { Link, useLocation } from "react-router-dom";

//icons
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useState } from "react";
import burceMars from "assets/images/bruce-mars.jpg";
import MDButton from "components/MDButton";
import profileImg from "assets/images/defaultProfile.png";
//styles
import { useStyles } from "./styles";
import { Card, Modal } from "@mui/material";
import FormField from "layouts/applications/wizard/components/FormField";
import moment from 'moment';

function GiglerProfileForm({ setOpen, handleChange, details }) {
  const classes = useStyles();
  const [openFile, setOpenFile] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  const handleCloseFile = () => setOpenFile(false);
  const handleCloseVideo = () => setOpenVideo(false);
  const [openReport, setOpenReport] = useState(false);
  const handleCloseReport = () => setOpenReport(false)
  const handleClose = () => setOpen(false);
  const [videoFile, setVideoFile] = useState();

  const [show, setShow] = useState(false);
  const formatDate = (string) => {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  };

  const handleClick = () => {
    setShow(!show);
  };
  return (
    <Card
      sx={{
        position: "relative",
        mx: 1,
        py: 2,
        px: 2,
        mb: 2,
      }}
    >
      <Grid container spacing={3} alignItems="center" alignContent="center">
        <Grid item sx={{ margin: '0px' }} xs={12}>
          <MDTypography
            variant="body2"
            color="secondary"
            m={1}
            onClick={() => setOpen("1")}
            sx={{ cursor: "pointer" }}
          >
            <ArrowBackIcon sx={{ mb: "-3px" }} />
            &nbsp;Back
          </MDTypography>
        </Grid>
        <Grid item xs={1}>
          <MDAvatar src={details?.avatar || profileImg} alt="profile-image" size="xl" shadow="sm" />
        </Grid>
        <Grid item xs={11}>
          <MDBox
            height="100%"
            mt={5}
            lineHeight={1}
            justifyContent="space-between"
            display="flex"
            width="100%"
          >
            <MDTypography variant="body" fontWeight="medium" textTransform="capitalize">
              {details?.firstName}&nbsp;{details?.lastName}
            </MDTypography>
            <MDBox>
              {details?.mettlReport ? (
                <MDBox>
                  <Modal open={openReport} onClose={handleCloseReport}>
                    <MDBox className={classes.resumeStyle}>
                      <iframe src={details?.mettlReport} frameborder="0" height="100%" width="100%"></iframe>
                    </MDBox>
                  </Modal>
                  <MDButton
                    sx={{ mr: "15px" }}
                    variant="outlined"
                    color="warning"
                    size="small"
                    onClick={() => setOpenFile(true)}
                  >
                    Pre-Assessment Report
                  </MDButton>
                </MDBox>
              ) : (
                <MDTypography variant="button">Pre-Assessment report pending...</MDTypography>
              )}

              {/* <MDButton
                sx={{ mr: "15px" }}
                variant="outlined"
                color="warning"
                size="small"
              >
                Pre-Assessment Report
              </MDButton> */}
              <MDButton
                sx={{ mr: "15px" }}
                variant="gradient"
                color="info"
                size="small"
                // className={classes.btn}
                onClick={() => {
                  setOpen("3")
                }}
              >
                Assess
              </MDButton>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
      <MDBox mt={2} />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} xl={4}>
          <MDBox alignItems="center" justifyContent="center" pt={2} px={2} pb={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Personal Information
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2} px={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Name&nbsp;: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{details?.firstName}&nbsp;{details?.lastName}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2} px={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Email&nbsp;: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{details?.email}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2} px={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Phone Number&nbsp;: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{details?.phoneNumber}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2} px={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              WhatsApp Number&nbsp;: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{details?.whatsappNumber}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2} px={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              D.O.B&nbsp;: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{moment(details?.birthDate).format("DD-MM-YYYY")}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2} px={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Gender&nbsp;: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{details?.gender}
            </MDTypography>
          </MDBox>
        </Grid>
        {/* <Grid item xs={12} md={6} xl={4}>
          <MDBox alignItems="center" justifyContent="center" pt={2} px={2} pb={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Education Details
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2} px={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Qualification&nbsp;: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{details?.qualification}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2} px={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Course Type&nbsp;: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{details?.type}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2} px={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              University&nbsp;: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{details?.university}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2} px={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Specialization&nbsp;: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{details?.specialization}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2} px={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Start Date&nbsp;: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;10-06-2012
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2} px={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              End Date&nbsp;: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;15-06-2015
            </MDTypography>
          </MDBox>
        </Grid> */}
        <Grid item xs={12} xl={6}>
          <MDBox alignItems="center" justifyContent="center" pt={2} px={2} pb={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Professional Information
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2} px={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Industry&nbsp;: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{details?.industry}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2} px={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Domain&nbsp;: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{details?.domain}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2} px={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Primary Skill&nbsp;: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp; {details?.primarySkills}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2} px={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              secondary Skill&nbsp;: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp; {details?.secondarySkills}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2} px={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Other Skill&nbsp;: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp; {details?.otherSkills}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2} px={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Years of Experience&nbsp;: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{details?.experienceYears?details.experienceYears + " "+ "Yrs.":''}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2} px={2} alignItems="baseline" alignContent="center">
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              LinkedIn ID&nbsp;: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp; {details?.linkedin}
            </MDTypography>
          </MDBox>
        </Grid>
        <Divider orientation="vertical" sx={{ mx: 0 }} />
        {/* <Grid item xs={12} xl={4}>
          <MDBox alignItems="center" justifyContent="center" pt={2} px={2} pb={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Career Story
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2} px={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Description&nbsp;: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{details?.description}
            </MDTypography>
          </MDBox>
        </Grid> */}
        <Grid item xs={12} md={6} xl={4}>
          <MDBox alignItems="center" justifyContent="center" pt={2} px={2} pb={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Engagement Model
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2} px={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Full Time&nbsp;: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{details?.fullTime ? "Yes" : "No"}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2} px={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Part Time&nbsp;: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{details?.partTime ? "Yes" : "No"}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2} px={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Engagement Based&nbsp;: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{details?.projectBased ? "Yes" : "No"}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2} px={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Spl. Category&nbsp;: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              {/* &nbsp;{details?.returningMom ? "Returning Mom" : details?.physicallyChallenged ? "Specially Abled" : details?.retiredBureaucrats ? "Retired Bureaucrats" : details?.defensePersonnel ? "Defense Personnel" : "-"} */}
              {/* &nbsp;{details?.returningMom ? "Returning Mom" : ""}{details?.returningMom ? ',' : ''}
              &nbsp;{details?.physicallyChallenged ? "Specially Abled" : ""}{details?.retiredBureaucrats ? ',' : ''}
              &nbsp;{details?.retiredBureaucrats ? "Retired Bureaucrats" : ""}{details?.defensePersonnel ? ',' : ''}
              &nbsp;{details?.defensePersonnel ? "Defence Personnel" : ""} */}
              {details?.specialCategory || ""}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12} xl={12} sm={12} alignContent="right">
          <MDBox>
            <Modal open={openFile} onClose={handleCloseFile}>
              <MDBox className={classes.resumeStyle}>
                {/* <iframe src={details?.resume} frameborder="0" height="100%" width="100%"></iframe> */}
                <iframe
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  src={`https://docs.google.com/gview?url=${details?.resume}&embedded=true`}
                ></iframe>
              </MDBox>
            </Modal>
            <MDButton
              variant="gradient"
              color="info"
              size="small"
              className={classes.previewBtn}
              onClick={() => setOpenFile(true)}
            >
              Preview Resume
            </MDButton>
            <MDButton
              className={classes.previewBtn}
              color="info"
              size="small"
              variant="gradient"
              href={details?.resume}
              sx={{ mr: "150px" }}
            >
              Download
            </MDButton>
          </MDBox>
          <MDButton
            variant="gradient"
            size="small"
            className={classes.showBtn}
            onClick={handleClick}
            color={!show ? "success" : "error"}
          >
            {!show ? "show more" : "show less"}{" "}
          </MDButton>
        </Grid>
        {show && (
          <>
            {details?.currentOrganization && (
              <Grid item xs={12} xl={4}>
                <MDBox alignItems="center" justifyContent="center" pt={2} px={2} pb={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Current Engagement
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Current Organization&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.currentOrganization}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Current Project Name&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.currentProjectName}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Project From&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.currentProjectFrom ? formatDate(details?.currentProjectFrom) : '-'}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Project To&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.currentProjectTo ? formatDate(details?.currentProjectTo) : '-'}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Location&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.yourLocation}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Preferred location&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.preferredLocation}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Availability&nbsp;: &nbsp;
                  </MDTypography>
                  <MDBox mt={-1}>
                    {details?.availability == 'zeroToSevenDays' &&
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp;0-7 days
                      </MDTypography>
                    }
                    {details?.availability == "eightToFifteenDays" &&
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp;8-15 days
                      </MDTypography>
                    }
                    {details?.availability == "sixteenToThirtyDays" &&
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp;16-30 days
                      </MDTypography>
                    }
                    {details?.availability == "moreThanThirtyDays" &&
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp;More Than 30 days
                      </MDTypography>
                    }
                  </MDBox>
                </MDBox>
              </Grid>
            )}

            <Grid item xs={12} md={6} xl={4}>
              <MDBox alignItems="center" justifyContent="center" pt={2} px={2} pb={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Preferred Engagement
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  On-Site&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{details?.onSite ? "Yes" : "No"}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Off-Site&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{details?.offSite ? "Yes" : "No"}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Both (Hybrid)&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{details?.both ? "Yes" : "No"}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Need Based&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{details?.needBased ? "Yes" : "No"}
                </MDTypography>
              </MDBox>
            </Grid>
            {/* <Grid item xs={12} md={6} xl={4}>
              <MDBox alignItems="center" justifyContent="center" pt={2} px={2} pb={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Availability for On-boarding
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  0-7 days&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{details?.zeroToSevenDays ? "Yes" : "No"}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  8-15 days&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{details?.eightToFifteenDays ? "Yes" : "No"}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  16-30 days&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{details?.sixteenToThirtyDays ? "Yes" : "No"}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  More than 30 days&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{details?.moreThanThirtyDays ? "Yes" : "No"}
                </MDTypography>
              </MDBox>
            </Grid> */}
            {/* <Grid item xs={12} md={6} xl={4}>
              <MDBox alignItems="center" justifyContent="center" pt={2} px={2} pb={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Other Information
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  PAN&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{details?.panNumber}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Aaadhaar Number (Optional)&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{details?.aadhaarNumber}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Passport Number (Optional)&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{details?.passport}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Valid Till (Optional)&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{details?.passportValidDate}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Languages Known&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{details?.languages}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  GST Number&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{details?.gstIn}
                </MDTypography>
              </MDBox>
            </Grid> */}
            {/* <Grid item xs={12} md={12} xl={12}>
              <MDBox>
                <MDBox alignItems="center" justifyContent="center" pt={1} px={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Other Information
                  </MDTypography>
                </MDBox>
                <Grid container>
                  <Grid item xs={2}>
                    <MDBox py={2} px={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        textTransform="capitalize"
                      >
                        PAN:
                      </MDTypography>
                      <br />
                      <MDTypography variant="button" textTransform="capitalize">
                        {details?.panNumber}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={2}>
                    <MDBox py={2} px={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        textTransform="capitalize"
                      >
                        Aaadhar Number(Optional):
                      </MDTypography>
                      <br />
                      <MDTypography variant="button" textTransform="capitalize">
                        {details?.aadhaarNumber}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={2}>
                    <MDBox py={2} px={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        textTransform="capitalize"
                      >
                        Passport Number (Optional):
                      </MDTypography>
                      <br />
                      <MDTypography variant="button" textTransform="capitalize">
                        {details?.passport}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={2}>
                    <MDBox py={2} px={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        textTransform="capitalize"
                      >
                        Valid Till (Optional):
                      </MDTypography>
                      <br />
                      <MDTypography variant="button" textTransform="capitalize">
                        {formatDate(details?.passportValidDate)}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={2}>
                    <MDBox py={2} px={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        textTransform="capitalize"
                      >
                        Languages Known:
                      </MDTypography>
                      <br />
                      <MDTypography variant="button" textTransform="capitalize">
                        {details?.languages}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={2}>
                    <MDBox py={2} px={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        textTransform="capitalize"
                      >
                        GST Number (Optional):
                      </MDTypography>
                      <br />
                      <MDTypography variant="button" textTransform="capitalize">
                        {details?.gstIn}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid> */}

            <Grid item xs={12} md={12} xl={12}>
              <MDBox>
                <MDBox alignItems="center" justifyContent="center" pt={1} px={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Education Details
                  </MDTypography>
                </MDBox>
                <Grid container>
                  <Grid item xs={2}>
                    <MDBox py={2} px={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        textTransform="capitalize"
                      >
                        Qualification
                      </MDTypography>
                      {/* <br />
                      <MDTypography variant="button" textTransform="capitalize">
                        1. {details?.qualification}
                      </MDTypography> */}
                    </MDBox>
                  </Grid>
                  <Grid item xs={2}>
                    <MDBox py={2} px={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        textTransform="capitalize"
                      >
                        Type
                      </MDTypography>
                      {/* <br />
                      <MDTypography variant="button" textTransform="capitalize">
                        {details?.type}
                      </MDTypography> */}
                    </MDBox>
                  </Grid>
                  <Grid item xs={2}>
                    <MDBox py={2} px={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        textTransform="capitalize"
                      >
                        College/University
                      </MDTypography>
                      {/* <br />
                      <MDTypography variant="button" textTransform="capitalize">
                        {details?.university}
                      </MDTypography> */}
                    </MDBox>
                  </Grid>
                  <Grid item xs={2}>
                    <MDBox py={2} px={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        textTransform="capitalize"
                      >
                        Area of Specialization
                      </MDTypography>
                      {/* <br />
                      <MDTypography variant="button" textTransform="capitalize">
                        {details?.specialization}
                      </MDTypography> */}
                    </MDBox>
                  </Grid>
                  <Grid item xs={2}>
                    <MDBox py={2} px={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        textTransform="capitalize"
                      >
                        Joining Date
                      </MDTypography>
                      {/* <br />
                      <MDTypography variant="button" textTransform="capitalize">
                        {moment(details?.joiningDate).format("DD-MM-YYYY") || ""}
                      </MDTypography> */}
                    </MDBox>
                  </Grid>
                  <Grid item xs={2}>
                    <MDBox py={2} px={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        textTransform="capitalize"
                      >
                        Passed Out Date
                      </MDTypography>
                      {/* <br />
                      <MDTypography variant="button" textTransform="capitalize">
                        {moment(details?.passOutDate).format("DD-MM-YYYY") || ""}
                      </MDTypography> */}
                    </MDBox>
                  </Grid>
                  {details?.education.map((element, index) =>
                    <>
                      <Grid item xs={2}>
                        <MDBox px={2}>
                          <MDTypography variant="button" textTransform="capitalize" >
                            {index + 1}. {element?.qualification}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={2}>
                        <MDBox px={2}>
                          <MDTypography variant="button" textTransform="capitalize">
                            {element?.type}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={2}>
                        <MDBox px={2}>
                          <MDTypography variant="button" textTransform="capitalize">
                            {element?.university}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={2}>
                        <MDBox px={2}>
                          <MDTypography variant="button" textTransform="capitalize">
                            {element?.specialization}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={2}>
                        <MDBox px={2}>
                          <MDTypography variant="button" textTransform="capitalize">
                            {element?.joiningDate ? formatDate(element?.joiningDate) : '-'}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={2}>
                        <MDBox px={2}>
                          <MDTypography variant="button" textTransform="capitalize">
                            {element?.passOutDate ? formatDate(element?.passOutDate) : '-'}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    </>
                  )}
                </Grid>
              </MDBox>
            </Grid>

            <Grid item xs={12} md={12} xl={12}>
              <MDBox>
                <MDBox alignItems="center" justifyContent="center" pt={1} px={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Experience Details
                  </MDTypography>
                </MDBox>
                <Grid container>
                  <Grid item xs={2}>
                    <MDBox py={2} px={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        textTransform="capitalize"
                      >
                        Employer
                      </MDTypography>
                      {/* <br />
                      <MDTypography variant="button" textTransform="capitalize">
                        1. {details?.employer}
                      </MDTypography> */}
                    </MDBox>
                  </Grid>
                  <Grid item xs={2}>
                    <MDBox py={2} px={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        textTransform="capitalize"
                      >
                        Designation
                      </MDTypography>
                      {/* <br />
                      <MDTypography variant="button" textTransform="capitalize">
                        {details?.designation}
                      </MDTypography> */}
                    </MDBox>
                  </Grid>
                  <Grid item xs={2}>
                    <MDBox py={2} px={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        textTransform="capitalize"
                      >
                        Project From
                      </MDTypography>
                      {/* <br />
                      <MDTypography variant="button" textTransform="capitalize">
                        {moment(details?.from).format("DD-MM-YYYY") || ""}
                      </MDTypography> */}
                    </MDBox>
                  </Grid>
                  <Grid item xs={2}>
                    <MDBox py={2} px={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        textTransform="capitalize"
                      >
                        Project To
                      </MDTypography>
                      {/* <br />
                      <MDTypography variant="button" textTransform="capitalize">
                        {moment(details?.to).format("DD-MM-YYYY") || ""}
                      </MDTypography> */}
                    </MDBox>
                  </Grid>
                </Grid>
                {details?.experience.map((element, index) =>
                  <Grid container>
                    <Grid item xs={2}>
                      <MDBox px={2}>
                        <MDTypography variant="button" textTransform="capitalize" >
                          {index + 1}. {element?.employer}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={2}>
                      <MDBox px={2}>
                        <MDTypography variant="button" textTransform="capitalize">
                          {element?.designation}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={2}>
                      <MDBox px={2}>
                        <MDTypography variant="button" textTransform="capitalize">
                          {formatDate(element?.from)}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={2}>
                      <MDBox px={2}>
                        <MDTypography variant="button" textTransform="capitalize">
                          {formatDate(element?.to)}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  </Grid>
                )}
              </MDBox>
            </Grid>
            <Grid item xs={12} xl={12} md={12}>
              <MDBox alignItems="center" justifyContent="center" pt={2} px={2} pb={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Career Story
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={2} px={2} sx={{ border: "10px", width: "800px" }}>
                <MDTypography variant="button" fontWeight="regular" color="text" textAlign="left">
                  &nbsp;{details?.description}
                </MDTypography>
              </MDBox>
            </Grid>

            <Grid item xs={12} md={12} xl={12}>
              <MDBox>
                <MDBox alignItems="center" justifyContent="center" pt={1} px={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Professional Reference
                  </MDTypography>
                </MDBox>
                <Grid container>
                  <Grid item xs={2}>
                    <MDBox py={2} px={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        textTransform="capitalize"
                      >
                        Name
                      </MDTypography>
                      <br />
                      <MDTypography variant="button" textTransform="capitalize">
                        {details?.proRefName}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={2}>
                    <MDBox py={2} px={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        textTransform="capitalize"
                      >
                        Email
                      </MDTypography>
                      <br />
                      <MDTypography variant="button" textTransform="capitalize">
                        {details?.proRefEmail}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={2}>
                    <MDBox py={2} px={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        textTransform="capitalize"
                      >
                        Phone number
                      </MDTypography>
                      <br />
                      <MDTypography variant="button" textTransform="capitalize">
                        {details?.proRefNumber}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={2}>
                    <MDBox py={2} px={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        textTransform="capitalize"
                      >
                        Position
                      </MDTypography>
                      <br />
                      <MDTypography variant="button" textTransform="capitalize">
                        {details?.proRefPosition}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={2}>
                    <MDBox py={2} px={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        textTransform="capitalize"
                      >
                        Organization
                      </MDTypography>
                      <br />
                      <MDTypography variant="button" textTransform="capitalize">
                        {details?.proRefOrganization}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
                <Grid>
                  <MDBox px={2}>
                    <MDTypography variant="h6">Mettl assessment Report</MDTypography>
                    <FormField
                      placeholder="Mettl assessment Report"
                      name="mettlReport"
                      multiline
                      value={details?.mettlReport}
                      rows={5}
                      inputProps={{ type: "text", readOnly: true }}
                    />
                  </MDBox>
                </Grid>
                <Grid>
                  <MDBox px={2} mb={6}>
                    <MDTypography variant="h6">Resume Text</MDTypography>
                    <FormField
                      placeholder="Resume text..."
                      name="resumetext"
                      multiline
                      value={details?.resumeText}
                      rows={5}
                      inputProps={{ type: "text", readOnly: true }}
                    />
                  </MDBox>
                </Grid>
                {details?.video ? (
                  <Grid item xs={12} xl={12} md={12}>
                    <MDBox alignItems="center" justifyContent="center" pt={2} px={2} pb={2}>
                      <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                        Personalized video
                      </MDTypography>
                    </MDBox>
                    <MDBox display="flex" py={1} pr={2} px={2}>
                      <Modal open={openVideo} onClose={handleCloseVideo}>
                        <MDBox className={classes.videoStyle}>
                          <video width="450" height="300" controls>
                            <source src={details?.video} type="video/mp4" />
                            {/* frameborder="0" height="100%" width="100%" > */}
                          </video>
                        </MDBox>
                      </Modal>

                      <MDButton
                        type="gradient"
                        color="info"
                        size="small"
                        // className={classes.previewBtn}
                        onClick={() => setOpenVideo(true)}
                      >
                        View Video
                      </MDButton>

                    </MDBox>
                  </Grid>
                ) : (
                  <MDBox>

                  </MDBox>
                )}
              </MDBox>
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  );
}

export default GiglerProfileForm;
