import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => {
  return {
    addDomain: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "30%",
      height: "30%",
      margin: "auto",
      boxShadow: 24,
      p: 4,
    },
  };
})