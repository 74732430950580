import { Card } from '@mui/material'
import MDTypography from 'components/MDTypography'
import { UserContext } from 'context/UserProvider'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useContext, useEffect, useState } from 'react'
import api from 'services/api'
import DataTable from './DataTable'

const EngagementHistory = () => {
  const { userInfo } = useContext(UserContext);
  const [details, setDetails] = useState({
    columns: [
      { Header: "Engagement Id", accessor: "engagementId", width: "12%" },
      { Header: "Title", accessor: "engagementTitle", width: "20%" },
      { Header: "Organization", accessor: "organizationName", width: "20%" },
      { Header: "Engagement Owner Name", accessor: "engagerName", width: "20%" },
      { Header: "Industry", accessor: "industry", width: "10%" },
      { Header: "Domain", accessor: "domain", width: "10%" },
      { Header: "Skills", accessor: "primarySkills", width: "15%" },
      { Header: "Status", accessor: "status", width: "10%" },
    ],
    rows: []
  })
  useEffect(() => {
    const id = userInfo?.id
    api.engagement.findByEngId(id, { status: "closed" })
      .then((data) => {
        if (data?.data?.result?.status == 0) {
          setDetails(0);
        } else {
          let temp = data?.data?.result?.data
          setDetails({ ...details, rows: temp });
        }
      })
  }, [])
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {details == 0 ?
        (
          <Card>
            <MDTypography px={2} py={2}>No Engagement's Closed</MDTypography>
          </Card>
        ) : (
          <DataTable table={details} canSearch />
        )
      }
    </DashboardLayout>

  )
}

export default EngagementHistory