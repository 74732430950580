import React from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import FormField from "layouts/pages/account/components/FormField";
import MDButton from "components/MDButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Fragment, useContext, useEffect } from "react";
import { UserContext } from "context/UserProvider";
import { SnackbarContext } from "context/SnackbarContext";

import api from "services/api";

const Social = ({ userData, updateUserData }) => {
  const { userInfo } = useContext(UserContext);
  const { setSnack } = useContext(SnackbarContext);

  const initialValues = {
    linkedin: "",
  };

  const validationSchema = Yup.object({
    linkedin: Yup.string(),
  });
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      try {
        let id = ""
        if(userInfo.type == "admin"){
          id = userData?.userId
        }else{
          id = userInfo?.id
        }
        updateUserData(values);
        const result = await api.userInfo.update(id, values);
        if (result) {
          // navigate(`/account/settings`);
          if (result?.data?.message == "success") {
            setSnack({
              message: "Social Link Updated!",
              open: true,
              type: "success",
            });
          } else if(result?.data?.message == "error") {
            setSnack({
              message: "Error updating details!",
              open: true,
              type: "error",
            });
          }
          
        } else {
          throw new error("failed to update");
        }
      } catch (error) {
        setSnack({
          message: "Error occurred during update!",
          open: true,
          type: "success",
        });
        console.log("ERROR LOGIN: ", { ...error });
      }
    },
  });
  useEffect(() => {
    if (userData) {
      formik.setValues(userData);
    }
  }, [userData])

  return (
    <>
      <Card id="social-links">
        <form onSubmit={formik.handleSubmit} method="post">
          <MDBox p={2}>
            <MDTypography variant="h5" pb={2}>
              Social Links
            </MDTypography>
            <MDBox display="flex" py={1} pr={2} px={2}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                Linkedin&nbsp;:
              </MDTypography>
              <FormField
                placeholder="linkedin.com/in/ravi-kumar-bb45913a"
                sx={{ mt: "-20px", ml: "5px" }}
                name="linkedin"
                {...formik.getFieldProps("linkedin")}
              />
            </MDBox>
            <MDBox pt={1}>
              <MDButton variant="gradient" size="small" color="success" type="submit">
                SAVE
              </MDButton>
            </MDBox>
          </MDBox>
        </form>
      </Card>
      <Card>
        <MDTypography varinat=""></MDTypography>
      </Card>
    </>
  );
};

export default Social;
