import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useContext } from "react";
import FormField from "layouts/pages/account/components/FormField";
import { useFormik } from "formik";
import MDButton from "components/MDButton";
import { SnackbarContext } from "context/SnackbarContext";
import api from "services/api";

const EditIndustry = ({ ind, handleClose, handleList }) => {
  const { setSnack } = useContext(SnackbarContext);
  const initialValues = {
    Industry: ind?.Industry || "",
  };
  const formik = useFormik({
    initialValues,
    // validationSchema,
    onSubmit: async (values) => {
      const id = ind.id;
      try {
        const result = await api.industry.updateOne(id, values);
        console.log("edit result.......", result);
        if (result?.data?.message == "success") {
          // let Industry = ind.Industry;
          // let setIndustry = values.Industry;
          // console.log("edit industry values", id, Industry, setIndustry);
          const domainIndustry = await api.domain.updateMany({ Industry: ind.Industry, setIndustry: values.Industry });
          if (domainIndustry?.data?.message == "success") {
            setSnack({
              message: `Industry ${values.Industry} edited!`,
              open: true,
              type: "success",
            });
            handleList();
            handleClose();
          } else {
            setSnack({
              message: `Error occurred While Editing Industry `,
              open: true,
              type: "error",
            });
          }
        } else {
          setSnack({
            message: "Error occurred while editing Industry",
            open: true,
            type: "error"
          })
        }
      } catch (error) {
        setSnack({
          message: "Error occurred during Editing!",
          open: true,
          type: "error",
        });
        console.log("ERROR update: ", { ...error });
      }
    },
    // validate,
  });
  
  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z\s]+$/;
  return (
    <Card>
      <MDBox px={2} py={2}>
        <MDTypography variant="h6" color="dark">
          Edit Industry
        </MDTypography>
        <form onSubmit={formik.handleSubmit} method="post">
          <MDBox px={2} py={2}>
            <MDBox>
              <FormField
                variant="outlined"
                label="Industry"
                name="Industry"
                {...formik.getFieldProps("Industry")}
                onKeyDown={(event) => {
                  if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </MDBox>
            <MDBox py={1} display="flex" justifyContent="space-between">
              <MDButton variant="gradient" color="success" size="small" type="submit">
                save
              </MDButton>
            </MDBox>
          </MDBox>
        </form>
      </MDBox>
    </Card>
  );
};

export default EditIndustry;
