import { Autocomplete, Checkbox, Divider, FormControlLabel, FormGroup } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useContext, useEffect, useState } from "react";
import FormField from "layouts/pages/account/components/FormField";
import api from "services/api";
import MDButton from "components/MDButton";
import { UserContext } from "context/UserProvider";
import { Box } from "@mui/system";

const SearchFilters = ({ eng, searchedList, searchFilterState, industryList, changeIndustry, setList }) => {
  const {userInfo} = useContext(UserContext)
  const [checkList, setCheckList] = useState();
  const [industryFilter, setIndustryFilter] = useState()
  const [checked, setChecked] = useState({
    primarySkills: false,
    secondarySkills: false,
    partTime: false,
    fullTime: false,
    projectBased: false,
    onSite: false,
    offSite: false,
    both: false,
    needBased: false,
    zeroToSevenDays: false,
    eightToFifteenDays: false,
    sixteenToThirtyDays: false,
    moreThanThirtyDays: false,
    compensation: {
      hourly: false,
      daily: false,
      monthly: false,
    },
    textSearch: "",
  });
  const [formData, setFormData] = useState({
    domain: eng?.Domain,
    industry: "",
    primarySkills: "",
    secondarySkills: "",
    partTime: false,
    fullTime: false,
    projectBased: false,
    onSite: false,
    offSite: false,
    both: false,
    needBased: false,
    zeroToSevenDays: false,
    eightToFifteenDays: false,
    sixteenToThirtyDays: false,
    moreThanThirtyDays: false,
    compensation: [],
    textSearch: [],
  });

  const handleChange = (e, fieldValue) => {
    const { name, value } = e.target;
    if (name == "primarySkills" || name == "secondarySkills") {
      setChecked({ ...checked, [name]: fieldValue });
      setFormData({ ...formData, [name]: fieldValue ? value : "" });
    } else if (name == "compensation") {
      setChecked({
        ...checked,
        compensation: {
          ...checked.compensation,
          [value]: fieldValue,
        },
      });
      let temp = formData;
      fieldValue ? temp.compensation.push(value) : temp.compensation.pop(value);
      setFormData({
        ...temp,
      });
    } else if (name == "textSearch") {
      console.log("value", value.split(" "));
      setFormData({ ...formData, [name]: value.split(" ") });
      setChecked({ ...checked, [name]: value });
    } else {
      setFormData({ ...formData, [name]: fieldValue });
      setChecked({ ...checked, [name]: fieldValue });
    }
  };

  const handleSearch = (formData) => {
    console.log("search form data", formData)
   formData.engagerId = userInfo.id
    api.userInfo.search(formData).then((data) => {
      let temp = data?.data?.result?.temp || [];
      console.log("Giglers matching with domain,industry not applied", temp);
      setList(data?.data?.result?.temp);
      searchedList(temp, checked);
    });
  };

  useEffect(() => {
    if (Object.keys(searchFilterState).length > 0) {
      setChecked(searchFilterState);
    }
  }, [searchFilterState]);

  const clearFilters = () => {
    setChecked({});  
  };

  useEffect(() => {
    if (Object.keys(checked).length <= 0) {
      console.log("no", checked);
      handleSearch({
        engagementId: eng?.engagementId,
        domain: eng?.domain,
        industry: eng?.industry,
      });
    } 
  }, [checked]);
  return (
    <MDBox px={2} py={2}>
      <MDBox bgColor="#fff" sx={{ width: "200px", position: "fixed", zIndex: "999" }}>
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            zIndex: "tooltip",
          }}
        >
          <MDTypography variant="h5" color="dark" fontWeight="bold">
            {" "}
            Filters
          </MDTypography>
          <MDButton
            type="button"
            size="small"
            color="success"
            variant="gradient"
            onClick={() => handleSearch(formData)}
          >
            search
          </MDButton>
        </MDBox>
        <Divider />
      </MDBox>
      
      <MDBox sx={{ mt: "70px" }}>
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <MDTypography variant="h6" color="dark">
            Skills
          </MDTypography>
          <MDButton
            type="button"
            size="small"
            color="success"
            variant="gradient"
            onClick={() => clearFilters()}
          >
            Clear filters
          </MDButton>
        </MDBox>
        <FormGroup>
          {eng?.engagementId ? (
            <>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked.primarySkills}
                name="primarySkills"
                value={eng?.primarySkills}
                onChange={(e, value) => {
                  handleChange(e, value);
                }}
              />
            }
            label={
              <MDTypography color="text" variant="p" fontWeight="regular">
                {eng?.primarySkills}
              </MDTypography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checked.secondarySkills}
                name="secondarySkills"
                value={eng?.secondarySkills}
                onChange={(e, value) => {
                  handleChange(e, value);
                }}
              />
            }
            label={
              <MDTypography color="text" variant="p" fontWeight="regular">
                {eng?.secondarySkills}
              </MDTypography>
            }
          />
          {/* <FormField
            label="Other skill"
            name='Other skill'
          /> */}
          </>
          ) : ("")}
          </FormGroup>

      </MDBox>
      <MDBox mb={1}>
      <MDTypography variant="h6" color="dark">
          Industry filter
        </MDTypography>
        <Autocomplete           
          name="industries"
          options={industryList}
          getOptionLabel={(option) => option.Industry}
          renderOption={(props, option) => (
            <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
              {option.Industry}
            </Box>
          )}
          renderInput={(params) => <FormField {...params} label="Select Industry" />}       
          onChange={(event, industryValue) => {
            console.log("industry", industryValue);
            // setIndustryFilter(industry?.Industry);
            setFormData({ ...formData, industry: industryValue?.Industry });
          }}          
        />
      </MDBox>
      <MDBox>
        <MDTypography variant="h6" color="dark">
          Engagement Model
        </MDTypography>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked?.partTime ? checked?.partTime : false}
                name="partTime"
                value={checked?.partTime}
                onChange={(e, value) => {
                  handleChange(e, value);
                }}
              />
            }
            label={
              <MDTypography color="text" variant="p" fontWeight="regular">
                Part Time
              </MDTypography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checked?.fullTime ? checked?.fullTime : false}
                name="fullTime"
                value={checked?.fullTime}
                onChange={(e, value) => {
                  handleChange(e, value);
                }}
              />
            }
            label={
              <MDTypography color="text" variant="p" fontWeight="regular">
                Full Time
              </MDTypography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checked?.projectBased ? checked?.projectBased : false}
                name="projectBased"
                value={checked?.projectBased}
                onChange={(e, value) => {
                  handleChange(e, value);
                }}
              />
            }
            label={
              <MDTypography color="text" variant="p" fontWeight="regular">
                Engagement Based
              </MDTypography>
            }
          />
        </FormGroup>
      </MDBox>
      <MDBox>
        <MDTypography variant="h6" color="dark">
          Engagement Type
        </MDTypography>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked?.onSite ? checked?.onSite : false}
                name="onSite"
                value={checked?.onSite}
                onChange={(e, value) => {
                  handleChange(e, value);
                }}
              />
            }
            label={
              <MDTypography color="text" variant="p" fontWeight="regular">
                On Site
              </MDTypography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checked?.offSite ? checked?.offSite : false}
                name="offSite"
                value={checked?.offSite}
                onChange={(e, value) => {
                  handleChange(e, value);
                }}
              />
            }
            label={
              <MDTypography color="text" variant="p" fontWeight="regular">
                WFH
              </MDTypography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checked?.both ? checked?.both : false}
                name="both"
                value={checked?.both}
                onChange={(e, value) => {
                  handleChange(e, value);
                }}
              />
            }
            label={
              <MDTypography color="text" variant="p" fontWeight="regular">
                Both
              </MDTypography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checked?.needBased ? checked?.needBased : false}
                name="needBased"
                value={checked?.needBased}
                onChange={(e, value) => {
                  handleChange(e, value);
                }}
              />
            }
            label={
              <MDTypography color="text" variant="p" fontWeight="regular">
                Need Based
              </MDTypography>
            }
          />
        </FormGroup>
      </MDBox>
      <MDBox>
        <MDTypography variant="h6" color="dark">
          Availability
        </MDTypography>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked?.zeroToSevenDays ? checked?.zeroToSevenDays : false}
                name="zeroToSevenDays"
                value={checked?.zeroToSevenDays}
                onChange={(e, value) => {
                  handleChange(e, value);
                }}
              />
            }
            label={
              <MDTypography color="text" variant="p" fontWeight="regular">
                0-7 Days
              </MDTypography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checked?.eightToFifteenDays ? checked?.eightToFifteenDays : false}
                name="eightToFifteenDays"
                value={checked?.eightToFifteenDays}
                onChange={(e, value) => {
                  handleChange(e, value);
                }}
              />
            }
            label={
              <MDTypography color="text" variant="p" fontWeight="regular">
                8-15 Days
              </MDTypography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checked?.sixteenToThirtyDays ? checked?.sixteenToThirtyDays : false}
                name="sixteenToThirtyDays"
                value={checked?.sixteenToThirtyDays}
                onChange={(e, value) => {
                  handleChange(e, value);
                }}
              />
            }
            label={
              <MDTypography color="text" variant="p" fontWeight="regular">
                16-30 Days
              </MDTypography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checked?.moreThanThirtyDays ? checked?.moreThanThirtyDays : false}
                name="moreThanThirtyDays"
                value={checked?.moreThanThirtyDays}
                onChange={(e, value) => {
                  handleChange(e, value);
                }}
              />
            }
            label={
              <MDTypography color="text" variant="p" fontWeight="regular">
                More than 30 days
              </MDTypography>
            }
          />
        </FormGroup>
      </MDBox>
      <MDBox>
        <MDTypography variant="h6" color="dark">
          Mode of Billing
        </MDTypography>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked?.compensation?.hourly ? checked?.compensation?.hourly : false}
                name="compensation"
                value="hourly"
                onChange={(e, value) => {
                  handleChange(e, value);
                }}
              />
            }
            label={
              <MDTypography color="text" variant="p" fontWeight="regular">
                Hourly
              </MDTypography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checked?.compensation?.daily ? checked?.compensation?.daily : false}
                name="compensation"
                value="daily"
                onChange={(e, value) => {
                  handleChange(e, value);
                }}
              />
            }
            label={
              <MDTypography color="text" variant="p" fontWeight="regular">
                Daily
              </MDTypography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checked?.compensation?.monthly ? checked?.compensation?.monthly : false}
                name="compensation"
                value="monthly"
                onChange={(e, value) => {
                  handleChange(e, value);
                }}
              />
            }
            label={
              <MDTypography color="text" variant="p" fontWeight="regular">
                Monthly
              </MDTypography>
            }
          />
        </FormGroup>
      </MDBox>
      <MDBox>
        <MDTypography variant="h6" color="dark">
          Search with Key words
        </MDTypography>
        <FormGroup>
          <FormField
            placeholder="Text Search"
            name="textSearch"
            value={checked?.textSearch}
            inputProps={{ type: "text" }}
            onChange={(e, value) => {
              handleChange(e, value);
            }}
          />
        </FormGroup>
      </MDBox>
    </MDBox>
  );
};

export default SearchFilters;
