// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import TimelineList from "examples/Timeline/TimelineList";
import TimelineItem from "examples/Timeline/TimelineItem";

// Data
import timelineData from "layouts/pages/projects/timeline/data/timelineData";
import { useContext, useEffect, useState } from "react";
import api from "services/api";
import ProfilesList from "examples/Lists/ProfilesList";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

//components
import dataTableData from "layouts/applications/data-tables/data/dataTableData";
// import Engagements from "./Engagements";
import Engagements from "../EngagementStage/ShortListed/Engagements";
import ContractSignedList from "./ContractSignedList";
// import ShortListedList from "./ShortListedList";
import { SnackbarContext } from "context/SnackbarContext";
import { UserContext } from "context/UserProvider";

const ContractManagement = () => {
  const { setSnack } = useContext(SnackbarContext);
  const [data, setData] = useState([]);
  const [gigs, setGigs] = useState([]);
  const [value, setValue] = useState();
  const { userInfo } = useContext(UserContext);

  useEffect(() => {
    const id = userInfo?.id;
    api.engagement.findByEngId(id, {status:"open"})
      // api.engagement
      //   .findAll()
      .then((data) => {
        // console.log("list of assessors", data);
        let temp = data?.data?.result?.data;
        console.log("list of engagements", temp);
        setData(temp);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const handleGiglerList = async (listValues) => {
    setValue(listValues);
    let id = listValues.engagementId;
    api.engagementProcess.findAll(id, { applied: true, shortListed: true, selected: true, contract: true }).then((data) => {
      if (data) {
        let temp = data?.data?.result?.data;
        setGigs(temp);
        console.log("giglist", temp);
      }
    });
  };
  const handleSubmit = (checked) => {
    let id = value.engagementId;
    let updateId = checked;
    api.engagementProcess.updateManyShortlisted(id, { updateId }).then((data) => {
      let N = data?.data?.result?.data?.n;
      if (N > 0) {
        setSnack({
          message: `GigTalent short-listed successfully `,
          open: true,
          type: "success",
        });
      } else {
        setSnack({
          message: `Error occurred during short-listing `,
          open: true,
          type: "error",
        });
      }
    });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={4} md={4}>
            <Card>
              <Engagements engagementList={data} handleGiglerList={handleGiglerList} />
            </Card>
          </Grid>
          <Grid item xs={12} xl={8}>
            <Card>
              <MDBox>
                <MDTypography type="title" px={2} py={2}>Contract Signed List</MDTypography>
                {gigs && gigs.length <= 0 ? (
                  <MDBox mb={2} sx={{ width: 500, height: 500 }}>
                    <MDTypography px={2} py={2}>No GigTalent selected for this engagement</MDTypography>
                  </MDBox>
                ) : (
                  // {gigs && gigs.length > 0 &&
                  <ContractSignedList giglerList={gigs} handleSubmit={handleSubmit} />
                )
                }
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default ContractManagement;