import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// MUI
import { Grid, Modal, Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
// Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Layouts
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import EngagementDetails from "components/EngagementDetails";

import DataTable from "./DataTable";
import { useStyles } from "../../EngagerDashboard/ContractManagement/styles";
import { SnackbarContext } from "context/SnackbarContext";
import api from "services/api";
import { UserContext } from "context/UserProvider";


const GiglerContractManagement = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { userInfo } = useContext(UserContext);
  const [docUrl, setDocUrl] = useState("");
  const [openView, setOpenView] = useState(false);
  const handleCloseFile = () => setOpenView(false);
  const { setSnack } = useContext(SnackbarContext);

  function sliceText(Title,maxLength){
    if(Title.length <= maxLength){
      return Title;
    }else{
      return Title.slice(0,maxLength-3) + '...';
    }
  }

  const [details, setDetails] = useState({
    columns: [
      // { Header: "Engagement ID", accessor: `engagementId`, width: "20%" },
      { Header: "Title", accessor: "selectedEngagements.engagementTitle", width:"20%", Cell: ({ row }) => (
        <Tooltip title={row.original.selectedEngagements.engagementTitle} placement='top'>
          {sliceText(row.original.selectedEngagements.engagementTitle, 10)}
        </Tooltip>),},
      { Header: "Status", accessor: `sentBy`, width: "25%" },
      { Header: "Contract Sent By", accessor: `documentReceived`, width: "25%" },
      // { Header: "Document Receieved", accessor: `documentSent`, width: "25%" },
      // { Header: "Status", accessor: `Status`, width: "25%" },
      { Header: "Contract", accessor: `contract` },
      { Header: "Actions", accessor: `user.actions` },
    ],
    rows: [],
  });
  useEffect(() => {
    console.log("userid", userInfo);
    api.userInfo
      .selectedEngagements(userInfo.id, { contract: true, to: 'gigler' })
      .then((data) => {
        // console.log("list of assessors", data);
        let temp = data?.data?.result?.data;
        console.log("list of engagements", temp);
        if (temp) {
          let z = temp.filter(t => t.contractDetails.length > 0);
          console.log("zzzzzzz", z);
          setDetails({ ...details, rows: z })
        } else {
          setDetails({ ...details, rows: [] })
        }
        // setData(temp);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const handleClick = (row) => {
    // console.log("row", row);
    // navigate("/gigTalent-details", {
    //   state: {
    //     values: row,
    //   },
    // });
  };

  const viewDoc = (url) => {
    console.log("doc", url);
    setDocUrl(url);
    setOpenView(true);
  };

  const editRow = (row) => {
    console.log("edit", row);
  };
  const cancelRow = (row) => {
    let id = row._id;
    console.log("cancelRow", row, id);
    api.engagementProcess
      .updateOne(id, { contractStatus: "3" })
      .then((data) => {
        if (data) {
          setSnack({
            message: `Contract Canceled`,
            open: true,
            type: "success",
          });
        }
      })
      .catch((err) => {
        setSnack({
          message: `error occured in Canceling`,
          open: true,
          type: "error",
        });
      });
  };
  const openContract = (row) => {
    console.log("row", row);
    navigate("/sign-contract", {
      state: row
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={12}>
            <Card>
              <MDBox>
                <MDTypography type="title" px={2} py={2}>
                  Contract Signed List
                </MDTypography>
              </MDBox>
              <MDBox>
                <DataTable
                  table={details}
                  canSearch
                  viewDoc={viewDoc}
                  editRow={editRow}
                  cancelRow={cancelRow}
                  type={'gigler'}
                  openContract={openContract}
                  openDetails={openContract}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Modal open={openView} onClose={handleCloseFile}>
        <MDBox className={classes.resumeStyle}>
          <iframe
            className={"docx"}
            width="100%"
            height="100%"
            frameBorder="0"
            src={`https://docs.google.com/gview?url=${docUrl}&embedded=true`}
          ></iframe>
        </MDBox>
      </Modal>
    </DashboardLayout>
  );
};

export default GiglerContractManagement;
