import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => {
  return {
    checkbox: {
      marginRight: "30px"
    },
    list: {
      width: '100%',
      maxWidth: 600,
      bgcolor: 'background.paper',
      marginLeft: "10px"
    },
    listItem: {
      padding: "2px",
      marginBottom: "2px"
    },
    btn: {
      float: "right",
      marginTop: "10px",
      marginBottom: "10px",
      marginRight: "10px"
    },
    resumeStyle: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '75%',
      height: '90%',
      margin: 'auto',
      bgColor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    },
    inputStyle:{
      display: 'none',
    }
  };
});