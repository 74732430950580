
// Otis Admin PRO React layouts
import Admin from "layouts/dashboards/analytics/admin";

// @mui icons
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import SpeakerNotesOutlinedIcon from "@mui/icons-material/SpeakerNotesOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import StorageIcon from '@mui/icons-material/Storage';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import GradingIcon from '@mui/icons-material/Grading';

// Components
import Engagements from "layouts/pages/AdminDashboard/Engagements";
import ManageEngager from "layouts/pages/AdminDashboard/ManageEngager";
import ManageAssessor from "layouts/pages/AdminDashboard/ManageAssessors";
import ManageGigler from "layouts/pages/AdminDashboard/ManageGigler";
import Assigning from "layouts/pages/AdminDashboard/Assigning";
import IndustryList from "layouts/pages/AdminDashboard/MasterData/IndustryList"
// import DomainList from "layouts/pages/AdminDashboard/MasterData/DomainList";
import ManagePlans from "layouts/pages/AdminDashboard/MasterData/ManagePlans"
import EngagerFeedback from "layouts/pages/AdminDashboard/ManageFeedback/EngagerFeedback";
import GiglerFeedback from "layouts/pages/AdminDashboard/ManageFeedback/GiglerFeedback";
import ManageContractsAdmin from "layouts/pages/AdminDashboard/ManageContracts";
import SubAdmin from "layouts/pages/AdminDashboard/ManageSubAdmin";
import AssignContractTemplates from "layouts/pages/AdminDashboard/ManageContracts/AssignContractTemplates";
import ListContracts from "layouts/pages/AdminDashboard/ManageContracts/ListContracts";
import ImportGiglers from "layouts/pages/AdminDashboard/ImportData/ImportGiglers";
import ImportEngagers from "layouts/pages/AdminDashboard/ImportData/ImportEngagers";
import ImportAssessors from "layouts/pages/AdminDashboard/ImportData/ImportAssessors";
import AdminManagePlans from "components/AdminManagePlans";

const adminRoutes = [
  {
    type: "route",
    name: "Dashboard",
    key: "dashboard",
    route: "/admin/dashboard",
    component: <Admin />,
    icon: <DashboardOutlinedIcon />,
  },
  {
    type: "route",
    name: "Manage Sub-Admin",
    key: "manage-sub-admin",
    route: "/admin/manage-sub-admin",
    component: <SubAdmin />,
    icon: <SupervisorAccountIcon />,
  },
  {
    type: "route",
    name: "Manage Engagers",
    key: "manage-engagers",
    route: "/admin/manage-engagers",
    component: <ManageEngager />,
    icon: <BusinessOutlinedIcon />,
  },
  {
    type: "route",
    name: "Manage Assessors",
    key: "manage-assessors",
    route: "/admin/manage-assessors",
    component: <ManageAssessor />,
    icon: <SpeakerNotesOutlinedIcon />,
  },
  {
    type: "route",
    name: "Manage GigTalent",
    key: "manage-gigTalent",
    route: "/admin/manage-gigTalent",
    component: <ManageGigler />,
    icon: <AccountBoxOutlinedIcon />,
  },
  {
    type: "route",
    name: "Assign GigTalent to Assessors",
    key: "assign-gigTalent",
    route: "/admin/assign-gigTalent",
    component: <Assigning />,
    icon: <GroupOutlinedIcon />,
  },
  {
    type: "collapse",
    name: "Manage Contracts",
    key: "manage-contracts",
    route: "/admin/master-data",
    icon: <StorageIcon />,
    collapse: [
      {
        name: "Contract Templates",
        key: "contract-templates",
        route: "/admin/contract-templates",
        component: <ListContracts />,
      },
      {
        name: "Engagement-wise",
        key: "engagement-wise",
        route: "/admin/engagement-wise",
        component: <AssignContractTemplates />,
      },
      {
        name: "Review Contracts",
        key: "review-contracts",
        route: "/admin/review-contracts",
        component: <ManageContractsAdmin />,
      },
    ]
  },
  {
    type: "route",
    name: "Manage Financials",
    key: "manage-financials",
    route: "/admin/manage-financials",
    component: "",
    icon: <PaidOutlinedIcon />,
  },
  {
    type: "route",
    name: "Manage Engagements",
    key: "engagement-history",
    route: "/admin/engagements",
    component: <Engagements />,
    icon: <RestoreOutlinedIcon />,
  },
  {
    type: "collapse",
    name: "Master Data",
    key: "master-data",
    route: "/admin/master-data",
    icon: <StorageIcon />,
    collapse: [
      {
        name: "Manage Industries",
        key: "industry-list",
        route: "/admin/master-data/industry-list",
        component: <IndustryList />,
      },
      // {
      //   name: " Manage Domains ",
      //   key: "domain-list",
      //   route: "/admin/master-data/domain-list",
      //   component: <DomainList />,
      // },
      // {
      //   name: " Manage Plans ",
      //   key: "manage-plans",
      //   route: "/admin/master-data/manage-plans",
      //   component: <ManagePlans />,
      // },
      {
        name: " Manage Plans ",
        key: "manage-plans",
        route: "/admin/master-data/manage-plans",
        component: <AdminManagePlans/>,
      }
    ],
  },
  {
    type: "collapse",
    name: "Feedback",
    key: "feedback",
    route: "/admin/feedback",
    icon: <GradingIcon />,

    collapse: [
      {
        name: "Engager Feedback",
        key: "engager-feedback",
        route: "/admin/feedback/engager-feedback",
        component: <EngagerFeedback />
      },
      {
        name: "GigTalent Feedback",
        key: "gigTalent-feedback",
        route: "/admin/feedback/gigTalent-feedback",
        component: <GiglerFeedback />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Import Data",
    key: "import-data",
    route: "/admin/import-data",
    icon: <GradingIcon />,

    collapse: [
      {
        name: "Import GigTalents",
        key: "import-gigtalents",
        route: "/admin/import/gigtalents",
        component: <ImportGiglers />
      },
      {
        name: "Import Engagers",
        key: "import-engagers",
        route: "/admin/import/engagers",
        component: <ImportEngagers />
      },
      {
        name: "Import Assessors",
        key: "import-assessors",
        route: "/admin/import/assessors",
        component: <ImportAssessors />
      },
    ],
  },
];

export default adminRoutes;
