import { ListItem, ListItemButton, ListItemText, Paper } from "@mui/material";
import MDTypography from "components/MDTypography";
import React, { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import InboxIcon from "@mui/icons-material/Inbox";
import SkillList from "./SkillList";
import { Fragment, useContext, useEffect } from "react";
import api from "services/api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "@mui/material/Modal";
import FormField from "layouts/pages/account/components/FormField";
import AddDomain from "./AddDomain";
import { useStyles } from "./styles";
import { SnackbarContext } from "context/SnackbarContext";
import EditDomain from "./EditDomain";
import DataTable from "./DataTable";
import { Types } from "aws-sdk/clients/budgets";

const DomainList = ({ type, showDomain }) => {
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);
  const [selectedIndex, setSelectedIndex] = useState();
  const [skillSet, setSkillSet] = useState();
  const [domain, setDomain] = useState();
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [ind, setInd] = useState();
  const [indData, setIndData] = useState([]);
  const [skills, setSkills] = useState();

  const [details, setDetails] = useState({
    columns: [
      { Header: "Name", accessor: "Domain", width: "20%" },
      { Header: "Series", accessor: "code", width: "20%" },
      // { Header: "Actions", accessor: "", width: "10%" }
    ],
    rows: []
  })
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditOpen(false);
  };
  const handleClick = (row, index) => {
    setSkillSet(row);
  };

  const handleDomainList = () => {
    api.domain
      .findAll()
      .then((data) => {
        // console.log("list of domains", data);
        let temp = data?.data?.result?.data;
        let y = temp.filter(x => x.Industry == type.Industry)
        setDetails({ ...details, rows: y });
        setDomain(y);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  useEffect(() => {
    handleDomainList()
  }, [type]);

  const handleDelete = (row) => {
    // console.log("row details", row);
    let id = row.id;
    api.domain
      .updateOne(id, { status: "0" })
      .then((data) => {
        // console.log("..............", data);
        setSnack({
          message: `Domain ${row.Domain} deleted!`,
          open: true,
          type: "success",
        });
      })
      .catch((err) => {
        setSnack({
          message: `Domain ${row.Domain} is not deleted!`,
          open: true,
          type: "success",
        });
      });
  };
  const handleEdit = (row) => {
    setEditOpen(true);
    setInd(row);
    // console.log("row details", row);
  };
  return (
    <MDBox display="flex">
      <Card>
        {showDomain && type ? (
          <>
            <MDBox px={2} py={3} display="flex" justifyContent="space-between">
              <MDTypography variant="h5" color="dark">
                <MDTypography variant="h5" color="info">{type.Industry}</MDTypography>Domain List
              </MDTypography>
              <MDButton variant="gradient" size="small" color="info" onClick={() => handleOpen()}>
                Add Domain
              </MDButton>
            </MDBox>
            <Modal open={open} onClose={handleClose}>
              <MDBox className={classes.addDomain}>
                <AddDomain handleClose={handleClose} mainIndustry={type} domainList={domain} handleDomainList={handleDomainList} />
              </MDBox>
            </Modal>
            <Modal open={editOpen} onClose={handleClose}>
              <MDBox className={classes.addDomain}>
                <EditDomain ind={ind} handleClose={handleClose} mainIndustry={type} domainList={domain} handleDomainList={handleDomainList} />
              </MDBox>
            </Modal>
            <DataTable table={details} handleClick={handleClick} handleEdit={handleEdit} handleDelete={handleDelete} canSearch />
          </>
        ) : (
          <MDTypography variant="title" px={2} py={2}>Select Industry to display Domain list</MDTypography>
        )
        }
      </Card>
      {skillSet &&
        <MDBox sx={{ paddingLeft: "20px", minWidth: "350px" }}>
          <Card>
            <SkillList row={skillSet} />
          </Card>
        </MDBox>
      }
    </MDBox>
  );
};

export default DomainList;
