import { generateUUID4 } from "utils/random";

export const getUserSpecificHeaders = () => {  
	let token;
  token = localStorage.getItem('authToken');

	if (!token) {
		return null;
	}
	
	return ({		
		Authorization: `Bearer ${token}`,
	});
};