import { useState, useContext, useEffect, useRef } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

import { UserContext } from "context/UserProvider";
import MDButton from "components/MDButton";
import { CircularProgress, Icon } from "@mui/material";
import api from "services/api";
import { useStyles } from "../../styles";
import { SnackbarContext } from "context/SnackbarContext";


function Header({ userData }) {
  let fileUploaded;
  const classes = useStyles();
  const [visible, setVisible] = useState(true);
  const { setIsAuthenticated, setUserInfo } = useContext(UserContext);
  const { userInfo } = useContext(UserContext);
  const { setSnack } = useContext(SnackbarContext);
  const [userAvatar, setUserAvatar] = useState(userInfo?.avatar);
  const handleSetVisible = () => setVisible(!visible);
  const [loading, setLoading] = useState(false);
  const imgRef = useRef(null);


  const handleClick = (event) => {
    document.getElementById("profile-img").click()
  }
  const removeImage =async() => {
    const result = await api.userInfo.update(userInfo.id, {avatar:""});
        if (result) {
          console.log("result", result);
          setUserAvatar("")
        }
  }
  

  //handleChange for image
  const handleChange = (event) => {
    let fileUploaded = event.target.files[0];
    // setUserAvatar(URL.createObjectURL(fileUploaded));
    const formData = new FormData();
    formData.append("avatar", fileUploaded);
    formData.append("id", userInfo.id);
    let type = event.target.files[0].type;
    if(type == "image/jpeg" || type == "image/jpg" || type == "image/png"){
      setLoading(true)
    api.userUploads.avatarUpdate(formData) //api call for sending image data
      .then((data) => {
        if (data?.data?.message == "success") {
          setSnack({
            message: "Profile Picture updated!",
            open: true,
            type: "success",
          });
          setUserAvatar(data?.data?.result?.data?.avatar);
          setLoading(false);
        } else {
          setSnack({
            message: "Error occurred while changing profile picture!",
            open: true,
            type: "error",
          });
          setLoading(false);
        }
        console.log("image data", data);
      });
      // setLoading(false);
  }
  else {
    setSnack({
      message: "Upload Only PNG or JPG or JPEG files",
      open: true,
      type: "error",
    });
  }
};

  useEffect(() => {
    if (userData) {
      const avatar = userData?.avatar;
      setUserAvatar(avatar)
    }
  }, [userData]);

  return (
    <Card id="profile">
      <MDBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDBox position="relative" height="max-content" mx="auto">
              {loading ? (
                <MDBox 
                sx={{display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p:1,
              }}>
                  <CircularProgress color="error" />
                </MDBox>
              ) : (
              <>
              <>
              {userInfo.type == "gigler" && 
              <MDBox alt="sportify logo" align="right" right={100} bottom={20}>
                <MDButton variant="gradient" color="error" size="small"  iconOnly onClick={() => removeImage()}>
                    <Icon >remove</Icon>
                </MDButton> 
                </MDBox>
              }
                <MDAvatar src={userAvatar} alt="profile picture" size="xl" variant="rounded" shadow="sm" />
                {userInfo.type == "gigler" && 
              <MDBox alt="spotify logo" position="absolute" right={0} bottom={0} mr={-1} mb={-1} type="input">
                <MDButton variant="gradient" color="info" size="small" iconOnly onClick={() => handleClick(event)}>
                  <Icon >edit</Icon>
                </MDButton>
                <input type="file" id="profile-img" className={classes.inputStyle} name="profile-img" accept="image/*" ref={imgRef} onChange={(e) => handleChange(e)} sx={{ display: "none" }} />
              </MDBox>
              }
              </>
              </>
              )}

            </MDBox>
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" textTransform="capitalize" fontWeight="medium">
                {userData?.firstName + " " + userData?.lastName}
              </MDTypography>
            </MDBox>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
            <MDBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <MDTypography variant="caption" fontWeight="regular">
                Switch to {visible ? "inactive" : "active"}
              </MDTypography>
              <MDBox ml={1}>
                <Switch checked={visible} onChange={handleSetVisible} />
              </MDBox>
            </MDBox>
          </Grid> */}
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Header;
