import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useContext, useState } from "react";
import FormField from "layouts/pages/account/components/FormField";
import { useFormik } from "formik";
import MDButton from "components/MDButton";
import { SnackbarContext } from "context/SnackbarContext";
import api from "services/api";
import * as Yup from "yup";

const initialValues = {
  // enagagerToGigler: '',
  // enagagerToGigleji: '',
  // giglejiToGigler: '',
  key: "",
  title: "",
  status: "",
};

const CreateContractTemplate = ({ handleClose, updateContracts }) => {
  const formData = new FormData();
  const [doc1, setDoc1] = useState();
  const [doc2, setDoc2] = useState();
  const [doc3, setDoc3] = useState();
  const fileChange = (e) => {
    console.log("clicked", e.target);
    let temp = e.target.files[0];
    let type =e.target.files[0].type;
    if (e.target.name == "enagagerToGigler" && (type == "application/pdf" || type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document")) {
      setDoc1(temp);
    } else if (e.target.name == "enagagerToGigleji" && (type == "application/pdf" || type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document")) {
      setDoc2(temp);
    } else if (e.target.name == "giglejiToGigler" && (type == "application/pdf" || type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document")) {
      setDoc3(temp);
    }
    else {
      setSnack({
        message:"Please upload only .pdf and .doc files",
        open:true,
        type:"error"
      })
    }
    formData.append(e.target.name, temp);
  };

  const { setSnack } = useContext(SnackbarContext);

  const validationSchema = Yup.object({
    title: Yup.string().required("Contract title is required")
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        if(!values.title){
          setSnack({
            message: "Please fill the title",
            open: true,
            type: "warning",
          });
        }
        else if( !doc1 || !doc2 || !doc3){
          setSnack({
            message: "Please attach all 3 documents of proper format",
            open: true,
            type: "warning",
          });
        } else {        
          const formData = new FormData();
          let insertData = {
            "title": values.title,
            "key": values.title,
            "status": "1",
            "enagagerToGigler": doc1,
            "enagagerToGigleji": doc2,
            "giglejiToGigler": doc3,
          }
          formData.append("title", values.title);
          formData.append("key", values.title);
          formData.append("status", "1");
          formData.append("enagagerToGigler", doc1);
          formData.append("enagagerToGigleji", doc2);
          formData.append("giglejiToGigler", doc3);
          console.log("values", insertData,formData);
          const result = await api.contract.CreateContractTemplates(formData);
          console.log("resuklt",result?.data);
          if (result?.data.message == 'success') {
            setSnack({
              message: `Created Successfully!`,
              open: true,
              type: "success",
            });
            updateContracts();
          } else {
            throw new error("failed to update");
          }
        } 
      }catch (error) {
        setSnack({
          message: "Error occurred during creation!",
          open: true,
          type: "error",
        });
        console.log("ERROR update: ", { ...error });
      }      
    },
    // validate,
  });
  return (
    <Card>
      <MDBox px={2} py={2}>
        <MDTypography variant="h6" color="dark">
          Create Contract Template
        </MDTypography>
        <form onSubmit={formik.handleSubmit} method="post">
          <MDBox px={2} py={2}>
            <MDBox>
              <FormField
                variant="outlined"
                label="Title *"
                name="title"
                {...formik.getFieldProps("title")}
                error={formik.touched.title && formik.errors.title ? true : false}
                success={formik.values.title > 0 && !formik.errors.title}
                helperText={
                  formik.touched.title && formik.errors.title
                    ? formik.errors.title
                    : null
                }
              />
            </MDBox>
            <MDBox>
              <MDTypography variant="h6" color="dark">
                Enagager To Gigler *
              </MDTypography>
              <input
                type="file"
                variant="outlined"
                label="Enagager To GigTalent"
                name="enagagerToGigler"
                onChange={(e) => fileChange(e)}
                // {...formik.getFieldProps("enagagerToGigler")}
              />
            </MDBox>
            <MDBox mt={1}>
              <MDTypography variant="h6" color="dark">
                Enagager To Gigleji *
              </MDTypography>
              <input
                type="file"
                variant="outlined"
                label="enagagerToGigleji"
                name="enagagerToGigleji"
                onChange={(e) => fileChange(e)}
                // {...formik.getFieldProps("enagagerToGigleji")}
              />
            </MDBox>
            <MDBox mt={1}>
              <MDTypography variant="h6" color="dark">
                Gigleji To GigTalent *
              </MDTypography>
              <input
                type="file"
                variant="outlined"
                label="Gigleji To GigTalent"
                name="giglejiToGigler"
                onChange={(e) => fileChange(e)}
                // {...formik.getFieldProps("giglejiToGigler")}
              />
            </MDBox>
            <MDBox mt={1} display="flex" justifyContent="space-between">
              {/* <FormField
                sx={{ maxWidth: "150px" }}
                variant="outlined"
                label="Series"
                name="code"
                {...formik.getFieldProps("code")}
              /> */}
              <MDButton variant="gradient" color="success" size="small" type="submit">
                Create
              </MDButton>
            </MDBox>
          </MDBox>
        </form>
      </MDBox>
    </Card>
  );
};

export default CreateContractTemplate;
