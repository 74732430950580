import React from 'react'
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "services/api";

import dataTableData from "./data/dataTableData";
import DataTable from "./DataTable";
const SubUserList = ({ data }) => {
  const navigate = useNavigate()
  const [details, setDetails] = useState({
    columns: [
      { Header: "name", accessor: "firstName", width: "20%" },
      { Header: "Organization", accessor: "organizationName", width: "20%" },
      { Header: "Enterprise", accessor: "enterprise", width: "10%" },
      { Header: "Industry", accessor: "industry", width: "10%" },
      { Header: "Location", accessor: "location", width: "10%" },
      { Header: "email", accessor: "email", width: "15%" },
      { Header: "Phone number", accessor: "phoneNumber", width: "15%" },
      { Header: "Designation", accessor: "designation", },
      { Header: "Department", accessor: "department", },
    ],
    rows: []
  })
  useEffect(() => {
    let id = data?.engagerId
    api.engager.findAllSubEmployer(id).then(data => {
      console.log("list of sub employers", data);
      let temp = data?.data?.result?.data;
      console.log(temp);
      setDetails({ ...details, rows: temp });
    }).catch(error => {
      console.log("error", error)
    })
  }, [])

  const handleClick = (Id) => {
    // console.log("eng id", engId)
    let id = Id
    api.engager.findOne(id)
      .then((data) => {
        let temp = data?.data?.result?.data
        console.log("result", data?.data?.result?.data)
        navigate('/admin/engager-details/', {
          state: {
            value: temp
          }
        })
      })
  }
  return (
    <DataTable table={details} canSearch />

  );
}

export default SubUserList



