import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Card, Grid, Modal } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import api from "services/api";
import FormField from "layouts/pages/account/components/FormField";
import { SnackbarContext } from "context/SnackbarContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

const initialValues = {
  startDate: "",
  tentativeEndDate: "",
};

const validationSchema = Yup.object({
  startDate: Yup.date().required("Start date is required"),
  tentativeEndDate: Yup.date().required("Tentative End Date is required"),
});
const StartContract = () => {
  const { state } = useLocation();
  const [gigDetails, setGigDetails] = useState();
  const [engDetails, setEngDetails] = useState({});
  const [contractDetails, setContractDetails] = useState([]);
  const { setSnack } = useContext(SnackbarContext);
  const [endDate, setEndDate] = useState();
  useEffect(() => {
    console.log("state values", state?.values?.contractStatus);
    setEngDetails(state?.values);
    setGigDetails(state?.values?.user);
    setContractDetails(state?.values?.contractDetails);
  }, []);

  const formatDate = (string) => {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  };

  const startEngagementContract = () => {
    console.log("engDetails", engDetails?._id);
    let reqData = {
      engagementId: engDetails.engagementId,
      engagerId: engDetails.engagerId,
      giglerId: gigDetails.userId,
      id: contractDetails[0]._id,
      startDate: new Date(),
    };
    console.log("req data", reqData);
    api.contract
      .startContract(reqData)
      .then((data) => {
        console.log("update data", data);
        setSnack({
          message: `Contract Started`,
          open: true,
          type: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        setSnack({
          message: `Error while updating`,
          open: true,
          type: "error",
        });
      });
  };

  const endContract = () => {
    console.log("co", contractDetails[0], engDetails);
    let reqData = {
      id: contractDetails[0]._id,
      engagementId: engDetails?.engagementId,
      giglerId: engDetails?.giglerId,
      contractStatus: "5",
      endDate: endDate,
    };
    api.contract.endContract(reqData).then((data) => {
      console.log(data);
      if (data) {
        setSnack({
          message: `Contract Closed`,
          open: true,
          type: "success",
        });
      }
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        console.log("engDetails", engDetails?._id);
        let reqData = {
          engagementId: engDetails.engagementId,
          engagerId: engDetails.engagerId,
          giglerId: gigDetails.userId,
          id: contractDetails[0]._id,
          startDate: values?.startDate,
          tentativeEndDate: values?.tentativeEndDate,
        };
        console.log("req data", reqData);
        api.contract
          .startContract(reqData)
          .then((data) => {
            console.log("update data", data);
            setSnack({
              message: `Contract Started`,
              open: true,
              type: "success",
            });
          })
          .catch((err) => {
            console.log(err);
            setSnack({
              message: `Error while updating`,
              open: true,
              type: "error",
            });
          });
      } catch (err) { }
    },
  });

  const dateChange = (e) => {
    console.log("date", e.target.value);
    setEndDate(e.target.value);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox position="relative" mb={5}>
        <Card
          sx={{
            position: "relative",
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={6}>
              <MDBox pt={2} px={2} pb={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Engagement Information
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Engagement Id&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{engDetails?.engagementId}
                </MDTypography>
              </MDBox>
              {/* <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Engagement Title&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{engDetails?.engagementTitle}
                </MDTypography>
              </MDBox> */}
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <MDBox pt={2} px={2} pb={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  GigTalent Information
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Name&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{gigDetails?.firstName}&nbsp;{gigDetails?.lastName}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} px={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Email&nbsp;: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{gigDetails?.email}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
        <Card
          sx={{
            position: "relative",
            mt: 2,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} xl={12}>
              <MDBox pt={2} px={2} pb={2} sx={{ display: "flex", justifyContent: "space-between" }}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Details of Contact Sent to {contractDetails[0]?.to}
                </MDTypography>
                <MDBox>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Status:{" "}
                    {engDetails?.contractStatus == "4"
                      ? "Started"
                      : engDetails?.contractStatus == "5"
                        ? "End"
                        : "Pending"}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <MDBox px={2}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  color="text"
                  textTransform="capitalize"
                >
                  Contract sent by - {contractDetails[0]?.from}, on -{" "}
                  {formatDate(contractDetails[0]?.sentDate)}
                </MDTypography>
                <MDButton
                  color="info"
                  size="small"
                  variant="outlined"
                  target="_blank"
                  href={contractDetails[0]?.documentSent}
                  sx={{ ml: "10px" }}
                >
                  Download
                </MDButton>
              </MDBox>
              {/* {contractDetails[0]?.documentReceived && (
                <MDBox px={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    color="text"
                    textTransform="capitalize"
                  >
                    Contract Signed by - {contractDetails[0]?.to}, on -{" "}
                    {formatDate(contractDetails[0]?.receiveDate)}
                  </MDTypography>
                  <MDButton
                    color="info"
                    size="small"
                    variant="outlined"
                    target="_blank"
                    href={contractDetails[0]?.documentReceived}
                    sx={{ ml: "10px" }}
                  >
                    Download
                  </MDButton>
                </MDBox>
              )} */}
              {contractDetails[0]?.documentReceived &&
                engDetails?.contractStatus != "4" &&
                engDetails?.contractStatus != "5" &&
                engDetails?.contractStatus != "6" ? (
                <MDBox px={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    color="text"
                    textTransform="capitalize"
                  >
                    Contract Signed by - {contractDetails[0]?.to}, on -{" "}
                    {formatDate(contractDetails[0]?.receiveDate)}
                  </MDTypography>
                  <MDButton
                    color="info"
                    size="small"
                    variant="outlined"
                    target="_blank"
                    href={contractDetails[0]?.documentReceived}
                    sx={{ ml: "10px" }}
                  >
                    Download
                  </MDButton>
                  <MDBox>
                    <form onSubmit={formik.handleSubmit} method="post">
                      <Grid container spacing={3} my={1}>
                        <Grid item xs={12} sm={4}>
                          <FormField
                            label="Start date"
                            placeholder="dd-mmm-yyyy"
                            name="startDate"
                            inputProps={{ type: "date" }}
                            {...formik.getFieldProps("startDate")}
                            error={
                              formik.touched.startDate && formik.errors.startDate ? true : false
                            }
                            helperText={
                              formik.touched.startDate && formik.errors.startDate
                                ? formik.errors.startDate
                                : null
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FormField
                            label="Tentative End date"
                            placeholder="dd-mmm-yyyy"
                            name="tentativeEndDate"
                            inputProps={{ type: "date" }}
                            {...formik.getFieldProps("tentativeEndDate")}
                            error={
                              formik.touched.tentativeEndDate && formik.errors.tentativeEndDate
                                ? true
                                : false
                            }
                            helperText={
                              formik.touched.tentativeEndDate && formik.errors.tentativeEndDate
                                ? formik.errors.tentativeEndDate
                                : null
                            }
                          />
                        </Grid>
                      </Grid>
                      <MDButton type="submit" color="info" size="small">
                        Start Contract
                      </MDButton>
                    </form>
                  </MDBox>
                </MDBox>
              ) : engDetails?.contractStatus == "4" ? (
                <>
                <MDBox ml="15px">
                <MDTypography
                    variant="button"
                    fontWeight="bold"
                    color="text"
                    textTransform="capitalize"
                  >
                    Contract Signed by - {contractDetails[0]?.to}, on -{" "}
                    {formatDate(contractDetails[0]?.receiveDate)}
                  </MDTypography>
                  <MDButton
                    color="info"
                    size="small"
                    variant="outlined"
                    target="_blank"
                    href={contractDetails[0]?.documentReceived}
                    sx={{ ml: "10px" }}
                  >
                    Download
                  </MDButton>
                </MDBox>
                <MDBox px={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    color="text"
                    textTransform="capitalize"
                  >
                    Contract started on {formatDate(contractDetails[0]?.startDate)} Tentative end
                    date {formatDate(contractDetails[0]?.tentativeEndDate)}
                  </MDTypography>
                </MDBox>
                </>
              ) : engDetails?.contractStatus == "5" ? (
                <>
                <MDBox ml ="15px">
                <MDTypography
                    variant="button"
                    fontWeight="bold"
                    color="text"
                    textTransform="capitalize"
                  >
                    Contract Signed by - {contractDetails[0]?.to}, on -{" "}
                    {formatDate(contractDetails[0]?.receiveDate)}
                  </MDTypography>
                  <MDButton
                    color="info"
                    size="small"
                    variant="outlined"
                    target="_blank"
                    href={contractDetails[0]?.documentReceived}
                    sx={{ ml: "10px" }}
                  >
                    Download
                  </MDButton>
                </MDBox>
                <MDBox px={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    color="text"
                    textTransform="capitalize"
                  >
                    Contract started on {formatDate(contractDetails[0]?.startDate)} Ended on{" "}
                    {formatDate(contractDetails[0].endDate)}
                  </MDTypography>
                </MDBox>
                </>
              ) : (
                <></>
              )}

              {engDetails?.contractStatus == "4" && (
                <MDBox pt={2} px={2} pb={2}>
                  <Grid container spacing={3} my={1}>
                    <Grid item xs={12} sm={4}>
                      <FormField
                        label="End date"
                        placeholder="dd-mmm-yyyy"
                        name="endDate"
                        inputProps={{
                          type: "date",
                          min: moment(contractDetails[0]?.startDate).format("YYYY-MM-DD"),
                          max: moment().format("YYYY-MM-DD"),
                        }}
                        onChange={dateChange}
                      />
                    </Grid>
                  </Grid>
                  <MDButton
                    color="warning"
                    size="small"
                    variant="outlined"
                    target="_blank"
                    disabled={endDate ? false : true}
                    onClick={endContract}

                  // sx={{ ml: "10px" }}
                  >
                    End Contract
                  </MDButton>
                </MDBox>
              )}
            </Grid>
          </Grid>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};
export default StartContract;
