// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import { Checkbox, FormControlLabel, FormGroup, Stack } from "@mui/material";

// Data
import MDButton from "components/MDButton";
// import Checkbox from "assets/theme/components/form/checkbox";

import { useFormik } from "formik";
import api from "services/api";
// import selectData from "./data/selectData";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "context/UserProvider";
import { SnackbarContext } from "context/SnackbarContext";

import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";

const Consent = ({ userData, updateUserData }) => {
  const { userInfo } = useContext(UserContext);
  const { setSnack } = useContext(SnackbarContext);
  const [minDate, setMinDate] = useState();
  const [checked, setChecked] = useState({});

  const initialValues = {
    consent:userData.consent || 'yes'
  };
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      console.log("gigler profile values", values);
      try {
        let id = ""
        if(userInfo.type == "admin"){
          id = userData?.userId
        }else{
          id = userInfo?.id
        }
        let temp = userData;
        temp.consent = values.consent;
        updateUserData(temp);
        const result = await api.userInfo.update(id, values);
        if (result) {
          if (result.data?.message == "success") {
            setSnack({
              message: "Consent  details updated!",
              open: true,
              type: "success",
            });
          } else if(result?.data?.message == "error") {
            setSnack({
              message: "Error updating details!",
              open: true,
              type: "error",
            });
          }
          
        } else {
          throw new error("failed to update");
        }
      } catch (error) {
        setSnack({
          message: "Error occurred during update!",
          open: true,
          type: "error",
        });
        console.log("ERROR LOGIN: ", error);
      }
    },
  });



  useEffect(() => {
    console.log("Conscent#########", userData?.consent);
    if (userData.consent == undefined) {
      formik.setFieldValue('consent', 'yes', false);
    } else {
      formik.setFieldValue('consent', userData.consent, false);
    }
  }, [userData]);
  return (
    <Card id="current-engagement" sx={{ px:"20px", py:"15px" }}>
      <form onSubmit={formik.handleSubmit} method="post">
      <MDBox>
        <MDTypography variant="h5">Background Verification Consent</MDTypography>
        <MDTypography variant="button">Should Gigleji show your details for Background Verification at any stage of our association</MDTypography>
        <MDBox>
          <FormControl component="fieldset">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              {...formik.getFieldProps("consent")}
              defaultValue="yes"
            >
              <Stack direction="row">
                <FormControlLabel

                  value="yes"
                  control={<Radio />}
                  label={
                    <MDTypography color="text" variant="p" fontWeight="regular">
                      Yes
                    </MDTypography>
                  }
                />
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label={
                    <MDTypography color="text" variant="p" fontWeight="regular">
                      No
                    </MDTypography>
                  }
                />
              </Stack>
            </RadioGroup>
          </FormControl>
          </MDBox>
          <MDButton variant="gradient" color="success" size="small" type="submit">save</MDButton>
      </MDBox>
      </form>
    </Card>
  );
};

export default Consent;
