// @mui material components
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { SnackbarContext } from "context/SnackbarContext";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "services/api";

// Otis Admin PRO React example components
// import DataTable from "examples/Tables/DataTable";
import dataTableData from "./data/dataTableData";
import DataTable from "./DataTable";

// Data

function EngagerList() {
  const { setSnack } = useContext(SnackbarContext);
  const navigate = useNavigate()
  const [pagination, setPagination] = useState({});
  const [details, setDetails] = useState({
    columns: [
      { Header: "name", accessor: "firstName", width: "20%" },
      { Header: "Reg. Date", accessor: "createdAt", width: "20%" },
      { Header: "Organization", accessor: "organizationName", width: "20%" },
      { Header: "Enterprise", accessor: "enterprise", width: "10%" },
      { Header: "Posting", accessor: 'engagementPostingAccess', width: "10%" },
      { Header: "Industry", accessor: "industry", width: "10%" },
      { Header: "Location", accessor: "location", width: "10%" },
      { Header: "email", accessor: "email", width: "15%" },
      { Header: "Phone number", accessor: "phoneNumber", width: "15%" },  
      { Header: "Designation", accessor: "designation", },
      { Header: "Department", accessor: "department", },
      // { Header: "salary", accessor: "salary" },
    ],
    rows: []
  })
  //pagination code
  // useEffect(() => {
  //   handlePaginationData(0, 5)
  // }, [])

  const handleAccess = (values, accessBool) => {
    const id = values.engagerId
    api.engager.updateEngagmenetPosting(id, { engagementPostingAccess: !accessBool })
      .then((data) => {
        setSnack({
          message: `Engagement Posting access is changed for ${values.firstName}`,
          open: true,
          type: "success",
        });
        // handlePaginationData(0, 5);
        handleEngagerList()
      })
      .catch((err) => {
        setSnack({
          message: "Error occurred while granting permission",
          open: true,
          type: "error"
        })
      })
  }
  const handleClick = (Id) => {
    let id = Id
    api.engager.findOne(id)
      .then((data) => {
        let temp = data?.data?.result?.data
        console.log("result", data?.data?.result?.data)
        navigate('/admin/engager-details/', {
          state: {
            value: temp
          }
        })
      })
  }

  const handleEngagement = (Id) => {
    console.log("engager Id", Id)
  }
//pagination code
  // const handlePaginationData = (index, size) => {
  //   // console.log("pageIndex, pageSize", index, size)
  //   api.engager.findAll({ page: index, limit: size }).then(data => {
  //     // console.log("list of engagers", data?.data?.result?.pagination);
  //     let temp = data?.data?.result?.data;
  //     if (temp.length <= 0) {
  //       setDetails("0")
  //     } else {
  //       setPagination(data?.data?.result?.pagination);
  //       setDetails({ ...details, rows: temp });
  //     }
  //   }).catch(error => {
  //     console.log("error", error)
  //   })
  // }

  const handleEngagerList  = () => {
    api.engager.findAll().then(data => {
      let temp = data?.data?.result?.data;
      if (temp.length <= 0) {
        setDetails("0")
      } else {
        console.log(temp);
        setDetails({ ...details, rows: temp });
      }
    }).catch(error => {
      console.log("error", error)
    })
  }
  useEffect(() => {
    handleEngagerList()
  }, [])
  return (
    <>
      {details == "0" ? (
        <MDTypography variant="button" fontWeight="medium">Engager list is empty</MDTypography>
      ) : (
        <DataTable
          table={details}
          handleClick={handleClick}
          paginationData={pagination}
          // handlePaginationData={handlePaginationData}
          handleAccess={handleAccess}
          canSearch />
      )}
    </>
  );

}

export default EngagerList;
