import React, { useContext } from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import * as Yup from "yup";
// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Otis Admin PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
// import backgroundImage from "assets/images/bg-profile.jpeg";
import backgroundImage from "assets/images/Gigleji-App-Profile-Background.jpg";
import basic from "assets/images/basicPlan.png";
import classic from "assets/images/classicPlan.png";
import premium from "assets/images/premiumPlan.png";

import { UserContext } from "context/UserProvider";
import api from "services/api";
import MDButton from "components/MDButton";
import { CardMedia, CircularProgress, Input } from "@mui/material";
import { useNavigate } from "react-router-dom";

//
import moment from "moment";
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard";
import MDBadge from "components/MDBadge";
import FormField from "layouts/pages/account/components/FormField";
import { useFormik } from "formik";
import { SnackbarContext } from "context/SnackbarContext";
import { Box } from "@mui/system";
import './style.css';
import EditIcon from '@mui/icons-material/Edit';
import EditEngagerProfile from "layouts/pages/AdminDashboard/ManageEngager/EditEngagerProfile";

const validationSchema = Yup.object({
  designation: Yup.string()
    .required("Designation is required"),
  department: Yup.string()
    .required("Department Name is required"),
  phoneNumber: Yup.string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .required("Phone number is required")
    .min(10, "Too short")
    .max(10, "Too long"),
});


function EngagerProfile({ subUserDetails }) {
  const navigate = useNavigate()
  const { setSnack } = useContext(SnackbarContext);
  const [details, setDetails] = useState();
  const [userAvatar, setUserAvatar] = useState(subUserDetails);
  const { userInfo } = useContext(UserContext);
  const [planDetails, setPlanDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const handleBack = () => {
    setShowForm(true)
    setLoading(true);
    handleApi()
  }
  const initialValues = {
    designation: details?.designation || "",
    department: details?.department || "",
    phoneNumber: details?.phoneNumber || ""
  }

  const handleClick = (event) => {
    document.getElementById("profile-img").click();
  };

  const removeImage = async () =>{
    const result = await api.engager.update(userInfo.id,{avatar:""});
    if (result){
      console.log("result",result);
      setUserAvatar(" ")
    }
  }

  const handleChange = (event) => {
    let temp = event.target.files[0];
    const formData = new FormData();
    formData.append("avatar", temp);
    formData.append("id", userInfo.id);
    let imageType = event.target.files[0].type;

    if (imageType == "image/jpeg" || type == "image/jpg" || type == "image/png") {
      setImageLoading(true);
      api.userUploads
        .engagerAvatar(formData) //api call for send image data
        .then((data) => {
          if (data?.data?.message == "success") {
            setSnack({
              message: "Profile Picture updated!",
              open: true,
              type: "success",
            });
            setUserAvatar(data?.data?.result?.data?.avatar);
            setImageLoading(false);
          } else {
            setSnack({
              message: "Error occurred while changing profile picture!",
              open: true,
              type: "error",
            });
            setImageLoading(false);
          }
          console.log("image data", data);
        });
    }
    else {
      setSnack({
        message: "Upload Only PNG or JPG or JPEG files",
        open: true,
        type: "error",
      });
    };
  };

  const handleApi = () => {
    const id = userInfo.id;
    api.engager.findOne(id).then((data) => {
      const temp = data?.data?.result?.data;
      setDetails(temp);
      let id = temp?.planId
      api.plan.findOne(id)
        .then((response) => {
          const temp2 = response?.data?.result?.data;
          setPlanDetails(temp2)
        })
      setUserAvatar(temp?.avatar);
      setLoading(false);
    });
  }
  useEffect(() => {
    handleApi()
  }, []);

  const handlePlan = () => {
    navigate("/employer/plan-details/", {
      state: {
        values: details,
      }
    })
  }
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;


  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log("engager profile values", values);
      try {
        const id = details?.engagerId;
        const result = await api.engager.update(id, values);
        if (result) {
          setSnack({
            message: "Engager Profile updated!",
            open: true,
            type: "success",
          });
        } else {
          throw new error("failed to update");
        }
      }
      catch (error) {
        setSnack({
          message: "Error occurred during update!",
          open: true,
          type: "error",
        });
        console.log("ERROR", { ...error });
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("designation", details?.designation, false);
    formik.setFieldValue("department", details?.department, false);
    formik.setFieldValue("phoneNumber", details?.phoneNumber, false);
  }, [details])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  py: 5,
                }}
              >
                <CircularProgress color="primary" />
              </Box>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <>
        {showForm &&
          <MDBox position="relative" mb={5}>
            <MDBox
              display="flex"
              alignItems="center"
              position="relative"
              minHeight="18.75rem"
              borderRadius="xl"
              sx={{
                backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                  `url(${backgroundImage})`,
                backgroundSize: "cover",
                backgroundPosition: "50%",
                overflow: "hidden",
              }}
            />
            <Card
              sx={{
                position: "relative",
                mt: -8,
                mx: 3,
                py: 2,
                px: 2,
              }}
            >


              <Grid container spacing={1} alignItems="center">
                {imageLoading ? (
                  <MDBox
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      p: 4,
                    }}>
                    <CircularProgress color="error" />
                  </MDBox>
                ) : (
                  <Grid item xs={1}>
                    <MDBox position="relative" height="max-content" mx="auto">

                    <MDBox alt="sportify logo" 
                           align="right" 
                           right={100} 
                           bottom={20}
                           >
                    <MDButton 
                           variant="gradient" 
                           color="error" 
                           size="small"  
                           iconOnly onClick={() => removeImage()}
                           >
                      <Icon >remove</Icon>
                    </MDButton> 
                    </MDBox>
                      <MDAvatar
                        src={userAvatar}
                        alt="profile picture"
                        size="xl"
                        variant="rounded"
                        shadow="sm"
                      />
                      <MDBox
                        alt="spotify logo"
                        position="absolute"
                        right={0}
                        bottom={0}
                        mr={-1}
                        mb={-1}
                        type="input"
                      >
                        <MDButton
                          variant="gradient"
                          color="info"
                          size="small"
                          iconOnly
                          onClick={() => handleClick(event)}
                        >
                          <Icon>edit</Icon>
                        </MDButton>
                        <Input
                          type="file"
                          id="profile-img"
                          name="profile-img"
                          accept="image/*"
                          onChange={(e) => handleChange(e)}
                          sx={{ display: "none" }}
                        />
                      </MDBox>
                    </MDBox>
                  </Grid>
                )}
                <Grid item xs={10}>
                  <MDBox height="100%" mt={0.5} lineHeight={1}>
                    <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize">
                      {details?.firstName}&nbsp;{details?.lastName}
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={1} display='flex'>
                <MDButton variant="outlined" startIcon={<EditIcon/>} color="warning" size="small" type="submit" onClick={() => {setShowForm(false)}}>
                EDIT
                </MDButton>
                </Grid>
              </Grid>
              <form onSubmit={formik.handleSubmit} method="post">
                <MDBox mt={1}>
                  <Grid container>
                    <Grid item xl={8}>
                      <Grid container >
                        <Grid item xs={12}>
                          <MDBox display="flex" py={1} pr={2} px={2}>
                            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                              Name&nbsp;: &nbsp;
                            </MDTypography>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              &nbsp;{details?.firstName}&nbsp;{details?.lastName}
                            </MDTypography>
                          </MDBox>
                          <MDBox display="flex" py={1} pr={2} px={2}>
                            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                              Organization Name&nbsp;: &nbsp;
                            </MDTypography>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              &nbsp;{details?.organizationName}
                            </MDTypography>
                          </MDBox>
                          <MDBox display="flex" py={1} pr={2} px={2}>
                            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                              Enterprise&nbsp;: &nbsp;
                            </MDTypography>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              &nbsp;{details?.enterprise}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} xl={6}>
                          <MDBox alignItems="center" justifyContent="center" pt={2} px={2} pb={2}>
                            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                              Personal Information
                            </MDTypography>
                          </MDBox>
                          <MDBox display="flex" py={1} pr={2} px={2}>
                            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                              Designation&nbsp;: &nbsp;
                            </MDTypography>
                            &nbsp;
                            {/* <FormField
                              // sx={{ mt: "-15px" }}
                              size="small"
                              variant="standard"
                              defaultValue={details?.designation}
                              name="designation"
                              inputProps={{ type: "text" }}
                              error={formik.touched.designation && formik.errors.designation ? true : false}
                              helperText={formik.touched.designation && formik.errors.designation ? formik.errors.designation : null}
                              InputProps={{
                                disableUnderline: true,
                              }}
                              {...formik.getFieldProps("designation")}
                            /> */}
                            <MDTypography variant="button" fontWeight="regular" color="text">
                          &nbsp;{details?.designation}
                        </MDTypography>
                          </MDBox>
                          <MDBox display="flex" px={2}>
                            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                              Department&nbsp;: &nbsp;
                            </MDTypography>
                            &nbsp;
                            {/* <FormField
                              sx={{ mt: "-15px" }}
                              size="small"
                              width="70%"
                              variant="standard"
                              name="department"
                              inputProps={{ type: "text" }}
                              error={formik.touched.department && formik.errors.department ? true : false}
                              helperText={formik.touched.department && formik.errors.department ? formik.errors.department : null}
                              InputProps={{
                                disableUnderline: true,
                              }}
                              {...formik.getFieldProps("department")}
                            /> */}
                            <MDTypography variant="button" fontWeight="regular" color="text">
                            &nbsp;{details?.department}
                          </MDTypography>
                          </MDBox>
                          <MDBox display="flex" sx={{ alignItems: 'baseline' }} px={2}>
                            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                              Phone Number&nbsp;: &nbsp;
                            </MDTypography>
                            &nbsp;
                            {/* <FormField
                              sx={{ width: '55%' }}
                              size="small"
                              variant="standard"
                              name="phoneNumber"
                              id="number"
                              inputProps={{ type: "number" }}
                              InputProps={{
                                disableUnderline: true,
                              }}
                              {...formik.getFieldProps("phoneNumber")}
                              error={formik.touched.phoneNumber && formik.errors.phoneNumber ? true : false}
                              helperText={
                                formik.touched.phoneNumber && formik.errors.phoneNumber ? formik.errors.email : null
                              }
                            /> */}
                            <MDTypography variant="button" fontWeight="regular" color="text">
                            &nbsp;{details?.phoneNumber}
                          </MDTypography>
                          </MDBox>
                          <MDBox display="flex" px={2} mt={2}>
                            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                              Email&nbsp;: &nbsp;
                            </MDTypography>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              &nbsp;{details?.email}
                            </MDTypography>
                          </MDBox>
                          {/* <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    D.O.B&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.birthDate}
                  </MDTypography>
                </MDBox> */}
                        <MDBox display="flex" px={2} mt={2}>
                            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                              Pan Number&nbsp;: &nbsp;
                            </MDTypography>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              &nbsp;{details?.panNumber}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} xl={6}>
                          <MDBox alignItems="center" justifyContent="center" pt={2} px={2} pb={2}>
                            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                              Organization Details
                            </MDTypography>
                          </MDBox>
                          <MDBox display="flex" py={1} pr={2} px={2}>
                            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                              Industry&nbsp;: &nbsp;
                            </MDTypography>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              &nbsp;{details?.industry}
                            </MDTypography>
                          </MDBox>
                          <MDBox display="flex" py={1} pr={2} px={2}>
                            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                              Number of Employees&nbsp;: &nbsp;
                            </MDTypography>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              &nbsp;{details?.numberOfEmployees}
                            </MDTypography>
                          </MDBox>
                          <MDBox display="flex" py={1} pr={2} px={2}>
                            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                              location&nbsp;: &nbsp;
                            </MDTypography>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              &nbsp;{details?.location}
                            </MDTypography>
                          </MDBox>

                          {/* <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Designation&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.designation}
                  </MDTypography>
                </MDBox> */}
                          {/* <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Department&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.department}
                  </MDTypography>
                </MDBox> */}
                          <MDBox display="flex" py={1} pr={2} px={2}>
                            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                              GST Number&nbsp;: &nbsp;
                            </MDTypography>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              &nbsp;{details?.gstIn}
                            </MDTypography>
                          </MDBox>
                          <MDBox display="flex" py={1} pr={2} px={2}>
                            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                              websiteAddress&nbsp;: &nbsp;
                            </MDTypography>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              &nbsp;{details?.websiteAddress}
                            </MDTypography>
                          </MDBox>
                          {/* <MDBox display="flex" py={1} pr={2} px={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Web Address&nbsp;: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{details?.websiteAddress}
                  </MDTypography>
                </MDBox> */}
                        </Grid>
                      </Grid>
                    </Grid>
                    {userInfo.type == "employer" &&
                      <Grid item xs={12} xl={4}>
                        {details?.planTitle ?
                          (
                            <Card sx={{ padding: "8px" }}>
                              <MDBox>
                                <MDBox justifyContent="center">
                                  <MDTypography variant="h5" color="info" fontWeight="bold" sx={{ mx: "35%" }}>
                                    &nbsp;{planDetails?.title}
                                  </MDTypography>
                                </MDBox>
                                <MDBox display="flex" alignItems="center">
                                  <MDTypography variant="button" color="text" fontWeight="bold" sx={{ px: "10px" }}>
                                    Job Postings :
                                  </MDTypography>
                                  <MDTypography variant="h6" color="text" fontWeight="regular">
                                    &nbsp;{planDetails?.numberOfJobPostings}
                                  </MDTypography>
                                </MDBox>
                                {/* <MDBox display="flex" alignItems="center">
                                  <MDTypography variant="button" color="text" fontWeight="bold" sx={{ px: "10px" }}>
                                    Profile View :
                                  </MDTypography>
                                  <MDTypography variant="h6" color="text" fontWeight="regular">
                                    &nbsp;{planDetails?.numberOfProfileView}
                                  </MDTypography>
                                </MDBox> */}
                                <MDBox display="flex" alignItems="center">
                                  <MDTypography variant="button" color="text" fontWeight="bold" sx={{ px: "10px" }}>
                                    Sub User :
                                  </MDTypography>
                                  <MDTypography variant="h6" color="text" fontWeight="regular">
                                    &nbsp;{planDetails?.numberOfSubUsers}
                                  </MDTypography>
                                </MDBox>
                                {/* <MDBox display="flex" alignItems="center">
                                  <MDTypography variant="button" color="text" fontWeight="bold" sx={{ px: "10px" }}>
                                    Access to Assessors :
                                  </MDTypography>
                                  <MDBadge size="small" badgeContent={planDetails?.accessToAssessors ? "Yes" : "NO"} variant="gradient" color={planDetails?.accessToAssessors ? "success" : "error"} circular />
                                </MDBox> */}
                                {/* <MDBox display="flex" alignItems="center" mt={1}>
                                  <MDTypography variant="button" color="text" fontWeight="bold" sx={{ px: "10px" }}>
                                    Profile Category Access :
                                  </MDTypography>
                                  <MDBadge size="small" badgeContent={planDetails?.accessToProfileCategory} variant="gradient" color="light" />
                                </MDBox> */}
                                <MDBox display="flex" alignItems="center" mt={1}>
                                  <MDTypography variant="button" color="text" fontWeight="bold" sx={{ px: "10px" }}>
                                    Profile Matching alert :
                                  </MDTypography>
                                  <MDBadge size="small" badgeContent={planDetails?.automaticProfileMatchingAlert ? "Yes" : "No"} variant="gradient" color={planDetails?.automaticProfileMatchingAlert ? "success" : "error"} circular />
                                </MDBox>
                                {/* <MDBox display="flex" alignItems="center" mt={1}>
                                  <MDTypography variant="button" color="text" fontWeight="bold" sx={{ px: "10px" }}>
                                    Background verifications :
                                  </MDTypography>
                                  <MDBadge size="small" badgeContent={planDetails?.bgvOfTheCandidates ? "Yes" : "No"} variant="gradient" color={planDetails?.bgvOfTheCandidates ? "success" : "error"} circular />
                                </MDBox> */}
                                <MDBox display="flex" alignItems="center" mt={1}>
                                  <MDTypography variant="button" color="text" fontWeight="bold" sx={{ px: "10px" }}>
                                    Carry Forward Option :
                                  </MDTypography>
                                  <MDBadge size="small" badgeContent={planDetails?.carryForwardOption ? "Yes" : "No"} variant="gradient" color={planDetails?.carryForwardOption ? "success" : "error"} circular />
                                </MDBox>
                                <MDBox display="flex" alignItems="center" mt={1}>
                                  <MDTypography variant="button" color="text" fontWeight="bold" sx={{ px: "10px" }}>
                                    Contact Details:
                                  </MDTypography>
                                  <MDBadge size="small" badgeContent={planDetails?.contactDetails ? "Yes" : "No"} variant="gradient" color={planDetails?.contactDetails ? "success" : "error"} circular />
                                </MDBox>
                                {/* <MDBox display="flex" alignItems="center" mt={1}>
                                  <MDTypography variant="button" color="text" fontWeight="bold" sx={{ px: "10px" }}>
                                    Mentoring Option:
                                  </MDTypography>
                                  <MDBadge size="small" badgeContent={planDetails?.mentoringOption ? "Yes" : "No"} variant="gradient" color={planDetails?.mentoringOption ? "success" : "error"} circular />
                                </MDBox> */}
                              </MDBox>
                            </Card>
                          ) : (
                            <>
                              <MDTypography variant="body2">Click On "Choose Plan" to choose your plan</MDTypography>
                              <MDButton variant="gradient" color="success" size="small" sx={{ mt: "10px" }} onClick={() => handlePlan()}>
                                Choose plan
                              </MDButton>
                            </>
                          )
                        }
                      </Grid>
                    }
                  </Grid>
                </MDBox>
              </form>
            </Card>
          </MDBox >
         }
         {!showForm && 
          <EditEngagerProfile details={details} load={loading} handleBack={handleBack}/> 
         }

        </>
      )}
    </DashboardLayout >
  );
}

export default EngagerProfile;
