import MDBox from 'components/MDBox'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import React, { useContext, useEffect, useState } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from 'react-router-dom';
import { Autocomplete, Checkbox, Grid, Modal } from '@mui/material';
import api from 'services/api';
import FormField from "layouts/pages/account/components/FormField";
import { Box } from '@mui/system';
import MDButton from 'components/MDButton';
import moment from 'moment';
import { SnackbarContext } from 'context/SnackbarContext';
import TermsAndConditions from 'components/TermsAndConditions';
import EngagerTermsAndConditions from "components/EngagerTermsAndConditions";
import { useStyles } from './styles';
import { ElectricalServices } from '@mui/icons-material';
import EngagerPrivacyPolicy from "components/EngagerPrivacyPolicy";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const EngagerRegistration = () => {
  const css = useStyles();
  const { setSnack } = useContext(SnackbarContext)
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [linkType, setLinkType] = useState('terms');
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClose = () => {
    setOpen(false);
    setChecked(true);
  }
  const [ind, setInd] = useState()
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    industry: "",
    enterprise: "",
    password: "",
    type: "employer",
  };

  useEffect(() => {
    api.industry
      .findAll()
      .then((data) => {
        let temp = data?.data?.result?.data;
        setInd(temp);
      })
      .catch((error) => {
        // console.log("error", error);
      });
  }, []);
  const phoneRegExp =  /^[0-9]*$/;
    // /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object({
    organizationName: Yup.string().required("Organization Name is required"),
    location: Yup.string().required("Location is required"),
    designation: Yup.string().required("Designation is required"),
    firstName: Yup.string()
      .max(25, "Maximum 25 characters")
      .required("First Name is required"),
    lastName: Yup.string()
      .max(25, "Maximum 25 characters") 
      .required("Last Name is required"),
    email: Yup.string().email().required("Email is required"),
    // industry: Yup.string().required("Industry is required").nullable(),
    enterprise: Yup.string().required("Enterprise is required").nullable(),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Too short")
      .max(10, "Too long"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Your password must be longer than 8 characters.")
      .max(25)
      .matches(/^(?=.{8,})/, "Must Contain 8 Characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])/,
        "Must Contain One Uppercase, One Lowercase"
      )
      .matches(
        /^(?=.*[!@#\$%\^&\*])/,
        "Must Contain One Special Case Character"
      )
      .matches(/^(?=.{6,20}$)\D*\d/, "Must Contain One Number"),
  });
  let navigate = useNavigate();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const userEmail = values.email
      const split = userEmail.split('@');
      console.log("spliy", split);
      const regularEmail = ['gmail.com', 'yahoo.com', 'hotmail.com', 'aol.com', 'hotmail.co.uk', 'hotmail.fr', 'msn.com', 'yahoo.fr', 'wanadoo.fr', 'orange.fr', 'comcast.net', 'yahoo.co.uk', 'rediffmail.com', 'live.com'];
      api.users.findOneUser({ userEmail: userEmail })
        .then(data => {
          if (data?.data?.message == "success") {
            setSnack({
              message: `${values.email} Already exits`,
              open: true,
              type: "error",
            });
          }
          else if (regularEmail.indexOf(split[1]) >= 0) {
            setSnack({
              message: `General Email domain are not allowed for Engager registration.`,
              open: true,
              type: "error",
            });
          }
          else {

            let date = new Date();
            values.planValidFrom = date;
            values.planValidTo = moment(date).add(364, 'days').toDate();
            values.type = "employer";
            console.log("engager registration", values)
            const temp3 = api.verifications.verify({ email: values.email, firstName: values.firstName });
            console.log(temp3);
            navigate("/register/verification", {
              state: {
                values: values,
              },
            });
          }
        }).catch(err => console.log("error", err))
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} method="post">
      <MDBox sx={{mb:'10px'}}>
        <Grid container spacing={0.5}>
          <Grid item xs={6} sm={6} xl={6} alignItems="center">
            <MDInput
              sx={{ my: "5px" }}
              type="text"
              label="Organization Name"
              variant="standard"
              name="organizationName"
              value=""
              {...formik.getFieldProps("organizationName")}
              success={formik.values.organizationName > 0 && !formik.errors.organizationName ? true : false}
              error={formik.touched.organizationName && formik.errors.organizationName ? true : false}
              helperText={
                formik.touched.organizationName && formik.errors.organizationName
                  ? formik.errors.organizationName
                  : null
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sm={6} xl={6} alignItems="center">
            <Autocomplete
              sx={{ my: "5px" }}
              options={["OPC", "LLP", "Public", "Private", "PSU", "Government", "NGO" , "Associates"]}
              onChange={(event, option) => {
                console.log("enterprise", option)
                formik.setFieldValue("enterprise", option, false);
              }}
              renderInput={(params) => (
                <FormField {...params} label="Enterprise" name="enterprise" InputLabelProps={{ shrink: true }}
                  {...formik.getFieldProps("enterprise")}
                  success={formik.values.enterprise > 0 && !formik.errors.enterprise ? true : false}
                  error={formik.touched.enterprise && formik.errors.enterprise ? true : false}
                  helperText={
                    formik.touched.enterprise && formik.errors.enterprise
                      ? formik.errors.enterprise
                      : null
                  } />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={6} xl={6} alignItems="center">
            <MDInput
              fullWidth
              sx={{ my: "5px" }}
              variant="standard"
              label="Location of HO"
              placeholder="Hyderabad"
              name="location"
              inputProps={{ type: "text" }}
              {...formik.getFieldProps("location")}
              error={formik.touched.location && formik.errors.location ? true : false}
              helperText={
                formik.touched.location && formik.errors.location
                  ? formik.errors.location
                  : null
              }
            />
          </Grid>
          <Grid item xs={6} sm={6} xl={6} alignItems="center">
            <Autocomplete
              sx={{ my: "5px" }}
              id="select-industry"
              name="industry"
              options={ind}
              autoHighlight
              getOptionLabel={(option) => option.Industry}
              renderOption={(props, option) => (
                <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.Industry}
                </Box>
              )}
              onChange={(event, newValue) => {
                formik.setFieldValue("industry", newValue.Industry, false);
              }}
              renderInput={(params) => <FormField {...params} label="Select Industry" InputLabelProps={{ shrink: true }}
                {...formik.getFieldProps("industry")}
                success={formik.values.industry > 0 && !formik.errors.industry ? true : false}
                error={formik.touched.industry && formik.errors.industry ? true : false}
                helperText={
                  formik.touched.industry && formik.errors.industry
                    ? formik.errors.industry
                    : null
                }
              />}
            />
          </Grid>
          <Grid item xs={6} sm={6} xl={6} alignItems="center">
            <MDInput
              sx={{ my: "5px" }}
              type="text"
              label="SPOC First Name"
              variant="standard"
              name="firstName"
              value=""
              {...formik.getFieldProps("firstName")}
              success={formik.values.firstName > 0 && !formik.errors.firstName ? true : false}
              error={formik.touched.firstName && formik.errors.firstName ? true : false}
              helperText={
                formik.touched.firstName && formik.errors.firstName
                  ? formik.errors.firstName
                  : null
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sm={6} xl={6} alignItems="center">
            <MDInput
              sx={{ my: "5px" }}
              type="text"
              label="SPOC Last Name"
              variant="standard"
              name="lastName"
              value=""
              {...formik.getFieldProps("lastName")}
              success={formik.values.lastName > 0 && !formik.errors.lastName ? true : false}
              error={formik.touched.lastName && formik.errors.lastName ? true : false}
              helperText={
                formik.touched.lastName && formik.errors.lastName
                  ? formik.errors.lastName
                  : null
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={6} sm={6} xl={6} alignItems="center">
            <MDInput
              sx={{ my: "5px" }}
              type="text"
              label="SPOC Designation"
              variant="standard"
              name="designation"
              value=""
              {...formik.getFieldProps("designation")}
              success={formik.values.designation > 0 && !formik.errors.designation ? true : false}
              error={formik.touched.designation && formik.errors.designation ? true : false}
              helperText={
                formik.touched.designation && formik.errors.designation
                  ? formik.errors.designation
                  : null
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sm={6} xl={6} alignItems="center">
            <MDInput
              sx={{ my: "5px" }}
              type="text"
              label="SPOC Email"
              variant="standard"
              name="email"
              value=""
              {...formik.getFieldProps("email")}
              inputProps={{ style: { textTransform: "lowercase" } }}
              success={formik.values.email > 0 && !formik.errors.email ? true : false}
              error={formik.touched.email && formik.errors.email ? true : false}
              helperText={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={6} sm={6} xl={6} alignItems="center">
            <MDInput
              sx={{ my: "5px" }}
              type="text"
              label="Phone Number"
              variant="standard"
              name="phoneNumber"
              value=""
              inputProps={{ type: "number" }}
              onKeyPress = {(event) => {
                if(!phoneRegExp.test(event.key))
                event.preventDefault();
              }}
              {...formik.getFieldProps("phoneNumber")}
              success={formik.values.phoneNumber > 0 && !formik.errors.phoneNumber ? true : false}
              error={formik.touched.phoneNumber && formik.errors.phoneNumber ? true : false}
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
                  ? formik.errors.phoneNumber
                  : null
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sm={6} xl={6} mt={0.6} alignItems="center">
          <FormControl sx={{ width: '100%' }} variant="standard">
          <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
            <Input
              // id="standard-adornment-password"
              sx={{ my: "5px" }}
              type={showPassword ? 'text' : 'password'}
              label="Password"
              variant="standard"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              {...formik.getFieldProps("password")}
              success={formik.values.password > Boolean(formik.values.password) }
              error={formik.touched.password && formik.errors.password ? true : false}
              helperText={
                formik.touched.password ? formik.errors.password : ''
              }
              endAdornment={
                <InputAdornment position="start">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              fullWidth
            />
             {formik.touched.password && formik.errors.password && (
                 <div style={{fontSize:'12px', textAlign:'left'}} className="text-danger">{formik.errors.password}</div>
                 )}
            </FormControl>
          </Grid>
          <Grid item xs={12} xl={12}>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox checked={checked} onChange={() => setChecked(!checked)} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{  userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I Agree&nbsp;
              </MDTypography>
              <MDTypography
                variant="button"
                fontWeight="bold"
                color="info"
                onClick={() => {setLinkType('terms'); setOpen(true)}}
                sx={{ cursor: "pointer" }}
              >
                Terms and Conditions
              </MDTypography>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{  userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;and&nbsp;&nbsp;
              </MDTypography>
              <MDTypography
                variant="button"
                fontWeight="bold"
                color="info"
                // textGradient
                onClick={() => {setLinkType('privacy');setOpen(true);}}
                sx={{ cursor: "pointer" }}
              >
                Privacy Policy
              </MDTypography>
              <Modal
                open={open}
                onClose={handleClose}
              >
                <MDBox className={css.modal}>
                  {/* <EngagerTermsAndConditions handleClose={handleClose} /> */}
                  {linkType == 'terms' ?
                    (<EngagerTermsAndConditions handleClose={handleClose} />)
                    :(<EngagerPrivacyPolicy handleClose={handleClose} />)
                  }
                </MDBox>
              </Modal>

            </MDBox>
          </Grid>
          <Grid item xs={12} sm={12} xl={12} mt={0.5}>
            <MDButton  disabled={!checked} color="info" fullWidth type="submit">login&nbsp;&&nbsp;signup</MDButton>
          </Grid>
          <Grid item xs={12} sm={12} xl={12} mt={0.5}>
            <MDTypography variant="button" color="text" sx={{ mt: "-10px", p: '0px' }}>
              Already have an account?
              <MDTypography
                component={Link}
                to="/login"
                variant="button"
                color="info"
                fontWeight="medium"
                // textGradient
              >
                Sign In
              </MDTypography>
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
    </form >
  )
}

export default EngagerRegistration