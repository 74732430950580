import { Card, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import React, { useContext, useEffect, useState } from 'react'
import { useFormik } from "formik";
import api from 'services/api'
import { SnackbarContext } from "context/SnackbarContext";
import { UserContext } from 'context/UserProvider'
import MDBadge from 'components/MDBadge'
import moment from 'moment';

const GiglerFeedbackForm = ({ feedbackDetails, handleClose, handleGiglerFeedback, handleClosedEngagements }) => {
  const { userInfo } = useContext(UserContext)
  const { setSnack } = useContext(SnackbarContext);
  const [initialValues, setInitialValues] = useState({
    engagementId: feedbackDetails?.engagementId || "",
    engagementTitle: feedbackDetails?.feedbackEngagements?.engagementTitle || "",
    engagerId: feedbackDetails?.engagerId || "",
    engagerName: feedbackDetails?.feedbackEngagements?.engagerName || "",
    giglerId: feedbackDetails?.giglerId || "",
    giglerName: userInfo?.firstName + userInfo?.lastName || "",
    questions: "",
    q1answer: "",
    q2answer: "",
    q3answer: "",
    q4answer: "",
    q5answer: "",
    workAgainEngager: "",
  })

  useEffect(() => {
    if (userInfo.type == "admin") {
      formik.setFieldValue("q1answer", feedbackDetails?.questions[0].q1a, false);
      formik.setFieldValue("q2answer", feedbackDetails?.questions[1].q2a, false);
      formik.setFieldValue("q3answer", feedbackDetails?.questions[2].q3a, false);
      formik.setFieldValue("q4answer", feedbackDetails?.questions[3].q4a, false);
      formik.setFieldValue("q5answer", feedbackDetails?.questions[4].q5a, false);
    }
  }, [])
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      console.log("Feedback form values", values);
      if(values.workAgainEngager){
      try {
        let question = [];
        question.push({ q: 'Did you struggle to get information to make better decisions at work?', q1a: values.q1answer })
        question.push({ q: 'Did the organization inform you about all the resources and tools to perform your duties well?', q2a: values.q2answer })
        question.push({ q: 'Do you feel that your opinions were heard and valued by the engager?', q3a: values.q3answer })
        question.push({ q: 'Do you feel there was a scope for personal growth in the engagement?', q4a: values.q4answer })
        question.push({ q: 'How challenging was your work and are you satisfied with the engagement overall?', q5a: values.q5answer })
        values.questions = question;
        const result = await api.giglerFeedback.create(values);
        if (result?.data?.message == "success") {
          setSnack({
            message: `Feedback for engagement ${values.engagementId} !`,
            open: true,
            type: "success",
          });
          handleClose();
          handleClosedEngagements();
        } else {
          throw new error("failed to update");
        }
      }
      catch (error) {
        setSnack({
          message: "Error occurred during feedback!",
          open: true,
          type: "error",
        });
        console.log("ERROR", { ...error });
      }
    }else{
      setSnack({
        message: "Choose an option in want to work with engager",
        open: true,
        type: "error",
      });
    }

    }
  });


  return (
    <Card sx={{ px: "40px", py: "20px" }}>
      <form onSubmit={formik.handleSubmit} method="post">
        <MDBox my={1} sx={{ textAlign: "center" }}>
          {userInfo.type == "admin" ? (
            <MDBox>
              <MDTypography variant="h4" color="dark" sx={{ display: "inline-block" }}>Feedback Sent</MDTypography>
            </MDBox>
          ) : (
            <MDTypography variant="h4" color="dark" sx={{ display: "inline-block" }}>Feedback Form</MDTypography>
          )}
        </MDBox>
        <MDBox display="flex" justifyContent="space-between">
          <MDBox >
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Engagement ID&nbsp;:
            </MDTypography>
            <MDTypography px={1} py={1} variant="button" fontWeight="bold" color="info" textTransform="capitalize">
              {feedbackDetails.engagementId}
            </MDTypography><br />
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              Date&nbsp;:
            </MDTypography>
            <MDTypography variant="button" fontWeight="medium" color="info">
              &nbsp;{moment(feedbackDetails?.createdAt).format("DD-MM-YYYY")}
            </MDTypography><br />
            {userInfo.type == 'admin' ? (
              ""
            ) : (
              <>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Engager organization&nbsp;:
                </MDTypography>
                <MDTypography px={1} py={1} variant="button" fontWeight="bold" color="info" textTransform="capitalize">
                  {feedbackDetails?.feedbackEngagements?.organizationName}
                </MDTypography><br />
              </>
            )}
          </MDBox>
          <MDBox>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Engagement Title&nbsp;:
            </MDTypography>
            {userInfo.type == "admin" ? (
              <>
                <MDTypography px={1} py={1} variant="button" fontWeight="bold" color="info" textTransform="capitalize">
                  {feedbackDetails.engagementTitle}
                </MDTypography><br />
              </>
            ) : (
              <>
                <MDTypography px={1} py={1} variant="button" fontWeight="bold" color="info" textTransform="capitalize">
                  {feedbackDetails?.feedbackEngagements?.engagementTitle}
                </MDTypography><br />
              </>
            )}
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Engagement owner name&nbsp;:
            </MDTypography>
            {userInfo.type == "admin" ? (
              <MDTypography px={1} py={1} variant="button" fontWeight="bold" color="info" textTransform="capitalize">
                {feedbackDetails?.engagerName}
              </MDTypography>
            ) : (
              <MDTypography px={1} py={1} variant="button" fontWeight="bold" color="info" textTransform="capitalize">
                {feedbackDetails?.feedbackEngagements?.engagerName}
              </MDTypography>
            )}
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox>
          {userInfo.type == "admin" ? (
            <>
              <MDTypography px={1} py={1} variant="button">
                Q1.	Did you struggle to get information to make better decisions at work?
              </MDTypography><br />
              <MDTypography px={1} py={1} fontWeight="medium" variant="button">
                Ans : {feedbackDetails?.questions[0]?.q1a}
              </MDTypography>
            </>
          ) : (
            <>
              <MDTypography px={1} py={1} variant="button" fontWeight="bold" textTransform="capitalize">
                Q1.	Did you struggle to get information to make better decisions at work?
              </MDTypography>
              <MDInput
                type="text"
                fullWidth
                name="q1answer"
                {...formik.getFieldProps("q1answer")}
              />
            </>
          )}
        </MDBox>
        <MDBox>
          {userInfo.type == "admin" ? (
            <>
              <MDTypography px={1} py={1} variant="button">
                Q2: Did the organization inform you about all the resources and tools to perform your duties well?
              </MDTypography><br />
              <MDTypography px={1} py={1} fontWeight="medium" variant="button">
                Ans : {feedbackDetails?.questions[1]?.q2a}
              </MDTypography>
            </>
          ) : (
            <>
              <MDTypography px={1} py={2} variant="button" fontWeight="bold" textTransform="capitalize">
                Q2: Did the organization inform you about all the resources and tools to perform your duties well?
              </MDTypography>
              <MDInput
                type="text"
                fullWidth
                name="q2answer"
                {...formik.getFieldProps("q2answer")}
              />
            </>
          )}
        </MDBox>
        <MDBox>
          {userInfo.type == "admin" ? (
            <>
              <MDTypography px={1} py={1} variant="button">
                Q3: Do you feel that your opinions were heard and valued by the engager?
              </MDTypography><br />
              <MDTypography px={1} py={1} fontWeight="medium" variant="button">
                Ans : {feedbackDetails?.questions[2]?.q3a}
              </MDTypography>
            </>
          ) : (
            <>
              <MDTypography px={1} py={2} variant="button" fontWeight="bold" textTransform="capitalize">
                Q3: Do you feel that your opinions were heard and valued by the engager?
              </MDTypography>
              <MDInput
                type="text"
                fullWidth
                name="q3answer"
                {...formik.getFieldProps("q3answer")}
              />
            </>
          )}
        </MDBox>
        <MDBox>
          {userInfo.type == "admin" ? (
            <>
              <MDTypography px={1} py={1} variant="button">
                Q4: Do you feel there was a scope for personal growth in the engagement?
              </MDTypography><br />
              <MDTypography px={1} py={1} fontWeight="medium" variant="button">
                Ans : {feedbackDetails?.questions[3]?.q4a}
              </MDTypography>
            </>
          ) : (

            <>
              <MDTypography px={1} py={2} variant="button" fontWeight="bold" textTransform="capitalize">
                Q4: Do you feel there was a scope for personal growth in the engagement?
              </MDTypography>
              <MDInput
                type="text"
                fullWidth
                name="q4answer"
                {...formik.getFieldProps("q4answer")}
              />
            </>
          )}
        </MDBox>
        <MDBox>
          {userInfo.type == "admin" ? (
            <>
              <MDTypography px={1} py={1} variant="button">
                Q5: How challenging was your work and are you satisfied with the engagement overall?
              </MDTypography><br />
              <MDTypography px={1} py={1} fontWeight="medium" variant="button">
                Ans : {feedbackDetails?.questions[4]?.q5a}
              </MDTypography>
            </>
          ) : (
            <>
              <MDTypography px={1} py={2} variant="button" fontWeight="bold" textTransform="capitalize">
                Q5: How challenging was your work and are you satisfied with the engagement overall?
              </MDTypography>
              <MDInput
                type="text"
                fullWidth
                name="q5answer"
                {...formik.getFieldProps("q5answer")}
              />
            </>
          )}
        </MDBox>
        {
          userInfo.type == "admin" ? (
            <>
              <MDTypography px={1} variant="button" fontWeight="bold">
                Want to work with this Engager in future&nbsp;: {feedbackDetails?.workAgainEngager ?
                  (<MDBadge badgeContent="Yes" size="sm" variant="gradient" color="success" container></MDBadge>) : (
                    <MDBadge badgeContent="No" size="sm" variant="gradient" color="error" container></MDBadge>
                  )}
              </MDTypography><br />
              <MDButton sx={{ mt: "10px" }} variant="outlined" color="dark" size="small" onClick={() => handleClose()}>close</MDButton>
            </>
          ) : (
            <>
              <MDBox mt={2}>
                <MDTypography px={1} py={2} variant="button" fontWeight="bold">
                  Want to work with this Engager in future&nbsp;:
                </MDTypography>
                <FormControl component="fieldset">
                  <RadioGroup
                    {...formik.getFieldProps("workAgainEngager")}
                  >
                    <Stack direction="row">
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label={
                          <MDTypography color="text" variant="p" fontWeight="regular">
                            Yes
                          </MDTypography>
                        }
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label={
                          <MDTypography color="text" variant="p" fontWeight="regular">
                            No
                          </MDTypography>
                        }
                      />
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </MDBox>
              <MDBox mt={1} >
                <MDButton variant="outlined" color="success" size="small" type="submit">submit</MDButton>
                <MDButton sx={{ ml: "10px" }} variant="outlined" color="dark" size="small" onClick={() => handleClose()}>cancel</MDButton>
              </MDBox>
            </>
          )
        }
      </form >
    </Card >
  )
}

export default GiglerFeedbackForm