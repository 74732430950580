import { Card, Modal } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { UserContext } from 'context/UserProvider';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React, { useContext, useEffect, useState } from 'react'
import api from 'services/api';
import EngagementList from '../EngagementStage/EngagementList';
import ClosedGiglerList from './ClosedGiglerList';
import DataTable from './DataTable'

const ClosedEngagements = () => {
  const { userInfo } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(true)
  const handleBack = () => setShow(!show)
  const [gigDetails, setGigDetails] = useState();
  const [engagmentDesc, setEngagementDesc] = useState();
  const [feedbackDetails, setFeedbackDetails] = useState();
  const [callAgainValues, setCallAgainValues] = useState();
  const handleClose = () => {
    setOpen(false);
  }
  const [details, setDetails] = useState({
    columns: [
      { Header: "Engagement Id", accessor: "engagementId", width: "12%" },
      { Header: "Title", accessor: "engagementTitle", width: "20%" },
      { Header: "Organization", accessor: "organizationName", width: "15%" },
      { Header: "Engagement Owner Name", accessor: "engagerName", width: "20%" },
      { Header: "Industry", accessor: "industry", width: "10%" },
      { Header: "Domain", accessor: "domain", width: "10%" },
      { Header: "Skills", accessor: "primarySkills", width: "15%" },
      { Header: "Status", accessor: "status", width: "15%" },
    ],
    rows: []
  })

  useEffect(() => {
    const id = userInfo?.id
    api.engagement.findByEngId(id, { status: "closed" })
      .then((data) => {
        console.log(".............", data)
        if (data?.data?.result?.status == 0) {
          setDetails(0);
        } else {
          let temp = data?.data?.result?.data
          setDetails({ ...details, rows: temp });
        }
      })
  }, [])

  const callAgainHandleClick = () => {
    handleClick(callAgainValues);
  }

  const handleClick = (engagementDetails) => {
    setCallAgainValues(engagementDetails);
    setEngagementDesc(engagementDetails);
    api.engager.getFeedbackEngagement({ engagementId: engagementDetails.engagementId })
      .then((data) => {
        if (data?.data?.result?.data == 0) {
          console.log("inside iff", data)
          // setShow(!show)
          setGigDetails("zero")
          setShow(!show)

        } else {
          console.log("closed gigler list", data?.data?.result?.data)
          setGigDetails(data?.data?.result?.data)
          setShow(!show)
        }
      })
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {show &&
        <MDBox>
          {details == 0 ?
            (
              <Card>
                <MDTypography px={2} py={2}>No Engagement's Closed</MDTypography>
              </Card>
            ) : (
              <DataTable table={details} handleClick={handleClick} canSearch />
            )
          }
        </MDBox>
      }
      {!show &&
        < MDBox >
          {gigDetails == "zero" ? (
            <Card sx={{ height: "80vh", width: "160vh" }}>
              <MDTypography px={2} py={2}>No pending feedback for this engagement</MDTypography>
            </Card>
          ) : (
            <ClosedGiglerList gigDetails={gigDetails} engagementDesc={engagmentDesc} callAgainHandleClick={callAgainHandleClick} />
          )}
        </MDBox >
      }

    </DashboardLayout>
  )
}

export default ClosedEngagements