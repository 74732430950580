import { Card, CardActions, CardContent, Modal } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import { UserContext } from 'context/UserProvider'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from 'services/api'
import DataTable from './DataTable'
import { useStyles } from './styles'
import EngagementDetails from 'components/EngagementDetails'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


function Engagements() {
  let navigate = useNavigate();
  const { userInfo } = useContext(UserContext);
  const [show, setShow] = useState(true)
  const handleBack = () => setShow(!show)
  const [engDetails, setEngDetails] = useState()

  function sliceText(Title, maxLength){
    if(Title.length <= maxLength){ 
      return Title;
    }else{
      return Title.slice(0,maxLength-3) + '...';
    }
  }

  const [details, setDetails] = useState({
    columns: [
      // { Header: "Engagement Id", accessor: "engagementId", width: "12%" },
      { Header: "Title", accessor: "engagementTitle", width: "20%", cell: ({row}) =>(
        <Tooltip Title={row.engagementTitle} placement='top'>
          {sliceText(row.engagementTitle,10)}
        </Tooltip>), },
      { Header: "Organization", accessor: "organizationName", width: "20%" },
      { Header: "Engagement owner Name", accessor: "engagerName", width: "15%" },
      { Header: "Industry", accessor: "industry", width: "10%" },
      { Header: "Domain", accessor: "domain", width: "10%" },
      { Header: "Skills", accessor: "primarySkills", width: "15%" },
      { Header: "Number of Positions", accessor: "numberOfPositions", width: "15%" },
      { Header: "Posted Date", accessor: "createdAt", width: "15%" },
      
    ],
    rows: []
  })
  useEffect(() => {
    const id = userInfo?.id
    api.engagement.findAll()
      .then((data) => {
        let temp = data?.data?.result?.data
        setDetails({ ...details, rows: temp });
      }) 
  }, [])

  const handleClick = (engId) => {
    // console.log("eng id", engId)
    let id = engId
    api.engagement.findByEngagementId(id)
      .then((data) => {
        // console.log("result", data?.data?.result?.data)
        setEngDetails(data?.data?.result?.data)
        // console.log("eng details", engDetails)
        setShow(!show)
      })
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {show &&
        <MDBox>
          {details?.length <= 0 ?
            (
              <Card>
                <MDTypography px={2} py={2}>No Engagement's posted</MDTypography>
              </Card>
            ) : (
              <DataTable table={details} handleClick={handleClick} canSearch />
            )
          }
        </MDBox>
      }
      {!show &&
        < MDBox >
          <EngagementDetails engDetails={engDetails} handleBack={handleBack} />
        </MDBox >
      }
    </DashboardLayout >
  )
}
export default Engagements;