import { Card, Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from 'context/UserProvider'
import api from 'services/api'
import DataTable from './DataTable'
import MDTypography from 'components/MDTypography'
import { useNavigate } from 'react-router-dom'
import EngagementDetails from 'components/EngagementDetails'
import EngagementTabs from './EngagementTabs'

const EngagementList = () => {
  let navigate = useNavigate();
  const { userInfo } = useContext(UserContext);
  const [show, setShow] = useState(true)
  const handleBack = () => setShow(!show)
  const [engDetails, setEngDetails] = useState()
  const [details, setDetails] = useState({
    columns: [
      { Header: "Engagement Id", accessor: "engagementId", width: "12%" },
      { Header: "Title", accessor: "engagementTitle", width: "20%" },
      { Header: "Organization", accessor: "organizationName", width: "15%" },
      { Header: "Engagement Owner Name", accessor: "engagerName", width: "20%" },
      { Header: "Industry", accessor: "industry", width: "10%" },
      { Header: "Domain", accessor: "domain", width: "10%" },
      { Header: "Skills", accessor: "primarySkills", width: "15%" },
    ],
    rows: []
  })
  useEffect(() => {
    const id = userInfo?.id
    api.engagement.findByEngId(id,{status:"open" , isDeleted:{$exists: false}})
      .then((data) => {
        if (data?.data?.result?.status == 0) {
          setDetails(0);
        } else {
          let temp = data?.data?.result?.data
          setDetails({ ...details, rows: temp });
        }
      })
  }, [])

  const handleClick = (engId) => {
    // console.log("eng id", engId)
    let id = engId
    api.engagement.findByEngagementId(id)
      .then((data) => {
        console.log("engagement details", data?.data?.result?.data)
        // setEngDetails(data?.data?.result?.data)
        navigate("/employer/engagement", {
          state: {
            engDetails: data?.data?.result?.data,
          },
        });
        console.log("eng details", engDetails)
        // setShow(!show)
      })
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {show &&
        <MDBox>
          {details == '0' ?
            (
              <Card>
                <MDTypography px={2} py={2}>No Engagement's posted</MDTypography>
              </Card>
            ) : (
              <DataTable table={details} handleClick={handleClick} canSearch />
            )
          }
        </MDBox>
      }
      {!show &&
        < MDBox >
          {/* <EngagementDetails engDetails={engDetails} handleBack={handleBack} /> */}
          <EngagementTabs engDetails={engDetails} handleBack={handleBack} />
        </MDBox >
      }
    </DashboardLayout >
  )
}

export default EngagementList