// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";
// Data
import MDButton from "components/MDButton";
import { useContext, useEffect } from "react";
import { UserContext } from "context/UserProvider";
import { SnackbarContext } from "context/SnackbarContext";

import { useFormik } from "formik";
import * as Yup from "yup";
import api from "services/api";
import { useState } from "react";
import { Box } from "@mui/system";
import MasterData from "layouts/pages/AdminDashboard/MasterData";
import { SearchOutlined } from "@mui/icons-material";

const initialValues = {
  industry: "",
  secondaryIndustry: [""],
  domain: "",
  primarySkills: "",
  secondarySkills: "",
  otherSkills: "",
};

const validationSchema = Yup.object({
  industry: Yup.string().required("Industry is required"),
  domain: Yup.string().required("Domain is required"),
  primarySkills: Yup.string().required("Primary skill is required")
});

const Skills = ({ userData, domainData, updateUserData, formik }) => {
  const { userInfo } = useContext(UserContext);
  const { setSnack } = useContext(SnackbarContext);
  const [data, setData] = useState([]);
  const [masterDomain, setMasterDomain] = useState([]);
  const [domain, setDomain] = useState();
  const [indData, setIndData] = useState([]);
  const [secondaryIndList, setSecondaryIndList] = useState([]);
  const [secondaryIndListValues, setSecondaryIndListValues] = useState([]);
  const [skills, setSkills] = useState([]);
  const [tempSkills, setTempSkills] = useState([]);
  const [industry, setIndustry] = useState();
  const [secondaryIndustry, setSecondaryIndustry] = useState([]);
  const [primarySkills, setPrimarySkills] = useState();
  const [secondarySkills, setSecondarySkills] = useState();
  const [selectedIndustry, setSelectedIndustry] = useState();
  const [selectedDomain, setSelectedDomain] = useState();
  const [selectedPrimarySkill, setSelectedPrimarySkill] = useState();
  const [selectedSecondarySkill, setSelectedSecondarySkill] = useState();
  const [disabledDomain, setDisabledDomain] = useState(true);

  const validate = (values) => {
    let errors = {};
    if (!values.industry) {
      errors.industry = "Required";
    }
    if (!values.domain) {
      errors.domain = "Required";
    }
    return errors;
  };



  // const formik = useFormik({
  //   initialValues,
  //   validationSchema,
  //   onSubmit: async (values) => {
  //     console.log("gigler profile values", values);
  //     try {
  //       const id = userInfo?.id;
  //       let temp = userData;
  //       temp = { ...userData, ...values };
  //       updateUserData(temp);
  //       const result = await api.userInfo.update(id, values);
  //       if (result) {
  //         if (result?.data?.message == "success") {
  //           setSnack({
  //             message: "Skills details updated!",
  //             open: true,
  //             type: "success",
  //           });
  //         } else if (result?.data?.message == "error") {
  //           setSnack({
  //             message: "Error updating details!",
  //             open: true,
  //             type: "error",
  //           });
  //         }
  //       } else {
  //         throw new error("failed to update");
  //       }
  //     } catch (error) {
  //       setSnack({
  //         message: "Error occurred during update!",
  //         open: true,
  //         type: "success",
  //       });
  //       // console.log("ERROR LOGIN: ", { ...error });
  //     }
  //   },
  //   // validate,
  // });

  useEffect(() => {
    if(userData.industry == "Services"){
      api.industry
      .findAll()
      .then((data) => {
        let temp = data?.data?.result?.data;
        // let x = temp.filter((x) => x.Industry != "Services")
        setIndData(temp);
      })
      .catch((error) => {
        // console.log("error", error);
      });
    }
    else{

    api.industry
      .findAll()
      .then((data) => {
        let temp = data?.data?.result?.data;
        let x = temp.filter((x) => x.Industry != "Services")
        setIndData(x);
      })
      .catch((error) => {
        // console.log("error", error);
      });
    }
  }, []);

  useEffect(() => {
    const tempDomain = domainData.filter((value, index, self) =>
    index === self.findIndex((t) => (
      (t.Domain).trim() === (value.Domain).trim()
    ))
  )
    setData(tempDomain);
    let selectedInd = domainData.find((x) => x.Domain == userData?.domain);
    setDomain(selectedInd);
    setMasterDomain(domainData);
  }, []);

  useEffect(() => {
    // formik.setValues(userData, false);
    formik.setFieldValue("otherSkills", userData?.otherSkills, true);
    setSecondaryIndListValues(userData?.secondaryIndustry);
    setSelectedIndustry()
  }, [userData]);

  useEffect(() => {
    let selectedInd = indData.find((x) => x.Industry == userData?.industry);
    setIndustry(selectedInd);
    let x = indData.filter((x) => x.Industry != userData?.industry);
    // setSecondaryIndList(indData.filter((x) => x.Industry != userData?.industry));
    setSecondaryIndList(x.map(e => e.Industry));
    // console.log("user data second ind@@@@@@@@@@@@@", x.map(e => e.Industry));
    formik.setFieldValue("industry", selectedInd?.Industry, true);
    let secondaryInd = indData.find((y) => y.Industry == userData?.secondaryIndustry);
    formik.setFieldValue("secondaryIndustry", userData?.secondaryIndustry, true)
    let t = indData.filter((x) => x.Industry != userData?.industry) || [];
    const myArrayFiltered = userData?.secondaryIndustry ? t.filter((el) => {
      return userData?.secondaryIndustry && userData?.secondaryIndustry.length > 0 && userData?.secondaryIndustry.some((f) => {
        return f === el.Industry;
      });
    }) : [];
    setSecondaryIndustry({ myArrayFiltered });
    // console.log("setSecondaryIndList##########",userData?.secondaryIndustry, { myArrayFiltered });
  }, [indData, userData]);

  useEffect(() => {
    if (data && userData) {
      let selectedInd = data.find((x) => x.Domain == userData?.domain);
      // setDomain(selectedInd);
      console.log("domain list", userData?.domain, selectedInd);
      if (selectedInd && selectedInd?.Domain) {
        formik.setFieldValue("domain", selectedInd.Domain, true);
        console.log("domain changes here##########", selectedInd.Domain);
        setSkills(selectedInd.skillSet);
        formik.setFieldValue("primarySkills", userData?.primarySkills, true);
        setPrimarySkills(userData?.primarySkills);
        var filteredArray = selectedInd.skillSet.filter((e) => e !== userData?.primarySkills);
        setTempSkills(filteredArray);
        // // console.log("primarySkills", selectedInd.skillSet);
        formik.setFieldValue("secondarySkills", userData?.secondarySkills, true);
        setSecondarySkills(userData?.secondarySkills);
      }
    }
  }, [data]);

  const skillDescription = "Skills"

  return (
    // <Card id="skills" sx={{ overflow: "visible", }}>
    <MDBox>
      <MDBox py={3}>
        <MDTypography variant="h5">
          Skills 
          <Tooltip title = {skillDescription} placement="right">
          <InfoIcon fontSize="small"/>
          </Tooltip>
          </MDTypography>
      </MDBox>
      {/* <form onSubmit={formik.handleSubmit} method="post"> */}
        <MDBox>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              {indData && industry && (
                <Autocomplete
                  // multiple
                  disabled={userData.industry == "Services"}
                  id="select-industry"
                  name="industry"
                  options={indData}
                  autoHighlight
                  getOptionLabel={(option) => option.Industry}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.Industry}
                    </Box>
                  )}
                  renderInput={(params) => <FormField {...params} label="Select Industry "
                  // error={formik.errors.industry ? true : false}
                  // success={formik.values.industry > 0 && !formik.errors.industry}
                  // helperText={
                  //   formik.errors.industry ? formik.errors.industry : null
                  // }
                  />}
                  defaultValue={industry}
                  onChange={(event, newValue) => {
                    setDomain();
                    formik.setFieldValue("domain", '');
                    setIndustry(newValue ? newValue : undefined);
                    formik.setFieldValue("industry", newValue ? newValue.Industry : "", true);
                    let y = masterDomain.filter(x => x?.Industry == newValue?.Industry);
                    if(newValue == undefined || ""){
                      console.log("inside if  conddition llllllllll");
                        setData(data);
                        setSkills([])
                        setTempSkills([])
                      }else{
                        setData(y);
                      }
                    var secInd = indData.filter((e) => e !== newValue);
                    setSecondaryIndList(secInd.map(e => e.Industry));
                    formik.setFieldValue("secondaryIndustry", '', true);
                    formik.setFieldValue("primarySkills", '', true);
                    formik.setFieldValue("secondarySkills", '', true);
                    formik.setFieldValue("secondaryIndustry", [], true);
                    setSecondaryIndListValues([]);
                    setDisabledDomain(false);
                  }}
                />
              )}
              {indData && !industry && (
                <Autocomplete
                disabled={userData.industry == "Services"}
                  id="select-industry"
                  name="industry"
                  options={indData}
                  autoHighlight
                  getOptionLabel={(option) => option.Industry}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.Industry}
                    </Box>
                  )}
                  renderInput={(params) => <FormField {...params} label="Select Industry *"
                    error={formik.errors.industry ? true : false}
                    success={formik.values.industry > 0 && !formik.errors.industry}
                    helperText={
                      formik.errors.industry ? formik.errors.industry : null
                    }
                  />}
                  // defaultValue={industry}
                  onChange={(event, newValue) => {
                    setDomain();
                    formik.setFieldValue("domain", '');
                    setIndustry(newValue ? newValue : undefined);
                    formik.setFieldValue("industry", newValue ? newValue.Industry : "", false);
                    let y = masterDomain.filter((x,index) => x?.Industry == newValue?.Industry)
                    // console.log("kkkkkkkkkkkkkkkkkkkk",y)
                    if(newValue == undefined || ""){
                        setData(data);
                        setSkills([])
                        setTempSkills([])
                      }else{
                        setData(y);
                      }
                    // setData(y);
                    var secInd = indData.filter((e) => e !== newValue);
                    setSecondaryIndList(secInd.map(e => e?.Industry));
                    formik.setFieldValue("secondaryIndustry", '', false);
                    formik.setFieldValue("primarySkills", '', false);
                    formik.setFieldValue("secondarySkills", '', false);
                    formik.setFieldValue("secondaryIndustry", [], false);
                    setSecondaryIndListValues([]);
                    setDisabledDomain(false);
                  }}
                />
              )}
            </Grid>
            {secondaryIndListValues && (
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  multiple
                  disabled={userData.industry == "Services"}
                  id="select-industry"
                  name="secondaryIndustry"
                  options={secondaryIndList}
                  // defaultValue={userData?.secondaryIndustry}
                  defaultValue={secondaryIndListValues}
                  // value={userData?.secondaryIndustry}
                  getOptionLabel={(option) => option}
                  // renderOption={(props, option) => (
                  //   <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                  //     {option}
                  //   </Box>
                  // )}
                  renderInput={(params) => <FormField {...params} InputLabelProps={{ shrink: true }} label="Secondary Industry" />}
                  {...formik.getFieldProps("secondaryIndustry")}
                  onChange={(event, secIndValue) => {
                    formik.setFieldValue("secondaryIndustry", secIndValue, false);
                  }}
                />
                {/* <Autocomplete
                  multiple
                  name="secondaryIndustry"
                  options={secondaryIndList}
                  defaultValue={userData?.secondaryIndustry}
                  // value={userData?.languages}
                  renderInput={(params, index) => (
                    <FormField {...params} key={index} InputLabelProps={{ shrink: true }} label="Secondary Industry" />
                  )}
                  // {...formik.getFieldProps("languages")}
                  onChange={(event, secIndValue) => {
                    let temp = secIndValue.map((row, index) => row.Industry)                    
                    formik.setFieldValue("secondaryIndustry", temp, false);
                  }}
                /> */}
              </Grid>
            )}
            {/* {!userData?.secondaryIndustry && (
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  multiple                  
                  id="select-industry"
                  name="secondaryIndustry"
                  options={secondaryIndList}
                  // defaultValue={userData?.secondaryIndustry}
                  // value={userData?.secondaryIndustry}
                  getOptionLabel={(option) => option.Industry}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.Industry}
                    </Box>
                  )}
                  renderInput={(params) => <FormField {...params} InputLabelProps={{ shrink: true }} label="Secondary Industry" />}
                  {...formik.getFieldProps("secondaryIndustry")}
                  onChange={(event, secIndValue) => {
                    let temp = secIndValue.map((row, index) => row.Industry)
                    // console.log("secondary industry value", temp);
                    formik.setFieldValue("secondaryIndustry", temp, false);
                  }}
                />
              </Grid>
            )} */}
            <Grid item xs={12} sm={12}>
              {data && domain && (
                <Autocomplete
                disabled={userData.industry == "Services"}
                  name="domain"
                  options={data}
                  autoHighlight
                  getOptionLabel={(option) => option.Domain}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.Domain}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label="Select Domain "
                      error={formik.errors.domain ? true : false}
                      success={formik.values.domain > 0 && !formik.errors.domain}
                      helperText={
                        formik.errors.domain ? formik.errors.domain : null
                      }
                    />
                  )}
                  defaultValue={domain}
                  onChange={(event, newValue) => {
                    setDomain(newValue ? newValue : undefined);
                    formik.setFieldValue("domain", newValue ? newValue?.Domain : "", false)
                    setSkills(newValue?.skillSet || []);
                    setTempSkills(newValue?.skillSet || []);
                    formik.setFieldValue("primarySkills", '', false);
                    setPrimarySkills('');
                    formik.setFieldValue("secondarySkills", '', false);
                    setSecondarySkills('');
                  }}
                />
              )}
              {data && !domain && (
                <Autocomplete
                disabled={userData.industry == "Services"}
                  name="domain"
                  options={data}
                  autoHighlight
                  getOptionLabel={(option) => option.Domain}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.Domain}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <FormField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label="Select Domain *"
                    error={formik.errors.domain ? true : false}
                    success={formik.values.domain > 0 && !formik.errors.domain}
                    helperText={
                      formik.errors.domain ? formik.errors.domain : null
                    }
                    />
                    )}
                    // {...formik.getFieldProps("domain")}                  
                    onChange={(event, newValue) => {
                    setDomain(newValue ? newValue : undefined);
                    formik.setFieldValue("domain", newValue?.Domain ? newValue?.Domain : "", false);
                    setSkills(newValue.skillSet || []);
                    setTempSkills(newValue?.skillSet || []);
                    formik.setFieldValue("primarySkills", '', false);
                    setPrimarySkills('');
                    // // console.log("select domain");
                    formik.setFieldValue("secondarySkills", '', false);
                    setSecondarySkills('');
                  }}
                // error={formik.touched.domain && formik.errors.domain ? true : false}
                // helperText={
                //   formik.touched.domain && formik.errors.domain ? formik.errors.domain : null
                // }
                />
              )}
            </Grid>
            {skills && primarySkills && (
              <Grid item xs={12} md={12}>
                <Autocomplete
                  disabled={userData.industry == "Services"}
                  name="primarySkills"
                  options={skills}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label="Primary skill set"
                    />
                  )}
                  defaultValue={primarySkills}
                  {...formik.getFieldProps("primarySkills")}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("primarySkills", newValue, false);
                    formik.setFieldValue("secondarySkills", '', false);
                    var filteredArray = skills.filter((e) => e !== newValue);
                    setTempSkills(filteredArray);
                    // // console.log("primarySkills", newValue);
                  }}
                />
              </Grid>
            )}
            {skills && !primarySkills && (
              <Grid item xs={12} md={12}>
                <Autocomplete
                  disabled={userData.industry == "Services"}
                  name="primarySkills"
                  options={skills}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label="Primary skill set"
                    />
                  )}
                  defaultValue={primarySkills}
                  {...formik.getFieldProps("primarySkills")}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("primarySkills", newValue, false);
                    formik.setFieldValue("secondarySkills", '', false);
                    var filteredArray = skills.filter((e) => e !== newValue);
                    setTempSkills(filteredArray);
                    // console.log("primarySkills", newValue);
                  }}
                />
              </Grid>
            )}
            {tempSkills && secondarySkills && (
              <Grid item xs={12} md={12}>
                <Autocomplete
                  disabled={userData.industry == "Services"}
                  name="secondarySkills"
                  options={tempSkills}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label="Secondary skill set"
                    />
                  )}
                  value={secondarySkills}
                  {...formik.getFieldProps("secondarySkills")}
                  onChange={(event, skills) => {
                    formik.setFieldValue("secondarySkills", skills, false);
                  }}
                  error={
                    formik.touched.secondarySkills && formik.errors.secondarySkills ? true : false
                  }
                  helperText={
                    formik.touched.secondarySkills && formik.errors.secondarySkills
                      ? formik.errors.secondarySkills
                      : null
                  }
                />
              </Grid>
            )}
            {tempSkills && !secondarySkills && (
              <Grid item xs={12} md={12}>
                <Autocomplete
                  disabled={userData.industry == "Services"}
                  name="secondarySkills"
                  options={tempSkills}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label="Secondary skill set"
                    />
                  )}
                  {...formik.getFieldProps("secondarySkills")}
                  onChange={(event, skills) => {
                    formik.setFieldValue("secondarySkills", skills, false);
                  }}
                  error={
                    formik.touched.secondarySkills && formik.errors.secondarySkills ? true : false
                  }
                  helperText={
                    formik.touched.secondarySkills && formik.errors.secondarySkills
                      ? formik.errors.secondarySkills
                      : null
                  }
                />
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <FormField
                disabled={userData.industry == "Services"}              
                label="Any other skill set"
                name="otherSkills"
                {...formik.getFieldProps("otherSkills")}
              />
            </Grid>
          </Grid>
          {userData.industry == "Services" ? (
            ""
          ) : (
            <MDBox pt={1}>
            {/* <MDButton variant="gradient" size="small" color="success" type="submit" disabled={userData.industry == "Services"}>
              SAVE
            </MDButton> */}
          </MDBox>
          )} 
          {/* <MDBox pt={1}> 
            <MDButton variant="gradient" size="small" color="success" type="submit" disabled={userData.industry == "Services"}>
              SAVE
            </MDButton>
           </MDBox> */}
        </MDBox>
      {/* </form> */}
    {/* </Card> */}
    </MDBox>
  );
};

export default Skills;
