import {
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import api from "services/api";
import { useStyles } from "./styles";
import { useNavigate } from "react-router-dom";
import MDBadge from "components/MDBadge";
import ShortListed from "../ShortListed";
import DataTable from "./DataTable";
const AppliedList = ({ giglerList, handleSubmit }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [checked, setChecked] = useState([]);
  const [list, setList] = useState();
  const [details, setDetails] = useState({
    columns: [
      { Header: "name", accessor: `user.firstName`, width: "20%" },
      { Header: "Industry", accessor: `user.industry`, width: "15%" },
      { Header: "Domain", accessor: `user.domain`, width: "15%" },
      { Header: "Email", accessor: `user.email`, width: "25%" },
      { Header: "Phone Number", accessor: `user.phoneNumber` },
      // { Header: "salary", accessor: "salary" },
    ],
    rows: giglerList
  })
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    // console.log("checked", newChecked)
    setChecked(newChecked);
  };

  const handleClick = (row) => {
    console.log("on click row details", row)
    navigate("/gigTalent-details", {
      state: {
        values: row,
      },
    });
  };
  return (
    <MDBox>
      <MDTypography type="title" px={2} py={2}>
        {" "}
        Applied List
      </MDTypography>
      {giglerList && giglerList.length <= 0 ? (
        <MDBox mb={2} sx={{ width: 500, height: 500 }}>
          <MDTypography px={2} py={2}>
            No GigTalent Applied for this engagement
          </MDTypography>
        </MDBox>
      ) : (
        // <>
        //   <List className={classes.list}>
        //     {giglerList?.map((row, index) => (
        //       <ListItemButton key={row.user.userId} id={index} onClick={() => handleClick(row)}>
        //         <ListItem
        //           className={classes.listItem}
        //           divider={index == giglerList.length - 1 ? false : true}
        //         >
        //           <ListItemAvatar>
        //             <MDAvatar src={row.user.avatar} alt="remy sharp" size="sm">
        //               {row.user.firstName}
        //               {row.user.lastName}
        //             </MDAvatar>
        //           </ListItemAvatar>
        //           <ListItemText
        //             primary={
        //               <MDBox display="flex" justifyContent="space-between">
        //                 <MDTypography variant="h6">
        //                   {row.user.firstName + ` ` + row.user.lastName}
        //                 </MDTypography>
        //                 <MDBadge
        //                   badgeContent={row.shortListed ? "shortListed" : "not ShortListed"}
        //                   color={row.shortListed ? "success" : "light"}
        //                   size="small"
        //                 />
        //               </MDBox>
        //             }
        //           />
        //         </ListItem>
        //       </ListItemButton>
        //     ))}
        //   </List>
        // </>
        <DataTable table={details} canSearch handleClick={handleClick} />
      )}
    </MDBox>
  );
};

export default AppliedList;
