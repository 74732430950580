import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState,useContext } from "react";
import * as XLSX from "xlsx";
import DataTable from "./DataTable";
import api from "services/api";
import moment from "moment";
import { SnackbarContext } from "context/SnackbarContext";
// import { useStyles } from "../styles";
// import CreateContractTemplate from "./CreateContractTemplate";
// import AddIndustry from "../../MasterData/IndustryList/AddIndustry";

const ImportAssessors = () => {
  const [templates, setTemplates] = useState([]);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [doc, setDoc] = useState();
  const classes = useState();
  const {setSnack}=useContext(SnackbarContext)
  const [details, setDetails] = useState({
    columns: [
      { Header: "First Name", accessor: "firstName", width: "10%" },
      { Header: "Last Name", accessor: "lastName", width: "10%" },
      { Header: "Email", accessor: "email", width: "10%" },
      { Header: "Phone Number", accessor: "phoneNumber", width: "10%" },
      { Header: "Industry", accessor: "industry", width: "10%" },
      { Header: "Domain", accessor: "domain", width: "10%" },      
      { Header: "Generated Password", accessor: "password", width: "10%" },
      { Header: "Status", accessor: "status", width: "10%" },
    ],
    rows: [],
  });

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    api.contract.getContractTemplates().then((res) => {
      if (res) {
        console.log("data", res.data.result.data);
        setTemplates(res.data.result.data);
      }
    });
  }, []);

  const fileChange = (e) => {
    console.log("clicked", e.target);
    let temp = e.target.files[0];
    setDoc(temp);
  };
  const convertToJson = async (csv) => {
    console.log("received csv", csv);
    var lines = csv.split("\n");
    var result = [];
    var headers = lines[0].split(",");
    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");
      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }
      result.push(obj);
    }
    // console.log(result); // shows data in json format
    return result;
  };

  const readFile = () => {
    var f = doc;
    const reader = new FileReader();
    reader.onload = async (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, {
        type: "binary",
        cellDates: true,
        dateNF: "mm/dd/yyyy",
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      console.log("ws", ws, wb);
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, {
        header: 1,
      });

      convertToJson(data).then(async (res) => {
        if (res) {
          setShow(true);
          let t = res.map((x) => (x.password = generatePassword(x.firstName)));
          console.log("after convertttt", res, t);
          setDetails({ ...details, rows: res });

          res.forEach(async (element, index) => {
            let register = {
              firstName: element.firstName,
              lastName: element.lastName,
              email: element.email,
              phoneNumber: element.phoneNumber,
              password: element.password,
              type: "assessor",
            };
            let values = {
              assessorId: "",
              firstName: element.firstName,
              lastName: element.lastName,
              currentPosition: element.currentPosition,
              currentOrganization: element.currentOrganization,
              email: element.email,
              password: element.password,
              phoneNumber: element.phoneNumber,
              industry:element.industry,
              domain:element.domain,
              status: 1,
              from: 'import'
            };
            const registerAssessor = await api.users.registerUser(register).then((response) => {
              const userId = response?.data?.result?.data?.id;
              values.assessorId = userId;
              if(userId){
                res[index].status = "completed";
                setDetails({ ...details, rows: [...res] });
              } else{
                res[index].status = "fail";
                setDetails({ ...details, rows: [...res] });
                setSnack({
                  message : `Users already exsist in the database. Can't Import`,
                  open : true,
                  type : "error"
                })
              }
              const result = api.assessor.create(values)
                .then((result) => {
                  
                })
                .catch((error) => {                  
                  console.log("Assessor error", error);
                });
            });
          });
        }
      });
    };
    reader.readAsBinaryString(f);
  };

  const generatePassword = (name) => {
    return name.charAt(0).toUpperCase() + name.slice(1)+'@'+moment().format('YYYY');    
  }

  // const checkDataKeys = async(data) => {
  //   let availableKeys = Object.keys(ITimes);
  //   let returnValue;
  //   data.forEach((row) => {
  //     let rowKeys = Object.keys(row);
  //     if (
  //       JSON.stringify(availableKeys.sort()) === JSON.stringify(rowKeys.sort())
  //     ) {
  //       returnValue = true;
  //     } else {
  //       returnValue = false;
  //     }
  //   });
  //   if (returnValue) {
  //     FirebaseServices.deleteCollection().then((res) => {
  //       console.log("after delete", res);
  //       FirebaseServices.updateCalender(data).then((a) => {

  //       });
  //     });
  //   } else {
  //   }
  //   console.log("ret", returnValue);
  // }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={12}>
            <Card>
              <MDBox p={3} display="flex" justifyContent="space-between">
                <MDTypography type="title">Import Assesors</MDTypography>
                <MDButton
                  variant="outlined"
                  size="medium"
                  color="dark"
                  // onClick={() => setOpen(true)}
                  href="https://giglejiusers.s3.ap-south-1.amazonaws.com/import-templates/import-assessor.csv"
                >
                  Download Template
                </MDButton>
              </MDBox>
              <MDBox p={3} display="flex" alignItems="center">
                <input
                  type="file"
                  variant="outlined"
                  label="Enagager To GigTalent"
                  name="enagagerToGigler"
                  onChange={(e) => fileChange(e)}
                />
                <MDButton variant="outlined" size="medium" color="dark" onClick={readFile}>
                  Import
                </MDButton>
              </MDBox>
              {show && <DataTable table={details} canSearch />}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};
// export default ListContracts;

export default ImportAssessors;
