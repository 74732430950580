import { Avatar, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material'
import React, { useState } from 'react'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MDInput from 'components/MDInput';
import theme from 'assets/theme';

const Engagements = ({ engagementList, handleGiglerList, handleClick }) => {
  const [selected, setSelected] = useState();
  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <MDBox display="flex" p={2} sx={{ alignItems: "center", justifyContent: "space-between" }}>
          <MDTypography variant="h6">Engagements's List</MDTypography>
          <MDInput type="search" label="search"></MDInput>
        </MDBox>
        <MDBox>
          <List dense>
            {engagementList && engagementList.length > 0 && engagementList?.map((row, index) => (
              <ListItemButton
                key={index}
                id={index}
                sx={{ 
                  backgroundColor: index == selected ? theme.palette.grey[300] : '#ffffff',
                  '&:hover': {                  
                    backgroundColor: index == selected ? theme.palette.grey[300] :  '#ffffff',
                    "& $addIcon": {
                      color: "purple"
                    }
                  }
                }}
              >
                <ListItem
                  secondaryAction={
                    <IconButton >
                      <ArrowForwardIosIcon color="info" fontSize='small' sx={{ cursor: "pointer", mr: "20px" }} />
                    </IconButton>
                  }
                  divider={index == engagementList.length-1 ? false : true}
                  sx={{ p: "15px" }}
                  onClick={() => {
                    setSelected(index);
                    handleGiglerList(row);
                  }}
                >
                  <ListItemText
                    primary={
                      <MDTypography variant="h6">
                        {row.engagementId}
                      </MDTypography>}
                    // secondary={row.industry}
                    secondary={
                      <MDTypography variant="p">
                        {row.domain}{`--`}{row.industry}
                      </MDTypography>                      
                    }
                  />
                </ListItem>
              </ListItemButton>
            ))}
          </List>
        </MDBox>
      </Grid>
    </Grid >
  )
}

export default Engagements